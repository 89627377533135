import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { memo } from "react";
import TextField from "../../../Common/TextField";

const DepositRefundForm = memo(function DepositRefundForm(props) {
    const { classes, form, bankAccountNumberFormatter, readOnly } = props;
    return (
        <>
            {!readOnly && (
                <Grid
                    item
                    xs={12}
                    container
                    justify="center"
                    className={classes.textContainer}
                >
                    <Grid item xs={12}>
                        <Typography className={classes.title} align="center">
                            Kaució visszaigénylése
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.acceptanceText}
                            align="center"
                        >
                            A kauciómat nem kívánom felajánlani. Kérem, az
                            alábbiak szerinti visszautalását:
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        name="nameForDepositRefund"
                        label="Név"
                        value={form?.nameForDepositRefund}
                        error={form.errors?.nameForDepositRefund}
                        helperText={form.errors?.nameForDepositRefund}
                        onChange={(event) => {
                            props.changeForm(
                                {
                                    ...form,
                                    nameForDepositRefund: event.target.value,
                                },
                                "form"
                            );
                        }}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="bankAccountNumberForDepositRefund"
                        value={form.bankAccountNumberForDepositRefund || ""}
                        label="Bankszámlaszám"
                        format={{ xs: 12 }}
                        onChange={
                            !readOnly &&
                            bankAccountNumberFormatter((value) => {
                                props.changeForm(
                                    {
                                        ...form,
                                        bankAccountNumberForDepositRefund:
                                            value,
                                    },
                                    "form"
                                );
                            })
                        }
                        error={form.errors?.bankAccountNumberForDepositRefund}
                        helperText={
                            form.errors?.bankAccountNumberForDepositRefund
                        }
                        disabled={readOnly}
                    />
                </Grid>
            </Grid>
        </>
    );
});

export default DepositRefundForm;
