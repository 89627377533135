import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import hu from 'react-phone-input-2/lang/hu.json';
import { useField, useFormikContext } from "formik";
import { useTheme } from "@material-ui/core";
import "./FormPhone.css";
import {isKep} from "../../utils/AppConst";

export default function FormikPhoneInputField(props) {
    const { functionToChild, formToChild, formNameToChild, name, ...rest } = props;
    const { setFieldValue, initialErrors } = useFormikContext();
    const [field, meta] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const theme = useTheme();

    const handleChange = value => {
        functionToChild({ ...formToChild, [name]: value }, formNameToChild);
        setFieldValue(field.name, value);
    }

    const handleBlur = (e) => {
        delete initialErrors[field.name];
        field.onBlur(e);
    }

    return (
        <PhoneInput
            type="text"
            name={name}
            value={props.value}
            country={ isKep() ? 'ro' : 'hu' }
            format={{ xs: 12 }}
            containerStyle={{
                margin: '8px 0 4px',
            }}
            specialLabel={props.label || ''}
            inputStyle={{
                borderColor: errorText ? theme.palette.error.main : '#000',
                boxShadow: '0 0 0 0 #000',
                padding: '8.5px 14px 8.5px 58px',
                lineHeight: '23px',
                width: '100%',
                borderRadius: '0',
            }}
            masks={{ hu: '.. ... ....' }}
            localization={hu}
            onChange={functionToChild && handleChange}
            defaultErrorMessage={errorText}
            onBlur={handleBlur}
            helperText={errorText}
            error={!!errorText}
            {...rest}
        />
    )
}