import { types } from "./types";

const initialState = {
    filter: {
        key: null,
        hu: null,
        en: null
    }
}

export function translations(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name] 
            }
        default: 
            return {
                ...state
            }
    }
}