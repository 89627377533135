import { connect } from "react-redux";
import campusesActions from "../../../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../../../store/loading/actions";
import notificationActions from "../../../../../../store/notification/actions";
import ListPageTemplate from "../../../../../Common/ListPageTemplate";
import OperationButton from "../../../../../Common/OperationButton";
import { useEffect } from "react";
import buildingsActions from '../../../../../../store/masterData/buildings/actions';
import floorsActions from '../../../../../../store/masterData/floors/actions';
import roomsActions from '../../../../../../store/masterData/rooms/actions';
import TextField from '../../../../../Common/TextField';
import axios from "axios";
import { filterStorageGet, filterStorageSave } from "../../../../../../utils/FilterStorage";
import Select from "../../../../../Common/Select";

function Rooms(props) {
    const { filter, data, campus, building, floor, filterStorageKey } = props;
    const { campusId, buildingId, floorId } = props.match.params;

    const searchOtherData = { campusId: campusId, buildingId: buildingId, floorId: floorId };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.getBuilding(buildingId),
            props.getFloor(floorId),
            props.search(storageFilter, searchOtherData),
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Szobák",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors`]: "Emeletek",
            "floors": "Szobák"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({}, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }


    function BtnCellRenderer(params) {
        return (
            <>
                 <OperationButton
                    type="visibility"
                    to={`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms/view/${params.data.id}`}
                />
                <OperationButton
                    tooltiptitle='Tárgyak'
                    type="house"
                    to={`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms/${params.data.id}/items`}
                />
            </>
        );
    }

    function AssignableCellRenderer(params) {
        return params.value ? "Igen" : "Nem";
    }

    const table = {
        columnDefs: [
            { headerName: "Szoba", field: "name" },
            { headerName: "Férőhely", field: "space" },
            { headerName: "Szobagenerálás releváns", field: "assignable", cellRenderer: "assignableCellRenderer" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            assignableCellRenderer: AssignableCellRenderer
        },

    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Campus"
                name="campus.name"
                value={campus?.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Épület"
                name="building.name"
                value={building?.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Szint"
                name="floor.name"
                value={floor?.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Szobaszám"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Férőhely"
                name="space"
                value={filter.space || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <Select
                selectLabel="Szobagenerálás releváns"
                name="assignable"
                value={filter.assignable || []}
                optionList={[{ value: "1", label: "Igen" }, { value: "0", label: "Nem" }]}
                format={{ xs: 12, md: 6, lg: 3 }}
                multiple
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.rooms;
    const campus = state.campuses.form;
    const building = state.buildings.form;
    const floor = state.floors.form;
    return { filter, data, filterStorageKey, campus, building, floor };
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,
    getFloor: floorsActions.get,
    search: roomsActions.filter,
    changeForm: roomsActions.changeForm,
    defaultForm: roomsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Rooms);