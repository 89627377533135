export const types = {
    CHANGE_FORM: "@@termination-student-declaration/CHANGE_FORM",
    DEFAULT_FORM: "@@termination-student-declaration/DEFAULT_FORM",

    GET_REQUEST: "@@termination-student-declaration/GET_REQUEST",
    GET_SUCCESS: "@@termination-student-declaration/GET_SUCCESS",
    GET_FAILURE: "@@termination-student-declaration/GET_FAILURE",

    FILL_STUDENT_DECLARATION_REQUEST:
        "@@termination-student-declaration/FILL_STUDENT_DECLARATION_REQUEST",
    FILL_STUDENT_DECLARATION_SUCCESS:
        "@@termination-student-declaration/FILL_STUDENT_DECLARATION_SUCCESS",
    FILL_STUDENT_DECLARATION_FAILURE:
        "@@termination-student-declaration/FILL_STUDENT_DECLARATION_FAILURE",

    GET_FORM_REQUEST: "@@termination-student-declaration/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@termination-student-declaration/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@termination-student-declaration/GET_FORM_FAILURE",
};
