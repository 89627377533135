export const types = {
    CHANGE_FORM: "@@relocation-multiple/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation-multiple/DEFAULT_FORM",
    CHANGE_EDITOR: "@@relocation-multiple/CHANGE_EDITOR",

    START_REQUEST: "@@relocation-multiple/START_REQUEST",
    START_SUCCESS: "@@relocation-multiple/START_SUCCESS",
    START_FAILURE: "@@relocation-multiple/START_FAILURE",

    GET_RELOCATABLE_STUDENTS_REQUEST: "@@relocation-multiple/GET_RELOCATABLE_STUDENTS_REQUEST",
    GET_RELOCATABLE_STUDENTS_SUCCESS: "@@relocation-multiple/GET_RELOCATABLE_STUDENTS_SUCCESS",
    GET_RELOCATABLE_STUDENTS_FAILURE: "@@relocation-multiple/GET_RELOCATABLE_STUDENTS_FAILURE",
}