import FooterImg from "../../images/footer.png";
import { Children, PureComponent } from "react";
import Logo from "../../images/MCClogo.jpg";
import { fieldsForPrint, fieldsForPrintAus } from "../../utils/fieldsForPrint";

export class Header extends PureComponent {
    render() {
        return (
            <div className="header">
                <img src={Logo} width="100%" alt="MCC Logo"/>
            </div>
        );
    }
}

const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
};

export class Content extends PureComponent {
    render() {
        const { form } = this.props;
        const printFields = fieldsForPrint(form);
        const printChunks = chunkArray(printFields, 22);

        const renderTable = (fields, isAfterHeader) => (
            <table className={isAfterHeader ? 'after-header' : ''}>
                <tbody>
                    {Children.toArray(
                        fields.map((field, i) => (
                            <tr key={i}>
                                <td>{field.label}:</td>
                                <td>{field.value}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        );

        return (
            <div>
                <h1 className="title after-header">Beiratkozási lap</h1>
                {printChunks.map((chunk, index) => (
                    <div key={index}>
                        {renderTable(chunk, index > 0)}
                        {index !== printChunks.length - 1 && (
                            <span className="page-break">&nbsp;</span>
                        )}
                        {index === printChunks.length - 1 && (
                            <span className="last-page-break">&nbsp;</span>
                        )}
                    </div>
                ))}
                <table className="after-header">
                    <tbody>
                        {Children.toArray(
                            fieldsForPrintAus(form).map(
                                (field, i) => (
                                    <tr key={i}>
                                        <td>{field.label}:</td>
                                        <td>{field.value}</td>
                                    </tr>
                                )
                            )
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export class SignatureContent extends PureComponent {
    render() {
        const { form } = this.props;

        return (
            <div className="after-table">
                <p style={{ margin: '20px 40px 20px 40px', textAlign: 'justify' }}>
                    Kijelentem, hogy az MCC házirendjét, oktatási
                    szabályzatát és tűzvédelmi szabályzatát
                    megismertem, és a bennük foglalt rendelkezéseket
                    betartom, azokat magamra nézve kötelezőnek
                    fogadom el. Az MCC adatkezelési tájékoztatóját
                    megismertem. Aláírásommal igazolom, hogy a fent
                    megadott adataim helyesek.
                </p>
                <p>
                    {form.city},{" "}
                    {new Date().toLocaleDateString("hu-HU")}
                </p>
                <span
                    style={{
                        borderTop: "2px dotted black",
                        padding: "0 64px",
                        float: 'right',
                        marginRight: '60px'
                    }}
                >
                    aláírás
                </span>
            </div>
        );
    }
}

export class Footer extends PureComponent {
    render() {
        return (
            <div className="footer">
                <img src={FooterImg} width="100%" alt="Footer Image"/>
            </div>
        );
    }
}

export default class FormToPrint extends PureComponent {
    render() {
        const { form } = this.props;
        const printStyles = `
            .title {
                position: relative;
                text-align: center;
            }

            .after-header {
                margin-top: 100px;
            }

            .after-table {
                margin-top: 100%;
            }
            
            .last-page-break {
                display: block;
                page-break-after: always;
            }

            p {
                margin: 20px 40px 20px 40px;
            }

            th, td {
                padding: 5px;
                text-align: left;
                border: 1px solid #ddd;
            }

            @media print {
                .header {
                    position: fixed;
                    width: 20%;
                    text-align: center;
                    padding: 10px;
                    left: 50%;
                    top: 50px;
                    transform: translate(-50%, -50%);
                }

                .page-break {
                    display: block;
                    page-break-before: always;
                }

                table {
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                    border: 1px solid #ddd;
                }

                .footer {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background-color: #f0f0f0;
                    padding: 10px;
                    text-align: center;
                }
            }
        `;

        return (
            <div>
                <style>{printStyles}</style>
                <Header />
                <Content form={form} />
                <SignatureContent form={form} />
                <Footer />
            </div>
        );
    }
}