import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import hu from 'react-phone-input-2/lang/hu.json';
import FormikPhoneInputField from "./FormikPhoneInputField";
import {isKep} from "../../utils/AppConst";

export default function PhoneInputField(props) {
    const { functionToChild, formToChild, formNameToChild, ...rest } = props;

    const handleChange = (value) => {
    functionToChild({ ...formToChild, [props.name]: value }, formNameToChild);
};

    if (props.isFormikForm !== undefined && props.isFormikForm === true) {
        return <FormikPhoneInputField {...props} />
    }


    return (
        <PhoneInput
            name={props.name}
            value={props.value}
            country={ isKep() ? 'ro' : 'hu' }
            format={{ xs: 12 }}
            helperText={props.helperText}
            containerStyle={{
                margin: '8px 0 4px',
            }}
            specialLabel={props.label || ''}
            inputStyle={{
                boxShadow: '0 0 0 0 #000',
                padding: '8.5px 14px 8.5px 58px',
                lineHeight: '23px',
                width: '100%',
                borderRadius: '0',
                border: '1px solid black',
            }}
            masks={{ hu: '.. ... ....' }}
            localization={hu}
            onChange={functionToChild && handleChange}
            {...rest}
        />
    )
}