import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import campusesActions from "../../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../../store/loading/actions";
import notificationActions from "../../../../../store/notification/actions";
import Accordion from "../../../../Common/Accordion";
import PageTemplate from "../../../../Common/PageTemplate";
import TextField from "../../../../Common/TextField";
import Form from "../../../../Common/Form";
import CancelButton from "../../../../Common/CancelButton";
import { history } from "../../../../../store";
import { useEffect } from "react";
import buildingsActions from '../../../../../store/masterData/buildings/actions';
import floorsActions from '../../../../../store/masterData/floors/actions';
import axios from "axios";
import * as yup from 'yup';

function FloorsForm(props) {
    const { form, campusId, buildingId, id, campusForm, buildingForm } = props;
    const title = "Szint megtekintése";


    const URL = `/campuses/${campusId}/buildings/${buildingId}/floors`;

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors`]: "Szintek",
            "floorsView": title,
            "name": form?.name ?? ''
        }
    }

    useEffect(() => {
        props.loadingOn();

        axios.all([
            props.get(id),
            props.getCampus(campusId),
            props.getBuilding(buildingId)
        ]).finally(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, building: buildingForm['@id'] }, "form")
    }, [buildingForm['@id'], form?.building]) //TODO: ez nem tuti, hogy jó így

    const validationSchema = yup.object().shape({
        name: yup.string().required("Kötelező megadni")
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        initialerrors: form['errors'] || {},
        initialvalues: form,
        validtionschema: validationSchema,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
        isformikform: true
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            label="Campus"
                            name="campus"
                            value={campusForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Épület"
                            name="building"
                            value={buildingForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Név"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <Grid
                            container
                            format={{ xs: 12 }}
                            spacing={1}
                        >
                            <Grid item>
                                <CancelButton onClick={() => history.push(URL)}>
                                    Vissza
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.floors;
    const campusForm = state.campuses.form;
    const buildingForm = state.buildings.form;
    return { form, campusForm, buildingForm };
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,

    get: floorsActions.get,
    changeForm: floorsActions.changeForm,
    defaultForm: floorsActions.defaultForm,
    create: floorsActions.create,
    update: floorsActions.update,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(FloorsForm);