import * as yup from "yup";
import API from "../../../../utils/API";

const validationSchema = yup.object().shape({
    firstName: yup
        .string()
        .max(128, "Túl hosszú")
        .required("Kötelező megadni"),
});

export function downloadFile(data, filename) {
    const blob = new Blob([data], { type: 'application/octet-stream'  });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export function getCentralFields() {
    return API.get(`/enrollments/disabled-central-fields`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export {
    validationSchema,
}