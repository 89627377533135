import API from '../../../utils/API';
import multiPartAPI from '../../../utils/multiPartAPI';
import downloadAPI from '../../../utils/downloadAPI';
import editApi from '../../../utils/editApi';
import SearchForm from '../../../utils/SearchForm';
import { templateDelete, templateGet } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/campuses/${otherData.campusId}/enrollment-documents`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function create(form, selectedFile) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(form)) {
        if (typeof value !== "object") {
            formData.append(`${key}`, value)
        }
    }

    formData.append("campus", form.campus['@id']);
    formData.append("file", selectedFile[0]);

    return multiPartAPI.post('/enrollment-documents', formData)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function update(id, form) {
    let formToSend = Object.assign({}, form);

    if (form.campus instanceof Object) {
        formToSend.campus = form.campus["@id"];
    }

    return editApi.patch(`/enrollment-documents/${id}`, formToSend)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function download(id) {
    return downloadAPI.get(`/enrollment-documents/${id}/download`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const documentsService = {
    filter,
    create,
    update,
    download,
    get: templateGet('enrollment-documents'),
    delete: templateDelete('enrollment-documents')
};

export default documentsService;