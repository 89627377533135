export const types = {
    CHANGE_FORM: "@@room-approval/CHANGE_FORM",
    DEFAULT_FORM: "@@room-approval/DEFAULT_FORM",

    ACCEPT_REQUEST: "@@room-approval/ACCEPT_REQUEST",
    ACCEPT_SUCCESS: "@@room-approval/ACCEPT_SUCCESS",
    ACCEPT_FAILURE: "@@room-approval/ACCEPT_FAILURE",

    ACCEPT_CLOSE_REQUEST: "@@room-approval/ACCEPT_CLOSE_REQUEST",
    ACCEPT_CLOSE_SUCCESS: "@@room-approval/ACCEPT_CLOSE_SUCCESS",
    ACCEPT_CLOSE_FAILURE: "@@room-approval/ACCEPT_CLOSE_FAILURE",
}