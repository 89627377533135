import { Grid } from "@material-ui/core";
import PageTemplate from "../../Common/PageTemplate";
import NavMenuItem from "../../Common/NavMenuItem";
import Accordion from "../../Common/Accordion";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import semestersActions from "./../../../store/masterData/semesters/actions";
import { useEffect, useState } from "react";
import Loading from "../../Common/Loading";
import axios from "axios";

/**
 * Ezen az oldalon tudja kiválasztani a felhasználó az átköltözési folyamat módját
 * (egyedi/tömeges)
 *
 * @param {*} props
 */
function SelectRelocationTypePage(props) {
    const {
        activeSemester,
        activeSemesterForRelocation,
    } = props;

    const [loading, setLoading] = useState(false);

    const header = {
        title: "Átköltözési folyamat indítása",
        breadcrumbs: {
            processes: "Folyamatok",
            "/new-process": "Új folyamat",
            selectRelocation: "Átköltözési folyamat indítása",
        },
    };

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                props.getActiveSemester(),
                props.getActiveSemesterForRelocation(),
            ])
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Loading />;
    }

    /**
     * TODO: ide, meg az eredeti oldalra (új folyamat) egy új féle ellenőrzés kellene:
     * -> ahol van szobabeosztás (BP) -> ott rá lehet kattolni, ha aktív a szemeszter vagy szobabeosztási időszak van
     * -> ahol nincs szobabeosztás (BP) -> ott rá lehet kattolni, ha aktív a szemeszter
     *
     * -> a választónál:
     *  -> ha budapest -> csak a tömeges lehet ha szobabeosztás van, emigy csak egyedi
     * -> ha nem budapest -> csak az egyedi elérhető
     */

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="Átköltözési folyamat típusa" expanded>
                    <NavMenuItem
                        to={`/new-process/relocation/single`}
                        icon={faUser}
                        text="Egyedi"
                        disabled={(!activeSemester || activeSemesterForRelocation)}
                    />
                    <NavMenuItem
                        to={`/new-process/relocation/multiple`}
                        icon={faUsers}
                        text="Tömeges"
                        disabled={!activeSemesterForRelocation}
                    />
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}


function mapState(state) {
    const activeSemesterForRelocation =
        state.semesters.activeSemesterForRelocation;
    const activeSemester = state.semesters.activeSemester;
    return {
        activeSemester,
        activeSemesterForRelocation,
    };
}

const actionCreators = {
    getActiveSemester:
        semestersActions.getActiveSemester,
    getActiveSemesterForRelocation:
        semestersActions.getActiveSemesterForRelocation,
};

export default connect(mapState, actionCreators)(SelectRelocationTypePage);
