import { makeIdOptions } from "./makeIdOptions";

export const universityOptions = (universities) => {
    const options = makeIdOptions(universities);
    const index = options.findIndex(
        (option) => option.label === "Külföldi képzés"
    );
    if (index === -1) {
        return options;
    }
    const foreignUniversity = options.splice(index, 1);
    return [...options, ...foreignUniversity];
};
