import {connect} from "react-redux";
import changePasswordActions from "../../store/layout/changePassword/actions";
import Dialog from '@material-ui/core/Dialog';
import Form from "../Common/Form";
import TextField from "../Common/TextField";
import SubmitButton from "../Common/SubmitButton";
import notificationActions from "../../store/notification/actions";
import React, {useEffect, useState} from "react";
import {DialogActions, DialogContent, DialogTitle, makeStyles, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import API from "../../utils/API";
import loadingActions from "../../store/loading/actions";
import CancelButton from "../Common/CancelButton";
import colors from "../../styles/colors";


const defaultErrors = {
    password: null,
    oldPassword: null,
    confirmPassword: null
}

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
}));

function ChangePassword(props) {
    const {form, snackbar} = props;
    const [errors, setErrors] = useState(defaultErrors);
    const {open, setOpen} = props;
    const classes = useStyles();

    useEffect(() => {
        if (!open) {
            props.defaultForm();
        }
    }, [open])

    function handleSubmit() {
        setErrors(defaultErrors);
        props.loadingOn();
        API.post(`${process.env.REACT_APP_API_URL}/change-password`, form).then(function () {
            props.defaultForm();
            props.addNotification('success', 'Az új jelszó sikeresen elmentésre került.');
            setOpen(false);
        }).catch(error => {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.errorMessage) {
                    props.addNotification('error', error.response.data.errorMessage);
                } else {
                    setErrors({...defaultErrors, ...error.response.data})
                }
            } else {
                props.addNotification('error',
                    'Váratlan hiba kérjük probálja meg később!');
            }
        }).finally(() => props.loadingOff());
    }

    return (
        <Dialog open={open} onClose={()=>setOpen(false)} >
            <DialogTitle className={classes.title}>Jelszóváltoztatás</DialogTitle>
            <DialogContent>
                <Form
                    form={form}
                    changeform={props.changeForm}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        name="oldPassword"
                        type="password"
                        label="Jelenlegi jelszó"
                        value={form.oldPassword || ""}
                        error={errors.oldPassword !== null}
                        helperText={errors.oldPassword}
                        format={{xs: 12}}
                    />
                    <TextField
                        name="password"
                        type="password"
                        label="Új jelszó"
                        value={form.password || ""}
                        error={errors.password !== null}
                        helperText={errors.password}
                        format={{xs: 12}}
                    />
                    <TextField
                        name="confirmPassword"
                        type="password"
                        label="Új jelszó megint"
                        value={form.confirmPassword || ""}
                        error={errors.confirmPassword !== null}
                        helperText={errors.confirmPassword}
                        format={{xs: 12}}
                    />
                    {/* material ui display none removes the button form the dom */}
                    <input style={{display: "none"}} type="submit" onClick={handleSubmit}/>

                </Form>
            </DialogContent>

            <DialogActions>
                <SubmitButton onClick={handleSubmit}>
                    Mentés
                </SubmitButton>
                <CancelButton onClick={() => setOpen(false)}>
                    {props.cancelText || "Bezár"}
                </CancelButton>
            </DialogActions>
            {
                snackbar.type &&
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={snackbar.type !== ''}
                    autoHideDuration={4000}
                    onClose={() => props.removeNotification()}
                >
                    <Alert severity={snackbar.type}>
                        {snackbar.msg}
                    </Alert>
                </Snackbar>
            }
        </Dialog>
    );
}

function mapState(state) {
    const {form} = state.changePassword;
    const {snackbar} = state.notification;
    return {form, snackbar};
}

const actionCreators = {
    changeForm: changePasswordActions.changeForm,
    defaultForm: changePasswordActions.defaultForm,
    addNotification: notificationActions.addNotification,
    removeNotification: notificationActions.removeNotification,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(ChangePassword);
