import Form from "./Form";
import Select from "./Select";
import { makeOptionsFromID } from "../../utils/listOptions/makeOptionsFromID";
import trainingLevelsActions from "../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../store/masterData/trainingPrograms/actions";
import collegeYearsActions from "../../store/masterData/collegeYears/actions";
import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import axios from "axios";
import loadingActions from "../../store/loading/actions";
import { makeCollegeYearOptions, makeTrainingProgramOptions } from "../MasterData/Students/options";

function Training(props) {
    const { formProps, trainingLevels, trainingPrograms, collegeYears } = props;
    const { form, changeform } = formProps;
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        axios.all([
            props.fetchTrainingLevels(),
            props.fetchTrainingPrograms(),
            props.fetchCollegeYears(),
        ]).then(() => setFirstLoad(false))
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            changeform({...form, trainingProgram: undefined}, "form")
        }
    }, [form.trainingLevel])

    useEffect(() => {
        if (!firstLoad) {
            changeform({...form, collegeYear: undefined}, "form")
        }
    }, [form.trainingProgram])

    return (
        <Form
            childComponent="true"
            {...formProps}
        >
            <Select
                selectLabel="Képzési szint"
                name="trainingLevel"
                value={(form.trainingLevel?.id ?? form.trainingLevel) || ""}
                optionList={makeOptionsFromID(trainingLevels) || []}
                format={{ xs: 12 }}
                loading={firstLoad}
            />
            <Select
                selectLabel="Képzési program"
                name="trainingProgram"
                value={(form.trainingProgram?.id ?? form.trainingProgram) || ""}
                optionList={makeTrainingProgramOptions(trainingPrograms["hydra:member"], form?.trainingLevel?.id ?? form.trainingLevel)}
                loading={firstLoad}
                format={{xs: 12}}
            />
            <Select
                selectLabel="Évfolyam"
                name="collegeYear"
                value={(form.collegeYear?.id ?? form.collegeYear) || ""}
                optionList={makeCollegeYearOptions(collegeYears["hydra:member"], form.trainingProgram?.id ?? form.trainingProgram)}
                loading={firstLoad}
                format={{ xs: 12 }}
            />
        </Form>
    );
}

function mapState(state) {
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    return { trainingLevels, trainingPrograms, collegeYears };
}

const actionCreators = {
    fetchTrainingLevels: () => trainingLevelsActions.filter({ archive: false }),
    fetchTrainingPrograms: () => trainingProgramsActions.filterTrainingProgramsForSelect({ archive: false }),
    fetchCollegeYears: () => collegeYearsActions.filter({ archive: false }),
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Training);