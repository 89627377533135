import { types } from "./types";

const initialState = {
    filter: {},
    form: {}
}

export function operationalControl(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name] 
            }
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                form: action.data.data
            };
        case types.GET_FAILURE:
            return {
                ...state,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default: 
            return {
                ...state
            }
    }
}