import Grid from "@material-ui/core/Grid";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import { useState } from "react";
import foreignUniversitiesService from "../../../../services/masterData/foreignUniversities";
import colors from "../../../../styles/colors";
import TextField from "../../../Common/TextField";

export default function ForeignUniversitiesAutocomplete(props) {
    const {
        errorMessage,
        value,
        onChange,
    } = props;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const n = 3;

    const handleInputChange = (_, value) => {
        setInputValue(value);
        setLoading(true);
        if (value.length >= n) {
            foreignUniversitiesService
                .filter({ name: value })
                .then((response) => {
                    const optionsTmp = response.data?.["hydra:member"]?.map(
                        (item) => item?.name
                    );
                    setOptions(optionsTmp);
                })
                .finally(() => setLoading(false));
        }
    };

    const debouncedHandleInputChange = debounce(handleInputChange, 500);

    return (
        <Grid item xs={12}>
            <Autocomplete
                onInputChange={(_, value) => {
                    debouncedHandleInputChange(_, value);
                    onChange(_, value);
                }}
                onClose={() => setOptions([])}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Külföldi egyetem neve"
                        placeholder={
                            "Írjon be " + n + " karaktert a kereséshez"
                        }
                        size="small"
                        helperText={errorMessage}
                        error={!!errorMessage}
                    />
                )}
                options={options}
                noOptionsText={
                    loading
                        ? "Betöltés..."
                        : inputValue.length >= n
                        ? "Nincs találat"
                        : "Írjon be " + n + " karaktert a kereséshez"
                }
                ChipProps={{
                    sx: {
                        backgroundColor: colors.primaryBlue,
                        color: colors.primaryYellow,
                        "& .MuiChip-deleteIcon": {
                            color: colors.primaryYellown,
                        },
                    },
                }}
                value={value}
                filterSelectedOptions
                freeSolo
                openOnFocus
                {...props}
            />
        </Grid>
    );
}
