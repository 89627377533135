import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import systemLogActions from "../../../store/administration/systemLog/actions";
import usersActions from "../../../store/masterData/users/actions";
import ActorCellRenderer from "../../../utils/cellRenderer/SystemLog/ActorCellRenderer";
import EventCellRenderer from "../../../utils/cellRenderer/SystemLog/EventCellRenderer";
import TypeCellRenderer from "../../../utils/cellRenderer/SystemLog/TypeCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import {
    filterStorageGet,
    filterStorageSave,
} from "../../../utils/FilterStorage";
import { makeUserOptions } from "../../../utils/listOptions/makeUserOptions";
import DatePicker from "../../Common/DatePicker";
import ListPageTemplate from "../../Common/ListPageTemplate";
import Loading from "../../Common/Loading";
import Select from "../../Common/Select";
import Title from "../../Common/Title";
import BtnCellRenderer from "./BtnCellRenderer";
import { formatDate } from "../../../utils/timezoneConverter";
import { useTenant } from "../../../utils/TenantContext";

const header = {
    title: "Rendszernapló",
    breadcrumbs: {
        administration: "Adminisztráció",
        "system-log": "Rendszernapló",
    },
};

function SystemLog(props) {
    const { filter, data, users, types, events, filterStorageKey, tableInfo } =
        props;
    const [loading, setLoading] = useState(false);
    const tenant = useTenant();
    const eventOptions = Object.keys(events).map((key) => {
        return {
            value: key,
            label: events[key].title,
        };
    });

    const typeOptions = Object.keys(types).map((key) => {
        return {
            value: key,
            label: types[key].title,
        };
    });

    useEffect(() => {
        setLoading(true);
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        axios
            .all([
                setInfo(
                    { ...props, search: props.search },
                    "order",
                    { createdAt: "desc" },
                    "filter",
                    filter
                ),
                props.fetchUsers({}),
                props.getTypesAndEvents(),
            ])
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Loading />;
    }

    const filterForm = {
        submitaction: () => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter, "order", { createdAt: "desc" });
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            setInfo(props, "filter", {}, "order", { createdAt: "desc" });
        },
        form: filter,
        changeform: props.changeForm,
    };

    function DateCellRenderer(params) {
        return formatDate(params.value, tenant.timezone.offset);
    }
    const table = {
        columnDefs: [
            {
                headerName: "Időpont",
                field: "createdAt",
                sort: "desc",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: "Felhasználó",
                field: "actor.fullName",
                cellRenderer: "actorCellRenderer",
            },
            {
                headerName: "Esemény",
                field: "action",
                cellRenderer: "eventCellRenderer",
            },
            {
                headerName: "Típus",
                field: "type",
                cellRenderer: "typeCellRenderer",
            },
            {
                headerName: "Változások",
                field: "changes",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data,
        frameworkComponents: {
            actorCellRenderer: ActorCellRenderer,
            btnCellRenderer: (params) => BtnCellRenderer(params, types, events),
            eventCellRenderer: (params) => EventCellRenderer(params, events),
            typeCellRenderer: (params) => TypeCellRenderer(params, types),
            dateCellRenderer: DateCellRenderer
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false,
    };

    return (
        <ListPageTemplate filter={filterForm} header={header} table={table}>
            <Select
                selectLabel="Felhasználó"
                name="user"
                value={filter.user || []}
                optionList={makeUserOptions(users) || []}
                format={{ xs: 12, md: 6 }}
                multiple
            />
            <Select
                selectLabel="Esemény"
                name="action"
                value={filter.action || []}
                optionList={eventOptions || []}
                format={{ xs: 12, md: 6 }}
                multiple
            />
            <Select
                selectLabel="Típus"
                name="type"
                value={filter.type || []}
                optionList={typeOptions || []}
                format={{ xs: 12, md: 6 }}
                multiple
            />
            <Title title="Időpont" {...filterForm} format={{ xs: 12 }} />
            <DatePicker
                label="-tól"
                name="createdAtFrom"
                value={filter.createdAtFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <DatePicker
                label="-ig"
                name="createdAtTo"
                value={filter.createdAtTo || ""}
                format={{ xs: 12, md: 6 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, types, events, filterStorageKey, tableInfo } =
        state.systemLog;
    const users = state.users.data;
    return { filter, data, users, types, events, filterStorageKey, tableInfo };
}

const actionCreators = {
    changeForm: systemLogActions.changeForm,
    defaultForm: systemLogActions.defaultForm,
    getTypesAndEvents: systemLogActions.getTypesAndEvents,
    setInfo: systemLogActions.setInfo,
    search: systemLogActions.filter,
    fetchUsers: usersActions.filter,
};

export default connect(mapState, actionCreators)(SystemLog);
