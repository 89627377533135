import { connect } from "react-redux";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import roomAllocationListActions from "../../../store/processes/roomAllocationList/actions";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../store/masterData/trainingPrograms/actions";
import collegeYearsActions from "../../../store/masterData/collegeYears/actions";
import universitiesActions from "../../../store/masterData/universities/actions";
import buildingsActions from "../../../store/masterData/buildings/actions";
import floorsActions from "../../../store/masterData/floors/actions";
import roomsActions from "../../../store/masterData/rooms/actions";
import notificationActions from "../../../store/notification/actions";
import { useEffect, useState } from "react";
import Title from "../../Common/Title";
import Select from "../../Common/Select";
import SubmitButton from "../../Common/SubmitButton";
import axios from "axios";
import {
    makeBuildingOptions,
    makeFloorOptions,
    makeRoomOptions,
    makeOptionsFromID,
} from "./options";
import {
    ASSIGN_STUDENTS_STATUSES,
    MEMBERSHIP_STATUS_OPTIONS,
} from "../../../utils/AppConst";
import {
    filterStorageGet,
    filterStorageSave,
} from "../../../utils/FilterStorage";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import colors from "../../../styles/colors";
import Dialog from "../../Common/Dialog";
import BtnCellRenderer from "./UnassignedStudents/BtnCellRenderer";
import createRowDataForUnassignedTable from "./UnassignedStudents/createRowDataForUnassignedTable";
import Loading from "../../Common/Loading";
import {
    makeOptionsFromIDWithArchive,
    makeTrainingProgramOptionsFromIDWithArchive,
} from "../../../utils/listOptions/makeOptionsFromID";
import { renderArchiveOption } from "../../MasterData/Students/options";

const useStyles = makeStyles(() => ({
    danger: {
        backgroundColor: colors.error,
        "&:hover": {
            backgroundColor: colors.errorHover,
        },
    },
    info: {
        backgroundColor: colors.primaryBlue,
        "&:hover": {
            backgroundColor: colors.primaryBlueHover,
        },
    },
    newButton: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    typography: {
        margin: "4px",
    },
}));

function AssignmentAttentionText({ classes, relocationExistsForAllocation }) {
    return (
        <>
            <Typography
                align="center"
                variant="h6"
                className={classes.typography}
            >
                <b> Figyelem! </b>
            </Typography>
            {relocationExistsForAllocation ? (
                <Typography className={classes.typography}>
                A művelet elvégzéséhez le kell zárni minden aktív átköltözési folyamatot a szemeszterre!
                </Typography>
            ) : (
                <>
                    <Typography className={classes.typography}>
                        A szobabeosztás újragenerelásakor a már meglévő beosztás{" "}
                        <b>ürítésre</b> kerül és egy <b>új</b> szobabeosztást
                        generál a rendszer.
                        <br />
                    </Typography>
                    <Typography className={classes.typography}>
                        Amennyiben szeretne <b>új</b> szobabeosztást generálni,
                        kattintson a Generálás gombra.
                    </Typography>
                </>
            )}
        </>
    );
}

const ResetAssignmentAttionText = ({
    classes,
    relocationExistsForAllocation,
}) => {
    return (
        <>
            {relocationExistsForAllocation ? (
                <>
                    <Typography
                        align="center"
                        variant="h6"
                        className={classes.typography}
                    >
                        <b> Figyelem! </b>
                    </Typography>
                    <Typography className={classes.typography}>
                        A művelet elvégzéséhez le kell zárni minden aktív átköltözési folyamatot a szemeszterre!
                    </Typography>
                </>
            ) : (
                "Biztosan üríti a generált szobabeosztást?"
            )}
        </>
    );
};

/**
 * A szobabeosztási lista komponens
 * - customTable-t használ ag-grid helyett
 *
 * @param {*} props
 * @returns
 */
function RoomAllocationList(props) {
    const {
        filter,
        data,
        filterStorageKey,
        unassignedStudents,
        allocation,
        relocationExistsForAllocation,
        trainingLevels,
        trainingPrograms,
        collegeYears,
        universities,
        buildings,
        floors,
        roomsForFilter,
    } = props;
    const id = props.match?.params?.id;
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const classes = useStyles();

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setLoading(true);
        axios
            .all([
                props.fetchTrainingLevels(),
                props.fetchTrainingPrograms(),
                props.fetchCollegeYears(),
                props.fetchUniversities(),
                props.fetchBuildings(),
                props.fetchFloors(),
                props.getAssignableRooms(),
                props.getAssignableRoomsForFilter(id),
                props.getAllocation(id),
                props.filterStudentAllocations(id, storageFilter),
                props.getFreeRooms(id, storageFilter),
                props.filterUnassignedStudents(id, storageFilter),
                props.checkIfRelocationExistsForAllocation(id),
                props.checkIfTerminationExistsForAllocation(id),
            ])
            .finally(() => setLoading(false));
    }, []);

    const customTable = {
        title: "Szobák",
        columnDefs: [
            { headerName: "Épület", field: "building" },
            { headerName: "Szint", field: "floor" },
            { headerName: "Szoba", field: "room" },
            { headerName: "Férőhely", field: "space" },
        ],
        rowData: data,
        allocationId: id,
    };

    const unassignedStudentsTable = {
        title: "Be nem osztott hallgatók",
        columnDefs: [
            { headerName: "E-mail", field: "email" },
            { headerName: "Név", field: "fullName", minWidth: 280 },
            { headerName: "Képzési szint", field: "trainingLevel" },
            { headerName: "Képzési program", field: "trainingProgram" },
            { headerName: "Évfolyam", field: "collegeYear" },
            {
                headerName: "Aktuális státusz",
                field: "currentMembershipStatus",
            },
            { headerName: "Egyetem", field: "university" },
            { headerName: "Szak", field: "universityMajor" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: (params) => BtnCellRenderer(params, id),
        },
        rowData: createRowDataForUnassignedTable(unassignedStudents),
        allocationId: id,
        minimized: true,
    };

    const header = {
        title: "Szobabeosztás (szoba)",
        breadcrumbs: {
            reports: "Riportok",
            "/room-allocation-list": "Szobabeosztás (szoba)",
            semester: allocation?.semester?.name,
        },
    };

    const filterData = (filter) => {
        setLoading(true);
        filterStorageSave(filterStorageKey, filter);
        axios
            .all([
                props.getAllocation(id),
                props.getFreeRooms(id, filter),
                props.filterStudentAllocations(id, filter),
                props.filterUnassignedStudents(id, filter),
                props.getAssignableRoomsForFilter(id),
            ])
            .finally(() => setLoading(false));
    };

    const assignAllStudents = () => {
        if (relocationExistsForAllocation) {
            props.addNotification(
                "error",
                "A művelet elvégzéséhez le kell zárni minden aktív átköltözési folyamatot a szemeszterre!"
            );
        } else {
            props.addNotification("info", "Generálás folyamatban...");
            props
                .assignAllStudents(id)
                .then((response) => sendAssignmentStatus(response));
        }
    };

    const assignNewStudents = () => {
        props.addNotification("info", "Új hallgatók beosztása folyamatban...");
        props
            .assignNewStudents(id)
            .then((response) => sendAssignmentStatus(response));
    };

    const handleResetStudentAssignment = () => {
        if (relocationExistsForAllocation) {
            props.addNotification(
                "error",
                "A művelet elvégzéséhez le kell zárni minden aktív átköltözési folyamatot a szemeszterre!"
            );
        } else {
            props.addNotification("info", "Ürítés folyamatban...");
            props
                .resetStudentAssignment(id)
                .then(() => {
                    props.addNotification("success", "Sikeres ürítés!");
                    filterData(filter);
                })
                .catch(() => {
                    props.addNotification(
                        "error",
                        "Hiba lépett fel ürítés közben!"
                    );
                });
        }
        
    };

    const sendAssignmentStatus = (response, sessionId = null) => {
        if (sessionId === null) {
            sessionId = response.data?.sessionId;
        }
        props
            .checkAssignmentStatus(id, sessionId)
            .then((res) => handleCheckAssignmentStatus(res.data, sessionId));
    };

    const handleCheckAssignmentStatus = (response, sessionId) => {
        switch (response) {
            case ASSIGN_STUDENTS_STATUSES.ACTION_ASSIGN_STUDENTS_DONE:
                props.addNotification("success", "Sikeres generálás!");
                filterData(filter);
                break;
            case ASSIGN_STUDENTS_STATUSES.ACTION_ASSIGN_STUDENTS_ERROR:
                props.addNotification(
                    "error",
                    "Hiba lépett fel generálás közben!"
                );
                break;
            case ASSIGN_STUDENTS_STATUSES.ACTION_ASSIGN_STUDENTS_STARTED:
                sendAssignmentStatus(response, sessionId);
                break;
            default:
                break;
        }
    };

    //TODO: ezt kivenni külön komponensbe
    const filterButtons = () => {
        function renderText() {
            if (relocationExistsForAllocation) {
                if (allocation?.studentAllocationsGenerated === true) {
                    return (
                        <AssignmentAttentionText
                            classes={classes}
                            relocationExistsForAllocation={
                                relocationExistsForAllocation
                            }
                        />
                    );
                } else {
                    return "Biztosan generál szobabeosztást?";
                }
            } else {
                return (
                    <>
                        <Typography
                            align="center"
                            variant="h6"
                            className={classes.typography}
                        >
                            <b> Figyelem! </b>
                        </Typography>
                        <Typography className={classes.typography}>
                            A szobagenerálás megkezdése előtt ellenőrizze, 
                            hogy minden kiiratkozó hallgatónak készült kiiratkozási folyamat. 
                            Amennyiben ez elmaradt egy kiiratkozó hallgatónál, 
                            abban az esetben bekerülhet a generált szobabeosztásba olyan hallgató is, 
                            aki nem fogja folytatni tanulmányait. <br/>
                            Biztosan elindítja a generálást?
                        </Typography>
                    </>
                );
            }
        }

        return (
            <>
                {allocation?.studentAllocationsGenerated === true ? (
                    <>
                        <Grid
                            item
                            xs={false}
                            sm={false}
                            md={false}
                            lg={3}
                            xl={6}
                        ></Grid>
                        <Grid item xs={6} sm={5} md={3} lg={2} xl={1}>
                            <Dialog
                                title="Szobabeosztás ürítése"
                                opener={
                                    <SubmitButton
                                        className={classes.info}
                                        type="button"
                                        fullWidth
                                    >
                                        Ürítés
                                    </SubmitButton>
                                }
                                action={
                                    !relocationExistsForAllocation &&
                                    (
                                        <SubmitButton
                                            onClick={handleResetStudentAssignment}
                                        >
                                            Ürítés
                                        </SubmitButton>
                                    )

                                }
                                cancelText="Vissza"
                            >
                                <ResetAssignmentAttionText
                                    classes={classes}
                                    relocationExistsForAllocation={
                                        relocationExistsForAllocation
                                    }
                                />
                            </Dialog>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} lg={3} xl={2}>
                            <Dialog
                                title="Új hallgatók beosztása"
                                opener={
                                    <SubmitButton
                                        className={classes.newButton}
                                        type="button"
                                        fullWidth
                                    >
                                        Új hallgatók beosztása
                                    </SubmitButton>
                                }
                                action={
                                    <SubmitButton onClick={assignNewStudents}>
                                        Beosztás
                                    </SubmitButton>
                                }
                                cancelText="Mégsem"
                            >
                                Biztosan beosztja az új hallgatókat?
                            </Dialog>
                        </Grid>
                    </>
                ) : (
                    <Grid
                        item
                        xs={false}
                        sm={false}
                        md={false}
                        lg={5}
                        xl={9}
                    ></Grid>
                )}
                <Grid item xs={6} sm={5} md={3} lg={2} xl={1}>
                    <Dialog
                        title="Szobabeosztás generálása"
                        opener={
                            <SubmitButton
                                className={classes.danger}
                                type="button"
                                fullWidth
                            >
                                Generálás
                            </SubmitButton>
                        }
                        action={
                            !relocationExistsForAllocation &&
                            (
                                <SubmitButton
                                    onClick={assignAllStudents}
                                >
                                    Generálás
                                </SubmitButton>
                            )
                        }
                        cancelText="Vissza"
                    >
                        {renderText()}
                    </Dialog>
                </Grid>
            </>
        );
    };

    const filterForm = {
        submitaction: filterData,
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            filterData({});
        },
        form: filter,
        changeform: props.changeForm,
        collapseFilter: true,
        filterButtons: () => filterButtons(),
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <ListPageTemplate
            header={header}
            filter={filterForm}
            hideTable
            customTable={customTable}
            unassignedStudentsTable={unassignedStudentsTable}
        >
            <Title title="Szoba" {...filterForm} format={{ xs: 12 }} />
            <TextField
                label="Campus"
                name="campus"
                value={filter.campus || user?.campus?.name || "Campus neve"}
                format={{ xs: 12, md: 3 }}
                disabled
            />
            <Select
                selectLabel="Épület"
                name="building"
                value={filter.building || ""}
                format={{ xs: 12, md: 3 }}
                optionList={makeBuildingOptions(
                    buildings["hydra:member"],
                    user?.campus
                )}
            />
            <Select
                name="floor"
                value={filter.floor || ""}
                selectLabel="Szint"
                optionList={makeFloorOptions(
                    floors["hydra:member"],
                    filter.building
                )}
                format={{ xs: 12, md: 3 }}
                disabled={!filter.building}
            />
            <Select
                name="room"
                value={filter.room || ""}
                selectLabel="Szoba"
                optionList={makeRoomOptions(
                    roomsForFilter["hydra:member"],
                    filter.floor
                )}
                format={{ xs: 12, md: 3 }}
                disabled={!filter.floor || !filter.building}
            />
            <TextField
                label="Férőhely"
                name="space"
                value={filter.space || ""}
                format={{ xs: 12, md: 3 }}
                rules={{
                    number: "true",
                }}
            />
            <Title title="Hallgató" {...filterForm} format={{ xs: 12 }} />
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={filter.lastName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={filter.firstName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Képzési szint"
                name="trainingLevel"
                value={filter.trainingLevel || []}
                optionList={makeOptionsFromIDWithArchive(trainingLevels) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Képzési program"
                name="trainingProgram"
                value={filter.trainingProgram || []}
                optionList={
                    makeTrainingProgramOptionsFromIDWithArchive(
                        trainingPrograms
                    ) || []
                }
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Évfolyam"
                name="collegeYear"
                value={filter.collegeYear || []}
                optionList={makeOptionsFromID(collegeYears) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Aktuális státusz"
                name="currentMembershipStatus"
                value={filter.currentMembershipStatus || []}
                optionList={MEMBERSHIP_STATUS_OPTIONS}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Egyetem"
                name="university"
                value={filter.university || []}
                optionList={makeOptionsFromID(universities) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <TextField
                label="Szak"
                name="universityMajor"
                value={filter.universityMajor || ""}
                format={{ xs: 12, md: 4 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const {
        filter,
        data,
        filterStorageKey,
        unassignedStudents,
        allocation,
        freeRooms,
        relocationExistsForAllocation,
        terminationExistsForAllocation,
    } = state.roomAllocationList;
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const universities = state.universities.data;
    const buildings = state.buildings.data;
    const floors = state.floors.data;
    const rooms = state.rooms.assignableRooms;
    const roomsForFilter = state.rooms.assignableRoomsForFilter;
    return {
        filter,
        data,
        filterStorageKey,
        unassignedStudents,
        allocation,
        freeRooms,
        relocationExistsForAllocation,
        terminationExistsForAllocation,
        trainingLevels,
        trainingPrograms,
        collegeYears,
        universities,
        buildings,
        floors,
        rooms,
        roomsForFilter,
    };
}

const actionCreators = {
    assignAllStudents: roomAllocationListActions.assignAllStudents,
    assignNewStudents: roomAllocationListActions.assignNewStudents,
    checkAssignmentStatus: roomAllocationListActions.checkAssignmentStatus,
    checkIfRelocationExistsForAllocation:
        roomAllocationListActions.checkIfRelocationExistsForAllocation,
    checkIfTerminationExistsForAllocation:
        roomAllocationListActions.checkIfTerminationExistsForAllocation,
    changeForm: roomAllocationListActions.changeForm,
    defaultForm: roomAllocationListActions.defaultForm,
    filterStudentAllocations:
        roomAllocationListActions.filterStudentAllocations,
    filterUnassignedStudents:
        roomAllocationListActions.filterUnassignedStudents,
    resetStudentAssignment: roomAllocationListActions.resetStudentAssignment,
    getAllocation: roomAllocationListActions.getAllocation,
    getFreeRooms: roomAllocationListActions.getFreeRooms,
    fetchTrainingLevels: trainingLevelsActions.filter,
    fetchTrainingPrograms: trainingProgramsActions.fetch,
    fetchCollegeYears: collegeYearsActions.fetch,
    fetchUniversities: universitiesActions.filter,
    fetchBuildings: buildingsActions.fetch,
    fetchFloors: floorsActions.fetch,
    getAssignableRooms: roomsActions.getAssignableRooms,
    getAssignableRoomsForFilter: roomsActions.getAssignableRoomsForFilter,
    addNotification: notificationActions.addNotification,
    removeNotification: notificationActions.removeNotification,
};

export default connect(mapState, actionCreators)(RoomAllocationList);
