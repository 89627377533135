import { connect } from "react-redux";
import campusesActions from "../../../store/masterData/campuses/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import Select from "../../Common/Select";
import { ACTIVE_OPTIONS, COUNTRIES, PERMISSIONS } from '../../../utils/AppConst';
import { useEffect } from "react";
import AddressCellRenderer from "../../../utils/cellRenderer/AddressCellRenderer";
import ActiveCellRenderer from "../../../utils/cellRenderer/ActiveCellRenderer";
import Address from "../../Common/Address";
import { hasRole, isSystemAdmin } from '../../../utils/User';
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";

function Campuses(props) {
    const { filter, data, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        props.search(storageFilter).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Campusok",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "campuses": "Campusok"
        }
    }

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={"/campuses/view/" + params.data.id}
                />

                <OperationButton
                    tooltiptitle='Épületek'
                    type="house"
                    to={`campuses/${params.data.id}/buildings`}
                />
                {isSystemAdmin() &&
                    <OperationButton
                        tooltiptitle='Dokumentumok'
                        type="document"
                        to={`campuses/${params.data.id}/documents`}
                    />
                }
            </>
        );
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({});
        },
        name: "filter",
        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            { headerName: "Név", field: "name" },
            { headerName: "Rövid név", field: "shortName" },
            { headerName: "Ország", field: "country" },
            { headerName: "Cím", field: "address", cellRenderer: "addressCellRenderer", comparator: (a, b) => a?.postCode?.localeCompare(b?.postCode) },
            { headerName: "Aktív", field: "active", cellRenderer: "activeCellRenderer" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            addressCellRenderer: AddressCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
    }

    return (
        <ListPageTemplate
            filter={hasRole(PERMISSIONS.OPERATOR_ADMIN) ? undefined : filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Név"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Rövid név"
                name="shortName"
                value={filter.shortName || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <Select
                selectLabel="Aktív"
                name="active"
                value={filter.active || ""}
                optionList={ACTIVE_OPTIONS}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <Select
                selectLabel="Ország"
                name="country"
                value={filter.country || []}
                format={{ xs: 12, md: 6, lg: 3 }}
                optionList={COUNTRIES}
                multiple
            />
            <Address
                {...filterForm}
                format={{ xs: 12 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.campuses;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: campusesActions.changeForm,
    defaultForm: campusesActions.defaultForm,
    search: campusesActions.filter,
    delete: campusesActions.delete,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Campuses);