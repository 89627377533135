import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import campusesActions from "../../../store/masterData/campuses/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import Accordion from "../../Common/Accordion";
import PageTemplate from "../../Common/PageTemplate";
import TextField from "../../Common/TextField";
import Form from "../../Common/Form";
import CancelButton from "../../Common/CancelButton";
import { history } from "../../../store";
import Address from "../../Common/Address";
import Checkbox from "../../Common/Checkbox";
import { useEffect } from "react";
import * as yup from "yup";
import { COUNTRIES } from "../../../utils/AppConst";
import Select from "../../Common/Select";

function CampusesForm(props) {
    const { form, id } = props;
    const title = "Campus megtekintése";

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            "campusesView": title,
            "name": form?.name ?? ''
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id).then(() => props.loadingOff());

        return props.defaultForm;
    }, []);



    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        shortName: yup.string().max(16, "Túl hosszú").required("Kötelező megadni"),
        country: yup.mixed().required("Kötelező megadni"),
        /* address: yup.object({
            city: yup.string().required("Kötelező megadni"),
            postCode: yup.string().required("Kötelező megadni"),
            streetName: yup.string().required("Kötelező megadni"),
            streetType: yup.string().required("Kötelező megadni"),
            houseNumber: yup.string().required("Kötelező megadni"),
        }), */
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        initialvalues: form,
        initialerrors: form['errors'] || {},
        validationschema: validationSchema,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
        isformikform: true,
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            label="Név"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 4 }}
                            disabled={true}
                        />
                        <TextField
                            label="Rövid név"
                            name="shortName"
                            value={form.shortName || ""}
                            format={{ xs: 12, md: 4 }}
                            disabled={true}
                        />
                        <Select
                            selectLabel="Ország"
                            name="country"
                            value={form.country || ""}
                            format={{ xs: 12, md: 4 }}
                            optionList={COUNTRIES}
                            disabled={true}
                        />
                        <Address
                            changeform={props.changeForm}
                            format={{ xs: 12 }}
                            form={form}
                            disabled={true}
                        />
                        <Checkbox
                            option={{ name: "active", label: "Aktív" }}
                            checked={form.active}
                            format={{ xs: 12 }}
                            disabled={true}
                        />
                        <CancelButton onClick={() => history.push("/campuses")}>
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.campuses;
    return { form };
}

const actionCreators = {
    changeForm: campusesActions.changeForm,
    defaultForm: campusesActions.defaultForm,
    create: campusesActions.create,
    get: campusesActions.get,
    update: campusesActions.update,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(CampusesForm);