export const types = {
    CHANGE_FORM: "@@relocation-single/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation-single/DEFAULT_FORM",
    CHANGE_EDITOR: "@@relocation-single/CHANGE_EDITOR",

    START_REQUEST: "@@relocation-single/START_REQUEST",
    START_SUCCESS: "@@relocation-single/START_SUCCESS",
    START_FAILURE: "@@relocation-single/START_FAILURE",

    GET_RELOCATABLE_STUDENTS_REQUEST: "@@relocation-single/GET_RELOCATABLE_STUDENTS_REQUEST",
    GET_RELOCATABLE_STUDENTS_SUCCESS: "@@relocation-single/GET_RELOCATABLE_STUDENTS_SUCCESS",
    GET_RELOCATABLE_STUDENTS_FAILURE: "@@relocation-single/GET_RELOCATABLE_STUDENTS_FAILURE",

    GET_NEW_ROOMS_REQUEST: "@@relocation-single/GET_NEW_ROOMS_REQUEST",
    GET_NEW_ROOMS_SUCCESS: "@@relocation-single/GET_NEW_ROOMS_SUCCESS",
    GET_NEW_ROOMS_FAILURE: "@@relocation-single/GET_NEW_ROOMS_FAILURE",

    DELETE_REQUEST: "@@relocation-single/DELETE_REQUEST",
    DELETE_SUCCESS: "@@relocation-single/DELETE_SUCCESS",
    DELETE_FAILURE: "@@relocation-single/DELETE_FAILURE",
}