import { withStyles } from '@material-ui/core';
import MapsHomeWorkIcon from '@material-ui/icons/Map';
import colors from '../../styles/colors';

const FloorPlan = withStyles({
    root: {
        borderRadius: 0,
        backgroundColor: colors.primaryBlue,
        fontSize: "xx-large",
        color: colors.white,
        marginTop: "0.7vh",
        '&:hover': {
            backgroundColor: colors.primaryBlueHover,
        }
    }
})(MapsHomeWorkIcon);

export default FloorPlan;