export const types = {
    CHANGE_FORM: "@@emailLog/CHANGE_FORM",
    DEFAULT_FORM: "@@emailLog/DEFAULT_FORM",

    FETCH_REQUEST: "@@emailLog/FETCH_REQUEST",
    FETCH_SUCCESS: "@@emailLog/FETCH_SUCCESS",
    FETCH_FAILURE: "@@emailLog/FETCH_FAILURE",

    GET_REQUEST: "@@emailLog/GET_REQUEST",
    GET_SUCCESS: "@@emailLog/GET_SUCCESS",
    GET_FAILURE: "@@emailLog/GET_FAILURE",

    FETCH_SET_INFO: "@@emailLog/FETCH_SET_INFO",
}