import API from '../../../utils/API';

function accept(form, id) {
    return API.post(`/enrollments/${id}/approve-room`, form)
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

const approvalService = {
    accept
};

export default approvalService;