import { connect } from "react-redux";
import usersActions from "../../../store/masterData/users/actions";
import loadingActions from "../../../store/loading/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import Select from "../../Common/Select";
import { rolesOptions } from "../../../utils/AppConst";
import { useEffect } from 'react';
import DatePicker from "../../Common/DatePicker";
import RoleCellRenderer from "../../../utils/cellRenderer/RoleCellRenderer";
import axios from "axios";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import notificationActions from '../../../store/notification/actions';
import { useTenant } from "../../../utils/TenantContext";
import { formatDate } from "../../../utils/timezoneConverter";

function Users(props) {
    const { filter, data, filterStorageKey } = props;
    const tenant = useTenant();
    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.search(storageFilter),
        ]).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Felhasználók",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "users": "Felhasználók"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({});
        },
        form: filter,
        changeform: props.changeForm
    }

    const DateCellRenderer = (params) => {
        return formatDate(params.value, tenant.timezone.offset);
    }

    const table = {
        columnDefs: [
            { headerName: "Név", field: "fullName", minWidth: 280 },
            { headerName: "E-mail cím", field: "email" },
            { 
                headerName: "Jogosultság", 
                field: "roles", 
                cellRenderer: "roleCellRenderer", 
                comparator: (a, b) => a[0]?.localeCompare(b[0]) 
            },
            { headerName: "Campus", field: "campus.name" },
            { 
                headerName: "Utolsó belépés",
                field: "lastLoggedInAt" ,
                cellRenderer: "dateCellRenderer",
            }, //TODO: date szűrőt megjavítani itt
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            },
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            roleCellRenderer: RoleCellRenderer,
            
            dateCellRenderer: DateCellRenderer,
        },
    }

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={"/users/view/" + params.data.id}
                />
            </>
        );
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Név"
                name="fullName"
                value={filter.fullName || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
            />
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
            />
            <Select
                selectLabel="Jogosultság"
                name="roles"
                value={filter.roles || []}
                optionList={rolesOptions}
                format={{ xs: 12, md: 4, lg: 3 }}
                multiple
            />
            <DatePicker
                label="Utolsó belépés (-tól)"
                value={filter.lastLoginFrom || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="lastLoginFrom"
            />
            <DatePicker
                label="Utolsó belépés (-ig)"
                value={filter.lastLoginUntil || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="lastLoginUntil"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.users;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: usersActions.changeForm,
    defaultForm: usersActions.defaultForm,
    search: usersActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Users);