import { templateChangeForm, templateDefaultForm } from "../../..";
import relocationService from "../../../../services/processes/relocation";
import notificationTypes from "../../../notification/types";
import { types } from "./types";

const changeEditor = content => dispatch => dispatch({ type: types.CHANGE_EDITOR, content });

function startRelocationMultiple(form) {
    const students = form.students?.map(student => {
        return parseInt(student.split("/")?.pop());
    });

    const formToSend = {
        students: students,
        emailSubject: form.emailSubject,
        emailContent: form.emailContent
    };

    return (dispatch) => {
        dispatch({ type: types.START_REQUEST, formToSend });
        return relocationService.startRelocationMultiple(formToSend).then(
            (data) => {
                dispatch({ type: types.START_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés" }
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "error", msg: "Hiba lépett fel küldés közben" }
                })
                return Promise.reject(error);
            }
        )
    }
}

function getRelocatableStudents(filter) {
    const formToSend = {
        campus: filter.campus?.split("/")?.pop(),
        semester: filter.semester
    }

    return (dispatch) => {
        dispatch({ type: types.GET_RELOCATABLE_STUDENTS_REQUEST, formToSend });
        return relocationService.getRelocatableStudents(formToSend).then(
            (data) => {
                dispatch({ type: types.GET_RELOCATABLE_STUDENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_RELOCATABLE_STUDENTS_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    getRelocatableStudents,
    startRelocationMultiple
}

export default actions;