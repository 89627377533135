import { Children, useState } from 'react';
import { AccordionDetails, Box, Chip, Collapse, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import colors from '../../../../styles/colors';
import Accordion from '../../../Common/Accordion';
import TableHeader from '../../../Common/CustomTable/TableHeader';
import createRowData from './createRowData';
import { useSelector } from 'react-redux';
import SmallTable from '../../../Common/SmallTable';
import BtnCellRenderer from './btnCellRenderer';
import { getCurrentMembershipStatus } from '../../../../utils/AppConst';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        "& .MuiChip-colorPrimary": {
            backgroundColor: colors.error
        },
        "& .MuiChip-colorSecondary": {
            backgroundColor: colors.primaryYellow
        }
    },
    heading: {
        color: colors.primaryYellow,
        fontSize: "large",
    },
    listActionsGrid: {
        justifyContent: 'unset',
    },
    exportButton: {
        backgroundColor: colors.export,
        '&:hover': {
            background: colors.exportHover,
            color: colors.white
        }
    },
    newButton: {
        backgroundColor: colors.new,
        '&:hover': {
            background: colors.newHover,
            color: colors.white
        }
    },
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    accordionDetails: {
        padding: "unset",
        overflow: "auto"
    },
    downloadIcon: {
        marginRight: 8
    },
    firstCell: {
        width: "10px",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    greenChip: {
        backgroundColor: colors.new,
        color: colors.white
    },
    redChip: {
        backgroundColor: colors.error,
        color: colors.white
    },
    blueChip: {
        backgroundColor: colors.badge,
        color: colors.white
    },
    greyChip: {
        backgroundColor: colors.lightGrey,
        color: colors.black
    }
}));

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const smallTableRows = props.smallTableRows?.map(x => {
        return {
            email: x?.student?.user?.email,
            fullName: x?.student?.user?.fullName,
            trainingLevel: x?.student?.currentTrainingLevel?.name,
            trainingProgram: x?.student?.currentTrainingProgram?.name,
            collegeYear: x?.student?.currentCollegeYear?.name,
            currentMembershipStatus: getCurrentMembershipStatus(x?.student?.currentMembershipStatus),
            university: x?.student?.university?.name,
            universityMajor: x?.student?.universityMajor,
            operations: BtnCellRenderer({
                student: x?.student,
                row: row,
                studentAllocationId: x.id,
                allocationId: props.allocationId,
                preference: x.studentAllocationPreference
            })
        };
    });

    const getClassNameOfChip = (space) => {
        const spaceSplit = space?.split("/");
        const students = Number(spaceSplit?.[0]?.trim());
        const spaces = Number(spaceSplit?.[1]?.trim());

        if (students == 0) return classes.greyChip;

        if (students > spaces) return classes.redChip;
        if (spaces > students) return classes.greenChip;
        return classes.blueChip;
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.building?.name}</TableCell>
                <TableCell>{row.floor?.name}</TableCell>
                <TableCell>{row.room?.name}</TableCell>
                <TableCell>
                    <Chip
                        className={getClassNameOfChip(row.space)}
                        label={row.space}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {
                                smallTableRows?.length > 0 ?
                                    <SmallTable columns={props.smallTableColumns} rows={smallTableRows} minWidth={50} />
                                    : "Nincs megjeleníthető adat"
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function CollapsibleTable(props) {
    const classes = useStyles();
    const takenRooms = useSelector(state => state.roomAllocationList.data?.['hydra:member']);
    const freeRooms = useSelector(state => state.roomAllocationList.freeRooms?.['hydra:member']);

    const data = takenRooms?.concat(freeRooms);


    const studentTableColumns = [
        { value: "email", label: "E-mail" },
        { value: "fullName", label: "Név" },
        { value: "trainingLevel", label: "Képzési szint" },
        { value: "trainingProgram", label: "Képzési program" },
        { value: "collegeYear", label: "Évfolyam" },
        { value: "currentMembershipStatus", label: "Aktuális státusz" },
        { value: "university", label: "Egyetem" },
        { value: "universityMajor", label: "Szak" },
        { value: "operations", label: "Műveletek" }
    ];

    const rowData = createRowData(data);

    return (
        <Grid item container className={classes.root}>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <Accordion expanded title={props.title || "Lista"}>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Grid item container xs={12}>
                                <Table aria-label="collapsible table">
                                    <TableHeader columnDefs={props.columnDefs} />
                                    <TableBody>
                                        {Children?.toArray(rowData?.map((row) => (
                                            <Row
                                                key={row?.id}
                                                row={row}
                                                smallTableColumns={studentTableColumns}
                                                smallTableRows={row?.studentAllocations}
                                                allocationId={props.allocationId}
                                            />
                                        )))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
    );
}