/**
 * Visszaadja az API Platform által visszaadott url-ből, hogy melyik oldalon vagyunk
 * jelen pillanatban a táblázatban
 *
 * @param {*} data
 * @returns
 */
export default function getCurrentPage(data) {
  if (!data) return 1;

  // Ez a string amiben keressük, hogy a 'page' mivel egyenlő
  const string = data["@id"];
  // Itt a regex-ünk
  const regex = /\bpage\b/;
  // Megadja a 'p' betű indexét a string-ben
  const startIndex = string.search(regex);
  // Ezt adjuk majd vissza a végén
  let result = "";
  // Ha nem találja a string-ben a 'page'-et akkor ez -1 lesz
  if (startIndex > 0) {
    // Tömbbé alakítjuk a string-et
    let characters = [...string];
    // Azért +5 mert 'p'-től így jutunk el a számokig
    for (let i = startIndex + 5; i < characters.length; i++) {
      // Ellenőrizzük, hogy a karakter szám-e,
      if (characters[i] >= "0" && characters[i] <= "9") {
        //ha igen, hozzáadjuk az eredményünkhöz
        result += characters[i];
      } else {
        // ha nem, akkor nem iterálunk tovább
        break;
      }
    }
  }

  // Ha megvan a karakter akkor számmá alakítjuk, ha nincs meg akkor 1-el tér vissza, mivel akkor 1 oldal lesz
  return Number(result) || 1;
}
 