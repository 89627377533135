import { templateChangeForm, templateDefaultForm } from "../..";
import { types } from "./types";
import allocationService from "../../../services/processes/allocation";
import loadingTypes from "../../loading/types";
import downloadBlob from '../../../utils/downloadBlob';

function getActiveAllocationByCampus(campusId) {
    return (dispatch) => {
        dispatch({ type: types.GET_ACTIVE_ALLOCATION_REQUEST });
        return allocationService.getActiveAllocationByCampus(campusId).then(
            (data) => {
                dispatch({ type: types.GET_ACTIVE_ALLOCATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ACTIVE_ALLOCATION_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function filter(id, form) {
    let formToSend = {};

    //TODO: szobatársak szűrő
    if (form) {
        formToSend['student.user.email'] = form.email;
        formToSend['student.user.lastName'] = form.lastName;
        formToSend['student.user.firstName'] = form.firstName;
        formToSend['student.currentTrainingLevel'] = form.trainingLevel;
        formToSend['student.currentTrainingProgram'] = form.trainingProgram;
        formToSend['student.currentCollegeYear'] = form.collegeYear;
        formToSend['student.currentMembershipStatus'] = form.currentMembershipStatus;
        formToSend['room'] = form.room;
        formToSend['room.floor'] = form.floor;
        formToSend['room.floor.building'] = form.building;
    }

    return (dispatch) => {
        dispatch({ type: types.FILTER_STUDENTS_REQUEST, formToSend });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return allocationService.filterStudents(id, formToSend).then(
            (data) => {
                dispatch({ type: types.FILTER_STUDENTS_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILTER_STUDENTS_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        )
    }
}

function downloadExport(filter, id, fileName = 'export.xlsx') {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_EXPORT_REQUEST });
        return allocationService.downloadExport(filter, id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function getSemesterNameForDownload(allocationId) {
    return (dispatch) => {
        dispatch({ type: types.GET_SEMESTER_NAME_FOR_DOWNLOAD_REQUEST });
        return allocationService.getSemesterNameForDownload(allocationId).then(
            (data) => {
                dispatch({ type: types.GET_SEMESTER_NAME_FOR_DOWNLOAD_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_SEMESTER_NAME_FOR_DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    downloadExport,
    getActiveAllocationByCampus,
    filter,
    getSemesterNameForDownload
}

export default actions;