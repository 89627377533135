import { makeStyles } from '@material-ui/core';
import Tooltip from '../../Components/Common/Tooltip';

const useStyles = makeStyles(() => ({
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
}));

export default function EmailHoverCellRenderer(params) {
    const classes = useStyles();

    return(
        <Tooltip title={params.data?.email || "-"}>
            <div className={classes.truncate}>{params.data?.fullName || "-"}</div>
        </Tooltip>
    );
}