import { types } from "./types";

const initialState = {
    form: {
        items: {}
    }
};

export function relocationOperatorRoomTakeOver(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
            }
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.OPERATOR_TAKE_OVER_ROOM_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.OPERATOR_TAKE_OVER_ROOM_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.OPERATOR_TAKE_OVER_ROOM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.GET_FORM_REQUEST:
            return {
                ...state,
            };
        case types.GET_FORM_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FORM_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        default:
            return state;
    }
}