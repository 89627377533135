import types from './types';
import { history } from "../index";

function addNotification(type, msg, redirect, options) {
    return dispatch => {
        const data = {type, msg, options}
        if (redirect) history.push(redirect);
        dispatch({type: types.ADD_NOTIFICATION, data});
    }
}

function removeNotification() {
    return dispatch => dispatch({type: types.REMOVE_NOTIFICATION});
}

const actions = {
    addNotification,
    removeNotification
};

export default actions;