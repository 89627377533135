import { Grid } from "@material-ui/core";
import React, { Children } from "react";
import loadingActions from '../../store/loading/actions';
import { connect } from 'react-redux';
import FormikForm from "./FormikForm";

function Form(props) {
    const {form, validation, childComponent, changeform, ...rest} = props;
    let previousErrors = typeof form !== typeof undefined ? (form.errors || []) : [];
    const getRules = name => validation ? validation.filter(v => v.field === name) : [];
    const getError = name => previousErrors.filter(v => v.name === name).length > 0;
    const getHelperText = name => previousErrors.filter(v => v.name === name)[0]?.label;

    if (props.isformikform) {
        return <FormikForm {...props} />
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (props.onSubmit !== undefined) {
            props.loadingOn();
            try {
                props.onSubmit()
                .then(props.loadingOff)
                .catch(props.loadingOff)
            } catch (e) {
                props.loadingOff();
            }
        } else {
            console.error("Ez a funkció még nem lett bekötve.");
        }
    }
    
    const buildElem = child => (
        child.props && child.props.button ?
            <Grid item {...child.props?.format}>
                {child}
            </Grid> :
            <Grid item {...child.props?.format}>
            {
                React.cloneElement(child, 
                { 
                    formToChild: props.form,
                    formNameToChild: props.name || "form",
                    functionToChild: changeform,
                    ...(getRules(child.props.name).length > 0 || (typeof form !== typeof undefined && form.errors?.length > 0)) &&
                    {
                        error: getError(child.props.validatorKey) || getError(child.props.name),
                        helperText: getHelperText(child)
                    }
                })
            }
            </Grid>
    )
    const contentGenerator = () => (
        <Grid item container spacing={1} {...rest}>
            {Children.toArray(Children.toArray(props.children).map(child => {
                    if(!child.props?.className?.includes("hidden"))
                        return buildElem(child)
                    else
                        return "";
                }
            ))}
        </Grid>
    )

    return childComponent ? 
        contentGenerator() :
        <form onSubmit={handleSubmit} noValidate>
            {contentGenerator()}
        </form>
}

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(null, actionCreators)(Form);