import { types } from "./types";

const initialState = {
    form: {
        membershipStatus: ['internal']
    },
    data: [],
}

export function studentAllocationPreference(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
            }
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    //content: action.content,
                    emailContent: action.content
                }
            }
        case types.START_REQUEST:
            return {
                ...state,
            }
        case types.START_SUCCESS:
            return {
                ...state,
            }
        case types.START_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        default:
            return {
                ...state
            }
    }
}