import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateGet } from '../..';

function filter(form) {
    const mapping = {
        sentAtFrom: 'sentAt[after]',
        sentAtUntil: 'sentAt[before]',
        generatedAtFrom: 'generatedAt[after]',
        generatedAtUntil: 'generatedAt[before]',
    };
    const searchForm = new SearchForm(form, mapping);

    return API.get('/email-logs', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const emailLogService = {
    filter,
    get: templateGet('email-logs'),

};

export default emailLogService;