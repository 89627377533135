import TooltipRenderer from "./TooltipRenderer";

export const membershipStatusLabel = value => {
    switch (value) {
        case "internal":
            return "Bentlakó";
        case "external":
            return "Külsős";
        default:
            return "";
    }
}

export default function MemberShipStatusCellRenderer(params) {
    return TooltipRenderer(membershipStatusLabel(params.value || ""));
}