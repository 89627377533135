import { AccordionDetails, makeStyles } from '@material-ui/core';
import AccordionBasic from '@material-ui/core/Accordion';
import React, { useState } from 'react';
import Expand from '../Icons/Expand';
import AccordionSummary from './AccordionSummary';

const useStyles = makeStyles (() => ( {
    accordionDetails: {
        display: "block"
    },
}));

/**
 * 
 * @param expanded - alapból legyen-e lenyitva (default: true)
 * @param title - címe
 */

export default function Accordion(props) {
    const [expanded, setExpanded] = useState(true);
    const classes = useStyles();
    
    return (
        <AccordionBasic 
            defaultExpanded={props.defaultExpanded} 
            expanded={expanded}
            onChange={() => setExpanded(props.expanded !== undefined ? true : !expanded)}
        >
            <AccordionSummary
                expandIcon={props.expanded === undefined && <Expand />}
                text={props.title}
            />
            <AccordionDetails className={classes.accordionDetails}>
                {props.children}
            </AccordionDetails>
        </AccordionBasic>
    );
}