import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { history } from '../../../store';
import emailLogActions from '../../../store/administration/emailLog/actions';
import loadingActions from "../../../store/loading/actions";
import Accordion from "../../Common/Accordion";
import CancelButton from '../../Common/CancelButton';
import PageTemplate from "../../Common/PageTemplate";

function EmailView(props) {
    const { form } = props;
    const { id } = props.match.params;
    const header = {
        title: "E-mail adatlap",
        breadcrumbs: {
            "home": "Főoldal",
            "/email-log": "E-mail napló",
            "email-view": "E-mail adatlap"
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id).then(() => props.loadingOff());
        return () => {
            props.defaultForm("form");
        }
    }, []);


    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="E-mail adatlap" expanded={true}>
                    <Card>
                        <CardContent>
                            <Grid item>
                                <div>
                                    <Typography>
                                        <b>Címzett:</b> {form?.mailingAddress || ''}
                                    </Typography>
                                    <Typography>
                                        <b>Tárgy:</b> {form?.subject || ''}
                                    </Typography>
                                    <Typography>
                                        <b>Tartalom:</b>
                                    </Typography>
                                    <Typography dangerouslySetInnerHTML={{ __html: form?.emailText || '' }} />
                                </div>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={6} sm={5} md={3} lg={2} xl={1}>
                                <CancelButton
                                    type="button"
                                    onClick={() => history.push("/email-log")}
                                    fullWidth
                                >
                                    Vissza
                                </CancelButton>
                            </Grid>
                        </CardActions>
                    </Card>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.emailLog;
    return { form };
}

const actionCreators = {
    defaultForm: emailLogActions.defaultForm,
    get: emailLogActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(EmailView);