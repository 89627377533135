import TooltipRenderer from "./TooltipRenderer";

/**
 * A cell renderer function that gets 'params' as the parameter
 * renders boolean values as Yes/No instead of true/false
 * and passes the value to the TooltipCellRender for displaying the tooltip.
 */
export function YesNoCellRenderer(params) {
    const result = params.value ? "Igen" : "Nem"
    return TooltipRenderer(result);
}