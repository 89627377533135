import {
    templateChangeForm,
    templateGet,
    templateDefaultForm,
} from '../..';
import { types } from "./types";
import documentsService from "../../../services/masterData/documents";
import downloadBlob from '../../../utils/downloadBlob';
import loadingTypes from "../../loading/types";

const changeEditor = content => dispatch => dispatch({ type: types.CHANGE_EDITOR, content });

const filter = (form, otherData) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    dispatch({ type: loadingTypes.TABLE_LOADING_ON });
    return documentsService.filter(form, otherData).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.reject(error);
        }
    );
};

function download(id, fileName, readOnly = false) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });
        return documentsService.download(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS, data });
                if (!readOnly) downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: filter,
    changeEditor,
    get: templateGet(types, documentsService),
    download,
}

export default actions;