import ListPageTemplate from "../../../Common/ListPageTemplate";
import OperationButton from "../../../Common/OperationButton";
import TextField from "../../../Common/TextField";
import Select from "../../../Common/Select";
import Autocomplete from "../../../Common/Autocomplete";
import EnrollmentTypeCellRenderer from "../../../../utils/cellRenderer/EnrollmentTypeCellRenderer";
import EnrollmentStatusCellRenderer from "../../../../utils/cellRenderer/EnrollmentStatusCellRenderer";
import {
    PROCESS_TYPES,
    PROCESS_TYPE_NAMES,
    PROCESS_STATUS_OPTIONS_DETAILED, isKep,
} from "../../../../utils/AppConst";
import { isSystemAdmin } from "../../../../utils/User";
import { makeOptionsFromID } from "../../../../utils/listOptions/makeOptionsFromID";
import { setInfo } from "../../../../utils/FilterHelper";
import { filterStorageSave } from "../../../../utils/FilterStorage";
import { useEffect } from "react";

function BtnCellRenderer(params, props) {
    if (params.data.type === PROCESS_TYPE_NAMES.ENROLLMENT) {
        return (
            <>
                <OperationButton
                    type="info"
                    to={`/processes/details/${params.data.id}`}
                />
                <OperationButton
                    type="visibility"
                    tooltiptitle="Idővonal megtekintése"
                    to={`/processes/timeline/${params.data.id}`}
                />
                {isSystemAdmin() && params.data.status !== "done" && (
                    <OperationButton
                        type="delete"
                        confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                        submittext="Igen"
                        handleClick={() => props.handleDelete(params.data.id)}
                        to={"#"}
                    />
                )}
            </>
        );
    }

    if (params.data.type === PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE) {
        return (
            <>
                <OperationButton
                    type="info"
                    to={`/processes/student-allocation-preference/details/${params.data.id}`}
                />
                {isSystemAdmin() && params.data.status !== "done" && (
                    <OperationButton
                        type="delete"
                        confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                        submittext="Igen"
                        handleClick={() =>
                            props.handleDeleteStudentAllocationPreference(
                                params.data.id
                            )
                        }
                        to={"#"}
                    />
                )}
            </>
        );
    }

    if (params.data.type === PROCESS_TYPE_NAMES.RELOCATION) {
        return (
            <>
                <OperationButton
                    type="info"
                    to={`/processes/relocation/details/${params.data.id}`}
                />
                {isSystemAdmin() && params.data.status !== "done" && (
                    <OperationButton
                        type="delete"
                        confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                        submittext="Igen"
                        handleClick={() =>
                            props.handleDeleteRelocation(
                                params.data.id
                            )
                        }
                        to={"#"}
                    />
                )}
            </>
        );
    }

    if (params.data.type === PROCESS_TYPE_NAMES.TERMINATION) {
        return (
            <>
                <OperationButton
                    type="info"
                    to={`/processes/termination/details/${params.data.id}`}
                />
                {isSystemAdmin() && params.data.status !== "done" && (
                    <OperationButton
                        type="delete"
                        confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                        submittext="Igen"
                        handleClick={() =>
                            props.handleDeleteTermination(
                                params.data.id
                            )
                        }
                        to={"#"}
                    />
                )}
            </>
        );
    }

    if (params.data.type === PROCESS_TYPE_NAMES.MOVE_OUT) {
        return (
            <>
                <OperationButton
                    type="info"
                    to={`/processes/move-out/details/${params.data.id}`}
                />
                {isSystemAdmin() && params.data.status !== "done" && (
                    <OperationButton
                        type="delete"
                        confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                        submittext="Igen"
                        handleClick={() =>
                            props.handleDeleteTermination(
                                params.data.id
                            )
                        }
                        to={"#"}
                    />
                )}
            </>
        );
    }

    return <>-</>;
}

function Table(props) {
    const { filter, data, semesters, filterStorageKey, tableInfo } = props;

    const filterForm = {
        submitaction: () => {
            setInfo(props, "filter", filter);
            filterStorageSave(filterStorageKey, filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            setInfo(props, "filter", {});
        },
        form: filter,
        changeform: props.changeForm,
    };

    let columns = [
        { headerName: "Sorszám", field: "displayId" },
        {
            headerName: "Típus",
            field: "type",
            cellRenderer: "enrollmentTypeCellRenderer",
        },
        {
            headerName: "Folyamatstátusz",
            field: "status",
            cellRenderer: "enrollmentStatusCellRenderer",
        },
        { headerName: "Szemeszter", field: "semester.name" },
        { headerName: "Név", field: "fullName", minWidth: 280 },
        { headerName: "E-mail cím", field: "email" },
        { headerName: "Campus", field: "campus.name" },
        {
            headerName: "Műveletek",
            field: "operations",
            cellRenderer: "btnCellRenderer",
            // The column autosizing does not work for rendered buttons for some reason.
            minWidth: 220,
            sortable: false,
        },
    ];

    if(isKep()){
        columns = columns.filter(column => column.field !== "omId");
    }

    const table = {
        columnDefs: columns,
        rowData: data["hydra:member"],
        frameworkComponents: {
            btnCellRenderer: (params) => BtnCellRenderer(params, props),
            enrollmentTypeCellRenderer: EnrollmentTypeCellRenderer,
            enrollmentStatusCellRenderer: EnrollmentStatusCellRenderer,
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false,
    };

    useEffect(() => {
        props.changeForm(
            {
                ...filter,
                status: []
            },
            "filter"
        );
    }, [filter["@type"]])


    return (
        <ListPageTemplate filter={filterForm} table={table}>
            <TextField
                label="Sorszám"
                name="displayId"
                value={filter.displayId || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Típus"
                name="@type"
                value={filter["@type"] || ""}
                optionList={PROCESS_TYPES}
                format={{ xs: 12, md: 4 }}
            />
            <Autocomplete
                id="status"
                name="status"
                label="Folyamatstátusz"
                value={filter?.status || []}
                options={PROCESS_STATUS_OPTIONS_DETAILED || []}
                filterOptions={(options) => options.filter(
                    (option) => option.type_en === filter["@type"]
                )}
                groupBy={(option) => option.type_hu}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                disabled={!filter["@type"]}
            />
            <TextField
                label="Név"
                name="fullName"
                value={filter.fullName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Szemeszter"
                name="semester"
                value={filter?.semester || []}
                optionList={makeOptionsFromID(semesters)}
                format={{ xs: 12, md: 4 }}
                multiple
            />
        </ListPageTemplate>
    );
}

export default Table;
