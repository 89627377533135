import { campuses } from "./campuses/reducer"
import { documents } from "./documents/reducer"
import { semesters } from "./semesters/reducer"
import { students } from "./students/reducer"
import { trainingLevels } from "./trainingLevels/reducer"
import { trainingPrograms } from "./trainingPrograms/reducer"
import { users } from "./users/reducer"
import { buildings } from "./buildings/reducer"
import { floors } from "./floors/reducer"
import { collegeYears } from "./collegeYears/reducer"
import { rooms } from "./rooms/reducer"
import { items } from "./items/reducer"
import { universities } from "./universities/reducer"

export const masterData = {
    users,
    campuses,
    documents,
    semesters,
    students,
    trainingLevels,
    trainingPrograms,
    buildings,
    floors,
    items,
    rooms,
    universities,
    collegeYears,
}