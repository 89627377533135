import { templateChangeForm, templateDefaultForm, templateGet, templateFetch } from "../..";
import { types } from "./types";
import trainingProgramsService from "../../../services/masterData/trainingPrograms";
import loadingTypes from "../../loading/types";

const filter = (form, otherData) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    dispatch({ type: loadingTypes.TABLE_LOADING_ON });
    return trainingProgramsService.filter(form, otherData).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.reject(error);
        }
    );
};

const filterTrainingProgramsForSelect = (form) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    return trainingProgramsService.filterTrainingProgramsForSelect(form).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            return Promise.reject(error);
        }
    );
};


const actions = {
    filter,
    filterTrainingProgramsForSelect,
    fetch: templateFetch(types, trainingProgramsService),
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, trainingProgramsService),
}

export default actions;