export const types = {
    CHANGE_FORM: "@@login/CHANGE_FORM",

    LOGIN_SUCCESS: "@@login/LOGIN_SUCCESS",
    LOGIN_FAILURE: "@@login/LOGIN_FAILURE",

    REFRESH_TOKEN_REQUEST: "@@login/REFRESH_TOKEN_REQUEST",
    REFRESH_TOKEN_SUCCESS: "@@login/REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_FAILURE: "@@login/REFRESH_TOKEN_FAILURE",

    SAVE_DESIRED_URL: "@@login/SAVE_DESIRED_URL",

    SET_USER: "@@login/SET_USER",
    SET_ROLES: "@@login/SET_ROLES",
    SET_CAMPUS: "@@login/SET_CAMPUS",
}