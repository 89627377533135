import API from '../../../utils/API';
import editApi from '../../../utils/editApi';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateGet, templateDelete, templateFetch } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/training-levels/${otherData.trainingLevelId}/training-programs`, { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function update(id, form) {
    let formToSend = Object.assign({}, form);

    delete formToSend.trainingLevel;

    return editApi.patch(`/training-programs/${id}`, formToSend)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filterTrainingProgramsForSelect(form) {
    const searchForm = new SearchForm(form);

    return API.get('/training-programs', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const trainingProgramsService = {
    filter,
    filterTrainingProgramsForSelect,
    fetch: templateFetch('training-programs'),
    create: templateCreate('training-programs'),
    get: templateGet('training-programs'),
    update,
    delete: templateDelete('training-programs'),
};

export default trainingProgramsService;