import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "../../../../utils/User";
import { makeStyles } from "@material-ui/core";
import loadingActions from "../../../../store/loading/actions";
import semestersActions from "../../../../store/masterData/semesters/actions";
import relocationSingleActions from "../../../../store/processes/newProcess/relocationSingle/actions";
import notificationActions from "../../../../store/notification/actions";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../../../../utils/AppConst";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import Title from "../../../Common/Title";
import CkEditor from "../../../Common/CkEditor";
import CancelButton from "../../../Common/CancelButton";
import axios from "axios";
import colors from "../../../../styles/colors";
import { useSnackbar } from "notistack";
import { history } from "../../../../store";
import Loading from "../../../Common/Loading";

const useStyles = makeStyles(() => ({
    insert: {
        backgroundColor: colors.insert,
        "&:hover": {
            backgroundColor: colors.insertHover,
        },
    },
}));

function RelocationSingle(props) {
    const {
        form,
        studentOptions,
        emailTemplate,
        relocatableStudents,
        newRooms,
        buildingOptions,
        floorOptions,
        roomOptions,
        activeSemester,
    } = props;
    const [studentLoading, setStudentLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [valid, setValid] = useState(false);
    const [currentRoom, setCurrentRoom] = useState({});
    const [space, setSpace] = useState("");
    const classes = useStyles();
    const campus = getUser()?.campus;
    const [semesterLoading, setSemesterLoading] = useState(true);
    const [roomLoading, setRoomLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const header = {
        title: "Egyedi átköltözés folyamat indítása",
        breadcrumbs: {
            processes: "Folyamatok",
            "/new-process": "Új folyamat",
            "relocation-single": "Egyedi átköltözés folyamat indítása",
        },
    };

    const validationSchema = yup.object().shape({
        emailSubject: yup.mixed().required(VALIDATION_MESSAGES.required),
        emailContent: yup.mixed().required(VALIDATION_MESSAGES.required),
    });

    useEffect(() => {
        validationSchema.isValid(form).then((valid) => {
            setValid(valid);
        });
    }, [form]);

    const customValidation = (propertyPath, message) => {
        if (propertyPath.includes("students[")) {
            enqueueSnackbar(
                { message: message, variant: "error" },
                { persist: false }
            );
        } else if (propertyPath === "") {
            props.addNotification("error", message);
        }
    };

    const formProps = {
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        onsubmit: () => {
            const ret = props.startRelocationSingle(form, campus);
            setReload(!reload);
            props.loadingOff();
            return ret;
        },
        isformikform: true,
        customvalidation: customValidation,
    };

    useEffect(() => {
        props.defaultForm("form");
        axios
            .all([
                props.getActiveSemester(),
                props.filterEmailTemplate(),
            ])
            .finally(() => setSemesterLoading(false));
    }, []);

    useEffect(() => {
        if (relocatableStudents) {
            props.changeForm(
                relocatableStudents.map((student) => ({
                    value: student["@id"],
                    label:
                        student.user?.lastName + " " + student.user?.firstName,
                })),
                "studentOptions"
            );
        }
    }, [relocatableStudents, reload]);

    useEffect(() => {
        props.changeForm(
            { ...form, students: [], campus: campus?.["@id"] },
            "form"
        );
        const campusId = campus?.["@id"];
        const semesterId = activeSemester?.id;
        if (!semesterLoading && semesterId && campusId) {
            props
                .getRelocatableStudents({
                    campus: campusId,
                    semester: semesterId,
                })
                .then(() => {
                    setStudentLoading(false);
                });
        }
    }, [semesterLoading, reload]);

    useEffect(() => {
        const campusId = campus?.["@id"];
        const semesterId = activeSemester?.id;
        if (!semesterLoading && semesterId && campusId) {
            props
                .getNewRooms({
                    campus: campusId,
                    semester: semesterId,
                })
                .then(() => {
                    setRoomLoading(false);
                });
        }
    }, [semesterLoading, reload]);

    useEffect(() => {
        props.changeForm(
            newRooms.map((room) => ({
                value: room.floor.building["@id"],
                label: room.floor.building.name
            })).filter((value, index, array) => array.map((e) => e.value).indexOf(value.value) === index),
            "buildingOptions"
        );
    }, [newRooms, reload]);

    useEffect(() => {
        props.changeForm(
            newRooms.filter((element) => element.floor.building['@id'] === form.newBuilding)
            .map((room) => ({
                value: room.floor["@id"],
                label: room.floor.name
            })).filter((value, index, array) => array.map((e) => e.value).indexOf(value.value) === index),
            "floorOptions"
        );
    }, [newRooms, form.newBuilding, reload]);

    useEffect(() => {
        props.changeForm(
            newRooms.filter((element) => element.floor.building['@id'] === form.newBuilding && element.floor['@id'] === form.newFloor)
            .map((room) => ({
                value: room["@id"],
                label: room.name
            })).filter((value, index, array) => array.map((e) => e.value).indexOf(value.value) === index),
            "roomOptions"
        );
    }, [newRooms, form.newBuilding, form.newFloor, reload]);

    useEffect(() => {
        setCurrentRoom(relocatableStudents.find((elem) => {
            return elem['@id'] === form.student;
        }));
    }, [form.student, reload]);

    useEffect(() => {
        if (form.newRoom) {
            const newRoomFound = newRooms.find((elem) => {
                return elem['@id'] === form.newRoom;
            });
            setSpace(newRoomFound.allocatedSpace + " / " + newRoomFound.space);
        } else {
            setSpace("");
        }
    }, [form.newRoom, reload]);

    if (studentLoading || semesterLoading) {
        return <Loading />;
    }

    return (
        <FormPageTemplate header={{ ...header }} form={formProps}>
            <TextField
                label='Campus'
                name='campus'
                value={campus?.name}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label='Szemeszter'
                name='semester'
                value={activeSemester?.name}
                format={{ xs: 12 }}
                disabled
            />
            <Select
                selectLabel='Hallgató'
                name='student'
                value={form.student || []}
                optionList={studentOptions || []}
                format={{ xs: 12 }}
                loading={studentLoading}
                required
            />
            {form.student && (
                <>
                    <TextField
                        label="Jelenlegi szoba"
                        name="currentRoom"
                        value={currentRoom && currentRoom.currentRoom ? currentRoom.currentRoom.floor.building.name + ", "
                            + currentRoom.currentRoom.floor.name + ", " + currentRoom.currentRoom.name : ""}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <Title {...formProps} title="Új szoba" format={{ xs: 12 }}>
                        <Select
                            selectLabel="Épület"
                            name="newBuilding"
                            value={form.newBuilding || []}
                            optionList={buildingOptions}
                            format={{ xs: 12 }}
                            loading={roomLoading}
                            onChange={(e) => {props.changeForm({...form, newFloor: null, newRoom: null, newBuilding: e.target.value}, "form")}}
                            required
                        />
                        <Select
                            selectLabel="Szint"
                            name="newFloor"
                            value={form.newFloor || []}
                            optionList={floorOptions}
                            format={{ xs: 12 }}
                            loading={roomLoading}
                            onChange={(e) => {props.changeForm({...form, newRoom: null, newFloor: e.target.value}, "form")}}
                            required
                            disabled={!form.newBuilding}
                        />
                        <Select
                            selectLabel="Szoba"
                            name="newRoom"
                            value={form.newRoom || []}
                            optionList={roomOptions}
                            format={{ xs: 12 }}
                            loading={roomLoading}
                            required
                            disabled={!form.newFloor}

                        />
                        <TextField
                            label="Férőhely"
                            name="space"
                            value={space}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                </>
            )}
            <Title {...formProps} title="E-mail" format={{ xs: 12 }}>
                <TextField
                    label="Tárgy"
                    name="emailSubject"
                    value={form.emailSubject || ""}
                    format={{ xs: 12 }}
                />
                <CkEditor
                    type="editor"
                    name="emailContent"
                    data={form.emailContent || ""}
                    format={{ xs: 12 }}
                    onChange={(_, editor) =>
                        props.changeEditor(editor.getData())
                    }
                />
            </Title>
            <SubmitButton
                name="insert"
                type="button"
                onClick={() =>
                    props.changeForm(
                        {
                            ...form,
                            emailSubject: emailTemplate?.subject,
                            emailContent: emailTemplate?.content,
                            errors: {},
                        },
                        "form"
                    )
                }
                format={{ xs: 12 }}
                className={classes.insert}
            >
                E-mail sablon beillesztése
            </SubmitButton>
            <SubmitButton type="submit" disabled={!valid}>
                Küldés
            </SubmitButton>
            <CancelButton onClick={() => history.push("/new-process")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form, relocatableStudents, studentOptions } =
        state.relocationSingle;
    const { newRooms, buildingOptions, floorOptions, roomOptions } =
        state.relocationSingle;
    const activeSemester =
        state.semesters.activeSemester;
    const emailTemplate = state.emailTemplates.data["hydra:member"]
        ? state.emailTemplates.data["hydra:member"]?.find(
              (element) => element["key"] === "relocation_started"
          )
        : {};
    return {
        form,
        relocatableStudents,
        studentOptions,
        newRooms,
        buildingOptions,
        floorOptions,
        roomOptions,
        emailTemplate,
        activeSemester,
    };
}

const actionCreators = {
    changeForm: relocationSingleActions.changeForm,
    defaultForm: relocationSingleActions.defaultForm,
    changeEditor: relocationSingleActions.changeEditor,
    getRelocatableStudents: relocationSingleActions.getRelocatableStudents,
    getNewRooms: relocationSingleActions.getNewRooms,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    filterEmailTemplate: () =>
        emailTemplatesActions.filter({ key: "relocation_started" }),
    addNotification: notificationActions.addNotification,
    startRelocationSingle: relocationSingleActions.startRelocationSingle,
    getActiveSemester:
        semestersActions.getActiveSemester,
};

export default connect(mapState, actionCreators)(RelocationSingle);
