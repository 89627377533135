import API from "../utils/API";
import editApi from "../utils/editApi";

/**
 * Szerkesztéskor, illetve egy-egy specifikus adat lekérésekor használt függvény.
 * @param {URL} url
 *           Lekérendő URL
 * @param  {number} id
 *           Lekérendő adat azonosítója
 * @returns {Object} Szervertől kapott adat
 */
 export const templateGet = (url) => (id) =>
 API.get(`/${url}/${id}`)
     .then((data) => data)
     .catch((error) => Promise.reject(error));

/**
 * Nem APIplatformot használó végpont formok lekéréséhez
 * @param {URL} url
 *           Lekérendő URL
 * @param  {number} id
 *           Lekérendő adat azonosítója
 * @returns {Object} Szervertől kapott adat
 */
export const templateGetNoApi = (url) => (id) =>
API.get(`/${url}/${id}/form`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));

/**
 * Egy elem létrehozásakor használt függvény.
 * @param {string} url
 *          URL, ahová elküldendő a létrehozás
 * @param {Object} form
 *          Létrehozandó elem értéke
 * @returns {Promise} Létrehozás sikeressége (201, ha jó)
 */
 export const templateCreate = (url) => (form, id) =>
 API.post(`/${id ? `${id}${url}` : url}`, form)
     .then((data) => data)
     .catch((error) => Promise.reject(error));

/**
 * Egy elem módosításakor használt függvény.
 * @param {URL} url
 *          URL, ahová elküldendő a módosítás
 * @param {number} id
 *          Módosítandó elem azonosítója
 * @param {Object} form
 *          Módosítás értéke
 * @returns {Promise} Módosítás sikeressége (201, ha jó)
 */
 export const templateUpdate = (url) => (id, form) =>
 editApi.patch(`/${url}/${id}`, form)
     .then((data) => data)
     .catch((error) => Promise.reject(error));

/**
 * Egy elem törléséhez használt függvény.
 * @param {URL} url
 *           URL, ahová a törlési igény küldendő
 * @param  {number} id
 *           Törlendő elem azonosítója
 * @returns {Object} Szervertől kapott adat
 */
 export const templateDelete = (url) => (id) =>
 API.delete(`/${url}/${id}`)
     .then((data) => data)
     .catch((error) => Promise.reject(error));

/**
 * Az összes elem lekérésére használt függvény
 * @param {URL} url
 *           Lekérendő URL
 * @returns {Object} Szervertől kapott adat
 */
export const templateFetch = (url) => () =>
API.get(`/${url}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));