import Grid from "@material-ui/core/Grid";
import Accordion from "../../Common/Accordion";
import PageTemplate from "../../Common/PageTemplate";
import Form from "../../Common/Form";
import TextField from "../../Common/TextField";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import TabsCompleted from "../../Common/TabsCompleted";
import { connect } from "react-redux";
import emailTemplatesActions from "../../../store/administration/emailTemplates/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import { history } from "../../../store";
import EmailTemplatesTab from "./EmailTemplatesTab";
import { useEffect } from "react";

function EmailTemplatesEdit(props) {
    const { form, originalName } = props;
    const { id } = props.match.params;
    const languages = Object.values(form?.translations || []);

    const header = {
        title: "E-mail sablon szerkesztése",
        breadcrumbs: {
            "home": "Főoldal",
            "/email-templates": "E-mail sablonok",
            "emailTemplatesEdit": "E-mail sablon szerkesztése",
            "name": originalName ?? "",
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => props.loadingOff());
        return () => {
            props.defaultForm("form");
        }
    }, []);

    const tabs = languages.map(language => ({
        label: language.locale,
        value: <EmailTemplatesTab language={language} changeForm={props.changeForm} changeEditor={props.changeEditor} />
    }));

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="E-mail sablon szerkesztése" expanded={true}>
                    <Form
                        form={form}
                        changeform={props.changeForm}
                    >
                        <TextField
                            label="Kulcs"
                            format={{ xs: 12 }}
                            name="key"
                            value={form.key || ""}
                            disabled={true}
                        />
                        <TabsCompleted tabContents={tabs} format={{ xs: 12 }} />
                        <SubmitButton onClick={() => {
                            props.loadingOn();
                            props.update(id, form.translations, "/email-templates").then(() => props.loadingOff());
                        }}>
                            Mentés
                        </SubmitButton>
                        <CancelButton onClick={() => history.push("/email-templates")}>
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, originalName } = state.emailTemplates;
    return { form, originalName };
}

const actionCreators = {
    changeForm: emailTemplatesActions.changeForm,
    changeFormInTab: emailTemplatesActions.changeFormInTab,
    defaultForm: emailTemplatesActions.defaultForm,
    changeEditor: emailTemplatesActions.changeEditor,
    get: emailTemplatesActions.get,
    update: emailTemplatesActions.update,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(EmailTemplatesEdit);