import FormPageTemplate from "../../Common/FormPageTemplate";
import financialControlActions from './../../../store/processes/financialControl/actions';
import emailTemplatesActions from './../../../store/administration/emailTemplates/actions';
import enrollmentActions from './../../../store/processes/newProcess/enrollment/actions';
import loadingActions from '../../../store/loading/actions';
import TextField from './../../Common/TextField';
import SubmitButton from './../../Common/SubmitButton';
import { connect } from 'react-redux';
import CancelButton from "../../Common/CancelButton";
import { makeStyles } from '@material-ui/core';
import colors from "../../../styles/colors";
import { history } from './../../../store/index';
import { useEffect } from "react";
import Reminder from "./Reminder";
import axios from "axios";
import { membershipStatusLabel } from "../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import {isKep} from "../../../utils/AppConst";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        }
    }
}))

function FinancialControlEdit(props) {
    const { form, enrollment } = props;
    const { id } = props.match.params;
    const classes = useStyles();

    const header = {
        title: "Részletek",
        breadcrumbs: {
            "process": "Folyamatok",
            "/financial-control": "Pénzügyi ellenőrzés",
            "details": "Részletek"
        }
    }

    const getSemesterStatus = (status) => {
        switch(status) {
            case "active":
                return "Aktív";
            case "passive":
                return "Passzív";
            case "foreign_scholarship":
                return "Külföldi ösztöndíjas";
            default:
                return "";
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.getEnrollment(id),
            props.filterEmailTemplate(),
        ]).then(() => props.loadingOff())
        .catch(() => props.loadingOff());
    }, []);

    const handleApprove = () => {
        props.loadingOn();
        props.approveFinancialCheck(id)
        .then(() => {
            props.loadingOff();
            history.push("/");
        })
        .catch(() => props.loadingOff())
    };

    const formProps = {
        name: "form",
        form: form,
        changeform: props.changeForm,
        isformikForm: true,
        initialerrors: form["errors"] || {},
    }
    
    return (
        <FormPageTemplate
            header={header}
            form={formProps}
        >
            <TextField
                label="Típus"
                name="type"
                value={enrollment.type === "enrollment" ? "Beiratkozás" : "Kiiratkozás" || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={enrollment.lastName || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={enrollment.firstName || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="E-mail cím"
                name="email"
                value={enrollment.email || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            {isKep() &&
                <TextField
                    name="identificationNumberCnp"
                    label="Személyi szám (CNP)"
                    value={enrollment.identificationNumberCnp || ""}
                    format={{xs: 12, md: 6}}
                    disabled
                />
            }
            <TextField
                label="Képzési szint"
                name="trainingLevel"
                value={enrollment.trainingLevel?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Képzési program"
                name="trainingProgram"
                value={enrollment.trainingProgram?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Évfolyam"
                name="collegeYear"
                value={enrollment.collegeYear?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Státusz"
                name="membershipStatus"
                value={membershipStatusLabel(enrollment.membershipStatus || "")}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Aktuális státusz"
                name="student.currentMembershipStatus"
                value={membershipStatusLabel(enrollment.currentMembershipStatus || "")}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Szemeszter státusz"
                name="student.semesterStatus"
                value={getSemesterStatus(enrollment.semesterStatus)}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Campus"
                name="campus"
                value={enrollment.campus?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Épület"
                name="building"
                value={enrollment.building?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Szint"
                name="floor"
                value={enrollment.floor?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <TextField
                label="Szoba"
                name="room"
                value={enrollment.room?.name || ""}
                format={{xs: 12, md: 6}}
                disabled
            />
            <Grid item style={{ marginTop: 'auto' }} format={{xs: 12, md: 12}}>
                <SubmitButton 
                    className={classes.submit}
                    onClick={handleApprove}
                    style={{ marginRight: '5px' }}
                >
                    Jóváhagyás
                </SubmitButton>
                <Reminder formProps={formProps} {...props} style={{ margin: '0 5px' }} />
                <CancelButton onClick={() => history.push("/")} style={{ marginLeft: '5px' }}>
                    Mégsem
                </CancelButton>
            </Grid>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.financialControl;
    const enrollment = state.enrollment.form;
    const emailTemplate = state.emailTemplates.data["hydra:member"] ? state.emailTemplates.data["hydra:member"]?.find(element => element["key"] === 'unpaid_debt') : {};
    return { form, enrollment, emailTemplate };
}

const actionCreators = {
    changeForm: financialControlActions.changeForm,
    approveFinancialCheck: financialControlActions.approveFinancialCheck,
    sendUnpaidDebtNotification: financialControlActions.sendUnpaidDebtNotification,
    getEnrollment: enrollmentActions.getForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    changeEditor: financialControlActions.changeEditor,
    filterEmailTemplate: () => emailTemplatesActions.filter({key: "unpaid_debt"}),
};

export default connect(mapState, actionCreators)(FinancialControlEdit);