export const types = {
    CHANGE_FORM: "@@training_programs/CHANGE_FORM",
    DEFAULT_FORM: "@@training_programs/DEFAULT_FORM",

    FETCH_REQUEST: "@@training_programs/FETCH_REQUEST",
    FETCH_SUCCESS: "@@training_programs/FETCH_SUCCESS",
    FETCH_FAILURE: "@@training_programs/FETCH_FAILURE",

    GET_REQUEST: "@@training_programs/GET_REQUEST",
    GET_SUCCESS: "@@training_programs/GET_SUCCESS",
    GET_FAILURE: "@@training_programs/GET_FAILURE",

}