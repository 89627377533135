import { getCurrentMembershipStatus } from "../../../../utils/AppConst";

function createRowDataForUnassignedTable(rowData) {
    const result = rowData?.map(x => {
        return {
            id: x?.id,
            email: x?.user?.email,
            fullName: x?.user?.fullName,
            trainingLevel: x?.currentTrainingLevel?.name,
            trainingProgram: x?.currentTrainingProgram?.name,
            collegeYear: x?.currentCollegeYear?.name,
            currentMembershipStatus: getCurrentMembershipStatus(x?.currentMembershipStatus),
            university: x?.university?.name,
            universityMajor: x?.universityMajor,
            studentAllocationId: x?.allocations?.[0]?.id,
            preference: x?.studentAllocationPreference,
            operations: ""
        };
    });

    return result;
}

export default createRowDataForUnassignedTable;