import approvalActions from '../../../store/processes/approval/actions';
import enrollmentActions from "../../../store/processes/newProcess/enrollment/actions";
import loadingActions from '../../../store/loading/actions';
import campusesActions from "../../../store/masterData/campuses/actions";
import universitiesActions from "../../../store/masterData/universities/actions";
import { connect } from 'react-redux';
import SubmitButton from '../../Common/SubmitButton';
import CancelButton from '../../Common/CancelButton';
import { history } from '../../../store';
import TemplateForm from './../../Common/TemplateForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    checkIcon: {
        marginRight: "4px"
    }, 
    dialogTitleRow: {
        fontWeight: "bold", 
        paddingLeft: "10px", 
        paddingleft: "10px"
    },
    dialogBodyText: {
        padding: "10px"
    }
}))

function Approval(props) {
    const { form, campuses, universities } = props;
    const { id } = props.match.params;
    const [isEdited, setIsEdited] = useState(false);
    const classes = useStyles();
    const enrollment = form["@type"] === "Enrollment";

    const header = {
        title: enrollment ? "Beiratkozás jóváhagyása" : "Kérvény jóváhagyása",
        breadcrumbs: {
            "approvalEdit": enrollment ? "Beiratkozás jóváhagyása" : "Kérvény jóváhagyása"
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.getEnrollment(id),
            props.fetchCampuses(),
            props.fetchUniversities({}),
        ])
        .then(() => props.loadingOff());
    }, [])

    const handleSubmit = () => {
        let formToSend = {};
        for (const [key, value] of Object.entries(form)) {
            if (typeof(value) === "string" && value.includes("/")) {
                const splittedValue = value.split("/");
                formToSend[key] = Number(splittedValue[splittedValue.length - 1]);
            } else if (typeof(value) === "object" && key !== "address") {
                formToSend[key] = value?.id || "";
            } else {
                formToSend[key] = value;
            }
        } 

        props.loadingOn();
        props.create(isEdited ? formToSend : {}, "/", id)
        .then(() => props.loadingOff())
        .catch(() => props.loadingOff());
    }

    const formProps = {
        form: form,
        changeform: props.changeForm
    }

    return(
        <TemplateForm
            header={header}
            formProps={formProps}
            setIsEdited={setIsEdited}
            campuses={campuses}
            universities={universities}
            id={id}
            getEnrollment={props.getEnrollment}
            saveEnrollment={props.saveEnrollment}
            loadingOn={props.loadingOn}
            loadingOff={props.loadingOff}
        >
            <SubmitButton onClick={handleSubmit}>
                <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
                Jóváhagyás
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </TemplateForm>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    const campuses = state.campuses.data;
    const universities = state.universities.data;
    return { form, campuses, universities };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    defaultForm: enrollmentActions.defaultForm,
    create: approvalActions.create,
    saveEnrollment: approvalActions.saveEnrollment,
    getEnrollment: enrollmentActions.getForm,
    fetchCampuses: campusesActions.filter,
    fetchUniversities: universitiesActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Approval);