import Form from "../../Common/Form";
import TextFieldBasic from "../../Common/TextField";
import EmailTemplatesCopy from "./EmailTemplatesCopy";
import { useSelector } from "react-redux";
import CkEditor from "./../../Common/CkEditor";

const TextField = (props) => {
    const { form, language, ...rest } = props;

    return (
        <TextFieldBasic
            onChange={(e) =>
                props.changeForm(
                    {
                        ...form,
                        translations: {
                            ...form.translations,
                            [language.locale]: {
                                ...form.translations[language.locale],
                                [e.target.name]: e.target.value,
                            },
                        },
                    },
                    "form"
                )
            }
            {...rest}
        />
    );
};

export default function EmailTemplatesTab(props) {
    const { language } = props;
    const { form } = useSelector((state) => state.emailTemplates);

    return (
        <Form childComponent form={form} changeform={props.changeForm}>
            {language.locale !== "hu" && (
                <EmailTemplatesCopy
                    form={form}
                    format={{ xs: 12 }}
                    language={language.locale}
                    changeForm={props.changeForm}
                    changeEditor={props.changeEditor}
                />
            )}
            <TextField
                label="Tárgy"
                format={{ xs: 12, md: 6 }}
                name="subject"
                value={form.translations[language.locale]?.subject || ""}
                form={form}
                language={language}
                changeForm={props.changeForm}
            />
            <TextField
                label="Név"
                format={{ xs: 12, md: 6 }}
                name="name"
                value={form.translations[language.locale]?.name || ""}
                form={form}
                language={language}
                changeForm={props.changeForm}
            />
            <CkEditor
                name="content"
                format={{ xs: 12 }}
                data={form.translations[language.locale]?.content || ""}
                onChange={(_, editor) =>
                    props.changeEditor(language.locale, editor.getData())
                }
            />
        </Form>
    );
}
