export const types = {
    CHANGE_FORM: "@@users/CHANGE_FORM",
    DEFAULT_FORM: "@@users/DEFAULT_FORM",

    FETCH_REQUEST: "@@users/FETCH_REQUEST",
    FETCH_SUCCESS: "@@users/FETCH_SUCCESS",
    FETCH_FAILURE: "@@users/FETCH_FAILURE",

    GET_REQUEST: "@@users/GET_REQUEST",
    GET_SUCCESS: "@@users/GET_SUCCESS",
    GET_FAILURE: "@@users/GET_FAILURE",

}