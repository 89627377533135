import {
    templateChangeForm,
    templateDefaultForm,
    templateFilter,
    templateGet,
} from '../..';
import { types } from "./types";
import usersService from "../../../services/masterData/users";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, usersService),
    get: templateGet(types, usersService),
}

export default actions;