export const types = {
    CHANGE_FORM: "@@room-allocation-list/CHANGE_FORM",
    DEFAULT_FORM: "@@room-allocation-list/DEFAULT_FORM",

    GET_REQUEST: "@@room-allocation-list/GET_REQUEST",
    GET_SUCCESS: "@@room-allocation-list/GET_SUCCESS",
    GET_FAILURE: "@@room-allocation-list/GET_FAILURE",

    GET_ALLOCATION_REQUEST: "@@room-allocation-list/GET_ALLOCATION_REQUEST",
    GET_ALLOCATION_SUCCESS: "@@room-allocation-list/GET_ALLOCATION_SUCCESS",
    GET_ALLOCATION_FAILURE: "@@room-allocation-list/GET_ALLOCATION_FAILURE",

    FILTER_STUDENT_ALLOCATIONS_REQUEST:
        "@@room-allocation-list/FILTER_STUDENT_ALLOCATIONS_REQUEST",
    FILTER_STUDENT_ALLOCATIONS_SUCCESS:
        "@@room-allocation-list/FILTER_STUDENT_ALLOCATIONS_SUCCESS",
    FILTER_STUDENT_ALLOCATIONS_FAILURE:
        "@@room-allocation-list/FILTER_STUDENT_ALLOCATIONS_FAILURE",

    FILTER_UNASSIGNED_STUDENTS_REQUEST:
        "@@room-allocation-list/FILTER_UNASSIGNED_STUDENTS_REQUEST",
    FILTER_UNASSIGNED_STUDENTS_SUCCESS:
        "@@room-allocation-list/FILTER_UNASSIGNED_STUDENTS_SUCCESS",
    FILTER_UNASSIGNED_STUDENTS_FAILURE:
        "@@room-allocation-list/FILTER_UNASSIGNED_STUDENTS_FAILURE",

    ASSIGN_ALLOCATION_REQUEST:
        "@@room-allocation-list/ASSIGN_ALLOCATION_REQUEST",
    ASSIGN_ALLOCATION_SUCCESS:
        "@@room-allocation-list/ASSIGN_ALLOCATION_SUCCESS",
    ASSIGN_ALLOCATION_FAILURE:
        "@@room-allocation-list/ASSIGN_ALLOCATION_FAILURE",

    ASSIGN_ALL_STUDENTS_REQUEST:
        "@@room-allocation-list/ASSIGN_ALL_STUDENTS_REQUEST",
    ASSIGN_ALL_STUDENTS_SUCCESS:
        "@@room-allocation-list/ASSIGN_ALL_STUDENTS_SUCCESS",
    ASSIGN_ALL_STUDENTS_FAILURE:
        "@@room-allocation-list/ASSIGN_ALL_STUDENTS_FAILURE",

    ASSIGN_NEW_STUDENTS_REQUEST:
        "@@room-allocation-list/ASSIGN_NEW_STUDENTS_REQUEST",
    ASSIGN_NEW_STUDENTS_SUCCESS:
        "@@room-allocation-list/ASSIGN_NEW_STUDENTS_SUCCESS",
    ASSIGN_NEW_STUDENTS_FAILURE:
        "@@room-allocation-list/ASSIGN_NEW_STUDENTS_FAILURE",

    CHECK_ASSIGNMENT_STATUS_REQUEST:
        "@@room-allocation-list/CHECK_ASSIGNMENT_STATUS_REQUEST",
    CHECK_ASSIGNMENT_STATUS_SUCCESS:
        "@@room-allocation-list/CHECK_ASSIGNMENT_STATUS_SUCCESS",
    CHECK_ASSIGNMENT_STATUS_FAILURE:
        "@@room-allocation-list/CHECK_ASSIGNMENT_STATUS_FAILURE",

    RESET_STUDENT_ASSIGNMENT_REQUEST:
        "@@room-allocation-list/RESET_STUDENT_ASSIGNMENT_REQUEST",
    RESET_STUDENT_ASSIGNMENT_SUCCESS:
        "@@room-allocation-list/RESET_STUDENT_ASSIGNMENT_SUCCESS",
    RESET_STUDENT_ASSIGNMENT_FAILURE:
        "@@room-allocation-list/RESET_STUDENT_ASSIGNMENT_FAILURE",

    GET_FREE_ROOMS_REQUEST: "@@room-allocation-list/GET_FREE_ROOMS_REQUEST",
    GET_FREE_ROOMS_SUCCESS: "@@room-allocation-list/GET_FREE_ROOMS_SUCCESS",
    GET_FREE_ROOMS_FAILURE: "@@room-allocation-list/GET_FREE_ROOMS_FAILURE",

    CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_REQUEST:
        "@@room-allocation-list/CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_REQUEST",
    CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_SUCCESS:
        "@@room-allocation-list/CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_SUCCESS",
    CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_FAILURE:
        "@@room-allocation-list/CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_FAILURE",

    CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_REQUEST:
        "@@room-allocation-list/CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_REQUEST",
    CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_SUCCESS:
        "@@room-allocation-list/CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_SUCCESS",
    CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_FAILURE:
        "@@room-allocation-list/CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_FAILURE",
};
