import { makeStyles } from "@material-ui/core";
import { memo, useCallback } from "react";
import colors from "../../../styles/colors";
import CkEditor from "../../Common/CkEditor";
import Dialog from "../../Common/Dialog";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import Title from "../../Common/Title";

/**
 * Styles for the ReminderDialog component.
 */
const useStyles = makeStyles(() => ({
    reminder: {
        backgroundColor: colors.reminder,
        "&:hover": {
            backgroundColor: colors.reminderHover,
        },
    },
    insert: {
        backgroundColor: colors.insert,
        "&:hover": {
            backgroundColor: colors.insertHover,
        },
    },
}));

/**
 * ReminderDialog component.
 * A reusable dialog component for sending reminder emails.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.reminderForm - Form data for the reminder email.
 * @param {Object} props.emailTemplate - Email template data.
 * @param {Function} props.handleSendNotification - Function to send the email notification.
 * @param {Function} props.changeForm - Function to change form values.
 * @param {Function} props.changeEditor - Function to change editor values.
 *
 */
const ReminderDialog = memo(function ReminderDialog({
    reminderForm,
    emailTemplate,
    handleSendNotification,
    changeForm,
    changeEditor,
}) {
    const classes = useStyles();

    const reminderFormProps = {
        form: reminderForm,
        name: "reminderForm",
        changeform: changeForm,
    };

    const handleEditorChange = useCallback(
        (_, editor) => changeEditor(editor.getData()),
        []
    );

    const handleInsert = useCallback(
        () =>
            changeForm(
                {
                    ...reminderForm,
                    emailSubject: emailTemplate.subject,
                    emailContent: emailTemplate.content,
                },
                "reminderForm"
            ),
        [changeForm, reminderForm, emailTemplate]
    );

    return (
        <Dialog
            opener={
                <SubmitButton className={classes.reminder}>
                    Emlékeztető
                </SubmitButton>
            }
            title="Emlékeztető"
            cancelText="Mégsem"
            action={
                <SubmitButton 
                    onClick={handleSendNotification} 
                    preventClose={true}
                >
                    Küldés
                </SubmitButton>
            }
        >
            <Form {...reminderFormProps} childComponent="true">
                <Title
                    {...reminderFormProps}
                    title="E-mail"
                    format={{ xs: 12 }}
                    error={reminderForm.errors?.emailContent}
                >
                    <TextField
                        label="Tárgy"
                        name="emailSubject"
                        value={reminderForm.emailSubject || ""}
                        format={{ xs: 12 }}
                        error={reminderForm.errors?.emailSubject}
                        helperText={reminderForm.errors?.emailSubject}
                    />
                    <CkEditor
                        type="editor"
                        name="emailContent"
                        data={reminderForm.emailContent || ""}
                        format={{ xs: 12 }}
                        onChange={handleEditorChange}
                    />
                </Title>
                <SubmitButton
                    name="insert"
                    type="button"
                    onClick={handleInsert}
                    format={{ xs: 12 }}
                    className={classes.insert}
                >
                    E-mail sablon beillesztése
                </SubmitButton>
            </Form>
        </Dialog>
    );
});

export default ReminderDialog;
