import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: "bold",
    },
}));

function KeyCell({ type, elem }) {
    const elemNames = elem.name?.split(".");
    let newElem = elemNames.join('_');
    let lastPart = null;

    if (!isNaN(parseFloat(elemNames[elemNames.length - 1]))) {
        lastPart = elemNames.pop();
        newElem = elemNames.join('_');
    } 

    return (
        <TableCell>
            {
                lastPart ? type?.fields[newElem]  + ' ' + lastPart || newElem + ' ' + lastPart :
                type?.fields[newElem] || elem?.name
            }
        </TableCell>
    );
}

function OldValueCell({ type, elem }) {
    return (
        <TableCell>
            {type?.values?.[`${elem?.old}`] || elem?.old || "-"}
        </TableCell>
    );
}

function NewValueCell({ type, elem }) {
    return (
        <TableCell>
            {type?.values?.[`${elem?.new}`] || elem?.new || "-"}
        </TableCell>
    );
}

export default function SystemLogTable({
    params,
    types,
    type,
    events,
    changes,
}) {
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeaderCell}>
                        Kulcs
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                        Régi érték
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                        Új érték
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Azonosító</TableCell>
                    <TableCell colSpan={2}>{params.data.id}</TableCell>
                </TableRow>
                {changes.map((elem) => (
                    <TableRow key={elem.name}>
                        <KeyCell type={type} elem={elem} />
                        <OldValueCell type={type} elem={elem} />
                        <NewValueCell type={type} elem={elem} />
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell>Esemény</TableCell>
                    <TableCell colSpan={2}>
                        {events?.[`${params.data?.action}`]?.title}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Típus</TableCell>
                    <TableCell colSpan={2}>
                        {types?.[`${params.data?.type}`]?.title}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Felhasználó</TableCell>
                    <TableCell colSpan={2}>
                        {params.data?.realActor
                            ? `${params.data?.actor?.fullName} (${params.data?.realActor?.fullName})`
                            : params.data?.actor?.fullName || "-"}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
