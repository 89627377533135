import API from '../../../utils/API';
import editApi from '../../../utils/editApi';
import SearchForm from '../../../utils/SearchForm';
import {templateDelete, templateGet} from '../..';

function filter(form) {
    //kiemeljük a form[user]-ből az adattagokat hogy megfelelően tudjunk szűrni:
    let formToSend = Object.assign({}, form);

    for (const prop in formToSend['user']) {
        formToSend[`user.${prop}`] = formToSend['user'][prop];
    }

    //campus mező átalakítása
    if (formToSend?.campus) {
        formToSend['user.campus'] = formToSend.campus;
        delete formToSend?.campus;
    }

    if (formToSend?.user) {
        delete formToSend?.user;
    }

    for (const elem in formToSend) {
       if (formToSend[elem] === "") delete formToSend[elem];
    }
    
    const searchForm = new SearchForm(formToSend);

    return API.get('/students', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function create(form) {
    let formToSend = Object.assign({}, form);

    if (form.campus) formToSend.user.campus = formToSend.campus
    delete formToSend?.campus;
    formToSend.currentRoom = form.room;
    delete formToSend?.room;

    for (const elem in formToSend) {
        if (formToSend[elem] === "") delete formToSend[elem];
    }

    if (formToSend?.university) {
        delete formToSend?.university;
    }

    for (const prop in formToSend) {
        if (typeof formToSend[`${prop}`] === "object" && prop !== "user") {
            formToSend[`${prop}`]= formToSend[`${prop}`]["@id"];
        }
    }

    return API.post(`/students`, formToSend)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function update(id, form) {
    let formToSend = Object.assign({}, form);

    //TODO:ezt kivenni innen
    if (formToSend.user && formToSend.user.campus !== "") formToSend.user.campus = form.campus;
    delete formToSend?.campus;
    delete formToSend?.room;

    for (const elem in formToSend) {
        if (formToSend[elem] === "") delete formToSend[elem];
    }

    if (formToSend?.university) {
        delete formToSend?.university;
    }

    for (const prop in formToSend) {
        if (typeof formToSend[`${prop}`] === "object" && prop !== "user") {
            formToSend[`${prop}`]= formToSend[`${prop}`]["@id"];
        }
    }

    // Kiiratkozik-e mező - csak update-ben kell, mert új hallgató nyilván nem iratkozik ki
    formToSend.terminating = form.terminating == 1 ? true : false;

    formToSend.currentRoom = form.currentRoom || null;
    
    return editApi.patch(`/students/${id}`, formToSend)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const studentsService = {
    filter,
    create,
    get: templateGet('students'),
    delete: templateDelete('students'),
    update,
};

export default studentsService;