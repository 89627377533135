import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AccordionSummary from './AccordionSummary';
import colors from "../../styles/colors.js";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import SubmitButton from './SubmitButton';
import Link from './Link';
import AgGrid from './AgGrid';
import Tooltip from './Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import CustomHeader from "./TableCustomHeader.js";
import TableRefresh from "./TableRefresh.js";
import TablePaginator from "./TablePaginator";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        color: colors.primaryYellow,
        fontSize: "large",
    },
    listActionsGrid: {
        justifyContent: 'unset',
    },
    exportButton: {
        backgroundColor: colors.export,
        '&:hover': {
            background: colors.exportHover,
            color: colors.white
        }
    },
    newButton: {
        backgroundColor: colors.new,
        '&:hover': {
            background: colors.newHover,
            color: colors.white
        }
    },
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    accordionDetails: {
        padding: "unset"
    },
    downloadIcon: {
        marginRight: 8
    }
}));

function TooltipRenderer(params) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const value = params.value === 0 ? "0" : params.value;

    return(
        <Tooltip title={value ? value : "-"}>
            <div className={classes.truncate}>{value ? (value) : "-"}</div>
        </Tooltip>
    );
}

/** 
 * @param columnDefs - oszlop tulajdonságok
 * @param rowData - adatok
 * @param frameworkComponents - használt függvények (btncellrenderer, stb.)
 * @param context - pluszban feldolgozandó adat (user.name, user.language, stb.)
 * @param newButtonText - "Új ... létrehozása" szöveg (opcionális)
 * @param to - ".../new" link (opcionális)
 * @param minWidth - oszlopok minimális szélessége (opcionális)
 * @param autoSize - legszélesebb adat is látszódik (opcionális; kevés oszlopos táblázatoknál nem ajánlott)
 * @param actionButtons - React.JSX.Element
*/

export default function Table(props) {
    const [gridApi, setGridApi] = useState(null);
    const classes = useStyles();

    const defaultComparator =  (a, b) => {
        if (
            typeof a !== "object" && typeof a !== "boolean" &&
            typeof b !== "object" && typeof b !== "boolean"
        ) {
            return a?.localeCompare(b);
        }
    }

    const defaultColDef = {
        suppressMovable: true,
        flex: 1,
        sortable: typeof props.sortable !== typeof undefined ? props.sortable : true,
        minWidth: props.minWidth ? props.minWidth : 125,
        cellRenderer: "tooltipRenderer",
        comparator: typeof props.comparator !== typeof undefined ? props.comparator : defaultComparator
    };

    const tableLoading = useSelector(state => state.loading.tableLoading);

    const frameworkComponents = props.frameworkComponents || {};
    

    frameworkComponents.tooltipRenderer = TooltipRenderer;

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (tableLoading && gridApi) {
            gridApi.showLoadingOverlay();
            gridApi.refreshHeader();
        } else if (gridApi) {
            gridApi.hideOverlay();
        }
    }, [tableLoading]);

    if (props.isPaginated) {
        frameworkComponents.agColumnHeader = CustomHeader;
        frameworkComponents.refreshTable = TableRefresh;
        frameworkComponents.searchfunc = props.searchfunc;
        frameworkComponents.setInfo = props.setInfo;
        frameworkComponents.info = props.info;
    }

    return (
    <Grid item container className={classes.root}>
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Accordion expanded={!props.minimized ? true : undefined}>
                    <AccordionSummary
                        text={<Typography className={classes.heading}>{props.title || "Lista"}</Typography>}
                        list="true"
                    />
                    <AccordionActions classes={{root: classes.listActionsGrid}}>
                        <Grid item container xs={12} spacing={1} justifyContent='flex-end'>
                            {props.newButtonText ?
                                <Grid item>
                                    <Link to={props.to} onClick={props.onClick}>
                                        <SubmitButton
                                            classes={{
                                                root: classes.newButton
                                            }}
                                        >
                                        {props.newButtonText}
                                        </SubmitButton>
                                    </Link>
                                </Grid> : ""
                            }
                            {props.download ?
                                <Grid item>
                                    <SubmitButton
                                        onClick={props.onClick}
                                        classes={{
                                            root: classes.newButton
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faDownload} className={classes.downloadIcon}/> {props.downloadText || "Letöltés"}
                                    </SubmitButton>
                                </Grid> : ""
                            }
                            {props.actionButtons}
                        </Grid>
                    </AccordionActions>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Grid item container xs={12}>
                            <div className="ag-theme-material agGridTable" style={{width: '100%'}}>
                                <AgGrid
                                    pagination={(!props.isPaginated && !props.hidePagination) || props.showPagination === true || props.showPagination === undefined ? true : props.showPagination}
                                    paginationPageSize={props.hidePagination === false || props.hidePagination === undefined ? 25 : false}
                                    domLayout='autoHeight'
                                    defaultColDef={defaultColDef}
                                    frameworkComponents={frameworkComponents}
                                    enableCellTextSelection={true}
                                    getRowNodeId={data => data.objectId ?? data.id}
                                    onGridReady={onGridReady}
                                    {...props}
                                />
                            </div>
                            {props.isPaginated && (
                                <TablePaginator
                                    searchfunc={props.searchfunc}
                                    info={props.info}
                                    setInfo={props.setInfo}
                                />
                            )}
                            {props.submitButton}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </Grid>);
}