import { Grid } from '@material-ui/core';
import { connect } from "react-redux";
import campusesActions from "../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../store/loading/actions";
import Accordion from "../../../Common/Accordion";
import PageTemplate from "../../../Common/PageTemplate";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store";
import { useEffect } from "react";
import buildingsActions from '../../../../store/masterData/buildings/actions';
import Address from '../../../Common/Address';
import * as yup from 'yup';

function BuildingsForm(props) {
    const { form, campusForm, id, campusId } = props;
    const title = "Épület megtekintése";

    const URL = `/campuses/${campusId}/buildings`;

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            "buildingsView": title,
            "name": form?.name ?? ''
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id).then(() => {
            props.getCampus(campusId).then(() => props.loadingOff());
        });
        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, campus: campusForm['@id'] }, "form")
    }, [campusForm['@id'], form?.campus])

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        /* address: yup.object({
            city: yup.string().required("Kötelező megadni"),
            postCode: yup.string().required("Kötelező megadni"),
            streetName: yup.string().required("Kötelező megadni"),
            streetType: yup.string().required("Kötelező megadni"),
            houseNumber: yup.string().required("Kötelező megadni"),
        }) */
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        initialvalues: form,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
        isformikform: true
    }


    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            label="Campus"
                            name="campus"
                            value={campusForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Név"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <Address
                            format={{ xs: 12 }}
                            {...formProps}
                            disabled={true}
                        />
                        <CancelButton onClick={() => history.push(URL)}>
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.buildings;
    const campusForm = state.campuses.form;
    return { form, campusForm };
}

const actionCreators = {
    changeForm: buildingsActions.changeForm,
    defaultForm: buildingsActions.defaultForm,
    getCampus: campusesActions.get,
    get: buildingsActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(BuildingsForm);