export const types = {
    CHANGE_FORM: "@@systemLog/CHANGE_FORM",
    DEFAULT_FORM: "@@systemLog/DEFAULT_FORM",

    FETCH_REQUEST: "@@systemLog/FETCH_REQUEST",
    FETCH_SUCCESS: "@@systemLog/FETCH_SUCCESS",
    FETCH_FAILURE: "@@systemLog/FETCH_FAILURE",

    GET_TYPES_AND_EVENTS_REQUEST: "@@systemLog/GET_TYPES_AND_EVENTS_REQUEST",
    GET_TYPES_AND_EVENTS_SUCCESS: "@@systemLog/GET_TYPES_AND_EVENTS_SUCCESS",
    GET_TYPES_AND_EVENTS_FAILURE: "@@systemLog/GET_TYPES_AND_EVENTS_FAILURE",

    FETCH_SET_INFO: "@@systemLog/FETCH_SET_INFO",
}