import operationalControlActions from '../../../store/processes/operationalControl/actions';
import enrollmentActions from '../../../store/processes/newProcess/enrollment/actions';
import loadingActions from '../../../store/loading/actions';
import { connect } from 'react-redux';
import SubmitButton from '../../Common/SubmitButton';
import CancelButton from '../../Common/CancelButton';
import { history } from '../../../store';
import campusesActions from "../../../store/masterData/campuses/actions";
import buildingsActions from "../../../store/masterData/buildings/actions";
import floorsActions from "../../../store/masterData/floors/actions";
import roomsActions from "../../../store/masterData/rooms/actions";
import TemplateForm from '../../Common/TemplateForm';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import TextField from '../../Common/TextField';
import axios from 'axios';

function OperationalControl(props) {
    const { form, campuses, buildings, floors, rooms } = props;
    const { id } = useParams();

    const header = {
        title: "Üzemeltetői ellenőrzés",
        breadcrumbs: {
            "approval": "Üzemeltetői ellenőrzés",
        }
    }

    const formProps = {
        form: form,
        changeform: props.changeForm
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.fetchCampuses(),
            props.fetchBuildings(),
            props.fetchFloors(),
            props.fetchRooms(),
            props.get(id)
        ])
            .then(() => props.loadingOff());
    }, [])

    const handleSubmit = () => {
        props.loadingOn();
        props.create(id, "/").then(() => props.loadingOff());
    }

    return (
        <TemplateForm
            header={header}
            formProps={formProps}
            disableEdit
        >
            <TextField
                label="Campus"
                name="campus"
                value={form.campus?.name || campuses?.['hydra:member']?.find(campus => campus?.['@id'] === form.campus)?.name || ""}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label="Épület"
                name="building"
                value={form.building?.name || buildings?.['hydra:member']?.find(building => building?.['@id'] === form.building)?.name || ""}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label="Szint"
                name="floor"
                value={form.floor?.name || floors?.['hydra:member']?.find(floor => floor?.['@id'] === form.floor)?.name || ""}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label="Szoba"
                name="room"
                value={form.room?.name || rooms?.['hydra:member']?.find(room => room?.['@id'] === form.room)?.name || ""}
                format={{ xs: 12 }}
                disabled
            />
            <SubmitButton onClick={handleSubmit}>
                Kiadás
            </SubmitButton>
            <CancelButton
                onClick={() => history.push("/")}
            >
                Mégsem
            </CancelButton>
        </TemplateForm>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    const campuses = state.campuses.data;
    const buildings = state.buildings.data;
    const floors = state.floors.data;
    const rooms = state.rooms.data;
    return { form, campuses, buildings, floors, rooms };
}

const actionCreators = {
    changeForm: operationalControlActions.changeForm,
    defaultForm: operationalControlActions.defaultForm,
    create: operationalControlActions.create,
    get: enrollmentActions.getForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    fetchCampuses: campusesActions.filter,
    fetchBuildings: buildingsActions.fetch,
    fetchFloors: floorsActions.fetch,
    fetchRooms: roomsActions.fetch
};

export default connect(mapState, actionCreators)(OperationalControl);