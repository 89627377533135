export const setInfo = (props, infokey, infoval, infokey2 = null, infoval2 = null) => {
    if (props.loading || props.tableLoading) {
        return;
    }

    props.setInfo(infokey, infoval);
    const newInfo = Object.assign({}, props.info);
    newInfo[infokey] = infoval;

    if (infokey2 && infoval2) {
        newInfo[infokey2] = infoval2;
        for (const [key, value] of Object.entries(newInfo[infokey2])) {
            if (Array.isArray(value)) {
                newInfo[infokey2][key] = value.map((val) =>
                    val.value === undefined || val.value === null ? val : val.value
                );
            }
        }
    }

    for (const [key, value] of Object.entries(newInfo[infokey])) {
        if (Array.isArray(value) && value.length > 0 && typeof value[0] !== typeof undefined) {
            newInfo[infokey][key] = value.map((val) =>
                val.value === undefined || val.value === null ? val : val.value
            );
        }
    }

    if (newInfo.filter) {
        for(const [key, value] of Object.entries(newInfo.filter)) {
            newInfo[key] = value;
            delete newInfo.filter;
        }
    }

    props.search(newInfo);
};
