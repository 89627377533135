//DELETEME:

const programOptions = [
    {value: "juniorTraining", label: "Juniorképzés"},
    {value: "leadershipTraining", label: "Vezetőképző akadémia"}
]

const trainingOptions = [
    {value: "law", label: "Jog"},
    {value: "economics", label: "Közgazdaságtan"},
    {value: "internationalRelations", label: "Nemzetközi kapcsolatok"},
    {value: "centralEurope", label: "Közép-Európa"},
    {value: "media", label: "Média"},
    {value: "psychology", label: "Pszichológia"}
];

const statusOptions = [
    {value: "new", label: "Új belépő"},
    {value: "internal", label: "Belsős"},
    {value: "external", label: "Külsős"}
];

const trainingFormOptions = [
    {value: "ba", label: "BA"},
    {value: "bsc", label: "BSc"},
    {value: "ma", label: "MA"},
    {value: "msc", label: "MSc"},
    {value: "undivided", label: "Osztatlan"},
    {value: "other", label: "Egyéb"}

];

const financingFormOptions = [
    {value: "state_subsidized", label: "Államilag támogatott"},
    {value: "cost", label: "Önköltséges"},
];

export const getFinancingFormLabelByValue = value => financingFormOptions.find(option => option.value === value)?.label || ""

const studentStatusOptions = [
    {value: "active", label: "Aktív"},
    {value: "passive", label: "Passzív"},
];

export const options = {
    statusOptions,
    programOptions,
    trainingOptions,
    trainingFormOptions,
    financingFormOptions,
    studentStatusOptions
}