export const types = {
    CHANGE_FORM: "@@termination-financial-check/CHANGE_FORM",
    DEFAULT_FORM: "@@termination-financial-check/DEFAULT_FORM",
    CHANGE_EDITOR: "@@relocation_financial_control/CHANGE_EDITOR",

    GET_REQUEST: "@@termination-financial-check/GET_REQUEST",
    GET_SUCCESS: "@@termination-financial-check/GET_SUCCESS",
    GET_FAILURE: "@@termination-financial-check/GET_FAILURE",

    SEND_UNPAID_DEBT_NOTIFICATION_REQUEST:
        "@@termination-financial-check/SEND_UNPAID_DEBT_NOTIFICATION_REQUEST",
    SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS:
        "@@termination-financial-check/SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS",
    SEND_UNPAID_DEBT_NOTIFICATION_FAILURE:
        "@@termination-financial-check/SEND_UNPAID_DEBT_NOTIFICATION_FAILURE",

    UPDATE_FINANCIAL_COMMENT_REQUEST:
        "@@termination-financial-check/UPDATE_FINANCIAL_COMMENT_REQUEST",
    UPDATE_FINANCIAL_COMMENT_SUCCESS:
        "@@termination-financial-check/UPDATE_FINANCIAL_COMMENT_SUCCESS",
    UPDATE_FINANCIAL_COMMENT_FAILURE:
        "@@termination-financial-check/UPDATE_FINANCIAL_COMMENT_FAILURE",

    APPROVE_FINANCIAL_CHECK_REQUEST:
        "@@termination-financial-check/APPROVE_FINANCIAL_CHECK_REQUEST",
    APPROVE_FINANCIAL_CHECK_SUCCESS:
        "@@termination-financial-check/APPROVE_FINANCIAL_CHECK_SUCCESS",
    APPROVE_FINANCIAL_CHECK_FAILURE:
        "@@termination-financial-check/APPROVE_FINANCIAL_CHECK_FAILURE",
    
    GET_FORM_REQUEST: "@@termination-financial-check/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@termination-financial-check/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@termination-financial-check/GET_FORM_FAILURE",
};
