import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import roomLayoutActions from "../../../../store/forms/roomLayout/actions";
import Accordion from "../../../Common/Accordion"
import Form from "../../../Common/Form"
import SubmitButton from "../../../Common/SubmitButton"
import TextField from "../../../Common/TextField"

function RoomLayout(props) {
    const { form } = props;

    return(
        <Grid item xs={12}>
            <Accordion title="Szobabeosztás" expanded>
                <Form
                    form={form}
                    changeform={props.changeForm}
                >
                    <TextField
                        label="Szint"
                        name="floor"
                        value={form.floor || ""}
                        format={{xs: 12, md: 4}}
                    />
                    <TextField
                        label="Szobaszám"
                        name="roomNumber"
                        value={form.roomNumber || ""}
                        format={{xs: 12, md: 4}}
                    />
                    <TextField
                        label="Szobatársak"
                        name="roommates"
                        value={form.roommates || ""}
                        format={{xs: 12, md: 4}}
                    />
                    <SubmitButton>
                        Mentés
                    </SubmitButton>
                </Form>
            </Accordion>
        </Grid>
    );
}

function mapState(state) {
    const { form } = state.roomLayout;
    return { form };
}

const actionCreators = {
    changeForm: roomLayoutActions.changeForm,
    defaultForm: roomLayoutActions.defaultForm
};

export default connect(mapState, actionCreators)(RoomLayout);