import { TableHead, TableRow } from "@material-ui/core";
import { Children } from "react";
import TableHeaderCell from "./TableHeaderCell";

function TableHeader(props) {
    const { columnDefs } = props;

    return (
        <TableHead>
            <TableRow>
                <TableHeaderCell />
                {Children.toArray(columnDefs?.map(x => (
                    <TableHeaderCell>{x.headerName}</TableHeaderCell>
                )))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeader;