import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tooltip, IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CachedIcon from "@material-ui/icons/Cached";
import API from "../../../utils/API";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import { clearFilterStorage } from "../../../utils/FilterStorage";
import { formatDate } from "../../../utils/timezoneConverter";
import { useTenant } from "../../../utils/TenantContext";

const syncOperations = [
    { name: "Egyetem", endpoint: "/sync/university", type: "universities" },
    { name: "Campus", endpoint: "/sync/campus", type: "campuses" },
    { name: "Beiratkozási dokumentumok", endpoint: "/sync/enrollment-document", type: "enrollment-documents" },
    { name: "Szemeszter", endpoint: "/sync/semester", type: "semesters" },
    { name: "Képzések", endpoint: "/sync/training-level", type: "training-levels" },
    { name: "Hallgatói folyamatok", endpoint: "/sync/student-process", type: "student-process" },
    { name: "Felhasználók", endpoint: "/sync/user", type: "users/allUserForSync" },
    { name: "Tanulók", endpoint: "/sync/student", type: "students/allStudentForSync" },
];

function Sync({ loadingOn, loadingOff, addNotification }) {
    const [syncLogs, setSyncLogs] = useState({});
    const tenant = useTenant();

    const clearRedisCache = () => {
        loadingOn();
        clearFilterStorage();
        API.post("/sync/clear-cache")
            .then(() => {
                addNotification("success", "Redis cache sikeresen törölve");
            })
            .catch(() => {
                addNotification("error", "Redis cache törlése sikertelen");
            })
            .finally(() => loadingOff());
    };

    const updateSyncLogDates = () => {
        loadingOn();
        API.get("/sync/logs")
            .then((response) => {
                const logs = response.data.reduce((acc, log) => {
                    acc[log.type] = log.endedAt;
                    return acc;
                }, {});
                setSyncLogs(logs);
            })
            .finally(() => loadingOff());
    };

    useEffect(() => {
        updateSyncLogDates();
    }, []);

    const handleSync = (name, endpoint) => {
        loadingOn();
        addNotification("info", `${name} szinkronizáció elindult`);

        API.get(endpoint).then((response) => {
            const newIntervalId = setInterval(() => {
                API.get(`/sync/check/${response.data.sessionId}`)
                    .then((checkResponse) => {
                        if (checkResponse.data.status === "success") {
                            loadingOff();
                            clearInterval(newIntervalId);
                            addNotification("success", `${name} szinkronizáció elkészült`);
                            updateSyncLogDates();
                        } else if (checkResponse.data.status === "error") {
                            loadingOff();
                            clearInterval(newIntervalId);
                            addNotification("error", `${name} szinkronizáció hibára futott`);
                        }
                    })
                    .catch(() => {
                        loadingOff();
                        clearInterval(newIntervalId);
                        addNotification("error", `${name} szinkronizáció hibára futott`);
                    });
            }, 5000);
        });
    };

    const header = {
        title: "Szinkronizáció",
        breadcrumbs: {
            administration: "Adminisztráció",
            processes: "Szinkronizáció",
        },
    };

    const BtnCellRenderer = (params) => (
        <Tooltip title={`${params.data.name} szinkronizáció`}>
            <IconButton style={{ color: "#5ca8c1" }} onClick={() => handleSync(params.data.name, params.data.endpoint)}>
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    );

    function DateCellRenderer(params) {
        return formatDate(params.value, tenant.timezone.offset);
    }

    const table = {
        columnDefs: [
            { headerName: "Név", field: "name" },
            {
                headerName: "Utolsó frissítés dátuma",
                field: "type",
                valueGetter: (params) => syncLogs[params.data.type] || "-",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            },
        ],
        rowData: syncOperations,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
        },
    };

    return (
        <ListPageTemplate
            header={header}
            table={table}
            actionButtons={
                <Tooltip title="Redis cache törlés">
                    <IconButton style={{ color: "#5ca8c1" }} onClick={clearRedisCache}>
                        <CachedIcon />
                    </IconButton>
                </Tooltip>
            }
        />
    );
}

const mapState = () => ({});

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Sync);
