import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import downloadAPI from '../../../utils/downloadAPI';

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/enrollments/report', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function downloadExport(filter) {
    const searchForm = new SearchForm(filter);

    return downloadAPI.get(`/enrollments/report/export`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function downloadUniversityAdmissionDecision(id) {
    return downloadAPI.get(`/enrollments/${id}/university-admission-decision/download`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const studentsListService = {
    filter,
    downloadExport,
    downloadUniversityAdmissionDecision
};

export default studentsListService;