import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import campusesActions from "../../../../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../../../../store/loading/actions";
import notificationActions from "../../../../../../../store/notification/actions";
import Accordion from "../../../../../../Common/Accordion";
import PageTemplate from "../../../../../../Common/PageTemplate";
import TextField from "../../../../../../Common/TextField";
import Form from "../../../../../../Common/Form";
import CancelButton from "../../../../../../Common/CancelButton";
import { history } from "../../../../../../../store";
import { useEffect } from "react";
import buildingsActions from '../../../../../../../store/masterData/buildings/actions';
import floorsActions from '../../../../../../../store/masterData/floors/actions';
import roomsActions from '../../../../../../../store/masterData/rooms/actions';
import itemsActions from '../../../../../../../store/masterData/items/actions';
import Select from '../../../../../../Common/Select';
import { itemStatus } from '../../../../../../../utils/AppConst';
import axios from "axios";
import * as yup from 'yup';

function ItemsForm(props) {
    const {
        form,
        campusId,
        buildingId,
        floorId,
        roomId,
        id,
        floorForm,
        campusForm,
        buildingForm,
        roomForm
    } = props;
    const title = "Tárgy megtekintése";

    const URL = `/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}/items`;

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors`]: "Szintek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms`]: "Szobák",
            [`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}/items`]: "Tárgyak",
            "itemsView": title,
            "name": form?.name ?? "",
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.getBuilding(buildingId),
            props.getFloor(floorId),
            props.getRoom(roomId),
            props.get(id),
        ])
            .finally(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, room: roomForm['@id'] }, "form")
    }, [roomForm['@id'], form?.room])

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        amount: yup.number(),
        inventoryNumber: yup.string().max(128, "Túl hosszú"),
        status: yup.mixed().required("Kötelező megadni")
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        initialvalues: form,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
        isformikform: true
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            label="Campus"
                            name="campus"
                            value={campusForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Épület"
                            name="building"
                            value={buildingForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Szint"
                            name="floor"
                            value={floorForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Szoba"
                            name="room"
                            value={roomForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Név"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Mennyiség"
                            type="number"
                            name="amount"
                            value={form.amount || ""}
                            format={{ xs: 12, md: 6 }}
                            rules={{
                                number: "true"
                            }}
                            disabled={true}
                        />
                        <TextField
                            label="Leltári szám"
                            name="inventoryNumber"
                            value={form.inventoryNumber || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <Select
                            selectLabel="Állapot"
                            name="status"
                            value={form.status || ""}
                            optionList={itemStatus}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <CancelButton onClick={() => history.push(URL)}>
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.items;
    const floorForm = state.floors.form;
    const campusForm = state.campuses.form;
    const buildingForm = state.buildings.form;
    const roomForm = state.rooms.form;
    return { form, floorForm, campusForm, buildingForm, roomForm };
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,
    getFloor: floorsActions.get,
    getRoom: roomsActions.get,

    get: itemsActions.get,
    changeForm: itemsActions.changeForm,
    defaultForm: itemsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(ItemsForm);