export const types = {
    CHANGE_FORM: "@@approval/CHANGE_FORM",
    DEFAULT_FORM: "@@approval/DEFAULT_FORM",

    CREATE_REQUEST: "@@approval/CREATE_REQUEST",
    CREATE_SUCCESS: "@@approval/CREATE_SUCCESS",
    CREATE_FAILURE: "@@approval/CREATE_FAILURE",

    UPDATE_REQUEST: "@@approval/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@approval/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@approval/UPDATE_FAILURE",

    GET_REQUEST: "@@approval/GET_REQUEST",
    GET_SUCCESS: "@@approval/GET_SUCCESS",
    GET_FAILURE: "@@approval/GET_FAILURE",
}