import API from '../../../utils/API';
import { templateGetNoApi } from '../..';

const headers = {
    "Content-Type": "application/ld+json",
    "X-Requested-With": "XMLHttpRequest",
}

function post(id) {
    return API.post(`/enrollments/${id}/take-over-room`, {}, {
        headers: headers
    })
    .then(data => data)
    .catch((error) => Promise.reject(error));
}

function get(enrollmentId) {
    return templateGetNoApi("enrollments", enrollmentId);
}

const roomAcceptanceReportService = {
    create: post,
    get: get
};

export default roomAcceptanceReportService;
