import React, { Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SelectBasic from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import colors from "../../styles/colors";
import "../../styles/Select.scss";
import { useField, useFormikContext } from "formik";
import FormikAutocomplete from "./FormikAutocomplete";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    minWidth: 120,
  },
  labelFocusedRoot: {
    "& label.MuiFormLabel-root.Mui-focused": {
      color: colors.primaryBlue,
    },
  },
}));

const useOutlinedInputStyles = makeStyles(() => ({
    root: {
        "& $notchedOutline": {
            borderColor: colors.primaryBlue,
        },
        "&:hover $notchedOutline": {
            borderColor: colors.primaryBlue,
        },
        "&$focused $notchedOutline": {
        borderColor: colors.primaryBlue,
        },
    },
    focused: {},
    notchedOutline: {},
}));

export default function FormikSelect(props) {
    const {name, functionToChild, formToChild, formNameToChild} = props;
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const { setFieldTouched, setFieldValue, initialErrors } = useFormikContext();
    const [field, meta] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    //kivesszük a value-t mert undefined először 
    const { value, ...fieldProps } = field;

    if (props.multiple) {
        return (
            <FormikAutocomplete
                options={props.optionList}
                label={props.selectLabel}
                {...props}
            />
        );
    }

    const handleChange = e => {
        if (e.target.name.includes(".")) {
            const innerForm =  e.target.name.split(".")[0];
            const name = e.target.name.split(".")[1];

            functionToChild({
                ...formToChild,
                [innerForm]: {
                    ...formToChild[innerForm],
                    [name]: e.target.value
                }
            }, formNameToChild)
            setFieldValue(field.name, value);
            setFieldTouched(field.name, true, true);
        } else {
            functionToChild({...formToChild, [e.target.name]: e.target.value}, formNameToChild)
            setFieldValue(field.name, value);
            setFieldTouched(field.name, true, true);
        }
        if (props.onChange) {
            props.onChange(e);
        }
    }

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current?.offsetWidth);
    }, []);

    const handleBlur = (e) => {
        delete initialErrors[field.name];
        field.onBlur(e);
    }

    if (props.loading) {
        return <Skeleton height={40} variant="rect" />
    }

    return (
        <FormControl
            margin={props.margin || "dense"}
            fullWidth
            variant="outlined"
            disabled={props.disabled}
            className={classes.formControl}
            error={props.error || false}
            classes={{ root: classes.labelFocusedRoot }}
            size="small"
        >
        <InputLabel
            ref={inputLabel}
            htmlFor={props.labelId}
            required={props.required}
            error={!!errorText}
        >
            {props.selectLabel}
        </InputLabel>
        <SelectBasic
            {...fieldProps}
            required={props.required}
            shrink={props.required ? "1" : "0"}
            onChange={handleChange}
            onBlur={handleBlur}
            input={
            <OutlinedInput
                required={props.required}
                shrink={props.required ? "1" : "0"}
                value={props.value}
                notched={props.shrink}
                defaultValue={props.defaultValue}
                labelWidth={labelWidth}
                name={props.name}
                id={props.id}
                margin="dense"
                size="small"
                classes={outlinedInputClasses}
            />
            }
            label={props.label}
            multiple={props.multiple}
            error={!!errorText}
        >
            {
                Children.toArray(
                    props.optionList?.map(item => (
                        <MenuItem value={item?.value}>
                            {item?.label}
                        </MenuItem>
                    ))
                )
            }
        </SelectBasic>
        {errorText ? <FormHelperText error={true}> {errorText} </FormHelperText> : ""}
        </FormControl>
  );
}
