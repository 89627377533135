import {
    templateChangeForm,
    templateDefaultForm,
    templateFilter,
    templateGet,
} from "../..";
import { types } from "./types";
import semestersService from "../../../services/masterData/semesters";

const getActiveSemester = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterFilter = {
        startDateTo: today,
        endDateFrom: today,
    };
    dispatch({ type: types.GET_ACTIVE_SEMESTER_REQUEST });
    return semestersService.filterJSON(activeSemesterFilter).then(
        (data) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_SUCCESS,
                data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FAILURE,
                error,
            });
            return Promise.reject(error);
        }
    );
};

const getActiveSemesterForEnrollment = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterForEnrollmentFilter = {
        enrollmentStartDateTo: today,
        enrollmentEndDateFrom: today,
    };
    dispatch({ type: types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_REQUEST });
    return semestersService.filterJSON(activeSemesterForEnrollmentFilter).then(
        (data) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_SUCCESS,
                data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_FAILURE,
                error,
            });
            return Promise.reject(error);
        }
    );
};

const getActiveSemesterForTermination = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterForTerminationFilter = {
        terminationStartDateTo: today,
        terminationEndDateFrom: today,
    };
    dispatch({ type: types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_REQUEST });
    return semestersService.filterJSON(activeSemesterForTerminationFilter).then(
        (data) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_SUCCESS,
                data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_FAILURE,
                error,
            });
            return Promise.reject(error);
        }
    );
};

const getActiveSemesterForRoomAllocation = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterForRoomAllocationFilter = {
        roomAllocationStartDateTo: today,
        roomAllocationEndDateFrom: today,
    };
    dispatch({ type: types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_REQUEST });
    return semestersService
        .filterJSON(activeSemesterForRoomAllocationFilter)
        .then(
            (data) => {
                dispatch({
                    type: types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
};

const getActiveSemesterForRelocation = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterForRelocationFilter = {
        relocationStartDateTo: today,
        relocationEndDateFrom: today,
    };
    dispatch({ type: types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_REQUEST });
    return semestersService.filterJSON(activeSemesterForRelocationFilter).then(
        (data) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_SUCCESS,
                data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_FAILURE,
                error,
            });
            return Promise.reject(error);
        }
    );
};

const getActiveSemesterForMoveOut = () => (dispatch) => {
    const today = new Date().toISOString().slice(0, 10);

    const activeSemesterForMoveOutFilter = {
        moveOutStartDateTo: today,
        moveOutEndDateFrom: today,
    };

    dispatch({ type: types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_REQUEST });
    return semestersService.filterJSON(activeSemesterForMoveOutFilter).then(
        (data) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_SUCCESS,
                data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_FAILURE,
                error,
            });
            return Promise.reject(error);
        }
    );
};

const actions = {
    getActiveSemester,
    getActiveSemesterForEnrollment,
    getActiveSemesterForTermination,
    getActiveSemesterForMoveOut,
    getActiveSemesterForRoomAllocation,
    getActiveSemesterForRelocation,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, semestersService),
    get: templateGet(types, semestersService),
};

export default actions;
