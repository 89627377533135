import { types } from "./types";

const initialState = {
    filterStorageKey: "administration.emailTemplates",
    filter: {},
    form: {},
    data: [],
    originalName: "",
}

export function emailTemplates(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                originalName: initialState.originalName,
            }
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    translations: {
                        ...state.form.translations,
                        [action.language]: {
                            ...state.form.translations[action.language],
                            content: action.value
                        }
                    }
                }
            }
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
                originalName: "",
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
                originalName: action.data.data.name,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
                originalName: "",
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}