import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import Accordion from "../../Common/Accordion";
import PageTemplate from "../../Common/PageTemplate";
import Form from "../../Common/Form";
import CancelButton from "../../Common/CancelButton";
import { history } from "../../../store";
import { useEffect } from "react";
import * as yup from 'yup';
import TextField from "../../Common/TextField";

function TrainingLevelsForm(props) {
    const { form, id } = props;
    const title = "Képzési szint megtekintése";


    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/training-levels": "Képzési szintek",
            "trainingLevelsView": title,
            "name": form.name ?? ""
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    const validationSchema = yup.object().shape({
        name: yup.string().required("Kötelező megadni")
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        initialvalues: form,
        isformikform: true,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            name="name"
                            id="name"
                            value={form.name || ""}
                            format={{ xs: 4 }}
                            disabled={true}
                        />
                        <TextField
                            label="Archiválva?"
                            name="archive"
                            id="archive"
                            value={form.archive ? "Igen" : "Nem"}
                            format={{ xs: 4 }}
                            disabled={true}
                        />
                        <Grid container format={{ xs: 12 }} spacing={1}>
                            <Grid item>
                                <CancelButton onClick={() => history.push("/training-levels")}>
                                    Vissza
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.trainingLevels;
    return { form };
}

const actionCreators = {
    changeForm: trainingLevelsActions.changeForm,
    defaultForm: trainingLevelsActions.defaultForm,
    get: trainingLevelsActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(TrainingLevelsForm);