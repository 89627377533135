import { options, VALIDATION_MESSAGES } from "../../../../utils/AppConst";
import Select from "../../../Common/Select";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import CkEditor from "../../../Common/CkEditor";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import colors from "../../../../styles/colors";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import studentAllocationPreferenceActions from "../../../../store/processes/newProcess/studentAllocationPreference/actions";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import notificationActions from "../../../../store/notification/actions";
import studentsActions from "../../../../store/masterData/students/actions";
import trainingLevelsActions from "../../../../store/masterData/trainingLevels/actions";
import loadingActions from "../../../../store/loading/actions";
import { useEffect, useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { getUser } from "../../../../utils/User";
import { makeOptionsFromID } from "../../../../utils/listOptions/makeOptionsFromID";
import { history } from "../../../../store";

const useStyles = makeStyles(() => ({
    insert: {
        backgroundColor: colors.insert,
        "&:hover": {
            backgroundColor: colors.insertHover,
        },
    },
}));

/**
 * -> Campus az első mező - read only és csak a budapesti központ lehet itt
 *      -> erre egy megoldás, hogy ID alapján korlátozzuk ezt a részt (vagy talán rövid név alapján)
 *      -> ezt az új folyamat menünpontban célszerű megadni
 *      -> továbbá kell egy validáció erre az oldalra, hogy ez jól működjön
 * -> Státusz
 *      -> alapból belsős és erre szűr a rendszer, de hozzá lehet adni a külsőset is (többesválasztó)
 * -> Képzési szint
 *      -> iskolarendszeri kézpés, műhelytag, iskolarendszer hosszabbítása
 * -> Hallgatók
 *      -> legalább egy szemeszterrel rendelkező hallgatók jelennek meg a listában
 *
 * @param {*} props
 * @returns
 */
function StudentAllocationPreference(props) {
    const { form, students, studentOptions, emailTemplate, trainingLevels } =
        props;
    const [studentLoading, setStudentLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const campus = getUser()?.campus;

    const header = {
        title: "Szobatárs preferencia",
        breadcrumbs: {
            processes: "Folyamatok",
            "/new-process": "Új folyamat",
            "student-allocation-preference": "Szobatárs preferencia",
        },
    };

    const validationSchema = yup.object().shape({
        currentTrainingLevel: yup
            .mixed()
            .required(VALIDATION_MESSAGES.required),
        membershipStatus: yup.mixed().required(VALIDATION_MESSAGES.required),
        students: yup.mixed().required(VALIDATION_MESSAGES.required),
        emailSubject: yup.mixed().required(VALIDATION_MESSAGES.required),
        emailContent: yup.mixed().required(VALIDATION_MESSAGES.required),
    });

    useEffect(() => {
        validationSchema.isValid(form).then((valid) => {
            setValid(valid);
        });
    }, [form]);

    const customValidation = (propertyPath, message) => {
        if (propertyPath.includes("students[")) {
            enqueueSnackbar(
                { message: message, variant: "error" },
                { persist: false }
            );
        } else if (propertyPath === "") {
            props.addNotification("error", message);
        }
    };

    const formProps = {
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        onsubmit: () => props.startStudentAllocationPreference(form),
        isformikform: true,
        customvalidation: customValidation,
    };

    useEffect(() => {
        props.loadingOn();
        axios
            .all([props.filterEmailTemplate(), props.fetchTrainingLevels()])
            .finally(() => props.loadingOff());
        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, students: [] }, "form");
        props.changeForm([], "studentOptions");
    }, [form.currentTrainingLevel, form.membershipStatus]);

    useEffect(() => {
        if (students["hydra:member"]) {
            props.changeForm(
                students["hydra:member"].map((student) => ({
                    value: student["@id"],
                    label:
                        student.user?.lastName + " " + student.user?.firstName,
                })),
                "studentOptions"
            );
        }
    }, [students]);

    useEffect(() => {
        props.changeForm(
            {...form, campus: campus?.["@id"]},
            "form"
        )
    }, [campus])

    const handleFilterStudents = () => {
        props.changeForm(
            { ...form, students: []},
            "form"
        );
        if (form.membershipStatus && form.currentTrainingLevel && form.campus) {
            setStudentLoading(true);
            props
                .fetchStudents({
                    user: {
                        campus: form.campus,
                    },
                    currentTrainingLevel: form.currentTrainingLevel,
                    membershipStatus: form.membershipStatus,
                    pagination: false,
                    terminating: false,
                })
                .then(() => setStudentLoading(false));
        }
    };

    const handleSelectAll = () =>
        props.changeForm(
            { ...form, students: studentOptions?.map((stud) => stud.value) },
            "form"
        );

    return (
        <FormPageTemplate header={{ ...header }} form={formProps}>
            <TextField
                name="campus"
                value={campus?.name}
                format={{ xs: 12 }}
                disabled
            />
            <Select
                selectLabel="Státusz"
                optionList={options.statusOptions}
                value={form.membershipStatus || []}
                name="membershipStatus"
                format={{ xs: 12 }}
                multiple
            />
            <Select
                selectLabel="Képzési szint"
                name="currentTrainingLevel"
                value={form.currentTrainingLevel || []}
                optionList={makeOptionsFromID(trainingLevels) || []}
                format={{ xs: 12 }}
                multiple
            />
            <Select
                selectLabel="Hallgatók"
                name="students"
                value={form.students || []}
                disabled={!form.campus || studentLoading}
                optionList={studentOptions || []}
                format={{ xs: 12 }}
                loading={studentLoading}
                multiple
            />
            <SubmitButton
                className={
                    form.campus && form.currentTrainingLevel && !studentLoading
                        ? classes.insert
                        : ""
                }
                disabled={
                    !form.campus ||
                    studentLoading ||
                    studentOptions?.length === 0
                }
                onClick={handleSelectAll}
            >
                Összes kiválasztása
            </SubmitButton>
            <SubmitButton
                disabled={!form.membershipStatus || !form.currentTrainingLevel}
                onClick={handleFilterStudents}
            >
                Hallgatók szűrése
            </SubmitButton>
            <Title {...formProps} title="E-mail" format={{ xs: 12 }}>
                <TextField
                    label="Tárgy"
                    name="emailSubject"
                    value={form.emailSubject || ""}
                    format={{ xs: 12 }}
                />
                <CkEditor
                    type="editor"
                    name="emailContent"
                    data={form.emailContent || ""}
                    format={{ xs: 12 }}
                    onChange={(_, editor) =>
                        props.changeEditor(editor.getData())
                    }
                />
            </Title>
            <SubmitButton
                name="insert"
                type="button"
                onClick={() =>
                    props.changeForm(
                        {
                            ...form,
                            emailSubject: emailTemplate?.subject,
                            emailContent: emailTemplate?.content,
                            errors: {},
                        },
                        "form"
                    )
                }
                format={{ xs: 12 }}
                className={classes.insert}
            >
                E-mail sablon beillesztése
            </SubmitButton>
            <SubmitButton type="submit" disabled={!valid}>
                Küldés
            </SubmitButton>
            <CancelButton onClick={() => history.push("/new-process")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form, studentOptions } = state.studentAllocationPreference;
    const emailTemplate = state.emailTemplates.data["hydra:member"]
        ? state.emailTemplates.data["hydra:member"]?.find(
              (element) =>
                  element["key"] === "student_allocation_preference_started"
          )
        : {};
    const students = state.students.data;
    const trainingLevels = state.trainingLevels.data;
    return { form, students, studentOptions, emailTemplate, trainingLevels };
}

const actionCreators = {
    changeForm: studentAllocationPreferenceActions.changeForm,
    changeEditor: studentAllocationPreferenceActions.changeEditor,
    defaultForm: studentAllocationPreferenceActions.defaultForm,
    startStudentAllocationPreference:
        studentAllocationPreferenceActions.startStudentAllocationPreference,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    fetchStudents: studentsActions.filter,
    filterEmailTemplate: () =>
        emailTemplatesActions.filter({
            key: "student_allocation_preference_started",
        }),
    addNotification: notificationActions.addNotification,
    fetchTrainingLevels: () =>
        trainingLevelsActions.filter({
            eligibleForStudentAllocationPreference: true,
            archive: false,
        }),
};

export default connect(mapState, actionCreators)(StudentAllocationPreference);
