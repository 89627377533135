import { Grid } from "@material-ui/core";
import { Children, useEffect } from "react";
import { connect } from "react-redux";
import PageTemplate from "../../Common/PageTemplate";
import NavMenuItem from "../../Common/NavMenuItem";
import Accordion from "../../Common/Accordion";
import loadingActions from "../../../store/loading/actions";
import roomAllocationListActions from "../../../store/processes/roomAllocationList/actions";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

/**
 * Ezen az oldalon tud a felhasználó szobabeosztási listát választani,
 * szemeszter alapján
 * 
 * @param {*} props 
 */
function SelectAllocationPageStudents(props) {
    const { allocations } = props;

    const header = {
        title: "Szobabeosztási lista (diák nézet)",
        breadcrumbs: {
            "reports": "Riportok",
            "selectAllocation": "Szobabeosztási lista (diák nézet)"
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.getAllocations()
        .then(() => props.loadingOff())
        .catch(() => props.loadingOff());
    }, [])
    
    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="Szemeszterek" expanded>
                    {Children.toArray(allocations?.map((allocation) => 
                        <NavMenuItem
                            to={`/student-allocation-preference-list/${allocation.id}`}
                            icon={faCalendarAlt}
                            text={allocation.semester?.name}
                        />
                    ))}
                </Accordion>
            </Grid>
        </PageTemplate>
    )
}

function mapState(state) {
    const { allocations } = state.roomAllocationList;
    return { allocations };
}

const actionCreators = {
    getAllocations: roomAllocationListActions.getAllocations,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(SelectAllocationPageStudents);