import Loading from "../../../Common/Loading";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";

export function SemesterAllocationInfo(props) {
    const { form, formProps, type } = props;

    if (!form && type === "new") {
        return <Loading />
    }

    return <Title
        title={`${type === "old" ? `Legutolsó aktív szemeszter - ${form?.semester?.name || ""}` : `Következő szemeszter - ${form?.allocation?.semester?.name || ""}`}`}
        {...formProps}
        format={{ xs: 12 }}
    >
        <TextField
            name="trainingLevel"
            label="Képzési szint"
            value={form?.trainingLevel?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="trainingProgram"
            label="Képzési program"
            value={form?.trainingProgram?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="collegeYear"
            label="Évfolyam"
            value={form?.collegeYear?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="currentMembershipStatus"
            label="Státusz"
            value={form?.currentMembershipStatus === "internal" ? "Bentlakó" : (form?.currentMembershipStatus === "external" ? "Külsős" : "-") || ""}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="campus"
            label="Campus"
            value={form?.room?.floor?.building?.campus?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="building"
            label="Épület"
            value={form?.room?.floor?.building?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="floor"
            label="Szint"
            value={form?.room?.floor?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
        <TextField
            name="room"
            label="Szoba"
            value={form?.room?.name || "-"}
            format={{ xs: 12, md: 6 }}
            disabled
        />
    </Title>;
}
