export const types = {
    CHANGE_FORM: "@@termination/CHANGE_FORM",
    DEFAULT_FORM: "@@termination/DEFAULT_FORM",
    CHANGE_EDITOR: "@@termination/CHANGE_EDITOR",

    GET_DATA_REQUEST: "@@termination/GET_DATA_REQUEST",
    GET_DATA_SUCCESS: "@@termination/GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "@@termination/GET_DATA_FAILURE",

    START_REQUEST: "@@termination/START_REQUEST",
    START_SUCCESS: "@@termination/START_SUCCESS",
    START_FAILURE: "@@termination/START_FAILURE",

    DELETE_REQUEST: "@@termination/DELETE_REQUEST",
    DELETE_SUCCESS: "@@termination/DELETE_SUCCESS",
    DELETE_FAILURE: "@@termination/DELETE_FAILURE",
};
