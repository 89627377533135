export const types = {
    CHANGE_FORM: "@@buildings/CHANGE_FORM",
    DEFAULT_FORM: "@@buildings/DEFAULT_FORM",

    FETCH_REQUEST: "@@buildings/FETCH_REQUEST",
    FETCH_SUCCESS: "@@buildings/FETCH_SUCCESS",
    FETCH_FAILURE: "@@buildings/FETCH_FAILURE",

    GET_REQUEST: "@@buildings/GET_REQUEST",
    GET_SUCCESS: "@@buildings/GET_SUCCESS",
    GET_FAILURE: "@@buildings/GET_FAILURE",
}