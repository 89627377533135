import { types } from "./types";

const initialState = {
    loading: false,
    users: []
}

export function switchUser(state = initialState, action) {
    switch(action.type) {
        case types.FETCH_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.data.data,
            };
        case types.FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.error,
            };

        default: 
            return {
                ...state
            }
    }
}