import OperationButton from "../../../Components/Common/OperationButton";
import { useTenant } from "../../../utils/TenantContext";
import { formatDate } from "../../../utils/timezoneConverter";
import Dialog from "../../Common/Dialog";
import SystemLogTable from "./SystemLogTable";

const checkChanges = (entry) => {
    const oldData = entry?.oldData;
    const newData = entry?.newData;

    try {
        const newDataAsJson = newData ? JSON.parse(newData) : {};
        const oldDataAsJson = oldData ? JSON.parse(oldData) : {};

        const oldDataPropNames = oldDataAsJson
            ? Object.getOwnPropertyNames(oldDataAsJson)
            : [];
        const newDataPropNames = newDataAsJson
            ? Object.getOwnPropertyNames(newDataAsJson)
            : [];
        const changedPropNames = Array.from(
            new Set(oldDataPropNames.concat(newDataPropNames))
        );

        return changedPropNames.map((propName) => {
            return {
                name: propName,
                old: oldDataAsJson?.[propName] || "",
                new: newDataAsJson?.[propName] || "",
            };
        });
    } catch (error) {
        const newDataAsJson = {"error": newData};
        const oldDataAsJson = {"error": oldData};
        const oldDataPropNames = oldDataAsJson
            ? Object.getOwnPropertyNames(oldDataAsJson)
            : [];
        const newDataPropNames = newDataAsJson
            ? Object.getOwnPropertyNames(newDataAsJson)
            : [];
        const changedPropNames = Array.from(
            new Set(oldDataPropNames.concat(newDataPropNames))
        );

        return changedPropNames.map((propName) => {
            return {
                name: propName,
                old: oldDataAsJson?.[propName] || "",
                new: newDataAsJson?.[propName] || "",
            };
        });
    }
};

export default function BtnCellRenderer(params, types, events) {
    const type = types[`${params.data?.type}`];
    const tenant = useTenant();
    const changes = checkChanges(params.data).map((elem) => {
        if (elem.name === "createdAt") {
            return {
                name: elem.name,
                old: formatDate(elem.old, tenant.timezone.offset),
                new: formatDate(elem.new, tenant.timezone.offset),
            }
        }
        return elem;
    });
    return (
        <Dialog opener={<OperationButton type="info" />} title="Változások">
            <SystemLogTable
                params={params}
                types={types}
                type={type}
                events={events}
                changes={changes}
            />
        </Dialog>
    );
}
