import API from '../../../utils/API';

const headers = {
    "Content-Type": "application/ld+json",
    "X-Requested-With": "XMLHttpRequest",
}

function approveFinancialCheck(enrollmentId) {
    return API.post(`/enrollments/${enrollmentId}/approve-financial-check`, {}, {
        headers: headers
    })
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function sendUnpaidDebtNotification(enrollmentId, form) {
    return API.post(`/enrollments/${enrollmentId}/send-unpaid-debt-notification`, form || {}, {
        headers: headers
    })
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const financialControlService = {
    approveFinancialCheck,
    sendUnpaidDebtNotification
};

export default financialControlService;