import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import colors from "../../styles/colors";

const useStyles = makeStyles(() => ({
    greenChip: {
        backgroundColor: colors.new,
        color: colors.white
    },
    redChip: {
        backgroundColor: colors.error,
        color: colors.white
    },
}))

export function ArchiveCellRenderer(params) {
    const classes = useStyles();
    const label = params?.data?.archive ? 'Igen' : 'Nem';
    return <Chip label={label} className={params?.data?.archive ? classes.redChip : classes.greenChip} />;
}