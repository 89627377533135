export const types = {
    CHANGE_FORM: "@@permissions/CHANGE_FORM",
    DEFAULT_FORM: "@@permissions/DEFAULT_FORM",

    FETCH_REQUEST: "@@permissions/FETCH_REQUEST",
    FETCH_SUCCESS: "@@permissions/FETCH_SUCCESS",
    FETCH_FAILURE: "@@permissions/FETCH_FAILURE",

    SAVE_REQUEST: "@@permissions/SAVE_REQUEST",
    SAVE_SUCCESS: "@@permissions/SAVE_SUCCESS",
    SAVE_FAILURE: "@@permissions/SAVE_FAILURE",

    AUTOCOMPLETE_FILTER_REQUEST: "@@permissions/AUTOCOMPLETE_FILTER_REQUEST",
    AUTOCOMPLETE_FILTER_SUCCESS: "@@permissions/AUTOCOMPLETE_FILTER_SUCCESS",
    AUTOCOMPLETE_FILTER_FAILURE: "@@permissions/AUTOCOMPLETE_FILTER_FAILURE",
}