import { withStyles } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import colors from '../../styles/colors';

const Copy = withStyles({
    root: {
        borderRadius: 0,
        color: colors.primaryBlue,
        fontSize: "x-large",
        marginTop: "0.7vh",
        '&:hover': {
            color: colors.primaryBlueHover,
        }
    }
})(FileCopyIcon);

export default Copy;