import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';


function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/user/permissions', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}
function autocompleteFilter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/users', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}
function save(form) {
    return API.post('/user/permissions', form)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const permissionsService = {
    filter,
    save,
    autocompleteFilter
};

export default permissionsService;