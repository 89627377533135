export const types = {
    CHANGE_FORM: "@@students/CHANGE_FORM",
    DEFAULT_FORM: "@@students/DEFAULT_FORM",

    FETCH_REQUEST: "@@students/FETCH_REQUEST",
    FETCH_SUCCESS: "@@students/FETCH_SUCCESS",
    FETCH_FAILURE: "@@students/FETCH_FAILURE",

    GET_REQUEST: "@@students/GET_REQUEST",
    GET_SUCCESS: "@@students/GET_SUCCESS",
    GET_FAILURE: "@@students/GET_FAILURE",

    FETCH_SET_INFO: "@@students/FETCH_SET_INFO"
}