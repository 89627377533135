import getCurrentPage from "../../../utils/getCurrentPage";
import { types } from "./types";

const initialState = {
    filterStorageKey: 'masterData.students',
    filter: {},
    form: {},
    data: [],
    tableInfo: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metadata: {}
    }
}

export function students(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: {
                        'allCount': action?.data?.data['hydra:totalItems'],
                        'filteredCount': action?.data?.data['hydra:totalItems'],
                        'lastPage': action?.data?.data['hydra:totalItems'] ? Math.ceil(action?.data?.data['hydra:totalItems'] / state.tableInfo.itemsPerPage) : 1,
                        'page': getCurrentPage(action?.data?.data['hydra:view']),
                        'itemsPerPage': state.tableInfo.itemsPerPage,
                    }
                },
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: {
                    ...action.data.data,
                    campus: action.data.data?.currentRoom?.floor?.building?.campus?.['@id'] 
                        || action.data.data?.user?.campus?.['@id'],
                    terminating: action.data.data?.terminating ? '1' : '0',
                },
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };

        default: 
            return {
                ...state
            }
    }
}