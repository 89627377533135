import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    AppBar,
    Container,
    createTheme,
    Divider,
    Drawer,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    Snackbar,
    ThemeProvider,
    Toolbar,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Link from "../Common/Link";
import Logo from "../../images/mcc_logo.svg";
import colors from "../../styles/colors.js";
import TextMenuItem from "../Common/TextMenuItem";
import SubmitButton from "../Common/SubmitButton";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SidebarMenu from "./SidebarMenu";
import { Alert } from "@material-ui/lab";
import { connect, useDispatch } from "react-redux";
import notificationActions from "../../store/notification/actions";
import { LAYOUT_TYPES, PERMISSIONS, axiosErrorRedirect } from "../../utils/AppConst";
import { hasRole, hasStudentAndOther, isSystemAdmin } from "../../utils/User";
import SwitchUserDialog from "../Common/SwitchUserDialog";
import loginService from "../../services/login";
import loadingActions from "../../store/loading/actions";
import ChangePassword from "./ChangePassword";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import loginActions from '../../store/modules/login/actions';
import { types } from '../../store/modules/login/types';
import Loading from "../Common/Loading";
import Cookies from "universal-cookie";

const theme = createTheme({
    palette: {
        primary: {
            light: colors.primaryYellow,
            main: colors.primaryBlue,
            dark: colors.primaryBlue,
            contrastText: colors.white,
        },
    },
});

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.48)",
            borderRadius: "5px",
        },
    },
    root: {
        display: "flex",
        backgroundColor: colors.white,
        height: "100vh",
    },
    toolbar: {
        paddingRight: 24,
        backgroundColor: colors.primaryBlue,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 8px",
        ...theme.mixins.toolbar,
        backgroundColor: colors.primaryBlue,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: colors.white,
        fontSize: "x-large",
    },
    menuButtonHidden: {
        display: "none",
    },
    drawerPaper: {
        position: "relative",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperMobile: {
        position: "absolute",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: "auto",
    },
    paper: {
        padding: theme.spacing(0),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    copyrightLink: {
        display: "inline",
    },
    title: {
        flexGrow: 1,
        position: "absolute",
        right: 30,
    },
    logo: {
        width: theme.spacing(20),
        height: theme.spacing(7),
    },
    link: {
        textDecoration: "none",
        color: colors.white,
        width: "100%",
    },
    userName: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        color: colors.white,
        fontSize: "large",
        fontWeight: "bolder",
    },
    email: {
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: colors.white,
        fontSize: "large",
    },
    accountCircle: {
        fontSize: "xx-large",
    },
    leftIcon: {
        color: colors.white,
        fontSize: "xx-large",
    },
    button: {
        padding: "unset",
    },
    campusBadge: {
        marginRight: 20,
    },
    campusText: {
        marginRight: 20,
        color: colors.primaryYellow,
        display: "flex",
        alignItems: "center",
    },
    badge: {
        marginLeft: "8px",
        backgroundColor:
            process.env.REACT_APP_SYSTEM_NAME_PRINT_HEADER === "PROD"
                ? colors.white
                : colors.primaryYellow,
    },
    badgeText: {
        fontSize: "large",
        ...(process.env.REACT_APP_SYSTEM_NAME_PRINT_HEADER === "PROD"
            ? {
                  color: colors.black,
              }
            : { color: colors.white }),
    },
}));

function Layout(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(anchorEl);
    const [xSwitchUserOpen, setXSwitchUserOpen] = React.useState(false);
    const [tenant, setTenant] = useState('Regisztrációs rendszer');
    const th = useTheme();
    const matches = useMediaQuery(th.breakpoints.up("sm"));
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
    const layout = JSON.parse(localStorage.getItem('layout'));
    const { snackbar } = props;

    useEffect(() => setOpen(matches), [location, matches]);

    useEffect(() => {
            axios
                .get(process.env.REACT_APP_API_URL + '/current-tenant')
                .then((response) => {
                    setTenant(`${response.data.tenant} Regisztrációs rendszer`);
                })
                .catch((error) => console.error('Error fetching current tenant name:', error)
            );
        }, []);

    const handleClose = () => setAnchorEl(null);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const cookies = new Cookies();

    const logoutXSwitchUser = () => {
        setAnchorEl(null);
        props.loadingOn();
        localStorage.setItem('layout', null);
        loginService.loginIaAs(0).then((response) => {
            loginService.setJWTToken(response.data.token);
            cookies.remove('skip_sentry_errors');
            window.location.href = `${
                process.env.REACT_APP_AUTH_URL || "https://auth01.mcc.hu/"
            }`;
        });
    };
    const recheckUser = () => {
        if (layout != LAYOUT_TYPES.STUDENT) {
            setLoading(true);
            axios
                .all([dispatch(loginActions.setUser()), loginService.getUser()])
                .then((d) => {
                    dispatch({ type: types.LOGIN_SUCCESS, user: d });
                    setLoading(false);
                })
                .catch((error) => {
                    axiosErrorRedirect(error);
                })
        }
    };

    const user = props.user;

    if (loading) {
        return (<Loading/>);
    } 

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <AppBar
                    className={clsx(
                        classes.appBar,
                        open && classes.appBarShift
                    )}
                >
                    <Toolbar className={classes.toolbar} variant="dense">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleDrawerOpen}
                            className={clsx(
                                classes.menuButton,
                                open && classes.menuButtonHidden
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid container spacing={1}>
                            <Grid item style={{ marginTop: "3px" }}>
                                <Typography
                                    style={{ color: colors.primaryYellow }}
                                >
                                    {tenant}
                                </Typography>
                            </Grid>
                            {process.env.REACT_APP_SYSTEM_NAME_PRINT_HEADER && (
                                <Grid item>
                                    <Chip
                                        label={
                                            <span className={classes.badgeText}>
                                                {
                                                    process.env
                                                        .REACT_APP_SYSTEM_NAME_PRINT_HEADER
                                                }
                                            </span>
                                        }
                                        className={classes.badge}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container justify="flex-end">
                            {user?.campus?.name &&
                            (user?.roles?.includes(PERMISSIONS.SYSTEM_ADMIN) ||
                                user?.roles?.includes(
                                    PERMISSIONS.FINANCIAL_ADMIN
                                )) ? (
                                <Link to="/campus-selector">
                                    <SubmitButton
                                        className={classes.campusBadge}
                                    >
                                        {user?.campus?.name || ""}
                                    </SubmitButton>
                                </Link>
                            ) : (
                                <div className={classes.campusText}>
                                    {user?.campus?.name || ""}
                                </div>
                            )}
                            <IconButton
                                onClick={handleMenu}
                                color="inherit"
                                className={classes.button}
                            >
                                <AccountCircle
                                    className={classes.accountCircle}
                                />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={userMenuOpen}
                                onClose={handleClose}
                            >
                                <div className={classes.userName}>
                                    {user.fullName}
                                </div>
                                <div className={classes.email}>
                                    {user.email}
                                </div>
                                {/*  <Link
                                    to="/profile"
                                    className={classes.link}
                                >
                                    <TextMenuItem>Profil</TextMenuItem>
                                </Link>
                                */}
                                <span
                                    className={classes.link}
                                    onClick={() => 
                                        window.location.href = process.env.REACT_APP_AUTH_URL
                                    }
                                >
                                    <TextMenuItem>
                                        Rendszerválasztó
                                    </TextMenuItem>
                                </span>
                                {
                                    hasStudentAndOther() &&
                                    <Link
                                        to="/switch-layout"
                                        className={classes.link}
                                    >
                                        <TextMenuItem>
                                            Nézetváltás
                                        </TextMenuItem>
                                    </Link>
                                }
                                <span
                                    className={classes.link}
                                    onClick={() => {
                                        setAnchorEl(null);
                                        setChangePasswordOpen(true);
                                    }}
                                >
                                    <TextMenuItem>
                                        Jelszóváltoztatás
                                    </TextMenuItem>
                                </span>

                                {(isSystemAdmin() ||
                                    hasRole(PERMISSIONS.FINANCIAL_ADMIN)) && (
                                    <Link
                                        to="/campus-selector"
                                        className={classes.link}
                                    >
                                        <TextMenuItem>
                                            Campusválasztó
                                        </TextMenuItem>
                                    </Link>
                                )}
                                {isSystemAdmin() && user.realUser === null && (
                                    <span
                                        className={classes.link}
                                        onClick={() => {
                                            window.location.href = process.env.REACT_APP_TENANT_FE_URL + '?epregImpersonation'
                                            // setXSwitchUserOpen(true);
                                            // setAnchorEl(null);
                                        }}
                                    >
                                        <TextMenuItem>
                                            Megszemélyesítés
                                        </TextMenuItem>
                                    </span>
                                )}
                                {user.realUser !== null && (
                                    <span
                                        className={classes.link}
                                        onClick={logoutXSwitchUser}
                                    >
                                        <TextMenuItem>
                                            Kilépés a megszemélyesítésből
                                        </TextMenuItem>
                                    </span>
                                )}
                                <Divider />
                                <span
                                    onClick={() => {
                                        setAnchorEl(null);
                                        props.loadingOn();
                                        localStorage.setItem('layout', null);
                                        loginService.logout();
                                    }}
                                    className={classes.link}
                                >
                                    <TextMenuItem>Kijelentkezés</TextMenuItem>
                                </span>
                            </Menu>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant={matches ? "permanent" : "temporary"}
                    classes={{
                        paper: clsx(
                            open && classes.drawerPaper,
                            !open && classes.drawerPaperClose
                        ),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <Link to="/">
                            <img
                                className={classes.logo}
                                src={Logo}
                                alt="Logo"
                            />
                        </Link>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon className={classes.leftIcon} />
                        </IconButton>
                    </div>
                    <Grid item xs={false}>
                        <SidebarMenu roles={props.user.roles} onClick={recheckUser}/>
                    </Grid>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={false}>{props.children}</Container>
                </main>
                {snackbar.type && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={snackbar.type !== ""}
                        autoHideDuration={4000}
                        onClose={() => props.removeNotification()}
                    >
                        <Alert severity={snackbar.type}>{snackbar.msg}</Alert>
                    </Snackbar>
                )}
                <SwitchUserDialog
                    open={xSwitchUserOpen}
                    closeAction={() => setXSwitchUserOpen(false)}
                    changeOpenFn={setXSwitchUserOpen}
                />
                <ChangePassword
                    open={changePasswordOpen}
                    setOpen={setChangePasswordOpen}
                />
            </ThemeProvider>
        </div>
    );
}

function mapState(state) {
    const { snackbar } = state.notification;
    const { user } = state.login;
    return { snackbar, user };
}

const actionCreators = {
    addNotification: notificationActions.addNotification,
    removeNotification: notificationActions.removeNotification,
    loadingOn: loadingActions.loadingOn,
};

export default connect(mapState, actionCreators)(Layout);
