import {makeStyles} from '@material-ui/core';
import Tooltip from '../../Components/Common/Tooltip';

const useStyles = makeStyles(() => ({
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
}));

export default function RoomMateCellRenderer(params) {
    const classes = useStyles();
    const roomMate = params.data?.preference?.roomMates?.[0]?.user?.fullName || "-";

    return (
        <Tooltip title={roomMate}>
            <div className={classes.truncate}>{roomMate}</div>
        </Tooltip>
    );
}