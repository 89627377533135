import EditRoomDialog from "./EditRoomDialog";
import ViewStudentAllocationPreferenceDialog from "../../Processes/RoomAllocationList/ViewStudentAllocationPreferenceDialog";

function BtnCellRenderer(props, allocationId) {
    const user = localStorage.getItem("user") || "";
    const userJSON = JSON.parse(user);
    const campus = userJSON.campus;
    const data = props.data;
    const preference = data?.preference;
    
    const preferenceDialogInfo = {
        fullName: data?.fullName || "-",
        email: data?.email || "-",
        displayId: preference?.displayId || "-",
        roomMates: preference?.roomMates?.[0]?.user?.fullName || "-",
        comment: preference?.comment || "-",
        building: preference?.room?.floor?.building?.name || "-",
        floor: preference?.room?.floor?.name || "-",
        room: preference?.room?.name || "-"
    }

    const editFormInfo = {
        fullName: data?.fullName || "-",
        studentAllocationId: data?.id || "-",
        buildingId: data?.building?.id,
        floorId: data?.floor?.id,
        roomId: data?.room?.id
    }

    return (
        <>
            {preference && <ViewStudentAllocationPreferenceDialog {...preferenceDialogInfo}/>}
            <EditRoomDialog 
                campus={campus} 
                allocationId={allocationId}
                editFormInfo={editFormInfo}
                {...props}
            />
        </>
    );
}

export default BtnCellRenderer;