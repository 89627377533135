import { Grid } from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { history } from "../../../../../../store";
import loadingActions from "../../../../../../store/loading/actions";
import buildingsActions from "../../../../../../store/masterData/buildings/actions";
import campusesActions from "../../../../../../store/masterData/campuses/actions";
import floorsActions from "../../../../../../store/masterData/floors/actions";
import roomsActions from "../../../../../../store/masterData/rooms/actions";
import notificationActions from "../../../../../../store/notification/actions";
import Accordion from "../../../../../Common/Accordion";
import CancelButton from "../../../../../Common/CancelButton";
import Checkbox from "../../../../../Common/Checkbox";
import Form from "../../../../../Common/Form";
import PageTemplate from "../../../../../Common/PageTemplate";
import TextField from "../../../../../Common/TextField";

function RoomsForm(props) {
    const {
        form,
        campusId,
        buildingId,
        floorId,
        id,
        floorForm,
        campusForm,
        buildingForm,
    } = props;
    const title = "Szoba megtekintése";

    const URL = `/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms`;

    const header = {
        title: title,
        breadcrumbs: {
            masterData: "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors`]: "Emeletek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms`]:
                "Szobák",
            roomsView: title,
            name: form?.name ?? "",
        },
    };

    useEffect(() => {
        props.loadingOn();

        axios
            .all([
                props.getCampus(campusId),
                props.getBuilding(buildingId),
                props.getFloor(floorId),
                props.get(id),
            ])
            .finally(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, floor: floorForm["@id"] }, "form");
    }, [floorForm["@id"], form?.floor]);

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        space: yup
            .number()
            .min(1, "A szám túl kicsi!")
            .max(99, "A szám túl nagy!")
            .required("Kötelező megadni"),
    });

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        onsubmit: () => console.log("Nem lehet már szerkeszteni és létrehozni"),
        isformikform: "true",
    };

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form {...formProps}>
                        <TextField
                            label="Campus"
                            name="campus"
                            value={campusForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Épület"
                            name="building"
                            value={buildingForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Szint"
                            name="floor"
                            value={floorForm.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Szobaszám"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Férőhely"
                            name="space"
                            value={form.space || ""}
                            format={{ xs: 12, md: 6 }}
                            rules={{
                                number: "true",
                                length: 2,
                            }}
                            disabled={true}
                        />
                        <Checkbox
                            option={{ name: "assignable", label: "Szobagenerálás releváns" }}
                            checked={form.assignable === "1"}
                            disabled={true}
                            format={{ xs: 12 }}
                        />
                        <Grid container item format={{ xs: 12 }}>
                            <CancelButton onClick={() => history.push(URL)}>
                                Vissza
                            </CancelButton>
                        </Grid>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.rooms;
    const campusForm = state.campuses.form;
    const buildingForm = state.buildings.form;
    const floorForm = state.floors.form;
    return { form, floorForm, campusForm, buildingForm };
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,
    getFloor: floorsActions.get,

    get: roomsActions.get,
    changeForm: roomsActions.changeForm,
    defaultForm: roomsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(RoomsForm);
