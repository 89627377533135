import { Grid } from "@material-ui/core";
import EditRoomDialog from "./EditRoomDialog";
import LastRoom from "../lastRoom";
import ViewStudentAllocationPreferenceDialog from "../ViewStudentAllocationPreferenceDialog";

/**
 * - ViewStudentAllocationPreferenceDialog: preferencia nézed: kitöltött szobatárspreferencia űrlap jelenik meg
 * - EditRoomDialog: szerkesztésre szolgáló felugró ablak (szobaadatokat lehet szerkeszteni)
 * - LastRoom: tooltip a sorral kapcsolatban
 */
function BtnCellRenderer(props) {
    const { student, row, preference, studentAllocationId } = props;
    const user = localStorage.getItem("user") || "";
    const userJSON = JSON.parse(user);
    const campus = userJSON.campus;

    const preferenceDialogInfo = {
        fullName: student?.user?.fullName || "-",
        email: student?.user?.email || "-",
        displayId: preference?.displayId || "-",
        roomMates: preference?.roomMates?.[0]?.user?.fullName || "-",
        comment: preference?.comment || "-",
        building: preference?.room?.floor?.building?.name || "-",
        floor: preference?.room?.floor?.name || "-",
        room: preference?.room?.name || "-"
    }

    const editFormInfo = {
        fullName: student?.user?.fullName || "-",
        studentAllocationId: studentAllocationId || "-",
        buildingId: row?.building?.id,
        floorId: row?.floor?.id,
        roomId: row?.room?.id
    }

    const studentAllocations = row?.studentAllocations;
    const lastRoom = studentAllocations?.filter(x => x.student?.id === student?.id);

    return (
        <Grid container direction="row" alignItems="center">
            {preference && 
            <Grid item>
                <ViewStudentAllocationPreferenceDialog {...preferenceDialogInfo}/>
            </Grid>
            }
            <Grid item>
                <EditRoomDialog campus={campus} allocationId={props.allocationId} editFormInfo={editFormInfo} viewType="room" {...props}/>
            </Grid>
            <Grid item>
                <LastRoom {...lastRoom}/> 
            </Grid>
        </Grid>
    );
}

export default BtnCellRenderer;