import { types } from "./types";

const initialState = {
    form: {},
    data: [],
    relocatableStudents: [],
    newRooms: [],
}

export function relocationSingle(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
            }
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    //content: action.content,
                    emailContent: action.content
                }
            }
        case types.START_REQUEST:
            return {
                ...state,
            }
        case types.START_SUCCESS:
            return {
                ...state,
            }
        case types.START_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        case types.GET_RELOCATABLE_STUDENTS_REQUEST:
            return {
                ...state,
            };
        case types.GET_RELOCATABLE_STUDENTS_SUCCESS:
            return {
                ...state,
                relocatableStudents: action.data?.data?.["hydra:member"],
            };
        case types.GET_RELOCATABLE_STUDENTS_FAILURE:
            return {
                ...state,
                data: [],
                error: action.error,
            };
        case types.GET_NEW_ROOMS_REQUEST:
            return {
                ...state,
            };
        case types.GET_NEW_ROOMS_SUCCESS:
            return {
                ...state,
                newRooms: action.data?.data?.["hydra:member"],
            };
        case types.GET_NEW_ROOMS_FAILURE:
            return {
                ...state,
                data: [],
                error: action.error,
            };
        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}