import TooltipRenderer from "./TooltipRenderer";

export const semesterStatusLabel = value => {
    switch (value) {
        case "active":
            return "Aktív";
        case "passive":
            return "Passzív";
        case "foreign_scholarship":
            return "Külföldi ösztöndíjas"
        default:
            return "";
    }
}

export default function SemesterStatusCellRenderer(params) {
    return TooltipRenderer(semesterStatusLabel(params.value || ""));
}