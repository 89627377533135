import React from 'react';

import VectorMap, { Layer, Label, Tooltip } from 'devextreme-react/vector-map';
import { roomsData, buildingData, staircaseData, liftsData } from './data.js';
import colors from './../../../../../styles/colors';
import { Typography, Grid } from '@material-ui/core';
import SmallTable from '../../../../Common/SmallTable.js';

const TooltipContent = (elem, data) => {
    if (elem.layer.name !== 'rooms') return null;

    const room = data.find((room) => room?.name === '' + elem.attribute('id'));

    const columns = [
        { value: 'fullName', label: 'Név' },
        { value: 'email', label: 'E-mail' },
        { value: 'phoneNumber', label: 'Telefonszám' },
    ];

    return (
        <Grid container spacing={2}>
            <Grid
                item
                container
                xs={12}
                style={{ borderBottom: '1px solid ' + colors.primaryBlue }}
            >
                <Grid item xs={8}>
                    <Typography>Szobaszám</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{room?.name || 'Nincs adat'}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={12}
                style={{
                    borderBottom: '1px solid ' + colors.primaryBlue,
                    marginTop: '4px',
                }}
            >
                <Grid item xs={8}>
                    <Typography>Férőhelyek száma</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{room?.space || 'Nincs adat'}</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography>Lakók</Typography>
                </Grid>
                <Grid item xs={12}>
                    {room?.allocations?.length > 0 ? (
                        <SmallTable
                            columns={columns}
                            rows={room.allocations.map((allocation) => ({
                                ...(allocation.student?.user || {}),
                                phoneNumber:
                                    allocation.student?.phoneNumber || '-',
                            }))}
                        />
                    ) : (
                        <Typography>Nincs megjeleníthető adat.</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default function FloorPlan(props) {
    const { rooms, floor } = props;

    const customizeLayer = (elements) => {
        elements.forEach((element) => {
            const room =
                rooms.find(
                    (elem) => elem.name === element.attribute('id') + ''
                ) || {};
            const students = room.allocations?.length || 0;
            const space = room.space;
            const color =
                students > space
                    ? '#E73333'
                    : students === space
                    ? '#aaa'
                    : '#f3ca12';
            element.applySettings({
                color: color,
                hoveredColor: colors.primaryYellowHover,
            });
        });
    };

    return (
        <VectorMap id='vector-map' zoomFactor={4}>
            <Layer
                dataSource={buildingData}
                hoverEnabled={false}
                borderWidth={1}
                color='black'
                name='building'
            ></Layer>
            <Layer
                dataSource={roomsData(floor.name, rooms)}
                name='rooms'
                borderWidth={1}
                color='rgba(243,202,18, 0.5)'
                customize={customizeLayer}
            >
                <Label
                    enabled={true}
                    dataField='name'
                    font={{ size: 11 }}
                ></Label>
            </Layer>
            <Layer
                dataSource={staircaseData}
                name='staircase'
                borderWidth={1}
                color='#ccc'
            >
                <Label enabled={true} dataField='name'></Label>
            </Layer>
            <Layer
                dataSource={liftsData}
                name='lifts'
                borderWidth={1}
                color='pink'
            >
                <Label enabled={true} dataField='name'></Label>
            </Layer>
            <Tooltip
                enabled={true}
                contentRender={(e) => TooltipContent(e, rooms)}
            ></Tooltip>
        </VectorMap>
    );
}
