import React, { createContext, useContext } from 'react';


const TenantContext = createContext(undefined);

export function TenantContextProvider(props) {
    return <TenantContext.Provider value={props.tenant}>{props.children}</TenantContext.Provider>;
}

export const useTenant = () => useContext(TenantContext);

export default TenantContext;
