import API from '../../../utils/API';
import { templateGetNoApi } from '../..';

function post(id) {
    return API.post(`/enrollments/${id}/approve-operator-check`, {})
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

const operationalControlService = {
    create: post,
    get: templateGetNoApi("enrollments")
};

export default operationalControlService;
