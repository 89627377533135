import { templateChangeForm, templateDefaultForm, templateGet } from "../../..";
import relocationsService from "../../../../services/processes/relocation";
import notificationTypes from "../../../notification/types";
import { types } from "./types";
import { history } from "../../.."

function fillStudentForm(form, id, url) {
    let phoneNumber = form.phoneNumber;
    
    // Backend-en csak számokat fogadunk el, ezért a mezőbe ütött "+"-ot levágjuk
    if (phoneNumber && phoneNumber.charAt(0) === "+")  {
        phoneNumber = phoneNumber?.substring(1);
    }

    const formToSend = {
        relocation: Number(id),
        phoneNumber: phoneNumber,
        // Ha nincs kitöltve a kiköltözési dátum, akkor a mai napot vesszük értéknek:
        moveOutDate: form.moveOutDate || new Date().toISOString().slice(0, 10).split("T")[0],
    }

    return (dispatch) => {
        dispatch({ type: types.FILL_STUDENT_FORM_REQUEST, formToSend });
        return relocationsService.fillStudentForm(formToSend, id).then(
            (data) => {
                dispatch({ type: types.FILL_STUDENT_FORM_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés!" }
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILL_STUDENT_FORM_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "error", msg: "Hiba lépett fel küldés közben" }
                })
                return Promise.reject(error);
            }
        )
    }
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return relocationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    fillStudentForm,
    get: templateGet(types, relocationsService),
    getForm,
}

export default actions;