import {withStyles} from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import BasicButton from "@material-ui/core/Button";

const StyledButton = withStyles({
    root: {
        background: colors.primaryYellow,
        borderRadius: 0,
        border: 0,
        color: colors.white,
        height: 35,
        padding:'0 20px',
        boxShadow: 'black',
        '&:hover': {
            background: colors.primaryYellowHover,
        },
        '&:disabled': {
            background: colors.disabledBackground,
            color: colors.disabledColor,
        }
    },
    label: {
        textTransform: 'none'
    },
})(BasicButton);

export default StyledButton;