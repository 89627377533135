export const types = {
    CHANGE_FORM: "@@financialControl/CHANGE_FORM",
    DEFAULT_FORM: "@@financialControl/DEFAULT_FORM",
    CHANGE_EDITOR: "@@financialControl/CHANGE_EDITOR",

    APPROVE_FINANCIAL_CHECK_REQUEST: "@@financialControl/APPROVE_FINANCIAL_CHECK_REQUEST",
    APPROVE_FINANCIAL_CHECK_SUCCESS: "@@financialControl/APPROVE_FINANCIAL_CHECK_SUCCESS",
    APPROVE_FINANCIAL_CHECK_FAILURE: "@@financialControl/APPROVE_FINANCIAL_CHECK_FAILURE",

    SEND_UNPAID_DEBT_NOTIFICATION_REQUEST: "@@financialControl/SEND_UNPAID_DEBT_NOTIFICATION_REQUEST",
    SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS: "@@financialControl/SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS",
    SEND_UNPAID_DEBT_NOTIFICATION_FAILURE: "@@financialControl/SEND_UNPAID_DEBT_NOTIFICATION_FAILURE",
}