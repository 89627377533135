import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import semestersActions from "../../../store/masterData/semesters/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import Accordion from "../../Common/Accordion";
import PageTemplate from "../../Common/PageTemplate";
import Form from "../../Common/Form";
import CancelButton from "../../Common/CancelButton";
import { history } from "../../../store";
import TextField from "../../Common/TextField";
import { useEffect } from "react";
import DatePicker from "../../Common/DatePicker";
import * as yup from "yup";

function SemestersForm(props) {
    const { form, id } = props;
    const title = "Szemeszter megtekintése";

    const header = {
        title: title,
        breadcrumbs: {
            masterData: "Törzsadatok",
            "/semesters": "Szemeszterek",
            semestersNew: title,
            name: form?.name ?? "",
        },
    };

    useEffect(() => {
        props.loadingOn();
        props.get(id).then(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        startDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .max(yup.ref("endDate"), "Nem lehet később a befejezés dátumnál")
            .required("Kötelező megadni"),
        endDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .min(yup.ref("startDate"), "Nem lehet előbb a kezdeti dátumnál")
            .required("Kötelező megadni"),
        enrollmentStartDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .max(
                yup.ref("enrollmentEndDate"),
                "Nem lehet később a befejezés dátumnál"
            ),
        enrollmentEndDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .min(
                yup.ref("enrollmentStartDate"),
                "Nem lehet előbb a kezdeti dátumnál"
            ),
        terminationStartDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .max(
                yup.ref("terminationEndDate"),
                "Nem lehet később a befejezés dátumnál"
            ),
        terminationEndDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .min(
                yup.ref("terminationStartDate"),
                "Nem lehet előbb a kezdeti dátumnál"
            ),
        roomAllocationStartDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .max(
                yup.ref("roomAllocationEndDate"),
                "Nem lehet később a befejezés dátumnál"
            ),
        roomAllocationEndDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .min(
                yup.ref("roomAllocationStartDate"),
                "Nem lehet előbb a kezdeti dátumnál"
            ),
        relocationStartDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .max(
                yup.ref("relocationEndDate"),
                "Nem lehet később a befejezés dátumnál"
            ),
        relocationEndDate: yup
            .date()
            .nullable()
            .typeError("Érvénytelen dátum formátum")
            .min(
                yup.ref("relocationStartDate"),
                "Nem lehet előbb a kezdeti dátumnál"
            ),
    });

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultForm: props.defaultForm,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        validationschema: validationSchema,
        onsubmit: () => console.log("Nem lehet már szerkeszteni és létrehozni"),
        isformikform: true,
    };

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form {...formProps}>
                        <TextField
                            name="name"
                            label="Név"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            name="processName"
                            label="Azonosító"
                            value={form.processName || ""}
                            format={{ xs: 12, md: 6 }}
                            helperText="Ez az azonosító alkotja az adott szemeszterben indított folyamatok középső 4 számjegyét (pl. 2101)."
                            disabled={true}
                        />
                        <DatePicker
                            label="Szemeszter kezdete"
                            name="startDate"
                            value={form.startDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Szemeszter vége"
                            name="endDate"
                            value={form.endDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Beiratkozási időszak kezdete"
                            name="enrollmentStartDate"
                            value={form.enrollmentStartDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Beiratkozási időszak vége"
                            name="enrollmentEndDate"
                            value={form.enrollmentEndDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Kiiratkozási időszak kezdete"
                            name="terminationStartDate"
                            value={form.terminationStartDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Kiiratkozási időszak vége"
                            name="terminationEndDate"
                            value={form.terminationEndDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Kiköltözési időszak kezdete"
                            name="moveOutStartDate"
                            value={form.moveOutStartDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Kiköltözési időszak vége"
                            name="moveOutEndDate"
                            value={form.moveOutEndDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Szobabeosztási időszak kezdete"
                            name="roomAllocationStartDate"
                            value={form.roomAllocationStartDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Szobabeosztási időszak vége"
                            name="roomAllocationEndDate"
                            value={form.roomAllocationEndDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Átköltözési időszak kezdete"
                            name="relocationStartDate"
                            value={form.relocationStartDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <DatePicker
                            label="Átköltözési időszak vége"
                            name="relocationEndDate"
                            value={form.relocationEndDate || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <CancelButton
                            onClick={() => history.push("/semesters")}
                        >
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.semesters;
    return { form };
}

const actionCreators = {
    changeForm: semestersActions.changeForm,
    defaultForm: semestersActions.defaultForm,
    get: semestersActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(SemestersForm);
