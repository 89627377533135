import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { memo } from "react";

/**
 * Custom styles for the MoveOutDeclaration component.
 */
const useStyles = makeStyles(() => ({
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    title: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

/**
 * MoveOutDeclaration component displays a series of instructions for the move-out process.
 *
 * @component
 * @example
 * return (
 *   <MoveOutDeclaration />
 * );
 */
const MoveOutDeclaration = memo(function MoveOutDeclaration() {
    const classes = useStyles();

    return (
        <Paper className={classes.container} elevation={4}>
            <Grid container className={classes.textContainer}>
                <Grid item xs={12} container justify="center">
                    <div className={classes.title}>
                        TÁJÉKOZTATÁS A KIKÖLTÖZÉS MENETÉRŐL
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        1. A kiköltözési adatlapot a tervezett kiköltözés előtt minimum 1 héttel 
                        kell leadni, megjelölve a kiköltözés tervezett időpontját! Az idei évben a 
                        leadási határidő: 2024.06.21
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        2. Pénzügyi tartozásokat a kiköltözés előtt rendezni kell, 
                        az aktuális egyenleg ellenőrizhető az oktatási portálon itt: 
                        Pénzügyeim: 
                        <a href="https://tar.mcc.hu/student/finances">
                            {" "}
                            https://tar.mcc.hu/student/finances
                        </a>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        3. A folyamat végén elérhetővé válik számodra a Regisztrációs Rendszerben 
                        (letölthető és nyomtatható formában) a kiköltözési nyilatkozat aláírt másolata, 
                        mellyel kiköltözésed hivatalossá válik
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        4. Amennyiben a tagságod vagy a bentlakó státuszod megszünteted lehetőséged van arra, 
                        hogy a befizetett kauciódat az MCC közössége számára felajánld.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        5. A kitakarítatlanul átadott szobákért takarítási díjat 
                        számítunk fel: 10000 Ft.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        6. A kulcs (szobakártya) elvesztése, leadásának elmulasztása esetén: 2500 Ft pótdíj fizetendő!
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default MoveOutDeclaration;
