import axios from "axios";
import Cookies from "universal-cookie";
import { axiosErrorRedirect } from "../utils/AppConst";

const cookies = new Cookies();

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    responseType: "blob",
});

instance.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            const AUTH_TOKEN = cookies.get(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');
            if (AUTH_TOKEN) config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;

            const XSwitchUser = localStorage.getItem("X-Switch-User");
            if (XSwitchUser) config.headers['X-Switch-User'] = XSwitchUser;

            const SENTRY_ERRORS = cookies.get('skip_sentry_errors');
            if (SENTRY_ERRORS)
                config.headers = Object.assign({}, config.headers, {
                    'X-Sentry-Errors': SENTRY_ERRORS,
                });
        }

        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && error.response.status) {
            if (401 === error.response.status) {
                axiosErrorRedirect(error);

                return Promise.reject(error);
            } else if (404 === error.response.status) {
                return Promise.reject(error);
            } else if (403 === error.response.status) {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
