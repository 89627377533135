import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateGet, templateUpdate } from '../..';

function filter(form) {
   //a következő sorok átalakítják a szűrési paramétereket
   let formToSend = Object.assign({}, form);

   for (const prop in formToSend['address']) {
       formToSend[`address.${prop}`] = formToSend['address'][prop];
   }

   delete formToSend.address;

    for (const elem in formToSend) {
        if (formToSend[elem] === "") delete formToSend[elem];
    }

   const searchForm = new SearchForm(formToSend);

    return API.get('/campuses', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const campusesService = {
    filter,
    create: templateCreate('campuses'),
    get: templateGet('campuses'),
    update: templateUpdate('campuses'),
    delete: templateDelete('campuses')
};

export default campusesService;