import { useEffect } from "react";
import { connect } from "react-redux";
import emailTemplatesActions from "../../../store/administration/emailTemplates/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";

function EmailTemplates(props) {
    const { filter, data, filterStorageKey } = props;

    const header = {
        title: "E-mail sablonok",
        breadcrumbs: {
            "administration": "Adminisztráció",
            "emailTemplates": "E-mail sablonok"
        }
    }

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        props.search(storageFilter)
        .then(() => props.loadingOff())
        .finally(() => props.loadingOff());
    }, []);

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            props.search({});
        },
        form: filter,
        changeform: props.changeForm
    }

    //itt a szerkesztés gombra kattintva átadjuk a linkben az objektumot is,
    //ugyanis rendereléskor fontos, hogy meglegyen előre az objektum -
    //- legalább is én más megoldást nem találtam erre
    function BtnCellRenderer(params) {
        return (
            <OperationButton
                type="edit"
                to={{
                    pathname: "/email-templates/edit/" + params.data.id,
                    emailTemplate: data['hydra:member']?.find(elem => {
                        return elem.id === params.data.id;
                    })
                }}
            />
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Kulcs", field: "key" },
            { headerName: "Név", field: "name" },
            { headerName: "Tárgy", field: "subject" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                minWidth: 220,
                sortable: false
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer
        }
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Kulcs"
                name="key"
                value={filter.key || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Név"
                name="translations.name"
                value={filter.translations?.name || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Tárgy"
                name="translations.subject"
                value={filter.translations?.subject || ""}
                format={{ xs: 12, md: 4 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.emailTemplates;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: emailTemplatesActions.changeForm,
    defaultForm: emailTemplatesActions.defaultForm,
    search: emailTemplatesActions.filter,
    delete: emailTemplatesActions.delete,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(EmailTemplates);