import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import {
    templateCreate,
    templateDelete,
    templateGet,
    templateUpdate,
} from '../..';

function filter(form) {
    const mapping = {
        lastLoginFrom: 'lastLoggedInAt[after]',
        lastLoginUntil: 'lastLoggedInAt[before]',
    };
    const searchForm = new SearchForm(form, mapping);

    return API.get('/users', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function impersonate(form) {
    const searchForm = new SearchForm(form);

    return API.get('/users/impersonate', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const usersService = {
    filter,
    create: templateCreate('users'),
    get: templateGet('users'),
    update: templateUpdate('users'),
    delete: templateDelete('users'),
    impersonate,
};

export default usersService;