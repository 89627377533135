export const types = {
    CHANGE_FORM: "@@rooms/CHANGE_FORM",
    DEFAULT_FORM: "@@rooms/DEFAULT_FORM",

    FETCH_REQUEST: "@@rooms/FETCH_REQUEST",
    FETCH_SUCCESS: "@@rooms/FETCH_SUCCESS",
    FETCH_FAILURE: "@@rooms/FETCH_FAILURE",

    GET_REQUEST: "@@rooms/GET_REQUEST",
    GET_SUCCESS: "@@rooms/GET_SUCCESS",
    GET_FAILURE: "@@rooms/GET_FAILURE",

    GET_ASSIGNABLE_ROOMS_REQUEST: "@@rooms/GET_ASSIGNABLE_ROOMS_REQUEST",
    GET_ASSIGNABLE_ROOMS_SUCCESS: "@@rooms/GET_ASSIGNABLE_ROOMS_SUCCESS",
    GET_ASSIGNABLE_ROOMS_FAILURE: "@@rooms/GET_ASSIGNABLE_ROOMS_FAILURE",

    GET_ASSIGNABLE_ROOMS_FOR_FILTER_REQUEST: "@@rooms/GET_ASSIGNABLE_ROOMS_FOR_FILTER_REQUEST",
    GET_ASSIGNABLE_ROOMS_FOR_FILTER_SUCCESS: "@@rooms/GET_ASSIGNABLE_ROOMS_FOR_FILTER_SUCCESS",
    GET_ASSIGNABLE_ROOMS_FOR_FILTER_FAILURE: "@@rooms/GET_ASSIGNABLE_ROOMS_FOR_FILTER_FAILURE",
}