import TextField from "./TextField";
import Title from './Title';

export default function Address(props) {
    const { form } = props;

    const formProps = {
        form: props.form,
        name: props.name,
        changeform: props.changeform,
        defaultform: props.defaultform,
        validationschema: props.validationschema,
        onsubmit: props.onsubmit,
        isformikform: props.isformikform,
    }

    return(
        <Title
            title={props.title || "Cím"}
            {...formProps}
        >
            <TextField
                label="Irányítószám"
                name="address.postCode"
                value={form.address?.postCode || ""}
                format={{xs: 12, md: 3}}
                rules={{
                    number: "true"
                }}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.postCode'] ?? form.errors?.address?.postCode}
                helperText={form.errors?.['address.postCode'] ?? form.errors?.address?.postCode}
            />
            <TextField
                label="Település"
                name="address.city"
                value={form.address?.city || ""}
                format={{xs: 12, md: 9}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.city'] ?? form.errors?.address?.city}
                helperText={form.errors?.['address.city'] ?? form.errors?.address?.city}
            />
            <TextField
                label="Közterület neve"
                name="address.streetName"
                value={form.address?.streetName || ""}
                format={{xs: 12, md: 4}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.streetName'] ?? form.errors?.address?.streetName}
                helperText={form.errors?.['address.streetName'] ?? form.errors?.address?.streetName}
            />
            <TextField
                label="Közterület jellege"
                name="address.streetType"
                value={form.address?.streetType || ""}
                format={{xs: 12, md: 4}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.streetType'] ?? form.errors?.address?.streetType}
                helperText={form.errors?.['address.streetType'] ?? form.errors?.address?.streetType}
            />
            <TextField
                label="Házszám"
                name="address.houseNumber"
                value={form.address?.houseNumber || ""}
                format={{xs: 12, md: 4}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.houseNumber'] ?? form.errors?.address?.houseNumber}
                helperText={form.errors?.['address.houseNumber'] ?? form.errors?.address?.houseNumber}
            />
            {props.detailedAddress &&
            <TextField
                label="Emelet"
                name="address.floor"
                value={form.address?.floor || ""}
                format={{xs: 12, md: 6}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.floor'] ?? form.errors?.address?.floor}
                helperText={form.errors?.['address.floor'] ?? form.errors?.address?.floor}
            />
            }
            {props.detailedAddress &&
            <TextField
                label="Ajtó"
                name="address.door"
                value={form.address?.door || ""}
                format={{xs: 12, md: 6}}
                margin="none"
                disabled={props.disabled === undefined ? false : props.disabled}
                error={form.errors?.['address.door'] ?? form.errors?.address?.door}
                helperText={form.errors?.['address.door'] ?? form.errors?.address?.door}
            />
            }
        </Title>
    );
}