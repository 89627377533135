import PageTemplate from "../../Common/PageTemplate"
import Accordion from "../../Common/Accordion"
import NavMenuItem from "../../Common/NavMenuItem"
import Grid from "@material-ui/core/Grid";

export default function Forms() {
    const header = {
        title: "Űrlapok",
        breadcrumbs: {
            "forms": "Űrlapok"
        }
    }

    return(
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="Űrlapok" expanded fullWidth>
                    <NavMenuItem
                        text="Beiratkozás"
                        to="/forms/enrollment"
                    />
                    <NavMenuItem
                        text="Szobabeosztás"
                        to="/forms/room-layout"
                    />
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}