import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import campusSelectorActions from "../../../store/modules/campusSelector/actions";
import campusesActions from "../../../store/masterData/campuses/actions";
import Accordion from "../../Common/Accordion";
import Form from "../../Common/Form";
import PageTemplate from "../../Common/PageTemplate";
import Select from "../../Common/Select";
import SubmitButton from "../../Common/SubmitButton";
import loginService from "../../../services/login";
import loadingActions from "../../../store/loading/actions";
import loginActions from "../../../store/modules/login/actions";
import notificationActions from "../../../store/notification/actions";
import { history } from "../../../store";

function CampusSelector(props) {
    const { form, data, loading } = props;
    const [options, setOptions] = useState([]);

    const header = {
        title: "Campusválasztó",
        breadcrumbs: {
            campusSelector: "Campusválasztó"
        }
    }

    useEffect(() => {
        if (data["hydra:member"]?.length > 0) {
            setOptions(
                data["hydra:member"]
                .map(option => (
                        {
                            value: option.id, 
                            label: option.name
                        }
                    )
                )
            )
        } else {
            props.fetch()
            .then(result => 
                setOptions(
                    result?.data["hydra:member"]
                    .map(option => (
                            {
                                value: option.id, 
                                label: option.name
                            }
                        )
                    )
                )
            )
        }
    },[])

    const handleSubmit = () => {
        if (form.campus) {
            const selected = data["hydra:member"].find(option => option.id === form.campus);
            props.changeForm(selected, "selected");
            props.loadingOn();
            props.setCampus({campus: form.campus})
            .then(
                () => loginService.getUser()
                .then(() => {
                    const storageUser = JSON.parse(localStorage.getItem("user"));
                    props.setUserCampus(storageUser.campus);
                    props.loadingOff();
                    props.removeNotification();
                    history.push("/");
                })
            )
        }
    }

    return (
        <PageTemplate header={header} loading={loading}>
            <Grid item xs={12}>
                <Accordion title="Campus kiválasztása">
                    <Form
                        changeform={props.changeForm}
                        form={form}
                        onSubmit={handleSubmit}
                    >
                        <Select
                            selectLabel="Campus"
                            name="campus"
                            optionList={options}
                            value={form.campus}
                            format={{xs: 12}}
                        />
                        <SubmitButton type="submit" onClick={handleSubmit}>
                            Kiválasztás
                        </SubmitButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.campusSelector;
    const { data } = state.campuses;
    const { loading } = state.loading;
    return { form, data, loading };
}

const actionCreators = {
    changeForm: campusSelectorActions.changeForm,
    setCampus: campusSelectorActions.setCampus,
    setUserCampus: loginActions.setUserCampus,
    fetch: () => campusesActions.filter({}),
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    removeNotification: notificationActions.removeNotification,
};


export default connect(mapState, actionCreators)(CampusSelector)