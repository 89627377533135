import { store } from "../store";
import { LAYOUT_TYPES, PERMISSIONS, PERMISSIONS_WITH_USER } from "./AppConst";

/**
 *
 * @returns Local storage-ban tárolt felhasználó
 */
export const getUser = () => {
    const state = store.getState();
    const { user } = state.login;
    if (!user) {
        return null;
    }
    return user;
};

/**
 *
 * @returns Felhasználó azonosítója
 */
export const getUserId = () => {
    const user = getUser();
    if (!user) return false;
    return user?.id;
};

/**
 *
 * @param {*} role - Vizsgálandó szerepkör
 * @returns Van-e a felhasználónak ilyen jogosultsága vagy nincs
 */
export const hasRole = (role) => {
    const user = getUser();
    if (!user) {
        return false;
    }

    return user?.roles?.includes(role);
};

/**
 *
 * @returns Csak felhasználói jogosultsága van
 */
export const hasOnlyUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
        return false;
    }
    const userRole = user?.roles?.includes(PERMISSIONS_WITH_USER.USER);
    const onlyUser = user?.roles?.length === 1;
    return userRole && onlyUser;
}

/**
 *
 * @returns Van-e hallgatói és plusz más jogosultsága vagy nincs
 */
export const hasStudentAndOther = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
        return false;
    }

    const student = user?.roles?.includes(PERMISSIONS.STUDENT);
    const others = user?.roles?.length > 1;

    return student && others;
}

/**
 *
 * @returns Csak hallgatói jogosultsága van-e
 */
export const hasOnlyStudent = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
        return false;
    }

    const student = user?.roles?.includes(PERMISSIONS.STUDENT);
    const others = user?.roles?.length == 1;

    return student && others;
}

/**
 *
 * @returns A felhasználónak van-e admin jogosultsága
 */
export const isSystemAdmin = () => {
    return hasRole(PERMISSIONS.SYSTEM_ADMIN);
};

/**
 *
 * @returns A felhasználónak van-e hallgató jogosultsága
 */
export const isStudent = () => {
    return hasRole(PERMISSIONS.STUDENT);
}

export const roleByLayout = (user, layout) => {
    if (layout === LAYOUT_TYPES.STUDENT) {
        return [PERMISSIONS.STUDENT];
    }

    return user.roles.filter((element) => element !== PERMISSIONS.STUDENT);
}