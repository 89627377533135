import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateGet } from '../..';
import { store } from '../../../store';
import { LAYOUT_TYPES } from '../../../utils/AppConst';

function filterOngoing(form) {
    const state = store.getState();
    if (state.main.layout && state.main.layout === LAYOUT_TYPES.STUDENT) {
        const searchForm = new SearchForm({...form, student: 1});
        return API.get('/processes/my/ongoing', {params: searchForm.getSend()})
        .then((data) => data)
        .catch((error) => Promise.reject(error));
    } else {
        const searchForm = new SearchForm({...form});
        return API.get('/processes/my/ongoing', {params: searchForm.getSend()})
        .then((data) => data)
        .catch((error) => Promise.reject(error));
    }
}

function filterClosed(form) {
    const state = store.getState();
    if (state.main.layout && state.main.layout === LAYOUT_TYPES.STUDENT) {
        const searchForm = new SearchForm({...form, student: 1});
        return API.get('/processes/my/done', {params: searchForm.getSend()})
        .then((data) => data)
        .catch((error) => Promise.reject(error));
    } else {
        const searchForm = new SearchForm({...form});
        return API.get('/processes/my/done', {params: searchForm.getSend()})
        .then((data) => data)
        .catch((error) => Promise.reject(error));
    }
}
    

const mainPageService = {
    filterOngoing,
    filterClosed,
    create: templateCreate('notification-templates'),
    get: templateGet('notification-templates'),
    delete: templateDelete('notification-templates'),
};

export default mainPageService;