import moment from "moment";

export function formatDate(date, timezoneOffset) {
    if (!date) {
        return '-';
    }
    const momentDate = moment(date);
    const adjustedDate = momentDate.add(timezoneOffset, 'seconds');
    return adjustedDate.format('YYYY.MM.DD. HH:mm:ss');
}

export function addOffsetToDate(date, timezoneOffset) {
    const momentDate = moment(date);
    const adjustedDate = momentDate.add(timezoneOffset, 'seconds');
    return adjustedDate.format('YYYY-MM-DDTHH:mm');
}