import { templateChangeForm, templateDefaultForm } from "../..";
import roomApprovalService from "../../../services/processes/roomApproval";
import makeFormToSend from "../../../utils/makeFormToSend";
import notificationTypes from "../../notification/types";
import { types } from "./types";
import { history } from "../..";

const accept = (form, id) => (dispatch) => {
    let formToSend = makeFormToSend(form);

    if (formToSend.campus) delete formToSend.campus;

    dispatch({ type: types.ACCEPT_REQUEST });
    return roomApprovalService.accept(formToSend, id).then(
        (data) => {
            dispatch({ type: types.ACCEPT_SUCCESS, formToSend });
            dispatch({ 
                type: notificationTypes.ADD_NOTIFICATION,
                data: {type: "success", msg: "Sikeres mentés"}
            });
            history.push("/");
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.ACCEPT_FAILURE, error });
            dispatch({ 
                type: notificationTypes.ADD_NOTIFICATION,
                data: {type: "error", msg: "Hiba lépett fel küldés közben"}
            })
            return Promise.reject(error);
        }
    )
}

const acceptAndClose = (form, id) => (dispatch) => {
    let formToSend = makeFormToSend(form);

    if (formToSend.campus) delete formToSend.campus;

    formToSend.skipRoomTakeOver = true;

    dispatch({ type: types.ACCEPT_REQUEST });
    return roomApprovalService.accept(formToSend, id).then(
        (data) => {
            dispatch({ type: types.ACCEPT_SUCCESS, formToSend });
            dispatch({ 
                type: notificationTypes.ADD_NOTIFICATION,
                data: {type: "success", msg: "Sikeres mentés"}
            });
            history.push("/");
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.ACCEPT_FAILURE, error });
            dispatch({ 
                type: notificationTypes.ADD_NOTIFICATION,
                data: {type: "error", msg: "Hiba lépett fel küldés közben"}
            })
            return Promise.reject(error);
        }
    )
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    accept,
    acceptAndClose
}

export default actions;