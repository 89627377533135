import { templateChangeForm, templateDefaultForm, templateGet, history } from "../..";
import operationalControlService from "../../../services/processes/operationalControl";
import { types } from "./types";
import notificationTypes from "../../notification/types";

const create = (id, url) => (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return operationalControlService.create(id).then(
        (data) => {
            dispatch({ type: types.CREATE_SUCCESS });
            dispatch({ type: types.DEFAULT_FORM, name: "form" });
            dispatch({ 
              type: notificationTypes.ADD_NOTIFICATION,
              data: {type: "success", msg: "Sikeres mentés"}
            })
            if (url) history.push(url);
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.CREATE_FAILURE, error });
            dispatch({ 
              type: notificationTypes.ADD_NOTIFICATION,
              data: {type: "error", msg: "Hiba lépett fel mentés közben"}
            })
            return Promise.reject(error);
        }
    );
  };

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    create,
    get: templateGet(types, operationalControlService),
}

export default actions;