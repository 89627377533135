import { templateChangeForm, templateDefaultForm } from "../..";
import financialControlService from "../../../services/processes/financialControl";
import { types } from "./types";
import notificationTypes from "../../notification/types";

function approveFinancialCheck(enrollmentId) {
    return (dispatch) => {
        dispatch({ type: types.APPROVE_FINANCIAL_CHECK_REQUEST, enrollmentId});
        return financialControlService.approveFinancialCheck(enrollmentId).then(
            (data) => {
                dispatch({ type: types.APPROVE_FINANCIAL_CHECK_SUCCESS, data});
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres jóváhagyás"}
                });
                dispatch({ type: types.DEFAULT_FORM }); 
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.APPROVE_FINANCIAL_CHECK_FAILURE, error });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "error", msg: "Hiba lépett fel jóváhagyás közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

function sendUnpaidDebtNotification(enrollmentId, form) {
    return (dispatch) => {
        dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_REQUEST, enrollmentId});
        return financialControlService.sendUnpaidDebtNotification(enrollmentId, form).then(
            (data) => {
                dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS, data});
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres küldés"}
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_FAILURE, error });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "error", msg: "Hiba lépett fel küldés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

const changeEditor = content => dispatch => dispatch({type: types.CHANGE_EDITOR, content});

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    approveFinancialCheck,
    sendUnpaidDebtNotification,
    changeEditor
}

export default actions;