import { activeProcesses } from "./activeProcesses/reducer";
import { approval } from "./approval/reducer";
import { closedProcesses } from "./closedProcesses/reducer";
import { financialControl } from "./financialControl/reducer";
import { enrollment } from "./newProcess/enrollment/reducer";
import { newProcess } from "./newProcess/reducer";
import { relocationMultiple } from "./newProcess/relocationMultiple/reducer";
import { relocationSingle } from "./newProcess/relocationSingle/reducer";
import { studentAllocationPreference } from "./newProcess/studentAllocationPreference/reducer";
import { termination } from "./newProcess/termination/reducer";
import { terminationStudentDeclaration } from "./termination/studentDeclaration/reducer";
import { terminationOperatorCheck } from "./termination/operatorCheck/reducer";
import { terminationFinancialCheck } from "./termination/financialCheck/reducer";
import { moveOut } from "./newProcess/moveOut/reducer";
import { operationalControl } from "./operationalControl/reducer";
import { relocationApproveRoomTakeOver } from "./relocation/approveRoomTakeOver/reducer";
import { relocationFinancialControl } from "./relocation/financialControl/reducer";
import { relocationMoveOutForm } from "./relocation/moveOutForm/reducer";
import { relocationOperatorRoomTakeOver } from "./relocation/operatorRoomTakeOver/reducer";
import { relocationStudentFilling } from "./relocation/studentFilling/reducer";
import { relocationStudentRoomTakeOver } from "./relocation/studentRoomTakeOver/reducer";
import { roomAcceptanceReport } from "./roomAcceptanceReport/reducer";
import { roomAllocationList } from "./roomAllocationList/reducer";
import { roomApproval } from "./roomApproval/reducer";
import { studentAllocationPreferenceForm } from "./studentAllocationPreferenceForm/reducer";

export const processes = {
    operationalControl,
    financialControl,
    approval,
    newProcess,
    enrollment,
    activeProcesses,
    closedProcesses,
    relocationSingle,
    relocationMultiple,
    relocationStudentFilling,
    relocationFinancialControl,
    relocationMoveOutForm,
    relocationOperatorRoomTakeOver,
    relocationStudentRoomTakeOver,
    relocationApproveRoomTakeOver,
    roomAcceptanceReport,
    roomAllocationList,
    roomApproval,
    studentAllocationPreference,
    studentAllocationPreferenceForm,
    termination,
    terminationStudentDeclaration,
    terminationOperatorCheck,
    terminationFinancialCheck,
    moveOut,
};
