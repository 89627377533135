import { types } from "./types";

const initialState = {
    filterStorageKey: "masterData.semesters",
    filter: {},
    form: {},
    data: [],
    activeSemester: null,
    activeSemesterForEnrollment: null,
    activeSemesterForTermination: null,
    activeSemesterForMoveOut: null,
    activeSemesterForRoomAllocation: null,
    activeSemesterForRelocation: null,
};

export function semesters(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemester: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemesterForEnrollment: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemesterForTermination: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FOR_TERMINATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemesterForMoveOut: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemesterForRoomAllocation: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSemesterForRelocation: action.data.data[0],
            };
        case types.GET_ACTIVE_SEMESTER_FOR_RELOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state,
            };
    }
}
