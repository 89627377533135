import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import colors from '../../styles/colors.js';

//Használata ua., mint a MenuItem-nek
const TxtMenuItem = withStyles({
    root: {
        '&:active': {
            backgroundColor: colors.primaryYellow,
            color: colors.white,
        },
        '&:hover': {
            backgroundColor: colors.primaryBlueHover,
            color: colors.white,
        },
        cursor: 'pointer',
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
        justifyContent: "space-between"
    },
})(MenuItem);

export default TxtMenuItem;