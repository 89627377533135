import { history, templateChangeForm, templateDefaultForm, templateGet } from "../..";
import studentAllocationPreferenceService from "../../../services/processes/studentAllocationPreference";
import notificationTypes from "../../notification/types";
import { types } from "./types";

function fillForm(form, id, url) {
    let formToSend = {};

    formToSend.studentAllocationPreference = form.id;
    formToSend.comment = form.comment;
    formToSend.roomMates = [form.roomMates.value];

    return (dispatch) => {
        dispatch({ type: types.FILL_FORM_REQUEST, formToSend });
        return studentAllocationPreferenceService.fillForm(formToSend, id).then(
            (data) => {
                dispatch({ type: types.FILL_FORM_SUCCESS, data });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres mentés"}
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILL_FORM_FAILURE, error });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "error", msg: "Hiba lépett fel mentés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

function getPreferableRoommates(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_PREFERABLE_ROOMMATES_REQUEST, id });
        return studentAllocationPreferenceService.getPreferableRoommates(id).then(
            (data) => {
                dispatch({ type: types.GET_PREFERABLE_ROOMMATES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_PREFERABLE_ROOMMATES_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const autocompleteFilter = (form, id) => (dispatch) => {
    dispatch({ type: types.AUTOCOMPLETE_FILTER_REQUEST, form });
    return studentAllocationPreferenceService.autocompleteFilter(form, id).then(
        (data) => {
            dispatch({ type: types.AUTOCOMPLETE_FILTER_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.AUTOCOMPLETE_FILTER_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, studentAllocationPreferenceService),
    fillForm,
    getPreferableRoommates,
    autocompleteFilter
}

export default actions;