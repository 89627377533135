import { templateChangeForm, templateDefaultForm, templateFilter, templateGet } from "../..";
import { types } from "./types";
import universitiesService from "../../../services/masterData/universities";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, universitiesService),
    get: templateGet(types, universitiesService),
}

export default actions;