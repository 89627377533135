import { PROCESS_TYPE_NAMES } from "../../AppConst";
import TooltipRenderer from "../TooltipRenderer";

const TypeCellRenderer = (params) => {
    const type = params?.data?.type;
    let result = "-";

    switch (type) {
        case PROCESS_TYPE_NAMES.ENROLLMENT:
            result = "Beiratkozás";
            break;
        case PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE:
            result = "Szobatárspreferencia";
            break;
        case PROCESS_TYPE_NAMES.RELOCATION:
            result = "Átköltözés";
            break;
        case PROCESS_TYPE_NAMES.TERMINATION:
            result = "Kiiratkozás";
            break;
        case PROCESS_TYPE_NAMES.MOVE_OUT:
            result = "Kiköltözés";
            break;
    }

    return TooltipRenderer(result);
};

export default TypeCellRenderer;
