import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from './Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import Edit from '../Icons/Edit';
import Delete from '../Icons/Delete';
import House from '../Icons/House';
import colors from '../../styles/colors';
import { makeStyles } from '@material-ui/styles';
import Visibility from '../Icons/Visibility';
import Info from '../Icons/Info';
import Document from '../Icons/Document';
import Download from '../Icons/Download';
import Approval from '../Icons/Approval';
import Repair from '../Icons/Repair';
import File from '../Icons/File';
import Print from '../Icons/Print';
import Email from "../Icons/Email";

function getIcon(type) {
    //igény szerint bővíthető
    switch (type) {
        case "edit":
            return <Edit/>;
        case "delete":
            return <Delete/>
        case "house":
            return <House/>;
        case "visibility":
            return <Visibility/>;
        case "info":
            return <Info/>;
        case "document":
            return <Document/>;
        case "download":
            return <Download />;
        case "approval":
            return <Approval/>;
        case "control":
            return <Repair/>;
        case "report":
            return <File/>;
        case "print":
            return <Print/>;
        case "mail":
            return <Email/>;
        default:
            return;
    }
}

function getTitle(type) {
    switch (type) {
        case "edit":
            return "Szerkesztés";
        case "delete":
            return "Törlés";
        case "info":
            return "Információ";
        case "visibility":
            return "Megtekintés";
        case "document":
            return "Dokumentumok";
        case "download":
            return "Letöltés";
        case "approval":
            return "Jóváhagyás";
        case "control":
            return "Üzemeltetői ellenőrzés";
        case "report":
            return "Szobaátvételi jegyzőkönyv";
        case "print":
            return "Nyomtatás"
        case "mail":
            return "Regisztrációs link küldés";
        default:
            return "";
    }
}

/*
    Használat:
        <OperationButton
            type="gomb típusa, lehetséges verziókat lásd fent"
            successmessage="Sikeres művelet esetén megjelenő üzenet" (opcionális, default: "Sikeres törlés")
            errormessage="Sikertelen művelet esetén megjelenő üzenet" (opcionális, default: "Hiba történt a törlés során")
            submittext="Megerősítő gombon megjelenező üzenet" (opcionális, default: "Törlés")
            canceltext="Elvetés gombon megjelenező üzenet" (opcionális, default: "Mégsem")
            confirmation="Biztos, hogy végre akarja hajtani a ...-ot?" (opcionális, default: ""Biztosan törölni kívánja? A művelet nem visszavonható."")
            params={params, amit a btncellrenderer is kap}
            special (ez csak a kurzuskategóriák oldalhoz kellett, mert ott más a delete fgv. paraméterezése)
            minden további paraméter a Link-nek lesz átadva (to, onClick, stb.)
        />
*/

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
    container: {
        padding: theme.spacing(0.5),
    },
}));

export default function OperationButton(props) {
    const { type } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const classes = useStyles();

    const handleSubmit = () => {
        if (props.handleClick) props.handleClick();
        handleClose();
    };

    return(
        <>
            <Tooltip title={props.tooltiptitle || getTitle(type) || ''}>
                <Link className={classes.container} to={props.to || "#"} onClick={props.type !== "edit" ? () => setOpen(true) : ""} {...props}>
                    {getIcon(props.type)}
                </Link>
            </Tooltip>
            {(props.dialogOpenerButton || type === "delete") && 
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle className={classes.title}>
                        {props.title || "Törlés"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {props.confirmation || "Biztos törölni akarja?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <SubmitButton
                            onClick={handleSubmit}
                        >
                            {props.submitText || "Biztos"}
                        </SubmitButton>
                        <CancelButton
                            onClick={handleClose}
                        >
                            {props.cancelText || "Mégsem"}
                        </CancelButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}