
import { makeStyles } from '@material-ui/core';
import Tooltip from '../../Components/Common/Tooltip';

const useStyles = makeStyles(() => ({
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
}));

export default function TooltipRenderer(param) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const value = param === 0 ? "0" : param;

    return(
        <Tooltip title={value ? value : "-"}>
            <div className={classes.truncate}>{value ? (value) : "-"}</div>
        </Tooltip>
    );
}