import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateFetch, templateGet, templateUpdate } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/campuses/${otherData.campusId}/buildings/${otherData.buildingId}/floors`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}


const buildingsService = {
    filter,
    fetch: templateFetch('floors'),
    create: templateCreate('floors'),
    get: templateGet('floors'),
    update: templateUpdate('floors'),
    delete: templateDelete('floors')
};

export default buildingsService;