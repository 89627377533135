import { useEffect } from "react";
import Loading from "../../../Common/Loading";
import loadingActions from "../../../../store/loading/actions";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import Form from "../../../Common/Form";
import { connect } from "react-redux";
import approveRoomTakeOverActions from "../../../../store/processes/relocation/approveRoomTakeOver/actions";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import Accordion from "../../../Common/Accordion";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import { SemesterAllocationInfo } from "../../Relocation/Forms/SemesterAllocationInfo";
import DatePicker from "../../../Common/DatePicker";
import Dialog from "../../../Common/Dialog";
import SubmitButton from "../../../Common/SubmitButton";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store";
import colors from "../../../../styles/colors";
import { roomCleaningOptions, roomKeyOptions } from "../../Relocation/Forms/OperatorRoomTakeOver";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 4px",
        fontWeight: "bold"
    },
    tableCell: {
        fontSize: "12px",
        color: "#0000008A",
        fontWeight: "600",
        whiteSpace: "nowrap",
    },
    headerCell: {
        fontSize: "12px",
        color: colors.primaryBlue,
        fontWeight: "1000",
        whiteSpace: "nowrap",
    },
    container: {
        background: "rgba(0,0,0,0.1)"
    },
    titleText: {
        fontSize: "1.5rem",
        marginBottom: "1rem"
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify"
    },
}))

function RelocationDetails(props) {
    const { form, loading } = props;
    const { id } = props.match?.params;
    const classes = useStyles();

    useEffect(() => {
        if (id) {
            props.loadingOn();
            props.getRelocation(id)
                .finally(() => props.loadingOff());
        }
    }, []);

    const formProps = {
        form: form,
        changeform: props.changeForm
    }

    if (loading) return <Loading />

    return (
        <Grid item>
            <Accordion title="Űrlap" expanded>
                <Form>
                    <Title title="Típus" format={{ xs: 12 }} />
                    <TextField
                        value={"Átköltözés"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <Title title="Név" format={{ xs: 12 }} />
                    <TextField
                        name="lastName"
                        label="Vezetéknév"
                        value={form["lastName"] || "-"}
                        format={{ xs: 12, md: 6 }}
                        disabled
                    />
                    <TextField
                        name="firstName"
                        label="Keresztnév"
                        value={form["firstName"] || "-"}
                        format={{ xs: 12, md: 6 }}
                        disabled
                    />
                    <Title title="Állandó lakcím" format={{ xs: 12 }} />
                    <TextField
                        name="address"
                        value={createAddressStringFromObject(form["address"]) || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <Title title="E-mail cím" format={{ xs: 12 }} />
                    <TextField
                        name="email"
                        value={form["email"] || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <Title title="Telefonszám" format={{ xs: 12 }} />
                    <TextField
                        name="phoneNumber"
                        value={form["phoneNumber"] || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <SemesterAllocationInfo
                        form={form?.oldStudentAllocation}
                        formProps={formProps}
                        type="old"
                    />
                    <SemesterAllocationInfo
                        form={form?.newStudentAllocation}
                        formProps={formProps}
                        type="new"
                    />
                    <Title title="Kiköltözés várható dátuma" format={{ xs: 12 }} />
                    <DatePicker
                        name="moveOutDate"
                        value={form?.moveOutDate}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <Title title="Pénzügyes megjegyzése" format={{ xs: 12 }} />
                    <TextField
                        name="financialComment"
                        value={form["financialComment"] || ""}
                        format={{ xs: 12 }}
                        multiline
                        rows={4}
                        disabled
                    />
                    <Title title="Üzemeltető megjegyzése" format={{ xs: 12 }} />
                    <TextField
                        name="operatorComment"
                        value={form["operatorComment"] || ""}
                        format={{ xs: 12 }}
                        multiline
                        rows={4}
                        disabled
                    />
                    {RoomInfoDialog(classes, form)}
                    <CancelButton onClick={() => history.push("/processes")}>
                        Vissza
                    </CancelButton>
                </Form>
            </Accordion>
        </Grid>
    )
}

function RoomInfoDialog(classes, form) {
    return <Dialog
        title="Szobaadatok"
        opener={<SubmitButton
            className={classes.submit}
            type="button"
            fullWidth
        >
            Szobaadatok
        </SubmitButton>}
    >
        <Typography>
            <Paper className={classes.container} elevation={4}>
                <Grid container className={classes.textContainer} justify="center">
                    <Grid item xs={12} container justify="center">
                        <div className={classes.title}>A hallgató régi és új szobájának adatai</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.text}>
                            <strong>A régi szoba adatai:</strong>
                        </div>
                        <div className={classes.text}>
                            Campus: {form?.oldStudentAllocation?.room?.floor?.building?.campus?.name} <br />
                            Épület: {form?.oldStudentAllocation?.room?.floor?.building?.name} <br />
                            Szint: {form?.oldStudentAllocation?.room?.floor?.name} <br />
                            Szoba: {form?.oldStudentAllocation?.room?.name} <br />
                        </div>
                        {form.handedOverItems && form.handedOverItems.length > 0 &&
                            <>
                                <div className={classes.text}>
                                    <strong>Tárgyak és takarítás:</strong>
                                </div>
                                <div className={classes.text}>
                                    {form?.oldStudentAllocation?.room?.items?.map((item) => {
                                        return (
                                            <div>
                                                {item?.name}: {form.handedOverItems?.find(x => x.item?.id === item?.id)?.amount || 0}/{item?.amount}
                                            </div>
                                        );
                                    })}
                                    Szobatakarítás: {roomCleaningOptions.find(x => x.value === form["roomCleaningStatus"])?.label || "Nem szükséges"} <br />
                                    Szobakulcs: {roomKeyOptions.find(x => x.value === form["roomKeyStatus"])?.label || "Rendben"}
                                </div>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.text}>
                            <strong>Az új szoba adatai:</strong>
                        </div>
                        <div className={classes.text}>
                            Campus: {form?.newStudentAllocation?.room?.floor?.building?.campus?.name} <br />
                            Épület: {form?.newStudentAllocation?.room?.floor?.building?.name} <br />
                            Szint: {form?.newStudentAllocation?.room?.floor?.name} <br />
                            Szoba: {form?.newStudentAllocation?.room?.name} <br />
                        </div>
                        {form.takenOverItems && form.takenOverItems.length > 0 &&
                            <>
                                <div className={classes.text}>
                                    <strong>Tárgyak:</strong>
                                </div>
                                <div className={classes.text}>
                                    {form?.takenOverItems?.map((item) => {
                                        return (
                                            <div>
                                                {item?.name}: {item?.amount}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Typography>
    </Dialog>;
}

function mapState(state) {
    const { form } = state.relocationApproveRoomTakeOver;
    const loading = state.loading.loading;
    return { form, loading };
}


const actionCreators = {
    getRelocation: approveRoomTakeOverActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(RelocationDetails)