import { types } from "./types";

export const initialState = {
    filterStorageKey: "masterData.trainingLevels",
    filter: {
        archive: ['0']
    },
    form: {},
    data: [],
    trainingLevelsForEnrollment: [],
}

export function trainingLevels(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };

        case types.GET_FOR_ENROLLMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FOR_ENROLLMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                trainingLevelsForEnrollment: action.data.data,
            };
        case types.GET_FOR_ENROLLMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return {
                ...state
            }
    }
}