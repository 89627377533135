import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CreateIcon from '@material-ui/icons/Create';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import AlarmIcon from '@material-ui/icons/Alarm';
import BuildIcon from '@material-ui/icons/Build';
import { Children } from 'react';
import { ENROLLMENT_HISTORY_STATUSES, ENROLLMENT_HISTORY_STATUSES_ARRAY } from '../../utils/AppConst';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    dateTime: {
        marginTop: theme.spacing(1)
    },
    status: {
        fontWeight: "bold"
    }
}));

/**
 * A beiratkozási folyamatot ábrázoló idővonal
 * @param enrollmentHistory a komponensnek átadott státuszváltozásokat jelölő adattömb
 * 
 */
export default function CustomizedTimeline(props) {
    const { enrollmentHistory } = props;
    const classes = useStyles();

    const totalStepsForExternalStudents = 4;
    const totalStepsForInternalStudents = 7;

    let count = 0;
    enrollmentHistory?.history?.map(x => {
        if (ENROLLMENT_HISTORY_STATUSES_ARRAY.includes(x.type)) count++;
    })
    const historyItemsWithStatusUpdates = count;

    const membershipStatus = enrollmentHistory?.membershipStatus;
    const lastHistoryItem = enrollmentHistory?.history?.[enrollmentHistory?.history?.length - 1];

    const isClosed = () => {
        if (membershipStatus === "internal" && lastHistoryItem?.type === "operator_check_approved") return true;
        if (membershipStatus === "external" && lastHistoryItem?.type === "student_filling_approved") return true;
        return false;
    }

    const getIcon = (type) => {
        switch(type) {
            case "started":
                return <PlayArrowIcon />;
            case "student_form_filled":
                return <AddIcon />;
            case "unpaid_debt_notification_sent":
                return <AlarmIcon />;
            case "financial_check_approved":
                return <LocalAtmIcon />;
            case "updated":
                return <CreateIcon />;
            case "student_filling_approved":
                return <ThumbUpAltIcon />;
            case "room_approved":
                return <LocalHotelIcon />;
            case "room_taken_over":
                return <AssignmentIcon />;
            case "operator_check_approved":
                return <BuildIcon />;
        }
    }

    const getStatusText = (type) => {
        switch(type) {
            case "started":
                return 'Beiratkozási folyamat indítása';
            case "student_form_filled":
                return 'Hallgatói adatok kitöltése';
            case "unpaid_debt_notification_sent":
                return "Emlékeztető e-mail kiküldése";
            case "financial_check_approved":
                return 'Pénzügyi ellenőrzés';
            case "updated":
                return "Hallgatói adatok szerkesztése"
            case "student_filling_approved":
                return 'Jóváhagyás';
            case "room_approved":
                return 'Szobajóváhagyás';
            case "room_taken_over":
                return 'Szoba átvételi jegyzőkönyv';
            case "operator_check_approved":
                return 'Üzemeltetői ellenőrzés';
        }
    }

    return (
        <Timeline align="alternate">
            {Children.toArray(enrollmentHistory.history?.map((elem, index) => {
                return (
                    <TimelineItem>
                        <TimelineOppositeContent >
                            <Typography variant="body2" color="textSecondary" className={classes.dateTime}>
                                {elem.time}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                {getIcon(elem.type)}
                            </TimelineDot>
                            {(isClosed() && index === enrollmentHistory?.history?.length - 1) ? "" : <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h6" component="h2">
                            {getStatusText(elem.type)}
                            </Typography>
                            <Typography>{elem.actor?.fullName}</Typography>
                        </Paper>
                        </TimelineContent>
                    </TimelineItem>
                )
            }))}

            {(membershipStatus === "internal" && historyItemsWithStatusUpdates < totalStepsForInternalStudents) &&
                Children.toArray(ENROLLMENT_HISTORY_STATUSES.map((elem, index) => {
                    if (index > historyItemsWithStatusUpdates - 1) {
                        return (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot>
                                        {getIcon(elem.en)}
                                    </TimelineDot>
                                    {index + 1 === totalStepsForInternalStudents ? "" : <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h2">
                                    {elem.hu}
                                    </Typography>
                                </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    }
            }))}

            {(membershipStatus === "external" && historyItemsWithStatusUpdates < totalStepsForExternalStudents) &&
                Children.toArray(ENROLLMENT_HISTORY_STATUSES.map((elem, index) => {
                    if (index > historyItemsWithStatusUpdates - 1 && index < totalStepsForExternalStudents) {
                        return (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot>
                                        {getIcon(elem.en)}
                                    </TimelineDot>
                                    {index + 1 === totalStepsForExternalStudents ? "" : <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h2">
                                    {elem.hu}
                                    </Typography>
                                </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    }
            }))}
        </Timeline>
    );
}