import {Grid} from '@material-ui/core';
import { connect } from "react-redux";
import Accordion from "../../../Common/Accordion";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField"
import Title from "../../../Common/Title";
import Address from '../../../Common/Address';
import {
  formatBankAccountNumberForDisplay,
  getDocumentField,
  semesterStatus,
} from '../../../../utils/AppConst';
import React, { useEffect, useState } from 'react';
import { membershipStatusLabel } from '../../../../utils/cellRenderer/MemberShipStatusCellRenderer';
import Universities from '../../../Administration/Forms/Enrollment/Universities';
import PhoneInputField from "../../../Common/PhoneInputField";
import API from "../../../../utils/API";
import Select from '../../../Common/Select';

function Big(props) {
  const { form } = props;

  const [nationalities, setNationalities] = useState([]);

  useEffect(() => {
      getNationalities();
  }, []);

  function getNationalities() {
      API.get(`/enrollments/nationalities`)
          .then((data) => {
              setNationalities(data);
          })
          .catch((error) => {
              console.error("Error fetching nationalities:", error);
          });
  }


  return (
      <Grid item>
        <Accordion title="Űrlap" expanded>
          <Form>
            <Title title="Típus" format={{xs: 12}}>
              <TextField
                  value={form["type"] === "enrollment" ? "Beiratkozás" : "Kiiratkozás" || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            <Title title="Név" format={{xs: 12}}>
              <TextField
                  label="Vezetéknév"
                  value={form?.lastName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Keresztnév"
                  value={form?.firstName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            <Title title="Képzés" format={{xs: 12}}>
              <TextField
                  label="Képzési szint"
                  value={form.trainingLevel?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Képzési program"
                  value={form.trainingProgram?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Évfolyam"
                  value={form.collegeYear?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Státusz"
                  value={membershipStatusLabel(form.membershipStatus || "")}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Aktuális státusz"
                  value={membershipStatusLabel(form.currentMembershipStatus || "")}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Szemeszter státusz"
                  value={semesterStatus(form.semesterStatus) || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            {form.omId !== undefined && (
            <Title title="OM azonosító" format={{xs: 12}}>
              <TextField
                  value={form.omId || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            )}
            {form.taxNumber !== undefined && (
                <Title title="Adóazonosító jel" format={{xs: 12}}>
                  <TextField
                      value={form.taxNumber || ""}
                      format={{xs: 12}}
                      disabled
                  />
                </Title>
                )}
            {form.taj !== undefined && (
                <Title title="TAJ szám" format={{xs: 12}}>
                  <TextField
                      value={form.taj || ""}
                      format={{xs: 12}}
                      disabled
                  />
                </Title>
                )}
            {form.identificationNumberCnp !== undefined && (
                <Title title="Személyi szám (CNP)" format={{xs: 12}}>
                  <TextField
                      value={form.identificationNumberCnp || ""}
                      format={{xs: 12}}
                      disabled
                  />
                </Title>
                )}
            {form.nationality !== undefined && (
                <Title title="Állampolgárság" format={{xs: 12}}>
                    <Select
                        value={form.nationality || []}
                        selectLabel="Állampolgárság"
                        optionList={nationalities.data || []}
                        name="nationality"
                        format={{ xs: 12 }}
                        multiple
                        error={form.errors?.nationality}
                        helperText={form.errors?.nationality}
                        disabled
                    />
                </Title>
                )}
            <Title title="Campus" format={{xs: 12}}>
              <TextField
                  label="Campus"
                  value={form.campus?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Épület"
                  value={form.building?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Szint"
                  value={form.floor?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Szoba"
                  value={form.room?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>

            <Address
                title="Állandó lakcím"
                form={form}
                detailedAddress
                disabled={true}
                format={{xs: 12}}
            />
            
            {form.motherLastName !== undefined || form.motherFirstName !== undefined && (
            <Title title="Anyja neve" format={{xs: 12}}>
              <TextField
                  label="Vezetéknév"
                  value={form.motherLastName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Keresztnév"
                  value={form.motherFirstName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            )}
            {form.birthPlace !== undefined || form.birthDate !== undefined && (
            <Title title="Születési hely/idő" format={{xs: 12}}>
              <TextField
                  label="Születési hely"
                  value={form.birthPlace || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Születési idő"
                  value={form.birthDate || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            )}
            {form.idCardNumber !== undefined && (
            <Title title="SZIG szám" format={{xs: 12}}>
              <TextField
                  value={form.idCardNumber || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            )}
            {form.kepIdCardNumber !== undefined && (
            <Title title="SZIG szám (KEP)" format={{xs: 12}}>
              <TextField
                  value={form.kepIdCardNumber || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            )}
            {form.bankName !== undefined && (
            <Title title="Pénzintézet neve" format={{xs: 12}}>
              <TextField
                  value={form.bankName || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            )}
            {form.bankAccountNumber !== undefined && (
            <Title title="Bankszámlaszám" format={{xs: 12}}>
              <TextField
                  value={formatBankAccountNumberForDisplay(form.bankAccountNumber) || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            )}
            <Title title="Telefonszám" format={{xs: 12}} >
              <PhoneInputField
                    name="phoneNumber"
                    value={form.phoneNumber || ""}
                    format={{ xs: 12 }}
                    disabled
              />
            </Title>
            <Title title="E-mail cím" format={{xs: 12}}>
              <TextField
                  value={form.email || ""}
                  format={{xs: 11}}
                  disabled
              />
            </Title>
            <Universities readonly={true} format={{xs: 12}}/>
            <Title title="Elfogadott dokumentumok" format={{xs: 12}}>
              {
                form.acceptedDocuments?.map(f => {
                  return <TextField
                      value={getDocumentField(f, form)}
                      format={{xs: 12}}
                      disabled
                  />;
                })
              }
            </Title>
          </Form>
        </Accordion>
      </Grid>
  );
}

export default connect(null, null)(Big);