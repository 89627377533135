import API from '../../../utils/API';
import editApi from '../../../utils/editApi';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateGet } from '../..';

function filter(form) {
    //kiemeljük a form[translations]-ből az adattagokat hogy megfelelően tudjunk szűrni:
    let formToSend = Object.assign({}, form);

    for (const prop in formToSend['translations']) {
        formToSend[`translations.${prop}`] = formToSend['translations'][prop];
    }

    delete formToSend.translations;

    for (const elem in formToSend) {
        if (formToSend[elem] === "") delete formToSend[elem];
     }
    
    const searchForm = new SearchForm(formToSend);

    return API.get('/notification-templates', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function update(id, form) {
    let formToSend = { translations: [] };

    for (let [key, value] of Object.entries(form)) {
        formToSend.translations.push({
            "locale": key,
            "name": value.name,
            "subject": value.subject,
            "content": value.content
        });
    }

    return editApi.patch(`/notification-templates/${id}`, formToSend)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const emailTemplatesService = {
    filter,
    update,
    create: templateCreate('notification-templates'),
    get: templateGet('notification-templates'),
    delete: templateDelete('notification-templates'),
};

export default emailTemplatesService;