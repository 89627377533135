import { rolesOptions } from "../AppConst";
import TooltipRenderer from "./TooltipRenderer";

export default function RoleCellRenderer(params) {
    const roles = params?.data?.roles;
    let result = "-";

    if (roles.length === 1) {
        const role = roles[0];
        result = rolesOptions.find(x => x.value === role)?.label || '-';
    }
    
    return TooltipRenderer(result);
}