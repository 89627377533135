import { FormControl, RadioGroup, FormLabel, Radio, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { Children } from 'react';
import colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
    radio: {
        color: colors.primaryYellow,
        transition: "none",
        ".MuiRadio-colorSecondary.Mui-checked": {
            color: colors.primaryYellow
        }
    },
    label: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue
    },
}));


export default function RadioButton(props) {
    const {options, value} = props;

    const classes = useStyles();

    return(
        <FormControl component="fieldset">
            <FormLabel className={classes.label}>{props.label}</FormLabel>
            <RadioGroup value={value} onChange={props.handleChange} row>
                
                {Children.toArray(options.map(option => {
                    return (
                        <FormControlLabel 
                            value={option.value} 
                            label={option.label} 
                            control={
                                <Radio 
                                className={classes.radio}
                                style={{ color: colors.primaryYellow }}
                                />
                            }
                        />
                    )
                }))}
            </RadioGroup>
        </FormControl>
    );
}