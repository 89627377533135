import { withStyles } from "@material-ui/core";
import { Print as PrintBasic } from "@material-ui/icons";
import colors from "../../styles/colors.js";

const Print = withStyles({
    root: {
        backgroundColor: colors.new,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
})(PrintBasic);

export default Print;
