import { makeStyles, TableCell } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    base: {
        fontSize: "12px",
        color: "#0000008A",
        fontWeight: "600",
        whiteSpace: "nowrap",
    },
    sortable: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f2f2f2"
        }
    }
}))

function TableHeaderCell(props) {
    const classes = useStyles();

    return <TableCell className={`${classes.base} ${props.sortable && classes.sortable}`} {...props}>{props.children}</TableCell>
}

export default TableHeaderCell;