import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Children, useEffect, useState } from 'react';
import CancelButton from "../Common/CancelButton";
import axios from 'axios';
import packageJson from '../../../package.json';
import { useStyles } from './Dialog';

const KEYS_TO_PUSH = ['Control', 'F2'];
function VersionModal({ title, versions, handleOpen }) {
    const [open, setOpen] = useState(false);
    const [keys, setKeys] = useState([]);
    const classes = useStyles();

    const handleKeyDown = (event) => {
        const { key } = event;
        setKeys((prev) => (prev.includes(key) ? prev : [...prev, key]));
    };
    const handleKeyUp = (event) => {
        const { key } = event;
        setKeys((prev) => prev.filter((elem) => elem !== key));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (open) handleOpen();
    }, [open]);

    useEffect(() => setOpen((prev) => prev || KEYS_TO_PUSH.every((keyToPush) => keys.includes(keyToPush))), [keys]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle className={classes.title}>{'Verziók' || title}</DialogTitle>
            <DialogContent style={{ marginTop: '16px' }}>
                {Children.toArray(
                    versions.map((version) => (
                        <Typography key={version.version + version.name}>
                            {version.name + ': ' + version.version}
                        </Typography>
                    )),
                )}
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => setOpen(false)}>Bezárás</CancelButton>
            </DialogActions>
        </Dialog>
    );
}

const initVersions = [
    {
        name: 'Frontend',
        version: packageJson.version,
        versionDate: process.env.REACT_APP_CODE_VERSION_DATE,
    },
    {
        name: 'Backend',
        version: 'Betöltés...',
        versionDate: '',
    },
];

export default function Versions() {
    const [versions, setVersions] = useState(initVersions);

    const setBackendVersion = (value, date) =>
        setVersions((prev) =>
            prev.map((version) =>
                version.name === 'Backend' ? { ...version, version: value, versionDate: date } : version,
            ),
        );

    const handleOpen = () => {
        axios
            .get(process.env.REACT_APP_API_URL + '/version')
            .then((response) =>
                setBackendVersion(response.data.version, response.data.versionDate),
            )
            .catch(() => setBackendVersion('-'));
    };

    return (
        <VersionModal
            versions={(versions ?? []).map((v) => {
                return {
                    'name': v.name,
                    'version': v.version + (v.versionDate ? ' (' + v.versionDate + ')' : ''),
                };
            })}
            handleOpen={handleOpen}
        />
    );
}