export const types = {
    CHANGE_FORM: "@@semesters/CHANGE_FORM",
    DEFAULT_FORM: "@@semesters/DEFAULT_FORM",

    FETCH_REQUEST: "@@semesters/FETCH_REQUEST",
    FETCH_SUCCESS: "@@semesters/FETCH_SUCCESS",
    FETCH_FAILURE: "@@semesters/FETCH_FAILURE",

    GET_REQUEST: "@@semesters/GET_REQUEST",
    GET_SUCCESS: "@@semesters/GET_SUCCESS",
    GET_FAILURE: "@@semesters/GET_FAILURE",

    GET_ACTIVE_SEMESTER_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_REQUEST",
    GET_ACTIVE_SEMESTER_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_SUCCESS",
    GET_ACTIVE_SEMESTER_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FAILURE",

    GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_REQUEST",
    GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_SUCCESS",
    GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ENROLLMENT_FAILURE",

    GET_ACTIVE_SEMESTER_FOR_TERMINATION_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_TERMINATION_REQUEST",
    GET_ACTIVE_SEMESTER_FOR_TERMINATION_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_TERMINATION_SUCCESS",
    GET_ACTIVE_SEMESTER_FOR_TERMINATION_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_TERMINATION_FAILURE",

    GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_REQUEST",
    GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_SUCCESS",
    GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_MOVE_OUT_FAILURE",

    GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_REQUEST",
    GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_SUCCESS",
    GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_ROOM_ALLOCATION_FAILURE",

    GET_ACTIVE_SEMESTER_FOR_RELOCATION_REQUEST:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_RELOCATION_REQUEST",
    GET_ACTIVE_SEMESTER_FOR_RELOCATION_SUCCESS:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_RELOCATION_SUCCESS",
    GET_ACTIVE_SEMESTER_FOR_RELOCATION_FAILURE:
        "@@semesters/GET_ACTIVE_SEMESTER_FOR_RELOCATION_FAILURE",
};
