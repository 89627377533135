import TooltipRenderer from "./TooltipRenderer";

export default function AddressCellRenderer(params) {
    const address = params.data?.address;

    let addressString = "-";
    if (address && address.length !== 0) {
        addressString = `${address.postCode || ""} ${address.city || ""}`;

        if (address.streetName) {
            addressString =
                addressString +
                `, ${address.streetName || ""} ${address.streetType || ""} ${
                    address.houseNumber || ""
                }`;
        }

        if (
            address.floor !== null &&
            address.floor !== undefined &&
            address.door !== null &&
            address.door !== undefined
        ) {
            addressString =
                addressString + ` ${address.floor}/${address.door}. ajtó`;
        }
        if (
            (address.floor === null || address.floor === undefined) &&
            address.door !== null &&
            address.door !== undefined
        ) {
            addressString = addressString + ` ${address.door || ""}. ajtó`;
        }
    } else {
        if (
            !params.data?.addressPostCode &&
            !params.data?.addressCity &&
            !params.data?.addressStreetName &&
            !params.data?.addressStreetType &&
            !params.data?.addressHouseNumber
        ) {
            return TooltipRenderer(addressString);
        }

        addressString = `${params.data?.addressPostCode || ""} ${
            params.data?.addressCity || ""
        }, ${params.data?.addressStreetName || ""} ${
            params.data?.addressStreetType || ""
        } ${params.data?.addressHouseNumber || ""}`;

        if (
            params.data?.addressFloor !== null &&
            params.data?.addressFloor !== undefined &&
            params.data?.addressDoor !== null &&
            params.data?.addressDoor !== undefined
        ) {
            addressString =
                addressString +
                ` ${params.data?.addressFloor || ""}/${
                    params.data?.addressDoor || ""
                }. ajtó`;
        }

        if (
            (params.data?.addressFloor === null ||
                params.data?.addressFloor === undefined) &&
            params.data?.addressDoor !== null &&
            params.data?.addressDoor !== undefined
        ) {
            addressString =
                addressString + ` ${params.data?.addressDoor || ""}. ajtó`;
        }
    }

    return TooltipRenderer(addressString);
}
