import {
    history,
    templateChangeForm,
    templateDefaultForm,
    templateGet,
} from "../../..";
import terminationsService from "../../../../services/processes/termination";
import { types } from "./types";
import notificationTypes from "../../../notification/types";

function approveFinancialCheck(form, id, url) {
    const formToSend = {
        terminationId: Number(id),
        financialComment: form.financialComment,
    };

    return (dispatch) => {
        dispatch({ type: types.APPROVE_FINANCIAL_CHECK_REQUEST, formToSend });
        return terminationsService.approveFinancialCheck(formToSend, id).then(
            (data) => {
                dispatch({ type: types.APPROVE_FINANCIAL_CHECK_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés!" },
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.APPROVE_FINANCIAL_CHECK_FAILURE,
                    error,
                });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel küldés közben!",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

function sendUnpaidDebtNotification(terminationId, form) {
    const formToSend = {
        terminationId: Number(terminationId),
        emailContent: form.emailContent,
        emailSubject: form.emailSubject,
    };

    return (dispatch) => {
        dispatch({
            type: types.SEND_UNPAID_DEBT_NOTIFICATION_REQUEST,
            payload: { terminationId, formToSend },
        });
        return terminationsService
            .sendUnpaidDebtNotification(terminationId, formToSend)
            .then(
                (data) => {
                    dispatch({
                        type: types.SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS,
                        data,
                    });
                    dispatch({
                        type: notificationTypes.ADD_NOTIFICATION,
                        data: { type: "success", msg: "Sikeres küldés" },
                    });
                    dispatch({ type: types.DEFAULT_FORM });
                    return Promise.resolve(data);
                },
                (error) => {
                    dispatch({
                        type: types.SEND_UNPAID_DEBT_NOTIFICATION_FAILURE,
                        error,
                    });
                    dispatch({
                        type: notificationTypes.ADD_NOTIFICATION,
                        data: {
                            type: "error",
                            msg: "Hiba lépett fel küldés közben",
                        },
                    });
                    return Promise.reject(error);
                }
            );
    };
}

function updateFinancialComment(form, id) {
    const formToSend = {
        terminationId: Number(id),
        financialComment: form.financialComment,
    };

    return (dispatch) => {
        dispatch({
            type: types.UPDATE_FINANCIAL_COMMENT_REQUEST,
            payload: { formToSend, id },
        });
        return terminationsService.updateFinancialComment(formToSend, id).then(
            (data) => {
                dispatch({
                    type: types.UPDATE_FINANCIAL_COMMENT_SUCCESS,
                    payload: { data, id },
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.UPDATE_FINANCIAL_COMMENT_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return terminationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const changeEditor = (content) => (dispatch) =>
    dispatch({ type: types.CHANGE_EDITOR, content });

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    approveFinancialCheck,
    sendUnpaidDebtNotification,
    updateFinancialComment,
    get: templateGet(types, terminationsService),
    getForm,
};

export default actions;
