import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "../../../../utils/User";
import { makeStyles } from "@material-ui/core";
import loadingActions from "../../../../store/loading/actions";
import semestersActions from "../../../../store/masterData/semesters/actions";
import relocationMultipleActions from "../../../../store/processes/newProcess/relocationMultiple/actions";
import notificationActions from "../../../../store/notification/actions";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../../../../utils/AppConst";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import Title from "../../../Common/Title";
import CkEditor from "../../../Common/CkEditor";
import CancelButton from "../../../Common/CancelButton";
import axios from "axios";
import colors from "../../../../styles/colors";
import { useSnackbar } from "notistack";
import { history } from "../../../../store";

const useStyles = makeStyles(() => ({
    insert: {
        backgroundColor: colors.insert,
        "&:hover": {
            backgroundColor: colors.insertHover,
        },
    },
}));

/**
 * A function that returns a FormPageTemplate component.
 * The fields of the form are:
 * - Campus: readonly and can only have the value "Budapest" for now
 * - Semester: the last active semester by default, readonly
 * - StudentList which is a list of students that can be selected by the following rules:
 *     - we check the last studentAllocations generated
 *     - we list the students that got a new room:
 *          - the room generated in the latest studentAllocation differs from the students room
 * - EmailTemplate
 *
 * @param {*} props
 */
function RelocationMultiple(props) {
    const {
        form,
        studentOptions,
        emailTemplate,
        relocatableStudents,
        activeSemesterForRelocation,
    } = props;
    const [studentLoading, setStudentLoading] = useState(false);
    const [studentOptionsEmpty, setStudentOptionsEmpty] = useState(true);
    const [valid, setValid] = useState(false);
    const classes = useStyles();
    const campus = getUser()?.campus;
    const { enqueueSnackbar } = useSnackbar();

    const header = {
        title: "Tömeges átköltözés folyamat indítása",
        breadcrumbs: {
            processes: "Folyamatok",
            "/new-process": "Új folyamat",
            "relocation-multiple": "Tömeges átköltözés folyamat indítása",
        },
    };

    const validationSchema = yup.object().shape({
        emailSubject: yup.mixed().required(VALIDATION_MESSAGES.required),
        emailContent: yup.mixed().required(VALIDATION_MESSAGES.required),
    });

    useEffect(() => {
        validationSchema.isValid(form).then((valid) => {
            setValid(valid);
        });
    }, [form]);

    const customValidation = (propertyPath, message) => {
        if (propertyPath.includes("students[")) {
            enqueueSnackbar(
                { message: message, variant: "error" },
                { persist: false }
            );
        } else if (propertyPath === "") {
            props.addNotification("error", message);
        }
    };

    const formProps = {
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        onsubmit: () => props.startRelocationMultiple(form),
        isformikform: true,
        customvalidation: customValidation,
    };

    useEffect(() => {
        props.defaultForm("form");
        props.loadingOn();
        axios
            .all([
                props.getActiveSemesterForRelocation(),
                props.filterEmailTemplate(),
            ])
            .finally(() => props.loadingOff());
    }, []);

    const handleSelectAll = () =>
        props.changeForm(
            { ...form, students: studentOptions?.map((stud) => stud.value) },
            "form"
        );

    useEffect(() => {
        if (relocatableStudents) {
            props.changeForm(
                relocatableStudents.map((student) => ({
                    value: student["@id"],
                    label:
                        student.user?.lastName + " " + student.user?.firstName,
                })),
                "studentOptions"
            );
        }
    }, [relocatableStudents]);

    const handleFilterStudents = () => {
        props.changeForm(
            { ...form, students: [], campus: campus?.["@id"] },
            "form"
        );
        const campusId = campus?.["@id"];
        const semesterId = activeSemesterForRelocation?.id;
        if (semesterId && campusId) {
            setStudentLoading(true);
            props
                .getRelocatableStudents({
                    campus: campusId,
                    semester: semesterId,
                })
                .then(() => {
                    setStudentLoading(false);
                    setStudentOptionsEmpty(false);
                });
        }
    };

    return (
        <FormPageTemplate header={{ ...header }} form={formProps}>
            <TextField
                name="campus"
                value={campus?.name}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="semester"
                value={activeSemesterForRelocation?.name}
                format={{ xs: 12 }}
                disabled
            />
            <Select
                selectLabel="Hallgatók"
                name="students"
                value={form.students || []}
                disabled={!form.campus || studentLoading}
                optionList={studentOptions || []}
                format={{ xs: 12 }}
                loading={studentLoading}
                multiple
            />
            <SubmitButton
                className={form.campus && !studentLoading ? classes.insert : ""}
                disabled={!form.campus || studentLoading || studentOptionsEmpty}
                onClick={handleSelectAll}
            >
                Összes kiválasztása
            </SubmitButton>
            <SubmitButton
                disabled={!activeSemesterForRelocation}
                onClick={handleFilterStudents}
            >
                Hallgatók szűrése
            </SubmitButton>
            <Title {...formProps} title="E-mail" format={{ xs: 12 }}>
                <TextField
                    label="Tárgy"
                    name="emailSubject"
                    value={form.emailSubject || ""}
                    format={{ xs: 12 }}
                />
                <CkEditor
                    type="editor"
                    name="emailContent"
                    data={form.emailContent || ""}
                    format={{ xs: 12 }}
                    onChange={(_, editor) =>
                        props.changeEditor(editor.getData())
                    }
                />
            </Title>
            <SubmitButton
                name="insert"
                type="button"
                onClick={() =>
                    props.changeForm(
                        {
                            ...form,
                            emailSubject: emailTemplate?.subject,
                            emailContent: emailTemplate?.content,
                            errors: {},
                        },
                        "form"
                    )
                }
                format={{ xs: 12 }}
                className={classes.insert}
            >
                E-mail sablon beillesztése
            </SubmitButton>
            <SubmitButton type="submit" disabled={!valid}>
                Küldés
            </SubmitButton>
            <CancelButton onClick={() => history.push("/new-process")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form, relocatableStudents, studentOptions } =
        state.relocationMultiple;
    const activeSemesterForRelocation =
        state.semesters.activeSemesterForRelocation;
    const emailTemplate = state.emailTemplates.data["hydra:member"]
        ? state.emailTemplates.data["hydra:member"]?.find(
              (element) => element["key"] === "relocation_started"
          )
        : {};
    return {
        form,
        relocatableStudents,
        studentOptions,
        emailTemplate,
        activeSemesterForRelocation,
    };
}

const actionCreators = {
    changeForm: relocationMultipleActions.changeForm,
    defaultForm: relocationMultipleActions.defaultForm,
    changeEditor: relocationMultipleActions.changeEditor,
    getRelocatableStudents: relocationMultipleActions.getRelocatableStudents,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    filterEmailTemplate: () =>
        emailTemplatesActions.filter({ key: "relocation_started" }),
    addNotification: notificationActions.addNotification,
    startRelocationMultiple: relocationMultipleActions.startRelocationMultiple,
    getActiveSemesterForRelocation:
        semestersActions.getActiveSemesterForRelocation,
};

export default connect(mapState, actionCreators)(RelocationMultiple);
