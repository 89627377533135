import { templateChangeForm, templateDefaultForm } from "../../..";
import terminationsService from "../../../../services/processes/termination";
import { types } from "./types";
import notificationTypes from "../../../notification/types";

const changeEditor = (content) => (dispatch) =>
    dispatch({ type: types.CHANGE_EDITOR, content });

function start(form) {
    const students = form.students?.map((student) => {
        return parseInt(student.split("/")?.pop());
    });

    const formToSend = {
        students: students,
        emailSubject: form.emailSubject,
        emailContent: form.emailContent,
    };

    return (dispatch) => {
        dispatch({ type: types.START_REQUEST, formToSend });
        return terminationsService.startMoveOut(formToSend).then(
            (data) => {
                dispatch({ type: types.START_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés" },
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel küldés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

function getStudentsForMoveOut(form) {
    return (dispatch) => {
        dispatch({ type: types.GET_STUDENTS_REQUEST });
        return terminationsService.getStudentsForMoveOut(form).then(
            (data) => {
                dispatch({ type: types.GET_STUDENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_STUDENTS_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel a hallgatók lekérdezése közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

function getData(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_DATA_REQUEST, id });

        return terminationsService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_DATA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DATA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    start,
    getStudentsForMoveOut,
    getData,
};

export default actions;
