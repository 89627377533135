import { PERMISSIONS } from "./AppConst";
import { hasRole } from "./User";

export const grantPermission = (requestedRoles) => {
    let result = false;

    if (hasRole(PERMISSIONS.SYSTEM_ADMIN)) return true;

    requestedRoles.map(requestedRole => {
        if (hasRole(requestedRole)) result = true;
    })

    return result;
};

export const grantPermissionForUser = (user, requestedRoles) => {
    let result = false;

    if (!user) {
        return false;
    }

    if (user?.roles?.includes(PERMISSIONS.SYSTEM_ADMIN)) return true;

    requestedRoles.map(requestedRole => {
        if (user?.roles?.includes(requestedRole)) result = true;
    })

    return result;
};