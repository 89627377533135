import { connect } from "react-redux";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import loadingActions from "../../../../store/loading/actions";
import relocationFinancialControlActions from "../../../../store/processes/relocation/financialControl/actions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";
import DatePicker from "../../../Common/DatePicker";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import colors from "../../../../styles/colors";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import { history } from "../../../../store/index";
import axios from "axios";
import Form from "../../../Common/Form";
import Dialog from "../../../Common/Dialog";
import CkEditor from "../../../Common/CkEditor";
import { roomCleaningOptions } from "./OperatorRoomTakeOver";
import { roomKeyOptions } from "./ApproveRoomTakeOver";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        }
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 0px",
        fontWeight: "bold"
    },
    reminder: {
        backgroundColor: colors.reminder,
        '&:hover': {
            backgroundColor: colors.reminderHover,
        }
    },
    title: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue
    },
    tableCell: {
        fontSize: "12px",
        color: "#0000008A",
        fontWeight: "600",
        whiteSpace: "nowrap",
    },
    headerCell: {
        fontSize: "12px",
        color: colors.primaryBlue,
        fontWeight: "1000",
        whiteSpace: "nowrap",
    },
    container: {
        background: "rgba(0,0,0,0.1)"
    },
    titleText: {
        fontSize: "1.5rem",
        marginBottom: "1rem"
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify"
    },
}))

function ApproveUnpaidDebt(props) {
    const { form, reminderForm, emailTemplate } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Pénzügyi ellenőrzés",
        breadcrumbs: {
            "process": "Folyamatok",
            "relocation": "Átköltözés",
            "financial-control": "Pénzügyi ellenőrzés",
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.get(id),
            props.filterEmailTemplate(),
        ])
            .finally(() => props.loadingOff());
    }, [])

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm
    }

    const reminderFormProps = {
        form: reminderForm,
        name: "reminderForm",
        changeform: props.changeForm
    }

    const handleApprove = () => {
        props.loadingOn();
        props.approveUnpaidDebt(form, id)
            .then(() => {
                history.push("/");
            })
            .finally(() => props.loadingOff())
    };

    const handleSendNotification = () => {
        props.loadingOn();
        props.sendUnpaidDebtNotification(id, {
            emailSubject: reminderForm.emailSubject,
            emailContent: reminderForm.emailContent
        })
            .finally(() => props.get(id))
            .finally(() => props.loadingOff())
    };

    return (
        <FormPageTemplate
            header={header}
            form={formProps}
        >
            <TextField
                name="@type"
                label="Típus"
                value={form["@type"] === "Relocation" ? "Átköltözés" : "Ismeretlen"} //FIXME: hardcoded
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <SemesterAllocationInfo
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={form?.moveOutDate}
                format={{ xs: 12 }}
                disabled //FIXME: ezt elszűrkíteni, ha disabled
            />
            <Typography format={{ xs: 12 }} className={classes.title}>
                Leltár
            </Typography>
            <Table format={{ xs: 12 }}>
                <TableHead>
                    <TableCell className={classes.headerCell}>Név</TableCell>
                    <TableCell className={classes.headerCell}>Ellenőrzés során</TableCell>
                    <TableCell className={classes.headerCell}>Leltár szerint</TableCell>
                </TableHead>

                <TableBody>
                    {form?.oldStudentAllocation?.room?.items?.map((item) => {
                        return (
                            <TableRow>
                                <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                <TableCell className={classes.tableCell}>{form?.handedOverItems?.find(x => x.item?.id === item.id)?.amount || 0}</TableCell>
                                <TableCell className={classes.tableCell}>{item.amount}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <TextField
                name="roomCleaningStatus"
                value={roomCleaningOptions.find(x => x.value === form["roomCleaningStatus"])?.label || roomCleaningOptions[0].label}
                format={{ xs: 12 }}
                label="Szobatakarítás"
                disabled
            />
            <TextField
                name="roomKeyStatus"
                value={roomKeyOptions.find(x => x.value === form["roomKeyStatus"])?.label || roomKeyOptions[0].label}
                format={{ xs: 12 }}
                label="Szobakulcs"
                disabled
            />
            <TextField
                label="Pénzügyes megjegyzése"
                name="financialComment"
                value={form["financialComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                disabled
            />
            <TextField
                label="Üzemeltető megjegyzése"
                name="operatorComment"
                value={form["operatorComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                disabled
            />
            <Typography
                className={classes.acceptanceText}
                format={{ xs: 12 }}
            >
                A pénzügyi adatokat ellenőriztem, az MCC felé tartozása nincsen.
            </Typography>
            <SubmitButton
                className={classes.submit}
                onClick={handleApprove}
            >
                Jóváhagyás
            </SubmitButton>
            <Dialog
                opener={
                    <SubmitButton
                        className={classes.reminder}
                    >
                        Emlékeztető
                    </SubmitButton>
                }
                title="Emlékeztető"
                cancelText="Mégsem"
                action={
                    <SubmitButton
                        onClick={handleSendNotification}
                    >
                        Küldés
                    </SubmitButton>
                }
            >
                <Form
                    {...reminderFormProps}
                    childComponent="true"
                >
                    <TextField
                        label="Tárgy"
                        name="emailSubject"
                        value={reminderForm.emailSubject || ""}
                        format={{ xs: 12 }}
                        rules={{ length: 64 }}
                    />
                    <CkEditor
                        type="editor"
                        name="emailContent"
                        data={reminderForm.emailContent || ""}
                        format={{ xs: 12 }}
                        onChange={(_, editor) => props.changeEditor(editor.getData())}
                    />
                    <SubmitButton
                        name="insert"
                        type="button"
                        onClick={() => props.changeForm({
                            ...reminderForm,
                            emailSubject: emailTemplate.subject,
                            emailContent: emailTemplate.content
                        }, "reminderForm")
                        }
                        format={{ xs: 12 }}
                        className={classes.insert}
                    >
                        E-mail sablon beillesztése
                    </SubmitButton>
                </Form>
            </Dialog>

            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    )
}

function mapState(state) {
    const { form, reminderForm } = state.relocationFinancialControl;
    const emailTemplate = state.emailTemplates.data["hydra:member"] ? state.emailTemplates.data["hydra:member"]?.find(element => element["key"] === 'relocation_unpaid_debt') : {};
    return { form, reminderForm, emailTemplate };
}

const actionCreators = {
    changeForm: relocationFinancialControlActions.changeForm,
    defaultForm: relocationFinancialControlActions.defaultForm,
    approveUnpaidDebt: relocationFinancialControlActions.approveUnpaidDebt,
    sendUnpaidDebtNotification: relocationFinancialControlActions.sendUnpaidDebtNotification,
    get: relocationFinancialControlActions.get,
    changeEditor: relocationFinancialControlActions.changeEditor,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    filterEmailTemplate: () => emailTemplatesActions.filter({ key: "relocation_unpaid_debt" })
}

export default connect(mapState, actionCreators)(ApproveUnpaidDebt);