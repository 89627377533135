import getCurrentPage from "../../../utils/getCurrentPage";
import { types } from "./types";

const initialState = {
    ongoingStorageKey: 'main.ongoing',
    closedStorageKey: 'main.closed',
    currentTab: 0,
    ongoingData: [],
    closedData: [],
    ongoing: {},
    closed: {},
    tableInfoOngoing: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metaData: {}
    },
    tableInfoClosed: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metaData: {}
    },
}

export function main(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.CHANGE_CURRENT_TAB:
            return {
                ...state,
                ...initialState,
                currentTab: action.data,
            }
        case types.DEFAULT_FORM:
            return {
                [action.name]: initialState[action.name],
                ...state,
                ...initialState,
                currentTab: state.currentTab
            }
        case types.SELECT_LAYOUT:
            return {
                ...state,
                layout: action.layout
            }
        case types.FETCH_SET_ONGOING_INFO:
            return {
                ...state,
                tableInfoOngoing: {
                    ...state.tableInfoOngoing,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_SET_CLOSED_INFO:
            return {
                ...state,
                tableInfoClosed: {
                    ...state.tableInfoClosed,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_ONGOING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_ONGOING_SUCCESS:
            return {
                ...state,
                loading: false,
                tableInfoOngoing: {
                    ...state.tableInfoOngoing,
                    metadata: {
                        'allCount': action?.data?.data['hydra:totalItems'],
                        'filteredCount': action?.data?.data['hydra:totalItems'],
                        'lastPage': action?.data?.data['hydra:totalItems'] ? Math.ceil(action?.data?.data['hydra:totalItems'] / state.tableInfoOngoing.itemsPerPage) : 1,
                        'page': getCurrentPage(action?.data?.data['hydra:view']),
                        'itemsPerPage': state.tableInfoOngoing.itemsPerPage,
                    }
                },
                ongoingData: action.data.data,
            };
        case types.FETCH_ONGOING_FAILURE:
            return {
                ...state,
                loading: false,
                ongoingData: [],
                error: action.error,
            };
        case types.FETCH_CLOSED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_CLOSED_SUCCESS:
            return {
                ...state,
                loading: false,
                tableInfoClosed: {
                    ...state.tableInfoClosed,
                    metadata: {
                        'allCount': action?.data?.data['hydra:totalItems'],
                        'filteredCount': action?.data?.data['hydra:totalItems'],
                        'lastPage': action?.data?.data['hydra:totalItems'] ? Math.ceil(action?.data?.data['hydra:totalItems'] / state.tableInfoClosed.itemsPerPage) : 1,
                        'page': getCurrentPage(action?.data?.data['hydra:view']),
                        'itemsPerPage': state.tableInfoClosed.itemsPerPage,
                    }
                },
                closedData: action.data.data,
            };
        case types.FETCH_CLOSED_FAILURE:
            return {
                ...state,
                loading: false,
                closedData: [],
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}