import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';

function filter(form) {
    const mapping = {
        createdAtFrom: 'createdAt[after]',
        createdAtTo: 'createdAt[before]',
        user: 'realActor.id'
    }
    const searchForm = new SearchForm(form, mapping);
    
    return API.get('/system-logs', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getTypesAndEvents() {
    return API.get("/system-logs/types-and-events")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const emailLogService = {
    filter,
    getTypesAndEvents
};

export default emailLogService;