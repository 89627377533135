import React, { Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SelectBasic from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import colors from "../../styles/colors";
import "../../styles/Select.scss";
import FormikSelect from "./FormikSelect.js";
import Autocomplete from "./Autocomplete";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        minWidth: 120,
    },
    labelFocusedRoot: {
        "& label.MuiFormLabel-root.Mui-focused": {
            color: colors.primaryBlue,
        },
    },
}));

const useOutlinedInputStyles = makeStyles(() => ({
    root: {
        "& $notchedOutline": {
            borderColor: colors.primaryBlue,
        },
        "&:hover $notchedOutline": {
            borderColor: colors.primaryBlue,
        },
        "&$focused $notchedOutline": {
            borderColor: colors.primaryBlue,
        },
    },
    focused: {},
    notchedOutline: {},
}));

export default function Select(props) {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const { functionToChild, formToChild, formNameToChild } = props;
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel?.current?.offsetWidth);
    }, []);

    const labelId = `label-${props?.name || ''}-${Math.random()}`;

    if (props.isFormikForm !== undefined && props.isFormikForm === true) {
        return <FormikSelect {...props} />
    }

    const handleChange = e => {
        if (e.target.name.includes(".")) {
            const innerForm = e.target.name.split(".")[0];
            const name = e.target.name.split(".")[1];

            functionToChild({
                ...formToChild,
                [innerForm]: {
                    ...formToChild[innerForm],
                    [name]: e.target.value
                }
            }, formNameToChild)

        } else {
            functionToChild({ ...formToChild, [e.target.name]: e.target.value }, formNameToChild)
        }
    }

    if (props.loading) {
        return <Skeleton height={40} variant="rect" />
    }

    if (props.multiple) {
        return (
            <Autocomplete
                options={props.optionList}
                label={props.selectLabel}
                {...props}
            />
        );
    }

    return (
        <FormControl
            margin={props.margin || "dense"}
            fullWidth
            variant="outlined"
            disabled={props.disabled}
            className={classes.formControl}
            error={props.error || false}
            classes={{ root: classes.labelFocusedRoot }}
            size="small"
        >
            <InputLabel
                id={labelId}
                ref={inputLabel}
                htmlFor={props.labelId}
                required={props.required}
            >
                {props.selectLabel}
            </InputLabel>
            <SelectBasic
                labelId={labelId}
                required={props.required}
                shrink={props.required ? "1" : "0"}
                onChange={handleChange}
                input={
                    <OutlinedInput
                        label={props.selectLabel}
                        required={props.required}
                        shrink={props.required ? "1" : "0"}
                        value={props.value}
                        notched={props.shrink}
                        defaultValue={props.defaultValue}
                        name={props.name}
                        id={props.id}
                        margin="dense"
                        size="small"
                        classes={outlinedInputClasses}
                        labelWidth={labelWidth}
                    />
                }
                label={props.selectLabel}
                multiple={props.multiple}
            >
                {
                    Children.toArray(
                        props.optionList.map(item => (
                            <MenuItem value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))
                    )
                }
            </SelectBasic>
            {props.error ? <FormHelperText> {props.helperText} </FormHelperText> : ""}
        </FormControl>
    );
}
