import { connect } from 'react-redux';
import ListPageTemplate from '../../Common/ListPageTemplate';
import OperationButton from '../../Common/OperationButton';
import TextField from '../../Common/TextField';
import studentsActions from '../../../store/masterData/students/actions';
import campusesActions from '../../../store/masterData/campuses/actions';
import loadingActions from '../../../store/loading/actions';
import SemesterStatusCellRenderer from '../../../utils/cellRenderer/SemesterStatusCellRenderer';
import MemberShipStatusCellRenderer from '../../../utils/cellRenderer/MemberShipStatusCellRenderer';
import GenderCellRenderer from '../../../utils/cellRenderer/GenderCellRenderer';
import trainingLevelsActions from '../../../store/masterData/trainingLevels/actions';
import trainingProgramsActions from '../../../store/masterData/trainingPrograms/actions';
import collegeYearsActions from '../../../store/masterData/collegeYears/actions';
import { useEffect } from 'react';
import Select from '../../Common/Select';
import { options } from '../../../utils/AppConst';
import axios from 'axios';
import { makeOptionsFromID, makeOptionsFromIDWithArchive, makeTrainingProgramOptionsFromIDWithArchive } from '../../../utils/listOptions/makeOptionsFromID';
import notificationActions from '../../../store/notification/actions';
import { isSystemAdmin } from '../../../utils/User';
import API from "../../../utils/API";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import { setInfo } from "../../../utils/FilterHelper";
import { YesNoCellRenderer } from '../../../utils/cellRenderer/YesNoCellRenderer';
import { renderArchiveOption } from './options';

function Students(props) {
    const { filter, data, filterStorageKey, trainingLevels, trainingPrograms, collegeYears, campuses, tableInfo } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.fetchCampuses(),
            props.fetchTrainingLevels(),
            props.fetchTrainingPrograms(),
            props.fetchCollegeYears(),
            setInfo({ ...props, search: props.search }, "filter", storageFilter),
        ]).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Hallgatók",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "students": "Hallgatók"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter)
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            setInfo(props, "filter", {});
        },

        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            { headerName: "E-mail cím", field: "user.email" },
            { headerName: "Vezetéknév", field: "user.lastName", minWidth: 170 },
            { headerName: "Keresztnév", field: "user.firstName", minWidth: 170 },
            { headerName: "Nem", field: "gender", cellRenderer: "genderCellRenderer" },
            { headerName: "Campus", field: "user.campus.name" },
            { headerName: "Képzési szint", field: "currentTrainingLevel.name" },
            { headerName: "Képzési program", field: "currentTrainingProgram.name" },
            { headerName: "Évfolyam", field: "currentCollegeYear.name", flex: 0.5 },
            { headerName: "Szemeszter státusz", field: "semesterStatus", cellRenderer: "semesterStatusCellRenderer" },
            {
                headerName: "Aktuális státusz",
                field: "currentMembershipStatus",
                cellRenderer: "memberShipStatusCellRenderer"
            },
            { headerName: "Kiiratkozik-e", field: "terminating", cellRenderer: "yesNoCellRenderer" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                // The column autosizing does not work for rendered buttons for some reason.
                minWidth: 130
            },
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            semesterStatusCellRenderer: SemesterStatusCellRenderer,
            memberShipStatusCellRenderer: MemberShipStatusCellRenderer,
            genderCellRenderer: GenderCellRenderer,
            yesNoCellRenderer: YesNoCellRenderer
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false
    }

    function BtnCellRenderer(params) {
        const expiration = params.data.user?.passwordResetTokenExpiration;
        const tokenExpired = expiration && new Date(expiration) < new Date();
        return (<>
            {isSystemAdmin() && tokenExpired &&
                <OperationButton
                    type="mail"
                    confirmation={`Biztosan újraküldi a "${params.data.user.lastName} ${params.data.user.firstName}" hallgatónak a regisztrációs linket?`}
                    submittext="Igen"
                    handleClick={() => handlePasswordTokenRequest(params.data.user.email)}
                    to={'#'}
                />
            }

            <OperationButton
                type="visibility"
                to={"/students/profile/" + params.data.id}
            />
        </>
        );
    }

    function handlePasswordTokenRequest(email) {
        props.loadingOn();
        API.post("/reset-password", { email })
            .then(() => props.addNotification("success", "Sikeres küldés"))
            .then(() => props.search(filter))
            .catch(() => props.addNotification("error", "Hiba lépett fel a küldés közben"))
            .finally(() => props.loadingOff());
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="E-mail cím"
                name="user.email"
                value={filter.user?.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Vezetéknév"
                name="user.lastName"
                value={filter.user?.lastName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Keresztnév"
                name="user.firstName"
                value={filter.user?.firstName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Nem"
                name="gender"
                value={filter.gender || []}
                optionList={options.genderOptions}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Campus"
                name="campus"
                value={filter.campus || []}
                optionList={makeOptionsFromID(campuses) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Képzési szint"
                name="currentTrainingLevel"
                value={filter.currentTrainingLevel || []}
                optionList={makeOptionsFromIDWithArchive(trainingLevels) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Képzési program"
                name="currentTrainingProgram"
                value={filter.currentTrainingProgram || []}
                optionList={makeTrainingProgramOptionsFromIDWithArchive(trainingPrograms) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Évfolyam"
                name="currentCollegeYear"
                value={filter.currentCollegeYear || []}
                optionList={makeOptionsFromIDWithArchive(collegeYears) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Szemeszter státusz"
                name="semesterStatus"
                value={filter.semesterStatus || []}
                optionList={options.semesterStatusOptions}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Aktuális státusz"
                name="currentMembershipStatus"
                value={filter.currentMembershipStatus || []}
                optionList={options.statusOptions}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Kiiratkozik"
                name="terminating"
                value={filter.terminating || []}
                optionList={options.yesNoOptions}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey, tableInfo } = state.students;
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const campuses = state.campuses.data;
    return { filter, data, filterStorageKey, trainingLevels, trainingPrograms, collegeYears, campuses, tableInfo };
}

const actionCreators = {
    changeForm: studentsActions.changeForm,
    defaultForm: studentsActions.defaultForm,
    setInfo: studentsActions.setInfo,
    search: studentsActions.filter,
    fetchCampuses: campusesActions.filter,
    fetchTrainingLevels: trainingLevelsActions.filter,
    fetchTrainingPrograms: trainingProgramsActions.fetch,
    fetchCollegeYears: collegeYearsActions.fetch,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Students);