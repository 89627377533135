import { makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "../../../../store/index";
import loadingActions from "../../../../store/loading/actions";
import operatorRoomTakeOverActions from "../../../../store/processes/relocation/operatorRoomTakeOver/actions";
import colors from "../../../../styles/colors";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import CancelButton from "../../../Common/CancelButton";
import DatePicker from "../../../Common/DatePicker";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";
import ApproveRoomTakeOverDialog from "../../Utils/ApproveRoomTakeOverDialog";
import RoomInventoryTable from "../../Utils/RoomInventoryTable";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    title: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue,
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 4px",
        fontWeight: "bold",
    },
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    titleText: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

/**
 * Warning: ezeket használja a operatorRoomTakeOverActions is form küldésnél
 * Ha nincs megadva érték veszi a [0] elem value property-jét
 */
export const roomCleaningOptions = [
    { value: "clean", label: "Nem szükséges" },
    { value: "need_cleaning", label: "Takarítási pótdíj" },
];

export const roomKeyOptions = [
    { value: "ok", label: "Rendben" },
    { value: "damaged", label: "Sérült" },
    { value: "missing", label: "Hiányzik" },
];

function OperatorRoomTakeOver(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Üzemeltetési szobaátvétel",
        breadcrumbs: {
            process: "Folyamatok",
            relocation: "Átköltözés",
            "operator-room-take-over": "Üzemeltetési szobaátvétel",
        },
    };

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => props.loadingOff());
    }, []);

    const formProps = {
        form: form,
        changeform: props.changeForm,
    };

    const handleApprove = () => {
        props.loadingOn();
        props
            .operatorTakeOverRoom(form, id, "/")
            .finally(() => props.loadingOff());
    };

    return (
        <FormPageTemplate header={header} form={formProps}>
            <TextField
                name="@type"
                label="Típus"
                value={
                    form["@type"] === "Relocation" ? "Átköltözés" : "Ismeretlen"
                } //FIXME: hardcoded
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <SemesterAllocationInfo
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={form?.moveOutDate}
                format={{ xs: 12 }}
                disabled //FIXME: ezt elszűrkíteni, ha disabled
            />
            <Typography format={{ xs: 12 }} className={classes.title}>
                Leltár
            </Typography>
            <RoomInventoryTable
                room={form?.oldStudentAllocation?.room}
                form={form}
                changeForm={props.changeForm}
                format={{ xs: 12 }}
            />
            <Select
                name="roomCleaningStatus"
                value={
                    form["roomCleaningStatus"] || roomCleaningOptions[0].value
                }
                optionList={roomCleaningOptions}
                format={{ xs: 12 }}
                selectLabel="Szobatakarítás"
            />
            <Select
                name="roomKeyStatus"
                value={form["roomKeyStatus"] || roomKeyOptions[0].value}
                optionList={roomKeyOptions}
                format={{ xs: 12 }}
                selectLabel="Szobakulcs"
            />
            <TextField
                label="Megjegyzés"
                name="operatorComment"
                value={form["operatorComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                rules={{ length: 255 }}
            />
            <ApproveRoomTakeOverDialog
                classes={classes}
                handleApprove={handleApprove}
                form={form}
                roomCleaningOptions={roomCleaningOptions}
                roomKeyOptions={roomKeyOptions}
            />
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.relocationOperatorRoomTakeOver;
    return { form };
}

const actionCreators = {
    defaultForm: operatorRoomTakeOverActions.defaultForm,
    get: operatorRoomTakeOverActions.getForm,
    operatorTakeOverRoom: operatorRoomTakeOverActions.operatorTakeOverRoom,
    changeForm: operatorRoomTakeOverActions.changeForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(OperatorRoomTakeOver);
