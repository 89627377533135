export const types = {
    CHANGE_FORM: "@@emailTemplates/CHANGE_FORM",
    DEFAULT_FORM: "@@emailTemplates/DEFAULT_FORM",
    CHANGE_EDITOR: "@@emailTemplates/CHANGE_EDITOR",

    FETCH_REQUEST: "@@emailTemplates/FETCH_REQUEST",
    FETCH_SUCCESS: "@@emailTemplates/FETCH_SUCCESS",
    FETCH_FAILURE: "@@emailTemplates/FETCH_FAILURE",

    CREATE_REQUEST: "@@emailTemplates/CREATE_REQUEST",
    CREATE_SUCCESS: "@@emailTemplates/CREATE_SUCCESS",
    CREATE_FAILURE: "@@emailTemplates/CREATE_FAILURE",

    GET_REQUEST: "@@emailTemplates/GET_REQUEST",
    GET_SUCCESS: "@@emailTemplates/GET_SUCCESS",
    GET_FAILURE: "@@emailTemplates/GET_FAILURE",

    UPDATE_REQUEST: "@@emailTemplates/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@emailTemplates/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@emailTemplates/UPDATE_FAILURE",

    DELETE_REQUEST: "@@emailTemplates/DELETE_REQUEST",
    DELETE_SUCCESS: "@@emailTemplates/DELETE_SUCCESS",
    DELETE_FAILURE: "@@emailTemplates/DELETE_FAILURE",
}