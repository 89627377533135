import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { history } from "../../../../store";
import terminationStudentDeclarationActions from "../../../../store/processes/termination/studentDeclaration/actions";
import { bankAccountFormatter } from "../../../../utils/AppConst";
import CancelButton from "../../../Common/CancelButton";
import DatePicker from "../../../Common/DatePicker";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import Loading from "../../../Common/Loading";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import MoveOutDeclaration from "../../Utils/MoveOutDeclaration";
import DepositRefundForm from "./DepositRefundForm";
import DonationConsentForm from "./DonationConsentForm";
import ReadOnlyForm from "./ReadOnlyForm";
import {
    donatingDepositOptions,
    reasonsOfTerminationOptions,
    useStyles,
} from "../utils";

const header = (isMoveOutProcess) => {
    return {
        title: "Hallgatói nyilatkozat",
        breadcrumbs: {
            processes: "Folyamatok",
            termination: isMoveOutProcess ? "Kiköltözés" : "Kiiratkozás",
            "student-declaration": "Hallgatói nyilatkozat",
        },
    };
};

function StudentDeclaration(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    // FIXME: Ezt nem használjuk:
    const validationSchema = yup.object().shape({
        moveOutDate_: yup.date().required("Kötelező megadni"),
    });

    useEffect(() => {
        setLoading(true);
        props.get(id).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />;
    }

    const bankAccountNumberFormatter = (onChange) => (event) => {
        bankAccountFormatter(onChange, event);
    };

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultForm: props.defaultForm,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        validationschema: validationSchema,
        onsubmit: () => props.fillStudentDeclaration({ ...form }, id, "/"),
        isformikform: true,
    };

    return (
        <FormPageTemplate
            header={header(form.isMoveOutProcess)}
            form={formProps}
        >
            <MoveOutDeclaration />
            <ReadOnlyForm form={form} formProps={formProps} />
            <Select
                selectLabel="Kiköltözés oka"
                name="reasonOfTermination"
                value={form["reasonOfTermination"] || ""}
                optionList={reasonsOfTerminationOptions}
                error={form.errors?.reasonOfTermination}
                helperText={form.errors?.reasonOfTermination}
                format={{ xs: 12 }}
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="terminationDate"
                value={
                    form?.terminationDate ||
                    new Date().toISOString().slice(0, 10)
                }
                error={form.errors?.terminationDate}
                helperText={form.errors?.terminationDate}
                format={{ xs: 12 }}
            />
            <Select
                selectLabel="Adományozási megállapodás"
                name="donatingDeposit"
                value={form["donatingDeposit"] || "true"}
                optionList={donatingDepositOptions}
                format={{ xs: 12 }}
            />
            {form["donatingDeposit"] === "false" ? (
                <DepositRefundForm
                    form={form}
                    classes={classes}
                    bankAccountNumberFormatter={bankAccountNumberFormatter}
                    format={{ xs: 12 }}
                    {...props}
                />
            ) : (
                <DonationConsentForm classes={classes} {...props} />
            )}
            <Typography className={classes.acceptanceText}>
                Kijelentem, hogy a fenti időpontban a szobát kitakarítva, a
                szobában található berendezési tárgyakkal együtt hiánytalanul
                átadom, valamint az átvett szobakulcsokat leadom. Tudomásul
                veszem, hogy a Collegiumban hagyott tárgyaimért, a Collegium
                felelősséget nem vállal.
            </Typography>
            <SubmitButton className={classes.submit} type="submit">
                Mentés
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.terminationStudentDeclaration;
    return { form };
}

const actionCreators = {
    changeForm: terminationStudentDeclarationActions.changeForm,
    defaulFrom: terminationStudentDeclarationActions.defaultForm,
    get: terminationStudentDeclarationActions.getForm,
    fillStudentDeclaration:
        terminationStudentDeclarationActions.fillStudentDeclaration,
};

export default connect(mapState, actionCreators)(StudentDeclaration);
