import { useEffect } from "react";
import Loading from "../../../Common/Loading";
import loadingActions from "../../../../store/loading/actions";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import Form from "../../../Common/Form";
import { connect } from "react-redux";
import studentAllocationPreferenceFormActions from "../../../../store/processes/studentAllocationPreferenceForm/actions";
import { Grid } from "@material-ui/core";
import Accordion from "../../../Common/Accordion";

function StudentAllocationPreferenceDetails(props) {
    const { form, loading } = props;
    const { id } = props.match?.params;

    useEffect(() => {
        if (id) {
            props.loadingOn();
            props.getPreference(id)
            .then(() => props.loadingOff())
            .catch(() => props.loadingOff());
        }
    }, []);

    if (loading) return <Loading />

    return (
        <Grid item>
            <Accordion title="Űrlap" expanded>
                <Form>
                    <Title title="Típus" format={{xs: 12}} />
                    <TextField
                        value={"Szobatárspreferencia"}
                        format={{xs: 12}}
                        disabled
                    />
                    <Title  title="Név" format={{xs: 12}} />
                    <TextField
                        label="Vezetéknév"
                        name="student.user.lastName"
                        value={form.student?.user?.lastName || ""}
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <TextField
                        label="Keresztnév"
                        name="student.user.firstName"
                        value={form.student?.user?.firstName || ""}
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <Title title="Előző szoba" format={{xs: 12}} />
                    <TextField 
                        name="campus"
                        value={form.campus?.name || ""}
                        label="Campus"
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <TextField 
                        name="building"
                        value={form?.room?.floor?.building?.name || ""}
                        label="Épület"
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <TextField 
                        name="floor"
                        value={form?.room?.floor?.name || ""}
                        label="Szint"
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <TextField 
                        name="room"
                        value={form?.room?.name || ""}
                        label="Szoba"
                        format={{xs: 12, md: 6}}
                        disabled
                    />
                    <Title title="Szobatárs" format={{xs: 12}} />
                    <TextField
                        name="roomMate"
                        value={form.roomMates?.[0]?.user?.fullName || ""}
                        format={{xs: 12}}
                        multiple={false}
                        disabled
                    />
                    <Title title="Megjegyzés" format={{xs: 12}}/>
                    <TextField
                        name="comment"
                        value={form.comment || ""}
                        format={{xs: 12}}
                        disabled
                    />
                </Form>
            </Accordion>
        </Grid>
    )
}

function mapState(state) {
    const { form } = state.studentAllocationPreferenceForm;
    const loading = state.loading.loading;
    return { form, loading };
  }
  

const actionCreators = {
    getPreference: studentAllocationPreferenceFormActions.get,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(StudentAllocationPreferenceDetails)