import financialControlActions from '../../../store/processes/financialControl/actions';
import enrollmentActions from '../../../store/processes/newProcess/enrollment/actions';
import campusesActions from '../../../store/masterData/campuses/actions';
import loadingActions from '../../../store/loading/actions';
import ListPageTemplate from './../../Common/ListPageTemplate';
import OperationButton from './../../Common/OperationButton';
import {connect} from 'react-redux';
import TextField from './../../Common/TextField';
import {useEffect} from 'react';
import EnrollmentTypeCellRenderer from '../../../utils/cellRenderer/EnrollmentTypeCellRenderer';
import FullNameCellRenderer from '../../../utils/cellRenderer/FullNameCellRenderer';
import Select from '../../Common/Select';
import {ENROLLMENT_TYPES} from '../../../utils/AppConst';
import axios from 'axios';
import {makeOptionsFromID} from '../../../utils/listOptions/makeOptionsFromID';

function BtnCellRenderer(params) {
    return (
        <OperationButton
            type="info"
            to={"/financial-control/edit/" + params.data.id}
        />
    );
}

function FinancialControl(props) {
    const {filter, enrollments, campuses} = props;

    const header = {
        title: "Pénzügyi ellenőrzés",
        breadcrumbs: {
            "processes": "Folyamatok",
            "financialControl": "Pénzügyi ellenőrzés"
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.search(filter),
            props.filterCampuses()
        ]).then(() => props.loadingOff());
        return props.defaultForm;
    }, []);

    const filterForm = {
        submitaction: props.search,
        defaultform: props.defaultForm,
        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            {headerName: "Sorszám", field: "displayId"},
            {headerName: "Típus", field: "@type", cellRenderer: "enrollmentTypeCellRenderer"},
            {headerName: "Név", field: "fullName", minWidth: 280, cellRenderer: "fullNameCellRenderer"},
            {headerName: "E-mail cím", field: "email"},
            {headerName: "OM azonosító", field: "omId"},
            {headerName: "Campus", field: "campus.name"},
            {headerName: "Műveletek", field: "operations", cellRenderer: "btnCellRenderer", sortable: false}
        ],
        rowData: enrollments['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            enrollmentTypeCellRenderer: EnrollmentTypeCellRenderer,
            fullNameCellRenderer: FullNameCellRenderer,
        }
    }

    return (
        <ListPageTemplate
            header={header}
            filter={filterForm}
            table={table}
        >
            <TextField
                label="Sorszám"
                name="displayId"
                value={filter.displayId || ""}
                format={{xs: 12, md: 4}}
            />
            <Select
                selectLabel="Típus"
                name="@type"
                value={filter['@type'] || ""}
                optionList={ENROLLMENT_TYPES}
                format={{xs: 12, md: 4}}
            />
            <TextField
                label="Név"
                name="fullName"
                value={filter.fullName || ""}
                format={{xs: 12, md: 4}}
            />
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{xs: 12, md: 4}}
            />
            <TextField
                label="OM azonosító"
                name="omId"
                value={filter.omId || ""}
                format={{xs: 12, md: 4}}
            />
            <Select
                name="campus"
                value={filter.campus || []}
                selectLabel="Campus"
                optionList={makeOptionsFromID(campuses)}
                format={{xs: 12, md: 4}}
                multiple
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const {filter} = state.financialControl;
    const enrollments = state.enrollment.data;
    const campuses = state.campuses.data;
    return {filter, enrollments, campuses};
}

const actionCreators = {
    changeForm: financialControlActions.changeForm,
    defaultForm: financialControlActions.defaultForm,
    search: enrollmentActions.filter,
    filterCampuses: campusesActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(FinancialControl);