import { types } from "./types";

const initialState = {
    form: {
        currentMembershipStatus: ["internal"],
    },
    data: [],
    students: [],
};

export function moveOut(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
                form: {
                    ...initialState.form,
                    campus: state.form.campus
                }
            };
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    //content: action.content,
                    emailContent: action.content,
                },
            };
        case types.START_REQUEST:
            return {
                ...state,
            };
        case types.START_SUCCESS:
            return {
                ...state,
            };
        case types.START_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_DATA_REQUEST:
            return {
                ...state,
            };
        case types.GET_DATA_SUCCESS:
            return {
                ...state,
                getData: action.data.data,
            };
        case types.GET_DATA_FAILURE:
            return {
                ...state,
                getData: {},
                error: action.error,
            };
        case types.GET_STUDENTS_REQUEST:
            return {
                ...state,
            };
        case types.GET_STUDENTS_SUCCESS:
            return {
                ...state,
                students: action.data?.data?.["hydra:member"],
            };
        case types.GET_STUDENTS_FAILURE:
            return {
                ...state,
                data: [],
                error: action.error,
            };
        default:
            return {
                ...state,
            };
    }
}
