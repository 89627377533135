import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";
import loadingActions from "../../../../store/loading/actions";
import collegeYearsActions from "../../../../store/masterData/collegeYears/actions";
import trainingLevelsActions from "../../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../../store/masterData/trainingPrograms/actions";
import { initialState } from "../../../../store/masterData/trainingPrograms/reducer";
import notificationActions from "../../../../store/notification/actions";
import { ARCHIVE_OPTIONS, YEAR_OPTIONS } from "../../../../utils/AppConst";
import ActiveCellRenderer from "../../../../utils/cellRenderer/ActiveCellRenderer";
import AddressCellRenderer from "../../../../utils/cellRenderer/AddressCellRenderer";
import { ArchiveCellRenderer } from "../../../../utils/cellRenderer/ArchiveCellRenderer";
import CollegeYearsCellRenderer from "../../../../utils/cellRenderer/CollegeYearsCellRenderer";
import { filterStorageGet, filterStorageSave } from "../../../../utils/FilterStorage";
import { makeOptionsFromHydra } from "../../../../utils/listOptions/makeOptionsFromHydra";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import OperationButton from "../../../Common/OperationButton";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";

function TrainingPrograms(props) {
    const { filter, data, trainingLevel, collegeYears, filterStorageKey } = props;
    const { trainingLevelId } = props.match.params;

    const searchOtherData = { trainingLevelId: trainingLevelId };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCollegeYears(),
            props.getTrainingLevel(trainingLevelId),
            props.search(storageFilter, searchOtherData)
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Képzési programok",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "trainings": "Képzések",
            "/training-levels": "Képzési szintek",
            "training-programs": "Képzési Programok"
        }
    }


    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={`/training-levels/${trainingLevelId}/training-programs/view/` + params.data.id}
                />

            </>
        );
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, initialState.filter);
            props.defaultForm();
            props.search(initialState.filter, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            { headerName: "Képzési program", field: "name" },
            { headerName: "Év", field: "duration" },
            { headerName: "Évfolyam", field: "collegeYears", cellRenderer: "collegeYearsCellRenderer" },
            { headerName: "Archiválva?", field: "archive", cellRenderer: "archiveCellRenderer", minWidth: 100 },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            addressCellRenderer: AddressCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
            archiveCellRenderer: ArchiveCellRenderer,
            collegeYearsCellRenderer: CollegeYearsCellRenderer
        },
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Képzési szint"
                name="trainingLevel.name"
                value={trainingLevel.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 4 }}
            />
            <Select
                selectLabel="Időtartam (év)"
                name="duration"
                value={filter.duration || []}
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6, lg: 4 }}
                multiple
            />
            <Select
                selectLabel="Évfolyamok"
                name="collegeYears"
                value={filter.collegeYears || []}
                optionList={makeOptionsFromHydra(collegeYears, "college_years")}
                format={{ xs: 12, md: 6, lg: 4 }}
                multiple
            />
            <Select
                selectLabel="Archiválva?"
                name="archive"
                value={filter.archive || []}
                optionList={ARCHIVE_OPTIONS}
                format={{ xs: 12, md: 6, lg: 3 }}
                multiple
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.trainingPrograms;
    const trainingLevel = state.trainingLevels.form;
    const collegeYears = state.collegeYears.data;
    return { filter, data, filterStorageKey, trainingLevel, collegeYears };
}

const actionCreators = {
    getTrainingLevel: trainingLevelsActions.get,
    getCollegeYears: collegeYearsActions.filter,
    changeForm: trainingProgramsActions.changeForm,
    defaultForm: trainingProgramsActions.defaultForm,
    search: trainingProgramsActions.filter,
    delete: trainingProgramsActions.delete,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(TrainingPrograms);