import { templateChangeForm, templateDefaultForm } from "../..";
import mainPageService from "../../../services/modules/main";
import { types } from "./types";
import loadingTypes from "../../loading/types";

const filterOngoing = (form) => {
    let formToSend = Object.assign({}, form);

    if (formToSend?.status) {
        formToSend.status = formToSend?.status.flatMap(element => {
            if (element === "financial_check_relocation") {
                return ["financial_check", "unpaid_debt"];
            }

            return element;
        });
    }

    return (dispatch) => {
        dispatch({ type: types.FETCH_ONGOING_REQUEST, formToSend });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return mainPageService.filterOngoing(formToSend).then(
            (data) => {
                dispatch({ type: types.FETCH_ONGOING_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_ONGOING_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

const filterClosed = (form) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_CLOSED_REQUEST, form });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return mainPageService.filterClosed(form).then(
            (data) => {
                dispatch({ type: types.FETCH_CLOSED_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_CLOSED_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}
const changeTab = (tabId) => (dispatch) => dispatch({ type: types.CHANGE_CURRENT_TAB, data: tabId });

function setOngoingInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_ONGOING_INFO, key, value });
    };
}

function setClosedInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_CLOSED_INFO, key, value });
    };
}

function selectLayout(layout) {
    return (dispatch) => {
        return dispatch({ type: types.SELECT_LAYOUT, layout});
    };
}

const actions = {
    filterOngoing,
    filterClosed,
    changeTab,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    selectLayout,
    setOngoingInfo,
    setClosedInfo
}

export default actions;