import { withStyles } from "@material-ui/core";
import { Build } from "@material-ui/icons";
import colors from "../../styles/colors.js";

const Repair = withStyles({
    root: {
        backgroundColor: colors.reminder,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.reminderHover,
        },
    },
})(Build);

export default Repair;
