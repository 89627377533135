import { connect } from "react-redux";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import TableActive from "./Active/table";
import TableClosed from "./Closed/table";
import { useEffect } from "react";
import axios from "axios";
import activeProcessesActions from "../../../store/processes/activeProcesses/actions";
import closedProcessesActions from "../../../store/processes/closedProcesses/actions";
import terminationActions from "../../../store/processes/newProcess/termination/actions";
import relocationActions from "../../../store/processes/newProcess/relocationSingle/actions";
import enrollmentActions from "../../../store/processes/newProcess/enrollment/actions";
import campusesActions from "../../../store/masterData/campuses/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import semestersActions from "../../../store/masterData/semesters/actions";
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageGet } from "../../../utils/FilterStorage";
import isEmpty from "../../../utils/isEmpty";

function ActiveCloseProcesses(props) {
    const {
        filterActiveData,
        activeFilterStorageKey,
        filterClosedData,
        closedFilterStorageKey,
        enrollmentsActive,
        enrollmentsDone,
        activeCloseTab,
        semesters,
        tableInfoActive,
        tableInfoDone,
    } = props;

    const header = {
        title: "Aktív folyamatok",
        breadcrumbs: {
            processes: "Folyamatok",
            processesTabs: "Folyamatok listája",
        },
    };

    const activeInfos = {
        search: props.filterActive,
        info: tableInfoActive,
        setInfo: props.setActiveInfo,
    };

    const closedInfos = {
        search: props.filterDone,
        info: tableInfoDone,
        setInfo: props.setClosedInfo,
    };

    function fetchData() {
        let activeSemesters = [];
        props.loadingOn();

        const activeStorageFilter = filterStorageGet(activeFilterStorageKey);
        const closedStorageFilter = filterStorageGet(closedFilterStorageKey);

        props.fetchSemesters().then((response) => {
            const active = response.data["hydra:member"].filter(
                (elem) => elem.active
            );

            if (active.length) {
                activeSemesters = active.map((elem) => {
                    return elem["id"];
                });
            } else if (response.data["hydra:member"].length && "id" in response.data["hydra:member"][0]) {
                activeSemesters = [response.data["hydra:member"][0]["id"] ?? null];
            }

            props.changeFormActive(
                !isEmpty(activeStorageFilter)
                    ? activeStorageFilter
                    : { semester: activeSemesters },
                "filter"
            );
            props.changeFormClosed(
                !isEmpty(closedStorageFilter)
                    ? closedStorageFilter
                    : { semester: activeSemesters },
                "filter"
            );

            axios
                .all([
                    activeCloseTab === 0
                        ? setInfo(
                              activeInfos,
                              "order", { displayId: "asc" },
                              "filter",
                              !isEmpty(activeStorageFilter)
                                  ? activeStorageFilter
                                  : { semester: activeSemesters }
                          )
                        : (setInfo(
                              closedInfos,
                              "filter",
                              !isEmpty(closedStorageFilter)
                                  ? closedStorageFilter
                                  : { semester: activeSemesters }
                          ),
                          props.fetchCampuses()),
                ])
                .then(() => props.loadingOff())
                .finally(() => props.loadingOff());
        });
    }

    function changeTab(tab) {
        if (tab !== activeCloseTab) {
            props.changeActiveCloseTab(tab);
        }
    }

    useEffect(fetchData, [activeCloseTab]);

    const handleDelete = (id) => {
        props.loadingOn();
        props
            .delete(id)
            .then(() => props.addNotification("success", "Sikeres törlés"))
            .then(() => props.filterActive(filterActiveData))
            .then(() => props.loadingOff())
            .catch(() =>
                props.addNotification("error", "Hiba lépett fel törlés közben")
            )
            .finally(() => props.loadingOff());
    };
    const handleDeleteStudentAllocationPreference = (id) => {
        props.loadingOn();
        props
            .deleteStudentAllocationPreference(id)
            .then(() => props.addNotification("success", "Sikeres törlés"))
            .then(() => props.filterActive(filterActiveData))
            .then(() => props.loadingOff())
            .catch(() =>
                props.addNotification("error", "Hiba lépett fel törlés közben")
            )
            .finally(() => props.loadingOff());
    };

    const handleDeleteRelocation = (id) => {
        props.loadingOn();
        props
        .deleteRelocation(id)
        .then(() => props.addNotification("success", "Sikeres törlés"))
        .then(() => props.filterActive(filterActiveData))
        .then(() => props.loadingOff())
        .catch(() =>
            props.addNotification("error", "Hiba lépett fel törlés közben")
        )
        .finally(() => props.loadingOff());
    }

    const handleDeleteTermination = (id) => {
        props.loadingOn();
        props
            .deleteTermination(id)
            .then(() => props.addNotification("success", "Sikeres törlés"))
            .then(() => props.filterActive(filterActiveData))
            .then(() => props.loadingOff())
            .catch(() =>
                props.addNotification("error", "Hiba lépett fel törlés közben")
            )
            .finally(() => props.loadingOff());
    };

    const tabs = [
        {
            label: "Aktív folyamatok",
            value: (
                <TableActive
                    filter={filterActiveData}
                    filterStorageKey={activeFilterStorageKey}
                    data={enrollmentsActive}
                    handleDelete={handleDelete}
                    semesters={semesters}
                    tableInfo={tableInfoActive}
                    setInfo={props.setActiveInfo}
                    search={props.filterActive}
                    handleDeleteStudentAllocationPreference={
                        handleDeleteStudentAllocationPreference
                    }
                    handleDeleteRelocation={
                        handleDeleteRelocation
                    }
                    handleDeleteTermination={
                        handleDeleteTermination
                    }
                    defaultForm={props.activeDefaultForm}
                    changeForm={props.changeFormActive}
                />
            ),
        },
        {
            label: "Lezárult folyamatok",
            value: (
                <TableClosed
                    filter={filterClosedData}
                    data={enrollmentsDone}
                    semesters={semesters}
                    tableInfo={tableInfoDone}
                    setInfo={props.setClosedInfo}
                    search={props.filterDone}
                    filterStorageKey={closedFilterStorageKey}
                    defaultForm={props.closedDefaultForm}
                    changeForm={props.changeFormClosed}
                />
            ),
        },
    ];

    return (
        <PageTemplate header={header}>
            <TabsCompleted
                tabContents={tabs}
                currentTab={activeCloseTab}
                onChange={changeTab}
            />
        </PageTemplate>
    );
}

function mapState(state) {
    const filterActiveData = state.activeProcesses.filter;
    const tableInfoActive = state.enrollment.tableInfoActive;
    const filterClosedData = state.closedProcesses.filter;
    const tableInfoDone = state.enrollment.tableInfoDone;
    const activeFilterStorageKey = state.activeProcesses.filterStorageKey;
    const closedFilterStorageKey = state.closedProcesses.filterStorageKey;
    const enrollmentsActive = state.enrollment.dataActive;
    const enrollmentsDone = state.enrollment.dataDone;
    const activeCloseTab = state.enrollment.activeCloseTab;
    const semesters = state.semesters.data;
    return {
        filterActiveData,
        tableInfoActive,
        activeFilterStorageKey,
        filterClosedData,
        tableInfoDone,
        closedFilterStorageKey,
        enrollmentsActive,
        enrollmentsDone,
        activeCloseTab,
        semesters,
    };
}

const actionCreators = {
    changeFormActive: activeProcessesActions.changeForm,
    changeFormClosed: closedProcessesActions.changeForm,
    activeDefaultForm: activeProcessesActions.defaultForm,
    closedDefaultForm: closedProcessesActions.defaultForm,
    filterActive: enrollmentActions.filterActive,
    filterDone: enrollmentActions.filterDone,
    setActiveInfo: enrollmentActions.setActiveInfo,
    setClosedInfo: enrollmentActions.setClosedInfo,
    delete: enrollmentActions.delete,
    deleteStudentAllocationPreference:
        enrollmentActions.deleteStudentAllocationPreference,
    deleteRelocation:
        relocationActions.deleteRelocation,
    deleteTermination:
        terminationActions.deleteTermination,
    changeActiveCloseTab: enrollmentActions.changeActiveCloseTab,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
    fetchCampuses: campusesActions.filter,
    fetchSemesters: () => semestersActions.filter({}),
};

export default connect(mapState, actionCreators)(ActiveCloseProcesses);
