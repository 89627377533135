export const types = {
    CHANGE_FORM: "@@move-out/CHANGE_FORM",
    DEFAULT_FORM: "@@move-out/DEFAULT_FORM",
    CHANGE_EDITOR: "@@move-out/CHANGE_EDITOR",

    START_REQUEST: "@@move-out/START_REQUEST",
    START_SUCCESS: "@@move-out/START_SUCCESS",
    START_FAILURE: "@@move-out/START_FAILURE",

    GET_DATA_REQUEST: "@@move-out/GET_DATA_REQUEST",
    GET_DATA_SUCCESS: "@@move-out/GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "@@move-out/GET_DATA_FAILURE",


    GET_STUDENTS_REQUEST: "@@move-out/GET_STUDENTS_REQUEST",
    GET_STUDENTS_SUCCESS: "@@move-out/GET_STUDENTS_SUCCESS",
    GET_STUDENTS_FAILURE: "@@move-out/GET_STUDENTS_FAILURE",
};
