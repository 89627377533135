import { withStyles } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import colors from "../../styles/colors.js";

const File = withStyles({
    root: {
        backgroundColor: colors.primaryYellow,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.primaryYellowHover,
        },
    },
})(Description);

export default File;
