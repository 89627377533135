import TooltipRenderer from "./TooltipRenderer";
import {
    PROCESS_TYPE_NAMES,
    translateEnrollmentStatus,
    translateRelocationStatus,
    translateStudentAllocationPreferenceStatus,
    translateTerminationStatus,
} from "../AppConst";

export default function EnrollmentStatusCellRenderer(params) {
    const processType = params.data.type;

    switch (processType) {
        case PROCESS_TYPE_NAMES.ENROLLMENT:
            return TooltipRenderer(translateEnrollmentStatus(params.value));
        case PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE:
            return TooltipRenderer(
                translateStudentAllocationPreferenceStatus(params.value)
            );
        case PROCESS_TYPE_NAMES.RELOCATION:
            return TooltipRenderer(translateRelocationStatus(params.value));
        case PROCESS_TYPE_NAMES.TERMINATION:
        case PROCESS_TYPE_NAMES.MOVE_OUT:
            return TooltipRenderer(translateTerminationStatus(params.value));
        default:
            return TooltipRenderer("Ismeretlen állapot");
    }
}
