import { Grid, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import enrollmentActions from '../../../../store/processes/newProcess/enrollment/actions';
import RawTitle from '../../../Common/RawTitle';
import StyledButton from '../../../Common/SubmitButton';
import SmallTable from './../../../Common/SmallTable';
import UniversityDetails from './UniversityDetails';
import { Delete, Edit } from '@material-ui/icons';
import Dialog from '../../../Common/Dialog';
import {getSemesterStatusOptionsLabelByValue, getTrainingFormLabelByValue, isKep} from '../../../../utils/AppConst';
import { getFinancingFormLabelByValue } from './options';
import { Skeleton } from '@material-ui/lab';
import instance from '../../../../utils/API';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFile } from './utils';

const columns = () => [
    {
        value: 'university',
        label: 'Egyetem',
        transformer: (value) => value?.name,
    },
    { value: 'major', label: 'Szak' },
    {
        value: 'trainingForm',
        label: 'Képzés formája',
        transformer: getTrainingFormLabelByValue
    },
    {
        value: 'financeForm',
        label: 'Finanszírozás formája',
        transformer: getFinancingFormLabelByValue
    },
    {
        value: 'semesterStatus',
        label: 'Hallgatói jogviszony',
        transformer: getSemesterStatusOptionsLabelByValue
    },
    { value: 'neptun', label: 'Neptun kód' },
    { value: 'pedigreeNumber', label: 'Törzskönyvi szám' },
    { value: 'operations', label: 'Műveletek' },
];

function Universities(props) {
    const readonly = props.readonly;
    const notitle = props.notitle;
    const [loading, setLoading] = useState(false);
    const { form } = props;
    const [universityForm, setUniversityForm] = useState(null);

    const handleDownload = (universityStudy) => {
        instance.get(`/enrollments/${form.id}/fill-student/university-study/${universityStudy.id}/download-file`, {responseType: 'blob'})
            .then((response) => {
                downloadFile(response.data, universityStudy.fileName);
            });
    }

    const universities = (form.actualUniversityStudies || []).map((university) => ({
        ...university,
        operations: (
            <>
            {!readonly && (
                <>
                    <IconButton
                        color='primary'
                        onClick={() => setUniversityForm(university)}
                    >
                        <Edit />
                    </IconButton>
                    <Dialog
                        title='Megerősítés'
                        opener={
                            <IconButton color='secondary'>
                                <Delete />
                            </IconButton>
                        }
                        action={
                            <StyledButton
                                onClick={() => {
                                    setLoading(true);
                                    instance.delete(
                                        `/enrollments/${form.id}/fill-student/university-study/${university.id}`
                                    ).then(() => {
                                        props.getActualUniversityStudies(form.id).then(() => {
                                            setUniversityForm(null);
                                            setLoading(false);
                                        })
                                    })
                                    .catch(() => {
                                        setLoading(false);
                                    })
                                }
                                }
                            >
                                Törlés
                            </StyledButton>
                        }
                    >
                        Biztosan törölni szeretnéd ezt az egyetemet?
                    </Dialog>
                </>
            )}
            { university.fileName && university.filePath && (
                <IconButton color='default' onClick={() => handleDownload(university)}>
                    <GetAppIcon />
                </IconButton>
            )}
            </>
        ),
    }));

    if (loading) {
        return <Skeleton/>
    }


    let cols = columns();
    if (isKep()) {
        cols = cols.filter(field => field.value !== "neptun" );
    } else {
        cols = cols.filter(field => field.value !== "pedigreeNumber");
    }

    return (
        <Grid item xs={12} container spacing={1} style={{marginBottom: '8px'}}>
            {!notitle && (<RawTitle title='Egyetemi tanulmányok' />)}
            {!readonly && (
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <StyledButton onClick={() => setUniversityForm({})}>
                        Egyetemi tanulmány hozzáadása
                    </StyledButton>
                </Grid>
            )}
            <Grid item xs={12}>
                <SmallTable columns={cols} rows={universities} />
            </Grid>
            <UniversityDetails
                universityForm={universityForm}
                setUniversityForm={setUniversityForm}
            />
        </Grid>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    return { form };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    getActualUniversityStudies: enrollmentActions.getActualUniversityStudies
    
};

export default connect(mapState, actionCreators)(Universities);
