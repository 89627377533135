import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { history } from "../../../../store";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import campusesActions from "../../../../store/masterData/campuses/actions";
import collegeYearsActions from "../../../../store/masterData/collegeYears/actions";
import trainingLevelsActions from "../../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../../store/masterData/trainingPrograms/actions";
import notificationActions from "../../../../store/notification/actions";
import moveOutActions from "../../../../store/processes/newProcess/moveOut/actions";
import colors from "../../../../styles/colors";
import { options, VALIDATION_MESSAGES } from "../../../../utils/AppConst";
import { makeOptionsFromID } from "../../../../utils/listOptions/makeOptionsFromID";
import { getUser } from "../../../../utils/User";
import Autocomplete from "../../../Common/Autocomplete";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import Loading from "../../../Common/Loading";
import Select from "../../../Common/Select";
import {
    makeCollegeYearOptionsMultiple,
    makeTrainingProgramOptionsMultiple,
} from "../../../MasterData/Students/options";
import CancelButton from "./../../../Common/CancelButton";
import CkEditor from "./../../../Common/CkEditor";
import SubmitButton from "./../../../Common/SubmitButton";
import TextField from "./../../../Common/TextField";
import Title from "./../../../Common/Title";

const useStyles = makeStyles(() => ({
    insert: {
        backgroundColor: colors.insert,
        "&:hover": {
            backgroundColor: colors.insertHover,
        },
    },
}));

const header = {
    title: "Kiköltözés",
    breadcrumbs: {
        processes: "Folyamatok",
        "/new-process": "Új folyamat",
        termination: "Kiköltözés",
    },
};

const validationSchema = yup.object().shape({
    trainingLevel: yup.mixed().required(VALIDATION_MESSAGES.required),
    trainingProgram: yup.mixed().required(VALIDATION_MESSAGES.required),
    collegeYear: yup.mixed().required(VALIDATION_MESSAGES.required),
    currentMembershipStatus: yup.mixed().required(VALIDATION_MESSAGES.required),
    semesterStatus: yup.mixed().required(VALIDATION_MESSAGES.required),
    students: yup.mixed().required(VALIDATION_MESSAGES.required),
    emailSubject: yup.mixed().required(VALIDATION_MESSAGES.required),
    emailContent: yup.mixed().required(VALIDATION_MESSAGES.required),
});

function MoveOut(props) {
    const {
        form,
        campuses,
        students,
        studentOptions,
        emailTemplate,
        trainingLevels,
        trainingPrograms,
        collegeYears,
    } = props;
    const [studentLoading, setStudentLoading] = useState(false);
    const [studentOptionsEmpty, setStudentOptionsEmpty] = useState(true);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const campus = getUser()?.campus;

    useEffect(() => {
        validationSchema.isValid(form).then((valid) => {
            setValid(valid);
        });
    }, [form]);

    const customValidation = (propertyPath, message) => {
        if (propertyPath.includes("students[")) {
            enqueueSnackbar(
                { message: message, variant: "error" },
                { persist: false }
            );
        } else if (propertyPath === "") {
            props.addNotification("error", message);
        }
    };

    const formProps = {
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        onsubmit: () => props.start(form),
        isformikform: true,
        customvalidation: customValidation,
    };

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                props.fetchCampuses(),
                props.fetchTrainingLevels({ archive: false }),
                props.fetchTrainingPrograms({ archive: false }),
                props.fetchCollegeYears({ archive: false }),
                props.filterEmailTemplate(),
            ])
            .finally(() => {
                props.changeForm({ ...form, campus: campus?.id }, "form");

                setLoading(false);
            });
        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, campus: campus?.id }, "form");
    }, [form.campus]);

    useEffect(() => {
        if (students) {
            props.changeForm(
                students.map((student) => ({
                    value: student["@id"],
                    label: student.user.lastName + " " + student.user.firstName,
                })),
                "studentOptions"
            );
        }
    }, [students]);

    useEffect(() => {
        props.changeForm({ ...form, students: [] }, "form");
        props.changeForm([], "studentOptions");
        setStudentOptionsEmpty(true);
    }, [
        form.trainingLevel,
        form.trainingProgram,
        form.collegeYear,
        form.currentMembershipStatus,
        form.semesterStatus,
    ]);

    useEffect(() => {
        props.changeForm({ ...form, trainingProgram: undefined }, "form");
    }, [form.trainingLevel]);

    useEffect(() => {
        props.changeForm({ ...form, collegeYear: undefined }, "form");
    }, [form.trainingProgram]);

    if (loading) {
        return <Loading />;
    }

    const handleFilterStudents = () => {
        props.changeForm(
            { ...form, students: [], campus: getUser()?.campus?.id },
            "form"
        );
        setStudentLoading(true);
        props
            .fetchStudents({
                campus: form.campus,
                currentTrainingLevel: form.trainingLevel,
                currentTrainingProgram: form.trainingProgram,
                currentCollegeYear: form.collegeYear,
                currentMembershipStatus: form.currentMembershipStatus,
                semesterStatus: form.semesterStatus,
                pagination: false,
                terminating: false,
            })
            .then(() => {
                setStudentLoading(false);
                setStudentOptionsEmpty(false);
            });
    };

    const handleSelectAll = () =>
        props.changeForm(
            { ...form, students: studentOptions?.map((stud) => stud.value) },
            "form"
        );

    return (
        <FormPageTemplate header={{ ...header }} form={formProps}>
            <Select
                name="campus"
                value={form.campus || ""}
                selectLabel="Campus"
                optionList={makeOptionsFromID(campuses)}
                format={{ xs: 12 }}
                disabled
            />
            <Select
                label="Képzési szint"
                name="trainingLevel"
                value={form.trainingLevel || []}
                options={makeOptionsFromID(trainingLevels) || []}
                format={{ xs: 12 }}
                multiple={true}
            />
            <Select
                label="Képzési program"
                name="trainingProgram"
                value={form.trainingProgram || []}
                options={makeTrainingProgramOptionsMultiple(
                    trainingPrograms["hydra:member"],
                    form?.trainingLevel
                )}
                format={{ xs: 12 }}
                disabled={!form.trainingLevel}
                multiple={true}
            />
            <Select
                label="Évfolyam"
                name="collegeYear"
                value={form.collegeYear || []}
                options={makeCollegeYearOptionsMultiple(
                    collegeYears["hydra:member"],
                    form.trainingProgram
                )}
                format={{ xs: 12 }}
                disabled={!form.trainingProgram}
                multiple={true}
            />
            <Autocomplete
                label="Aktuális státusz"
                name="currentMembershipStatus"
                value={form.currentMembershipStatus || []}
                options={options.statusOptions || []}
                format={{ xs: 12 }}
                multiple={true}
                disabled
            />
            <Autocomplete
                label="Szemeszter státusz"
                name="semesterStatus"
                value={form.semesterStatus || []}
                options={options.semesterStatusOptions || []}
                format={{ xs: 12 }}
                multiple={true}
            />
            <Select
                selectLabel="Hallgatók"
                name="students"
                value={form.students || []}
                disabled={!form.campus || !form.collegeYear}
                optionList={studentOptions || []}
                format={{ xs: 12 }}
                loading={studentLoading}
                multiple
            />
            <SubmitButton
                className={
                    form.campus && form.collegeYear && !studentLoading
                        ? classes.insert
                        : ""
                }
                disabled={
                    !form.campus ||
                    !form.collegeYear ||
                    studentLoading ||
                    studentOptionsEmpty
                }
                onClick={handleSelectAll}
            >
                Összes kiválasztása
            </SubmitButton>
            <SubmitButton
                disabled={
                    !form.collegeYear ||
                    !form.currentMembershipStatus ||
                    !form.semesterStatus
                }
                onClick={handleFilterStudents}
            >
                Hallgatók szűrése
            </SubmitButton>
            <Title
                {...formProps}
                title="E-mail"
                format={{ xs: 12 }}
                error={form.errors?.emailContent}
            >
                <TextField
                    label="Tárgy"
                    name="emailSubject"
                    value={form.emailSubject || ""}
                    format={{ xs: 12 }}
                />
                <CkEditor
                    type="editor"
                    name="emailContent"
                    data={form.emailContent || ""}
                    format={{ xs: 12 }}
                    onChange={(_, editor) =>
                        props.changeEditor(editor.getData())
                    }
                />
            </Title>
            <SubmitButton
                name="insert"
                type="button"
                onClick={() =>
                    props.changeForm(
                        {
                            ...form,
                            emailSubject: emailTemplate?.subject,
                            emailContent: emailTemplate?.content,
                            errors: {},
                        },
                        "form"
                    )
                }
                format={{ xs: 12 }}
                className={classes.insert}
            >
                E-mail sablon beillesztése
            </SubmitButton>
            <SubmitButton type="submit" disabled={!valid}>
                Küldés
            </SubmitButton>
            <CancelButton onClick={() => history.push("/new-process")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form, studentOptions, students } = state.moveOut;
    const emailTemplate = state.emailTemplates.data["hydra:member"]
        ? state.emailTemplates.data["hydra:member"]?.find(
              (element) => element["key"] === "move_out_started"
          )
        : {};
    const trainingLevels = state.trainingLevels.trainingLevelsForEnrollment;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const campuses = state.campuses.data;
    return {
        form,
        campuses,
        students,
        trainingLevels,
        trainingPrograms,
        collegeYears,
        studentOptions,
        emailTemplate,
    };
}

const actionCreators = {
    changeForm: moveOutActions.changeForm,
    changeEditor: moveOutActions.changeEditor,
    defaultForm: moveOutActions.defaultForm,
    start: moveOutActions.start,
    fetchTrainingLevels: trainingLevelsActions.getForEnrollment,
    fetchTrainingPrograms:
        trainingProgramsActions.filterTrainingProgramsForSelect,
    fetchCollegeYears: collegeYearsActions.filter,
    fetchCampuses: campusesActions.filter,
    fetchStudents: moveOutActions.getStudentsForMoveOut,
    filterEmailTemplate: () =>
        emailTemplatesActions.filter({ key: "move_out_started" }),
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(MoveOut);
