import { types } from "./types";

const initialState = {
    filterStorageKey: "processes.activeProcesses",
    filter: {},
    form: {}
}

export function activeProcesses(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                ...initialState,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name] 
            }
        default: 
            return {
                ...state
            }
    }
}