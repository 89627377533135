import types from './types';

const initialState = { snackbar: {type: '', msg: ''}};

export function notification(state = initialState, action) {
    switch (action.type) {
        case types.ADD_NOTIFICATION:
            return {
                ...state,
                snackbar: action.data,
            };
        case types.REMOVE_NOTIFICATION:
            return {
                ...state,
                snackbar: initialState.snackbar,
            }
        default:
            return state;
    }
}