import { types } from "./types";

const initialState = {
    form: {},
    reminderForm: {}
};

export function relocationFinancialControl(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
                reminderForm: {
                    emailContent: action.data.form.emailContent ?? state.reminderForm.emailContent,
                    emailSubject: action.data.form.emailSubject ?? state.reminderForm.emailSubject,
                    errors: action.data.form.errors ?? null
                },
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
            };
        case types.CHANGE_EDITOR:
            return {
                ...state,
                reminderForm: {
                    ...state.reminderForm,
                    emailContent: action.content
                }
            };
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.APPROVE_FINANCIAL_CHECK_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.APPROVE_FINANCIAL_CHECK_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.APPROVE_FINANCIAL_CHECK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.UPDATE_FINANCIAL_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.UPDATE_FINANCIAL_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.UPDATE_FINANCIAL_COMMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.APPROVE_UNPAID_DEBT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.APPROVE_UNPAID_DEBT_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.APPROVE_UNPAID_DEBT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
            case types.GET_FORM_REQUEST:
                return {
                    ...state,
                };
            case types.GET_FORM_SUCCESS:
                return {
                    ...state,
                    form: action.data.data,
                };
            case types.GET_FORM_FAILURE:
                return {
                    ...state,
                    form: {},
                    error: action.error,
                };
        default:
            return state;
    }
}