import { templateChangeForm, templateDefaultForm, templateFilter } from "../..";
import { types } from "./types";
import systemLogService from "../../../services/administration/systemLog";

function getTypesAndEvents() {
    return (dispatch) => {
        dispatch({ type: types.GET_TYPES_AND_EVENTS_REQUEST });
        return systemLogService.getTypesAndEvents().then(
            (data) => {
                dispatch({ type: types.GET_TYPES_AND_EVENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_TYPES_AND_EVENTS_FAILURE });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, systemLogService),
    getTypesAndEvents,
    setInfo
}

export default actions;