import { templateDelete, templateGet } from "../..";
import API from "../../../utils/API";
import SearchForm from "../../../utils/SearchForm";

function start(form) {
    return API.post(`/terminations/start`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function fillStudentDeclaration(form, id) {
    return API.post(`/terminations/${id}/fill-student-declaration`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function approveOperatorCheck(form, id) {
    return API.post(`/terminations/${id}/approve-operator-check`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function sendUnpaidDebtNotification(id, form) {
    return API.post(`/terminations/${id}/send-unpaid-debt-notification`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function approveFinancialCheck(form, id) {
    return API.post(`/terminations/${id}/approve-financial-check`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function updateFinancialComment(form, id) {
    return API.post(`/terminations/${id}/update-financial-comment`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function startMoveOut(form) {
    return API.post(`/terminations/start-move-out`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getStudentsForMoveOut(filter) {
    const searchForm = new SearchForm(filter);

    return API.get(`/students-for-move-out`, {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getForm(terminationId) {
    return API.get(`/terminations/${terminationId}/form`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const terminationsService = {
    start,
    get: templateGet("terminations"),
    delete: templateDelete("terminations"),
    getForm,
    fillStudentDeclaration,
    approveOperatorCheck,
    sendUnpaidDebtNotification,
    approveFinancialCheck,
    updateFinancialComment,
    startMoveOut,
    getStudentsForMoveOut,
};

export default terminationsService;
