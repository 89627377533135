import CkEditor from "../../Common/CkEditor";
import Dialog from "../../Common/Dialog";
import SubmitButton from "../../Common/SubmitButton";
import TextField from './../../Common/TextField';
import { makeStyles } from '@material-ui/core';
import colors from "../../../styles/colors";
import Form from "../../Common/Form";
import { useState } from "react";
import { Skeleton } from "@material-ui/lab";
import Title from "../../Common/Title";

const useStyles = makeStyles(() => ({
    reminder: {
        backgroundColor: colors.reminder,
        '&:hover': {
            backgroundColor: colors.reminderHover,
        }
    }
}))

export default function Reminder(props) {
    const { form, emailTemplate, formProps } = props;
    const [loading, setLoading] = useState(false);
    const { id } = props.match.params;
    const classes = useStyles();
    
    const handleSendNotification = () => {
        setLoading(true);
        props.sendUnpaidDebtNotification(id, {
            emailSubject: form.emailSubject,
            emailContent: form.emailContent
        })
        .then(() => {
            props.loadingOn();
        })
        .catch((error) => props.changeForm(
            {
                ...form,
                errors: error?.response?.data?.violations.reduce(
                    (obj, item) => ({...obj, [item?.propertyPath]: item?.message}), {})
            },
            "form"
        ))
        .finally(() => {
            props.loadingOff();
            setLoading(false);
        })
    };

    if (loading) {
        return (
            <Dialog
                opener={
                    <SubmitButton
                        className={classes.reminder}
                    >
                        Emlékeztető
                    </SubmitButton>
                }
                title="Emlékeztető"
            >
                <Skeleton/>
            </Dialog>
        )
    }

    return (
        <Dialog
            opener={
                <SubmitButton
                    className={classes.reminder}
                >
                    Emlékeztető
                </SubmitButton>
            }
            title="Emlékeztető"
            cancelText="Mégsem"
            action={
                <SubmitButton 
                    onClick={handleSendNotification}
                    preventClose={true}
                >
                    Küldés
                </SubmitButton>
            }
        >
            <Form
                {...formProps}
                childComponent="true"
            >
                <Title
                    {...formProps}
                    title="E-mail"
                    format={{ xs: 12 }}
                    error={form.errors?.emailContent}
                >
                    <TextField 
                        label="Tárgy"
                        name="emailSubject"
                        value={form.emailSubject || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.emailSubject}
                        helperText={form.errors?.emailSubject}
                    />
                    <CkEditor 
                        type="editor"
                        name="emailContent"
                        data={form.emailContent || ""}
                        format = {{ xs: 12 }}
                        onChange={(_, editor) => props.changeEditor(editor.getData())}
                    />
                </Title>
                <SubmitButton
                    name="insert"
                    type="button"
                    onClick={() => props.changeForm({
                            ...form, 
                            emailSubject: emailTemplate.subject, 
                            emailContent: emailTemplate.content 
                        }, "form")
                    }
                    format= {{ xs: 12 }}
                    className={classes.insert}
                >
                    E-mail sablon beillesztése
                </SubmitButton>
            </Form>
        </Dialog>
    );
}