export const types = {
    CHANGE_FORM: "@@student-allocation-preference-list/CHANGE_FORM",
    DEFAULT_FORM: "@@student-allocation-preference-list/DEFAULT_FORM",

    FILTER_STUDENTS_REQUEST: "@@student-allocation-preference-list/FILTER_STUDENTS_REQUEST",
    FILTER_STUDENTS_SUCCESS: "@@student-allocation-preference-list/FILTER_STUDENTS_SUCCESS",
    FILTER_STUDENTS_FAILURE: "@@student-allocation-preference-list/FILTER_STUDENTS_FAILURE",

    GET_ACTIVE_ALLOCATION_REQUEST: "@@student-allocation-preference-list/GET_ACTIVE_ALLOCATION_REQUEST",
    GET_ACTIVE_ALLOCATION_SUCCESS: "@@student-allocation-preference-list/GET_ACTIVE_ALLOCATION_SUCCESS",
    GET_ACTIVE_ALLOCATION_FAILURE: "@@student-allocation-preference-list/GET_ACTIVE_ALLOCATION_FAILURE",

    DOWNLOAD_EXPORT_REQUEST: "@@student-allocation-preference-list/DOWNLOAD_EXPORT_REQUEST",
    DOWNLOAD_EXPORT_SUCCESS: "@@student-allocation-preference-list/DOWNLOAD_EXPORT_SUCCESS",
    DOWNLOAD_EXPORT_FAILURE: "@@student-allocation-preference-list/DOWNLOAD_EXPORT_FAILURE",

    GET_SEMESTER_NAME_FOR_DOWNLOAD_REQUEST: "@@student-allocation-preference-list/GET_SEMESTER_NAME_FOR_DOWNLOAD_REQUEST",
    GET_SEMESTER_NAME_FOR_DOWNLOAD_SUCCESS: "@@student-allocation-preference-list/GET_SEMESTER_NAME_FOR_DOWNLOAD_SUCCESS",
    GET_SEMESTER_NAME_FOR_DOWNLOAD_FAILURE: "@@student-allocation-preference-list/GET_SEMESTER_NAME_FOR_DOWNLOAD_FAILURE",
}