import { connect } from "react-redux";
import campusesActions from "../../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../../store/loading/actions";
import notificationActions from "../../../../../store/notification/actions";
import ListPageTemplate from "../../../../Common/ListPageTemplate";
import OperationButton from "../../../../Common/OperationButton";
import { useEffect, useState } from "react";
import buildingsActions from "../../../../../store/masterData/buildings/actions";
import semestersActions from "../../../../../store/masterData/semesters/actions";
import floorsActions from "../../../../../store/masterData/floors/actions";
import TextField from "../../../../Common/TextField";
import { filterStorageGet, filterStorageSave } from "../../../../../utils/FilterStorage";
import Dialog from "../../../../Common/Dialog";
import FloorPlanIcon from "../../../../Icons/FloorPlan";
import FloorPlan from "./FloorPlan";
import Tooltip from './../../../../Common/Tooltip';
import { Link } from "react-router-dom";
import roomAllocationListActions from "../../../../../store/processes/roomAllocationList/actions";
import axios from 'axios';
import Loading from "../../../../Common/Loading";

function Floors(props) {
    const { filter, data, campus, building, filterStorageKey, semesters, allocations, rooms } = props;
    const { campusId, buildingId } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [startCheck, setStartCheck] = useState(false);
    const [isBuildingHavePlan, setIsBuildingHavePlan] = useState(false); // Van-e az épületnek tervrajza
    const searchOtherData = { campusId: campusId, buildingId: buildingId };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.search(storageFilter, searchOtherData),
            props.getSemesters(),
            props.getBuilding(buildingId),
        ]).then( () => setStartCheck(true)) // FIXME: ezt szebben is lehetne
            .catch((e) => {
                console.error("Floors error", e);
                setLoading(false);
                props.loadingOff()
            });
    }, []);

    useEffect(() => {
        if(building?.name){
            setIsBuildingHavePlan(building?.name === "Főépület" && building?.campus?.name === "MCC Budapesti Központ"); // FIXME: ezt szebben
        }
    }, [building, startCheck]);

    useEffect(() => {
        if(!isBuildingHavePlan){ // FIXME: ezt a részt lehetne szebben
            if(startCheck){
                setLoading(false);
                props.loadingOff()
            }
            return;
        }

        props.loadingOn();
        axios.all([
            props.getAllocations()
        ]);
    }, [isBuildingHavePlan, startCheck]);

    useEffect(() => {
        if (!semesters?.["hydra:member"] || allocations?.length < 1) {
            return;
        }
        const semesterId = semesters["hydra:member"].find(semester => semester.active)?.id || null;
        if (semesterId !== null && isBuildingHavePlan) {
            const allocationId = allocations?.find(all => all.semester.id === semesterId)?.id || null;
            if (allocationId !== null) {
                props.getAllocation(allocationId).then((d) => {
                    props.changeForm((d.data?.["hydra:member"]).filter(room => room?.floor?.building?.id === 1 * buildingId), "rooms");
                    props.loadingOff();
                    setLoading(false);
                })
            } else {
                props.loadingOff();
                setLoading(false);
            }
        } else {
            props.loadingOff();
            setLoading(false);
        }
    }, [semesters, allocations, isBuildingHavePlan]);

    const header = {
        title: "Szintek",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            "floors": "Szintek"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({}, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }




    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={`/campuses/${campusId}/buildings/${buildingId}/floors/view/${params.data.id}`}
                />

                <OperationButton
                    tooltiptitle='Szobák'
                    type="house"
                    to={`/campuses/${campusId}/buildings/${buildingId}/floors/${params.data.id}/rooms`}
                />
                {
                    isBuildingHavePlan &&
                    <Dialog
                        opener={
                            <Tooltip title="Tervrajz">
                                <Link to="#" style={{ padding: "4px" }}>
                                    <FloorPlanIcon />
                                </Link>
                            </Tooltip>
                        }
                        title="Tervrajz"
                        fullScreen
                    >
                        <FloorPlan floor={params.data} rooms={rooms.filter(room => room.floor.id === 1 * params.data.id)} />
                    </Dialog>
                }
            </>
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Szint", field: "name" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer
        },
    }

    if (loading) {
        return <Loading />
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Campus"
                name="campus.name"
                value={campus?.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Épület"
                name="building.name"
                value={building?.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Szint"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey, rooms } = state.floors;
    const campus = state.campuses.form;
    const building = state.buildings.form;
    const semesters = state.semesters.data;
    const allocations = state.roomAllocationList.allocations;
    return { filter, data, campus, building, semesters, filterStorageKey, allocations, rooms };
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,
    getSemesters: semestersActions.filter,
    search: floorsActions.filter,
    changeForm: floorsActions.changeForm,
    defaultForm: floorsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
    getAllocation: roomAllocationListActions.filterStudentAllocations,
    getAllocations: roomAllocationListActions.getAllocations
};

export default connect(mapState, actionCreators)(Floors);