import { templateDelete, templateGet } from '../..';
import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';

function startRelocationSingle(form) {
    return API.post(`/relocations/start/single`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function startRelocationMultiple(form) {
    return API.post(`/relocations/start/multiple`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getRelocatableSingleStudents(filter) {
    const searchForm = new SearchForm(filter);

    return API.get(`/relocatable-single-students`, { params: searchForm.getSend() })
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getNewRooms(filter) {
    const searchForm = new SearchForm(filter);

    return API.get(`/relocation-new-rooms`, { params: searchForm.getSend() })
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getRelocatableStudents(filter) {
    const searchForm = new SearchForm(filter);

    return API.get(`/relocatable-students`, { params: searchForm.getSend() })
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function fillStudentForm(form, id) {
    return API.post(`/relocations/${id}/fill-student`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function approveFinancialCheck(form, id) {
    return API.post(`/relocations/${id}/approve-financial-check`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function sendUnpaidDebtNotification(id, form) {
    return API.post(`/relocations/${id}/send-unpaid-debt-notification`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function fillMoveOutForm(id, form) {
    return API.post(`/relocations/${id}/fill-move-out-form`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function operatorTakeOverRoom(id, form) {
    return API.post(`/relocations/${id}/operator-take-over-room`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}


function studentTakeOverRoom(id, form) {
    return API.post(`/relocations/${id}/student-take-over-room`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function approveTakeOverRoom(id, form) {
    return API.post(`/relocations/${id}/approve-take-over-room`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function approveUnpaidDebt(id, form) {
    return API.post(`/relocations/${id}/approve-unpaid-debt`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function updateFinancialComment(form, id) {
    return API.post(`/relocations/${id}/update-financial-comment`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getForm(relocationId) {
    return API.get(`/relocations/${relocationId}/form`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const relocationsService = {
    approveFinancialCheck,
    fillMoveOutForm,
    fillStudentForm,
    get: templateGet('relocations'),
    delete: templateDelete("relocations"),
    getForm,
    getRelocatableSingleStudents,
    getNewRooms,
    getRelocatableStudents,
    operatorTakeOverRoom,
    sendUnpaidDebtNotification,
    startRelocationSingle,
    startRelocationMultiple,
    studentTakeOverRoom,
    approveTakeOverRoom,
    approveUnpaidDebt,
    updateFinancialComment
}

export default relocationsService;