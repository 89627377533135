import axios from "axios";
import { history } from "../store";
import { axiosErrorRedirect } from "../utils/AppConst";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/ld+json",
        "X-Requested-With": "XMLHttpRequest",
    },
    responseType: "json",
});

instance.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            const AUTH_TOKEN = cookies.get(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');
            if (AUTH_TOKEN) config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }

        const SENTRY_ERRORS = cookies.get('skip_sentry_errors');
        if (SENTRY_ERRORS)
            config.headers = Object.assign({}, config.headers, {
                'X-Sentry-Errors': SENTRY_ERRORS,
            });

        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    // remember to make this async as the store action will
    // need to be awaited
    async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error?.response?.status === 401) {
            axiosErrorRedirect(error);

           return Promise.reject(error);
       } else {
            if (404 === error?.response?.status) {
                history.push("/404");
                return Promise.reject(error);
            } else if (403 === error?.response?.status) {
                history.push("/403");
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
