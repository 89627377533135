import { connect } from "react-redux";
import semestersActions from "../../../store/masterData/semesters/actions";
import loadingActions from "../../../store/loading/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import Select from "../../Common/Select";
import { BOOLEAN_OPTIONS } from "../../../utils/AppConst";
import { useEffect } from "react";
import ActiveCellRenderer from "../../../utils/cellRenderer/ActiveCellRenderer";
import DatePicker from "../../Common/DatePicker";
import {
    filterStorageGet,
    filterStorageSave,
} from "../../../utils/FilterStorage";

function BtnCellRenderer(params) {
    return (
        <OperationButton
            type="visibility"
            to={"/semesters/view/" + params.data.id}
        />
    );
}

function Semesters(props) {
    const { filter, data, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        props.search(storageFilter).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Szemeszterek",
        breadcrumbs: {
            masterData: "Törzsadatok",
            semesters: "Szemeszterek",
        },
    };

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({});
        },
        form: filter,
        name: "filter",
        changeform: props.changeForm,
    };

    const rowData = data["hydra:member"]?.map((elem) => {
        return {
            id: elem.id || "",
            name: elem.name || "",
            processName: elem.processName || "",
            active: elem.active || "",
            period: `${elem.startDate || ""} - ${elem.endDate || ""}`,
            enrollmentActive: elem.enrollmentActive || "",
            enrollmentPeriod: `${elem.enrollmentStartDate || ""} - ${
                elem.enrollmentEndDate || ""
            }`,
            terminationActive: elem.terminationActive || "",
            terminationPeriod: `${elem.terminationStartDate || ""} - ${
                elem.terminationEndDate || ""
            }`,
            moveOutActive: elem.moveOutActive || "",
            moveOutPeriod: `${elem.moveOutStartDate || ""} - ${
                elem.moveOutEndDate || ""
            }`,
            roomAllocationActive: elem.roomAllocationActive || "",
            roomAllocationPeriod: `${elem.roomAllocationStartDate || ""} - ${
                elem.roomAllocationEndDate || ""
            }`,
            relocationActive: elem.relocationActive || "",
            relocationPeriod: `${elem.relocationStartDate || ""} - ${
                elem.relocationEndDate || ""
            }`,
        };
    });

    const table = {
        columnDefs: [
            { headerName: "Név", field: "name", minWidth: 150 },
            { headerName: "Azonosító", field: "processName" },
            {
                headerName: "Aktív",
                field: "active",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Időszak", field: "period" },
            {
                headerName: "Beiratkozás aktív",
                field: "enrollmentActive",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Beiratkozási időszak", field: "enrollmentPeriod" },
            {
                headerName: "Kiiratkozás aktív",
                field: "terminationActive",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Kiiratkozási időszak", field: "terminationPeriod" },
            {
                headerName: "Kiköltözés aktív",
                field: "moveOutActive",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Kiköltözési időszak", field: "moveOutPeriod" },
            {
                headerName: "Szobabeosztás aktív",
                field: "roomAllocationActive",
                cellRenderer: "activeCellRenderer",
            },
            {
                headerName: "Szobabeosztási időszak",
                field: "roomAllocationPeriod",
            },
            {
                headerName: "Átköltözés aktív",
                field: "relocationActive",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Átköltözési időszak", field: "relocationPeriod" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: rowData,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
    };

    return (
        <ListPageTemplate filter={filterForm} header={header} table={table}>
            <TextField
                label="Név"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Azonosító"
                name="processName"
                value={filter.processName || ""}
                format={{ xs: 12, md: 6 }}
            />
            <Select
                selectLabel="Aktív"
                name="active"
                value={filter.active || ""}
                optionList={BOOLEAN_OPTIONS}
                format={{ xs: 12, md: 6 }}
            />
            <DatePicker
                label="Szemeszter kezdete (-tól)"
                name="startDateFrom"
                value={filter.startDateFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <DatePicker
                label="Szemeszter kezdete (-ig)"
                name="startDateTo"
                value={filter.startDateTo || ""}
                format={{ xs: 12, md: 6 }}
            />
            <DatePicker
                label="Szemeszter vége (-tól)"
                name="endDateFrom"
                value={filter.endDateFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <DatePicker
                label="Szemeszter vége (-ig)"
                name="endDateTo"
                value={filter.endDateTo || ""}
                format={{ xs: 12, md: 6 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.semesters;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: semestersActions.changeForm,
    defaultForm: semestersActions.defaultForm,
    search: semestersActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Semesters);
