export const types = {
    CHANGE_FORM: "@@training_levels/CHANGE_FORM",
    DEFAULT_FORM: "@@training_levels/DEFAULT_FORM",

    FETCH_REQUEST: "@@training_levels/FETCH_REQUEST",
    FETCH_SUCCESS: "@@training_levels/FETCH_SUCCESS",
    FETCH_FAILURE: "@@training_levels/FETCH_FAILURE",

    GET_REQUEST: "@@training_levels/GET_REQUEST",
    GET_SUCCESS: "@@training_levels/GET_SUCCESS",
    GET_FAILURE: "@@training_levels/GET_FAILURE",

    GET_FOR_ENROLLMENT_REQUEST: "@@training_levels/GET_FOR_ENROLLMENT_REQUEST",
    GET_FOR_ENROLLMENT_SUCCESS: "@@training_levels/GET_FOR_ENROLLMENT_SUCCESS",
    GET_FOR_ENROLLMENT_FAILURE: "@@training_levels/GET_FOR_ENROLLMENT_FAILURE",
}