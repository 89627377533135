import { templateChangeForm, templateDefaultForm, templateFilter } from "../..";
import { types } from "./types";
import studentsListService from "../../../services/reports/studentsList";
import downloadBlob from '../../../utils/downloadBlob';

function downloadExport(filter, fileName = 'export.xlsx') {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_EXPORT_REQUEST });
        return studentsListService.downloadExport(filter).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function donwloadUniversityAdmissionDecision(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_EXPORT_REQUEST, id });
        return studentsListService.downloadUniversityAdmissionDecision(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}


const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    downloadExport,
    donwloadUniversityAdmissionDecision,
    filter: templateFilter(types, studentsListService),
    setInfo
}

export default actions;