import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateFetch, templateGet, templateUpdate } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/campuses/${otherData.campusId}/buildings/${otherData.buildingId}/floors/${otherData.floorId}/rooms`, {params: searchForm.getSend()})
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getAssignableRooms() {
    return API.get('/rooms?assignable[]=1')
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getAssignableRoomsForFilter(allocationId) {
    return API.get(`/rooms/${allocationId}/assignable-for-filter`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

const buildingsService = {
    filter,
    fetch: templateFetch('rooms'),
    create: templateCreate('rooms'),
    get: templateGet('rooms'),
    update: templateUpdate('rooms'),
    delete: templateDelete('rooms'),
    getAssignableRooms,
    getAssignableRoomsForFilter
};

export default buildingsService;