const colors = {
    primaryYellow: "#f3ca12",
    primaryYellowHover: "#dabd3c",
    primaryBlue: "#364354",
    primaryBlueHover: "#314b6e",
    white: "#fff",
    black: "#000",
    error: "#E73333",
    errorHover: "#ce5656",
    tabBackground: "#f4f4f4",
    reminder: "#ff7366",
    reminderHover: "#e6928a",
    insert: "#5ca8c1",
    insertHover: "#729aa8",
    disabledBackground: "#d9dadb",
    disabledColor: "#aaa", 
    new: "#35A899",
    newHover: "#498e85",
    badge: "#10aaf2",
    gray: "#555555",
    grayHover: "#666666",
    lightGrey: "#e0e0e0",
    greenRow: "#56db8f",
}

export default colors;