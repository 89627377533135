import API from "../../../utils/API";
import SearchForm from "../../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/foreign-universities", {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const foreignUniversitiesService = {
    filter,
};

export default foreignUniversitiesService;
