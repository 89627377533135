import TooltipRenderer from "../TooltipRenderer";

const ActorCellRenderer = (params) => {
    let result = params?.data?.actor?.fullName;

    if (params?.data?.realActor?.fullName && params?.data?.realActor?.fullName !== params?.data?.actor?.fullName) {
        result += ` (${params.data.realActor.fullName})`;
    }
    
    return TooltipRenderer(result)
}

export default ActorCellRenderer;