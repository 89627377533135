import { templateChangeForm, templateDefaultForm } from "../../..";
import relocationService from "../../../../services/processes/relocation";
import notificationTypes from "../../../notification/types";
import { types } from "./types";

const changeEditor = content => dispatch => dispatch({ type: types.CHANGE_EDITOR, content });

function extractId(str) {
    const parts = str.split("/");
    const lastId = parts[parts.length - 1];
    return parseInt(lastId);
}

function startRelocationSingle(form) {
    const formToSend = {
        student: extractId(form.student),
        emailSubject: form.emailSubject,
        emailContent: form.emailContent,
        newRoom: extractId(form.newRoom),
    };

    return (dispatch) => {
        dispatch({ type: types.START_REQUEST, formToSend });
        return relocationService.startRelocationSingle(formToSend).then(
            (data) => {
                dispatch({ type: types.START_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés" }
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "error", msg: "Hiba lépett fel küldés közben" }
                })
                return Promise.reject(error);
            }
        )
    }
}

function getRelocatableStudents(filter) {
    const formToSend = {
        campus: filter.campus?.split("/")?.pop(),
        semester: filter.semester
    }

    return (dispatch) => {
        dispatch({ type: types.GET_RELOCATABLE_STUDENTS_REQUEST, formToSend });
        return relocationService.getRelocatableSingleStudents(formToSend).then(
            (data) => {
                dispatch({ type: types.GET_RELOCATABLE_STUDENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_RELOCATABLE_STUDENTS_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function getNewRooms(filter) {
    const formToSend = {
        campus: filter.campus?.split("/")?.pop(),
        semester: filter.semester
    }

    return (dispatch) => {
        dispatch({ type: types.GET_NEW_ROOMS_REQUEST, formToSend });
        return relocationService.getNewRooms(formToSend).then(
            (data) => {
                dispatch({ type: types.GET_NEW_ROOMS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_NEW_ROOMS_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const deleteRelocation = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_REQUEST });
    return relocationService.delete(id).then(
        (data) => {
            dispatch({ type: types.DELETE_SUCCESS, data });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "success", msg: "Sikeres törlés" },
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.DELETE_FAILURE, error });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "error", msg: "Hiba lépett fel törlés közben" },
            });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    getRelocatableStudents,
    getNewRooms,
    startRelocationSingle,
    deleteRelocation,
}

export default actions;