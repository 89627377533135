import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import OperationButton from '../../Common/OperationButton';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        top: 0,
    },
}));

const ColorButton = withStyles(() => ({
    root: {
        backgroundColor: '#b4645d',
        '&:hover': {
            backgroundColor: '#851400',
        },
    },
}))(Button);

export default function BtnCellRenderer(props) {
    const classes = useStyles();
    let [editing, setEditing] = useState(false);
    let [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        props.api.addEventListener('rowEditingStarted', onRowEditingStarted);
        props.api.addEventListener('rowEditingStopped', onRowEditingStopped);

        return () => {
            props.api.removeEventListener('rowEditingStarted', onRowEditingStarted);
            props.api.removeEventListener('rowEditingStopped', onRowEditingStopped);
        };
    }, []);

    function onRowEditingStarted(params) {
        if (props.node === params.node) {
            setEditing(true);
        } else {
            setDisabled(true);
        }
    }

    function onRowEditingStopped(params) {
        if (props.node === params.node) {
            setEditing(false);
        } else {
            setDisabled(false);
        }
    }

    function startEditing(e) {
        e.preventDefault();
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId
        });
    }

    function stopEditing(bool) {
        props.api.stopEditing(bool);
    }

    function save() {
        setOpen(true);
        // let data = props.data;
        // let valid = true;

        // for(const val of Object.values(data.value))
        //     valid = valid && val !== ''

        // if(valid) {
        //     console.log("küldés")
        //     //translationsService.patch(data, data.id).then(() => setOpen(true));
        // }
    }


    return (
        <div>
            {editing
                ? <>

                    <Button variant="contained" size="small" color="primary" className={classes.margin}
                            onClick={() => {
                                stopEditing(false)
                                save();
                            }}
                            disabled={disabled}
                    >
                        Mentés
                    </Button>
                    <ColorButton variant="contained" size="small" color="secondary" className={classes.margin}
                            onClick={() => stopEditing(true)}
                            disabled={disabled}
                    >
                        Mégsem
                    </ColorButton>
                    
                </>
                : <>
                    <OperationButton
                        type="edit"
                        onClick={startEditing}
                        disabled={disabled}
                    />
                    <Snackbar 
                        style={{top: "auto", bottom: 0}}
                        open={open} 
                        autoHideDuration={2000}
                        onClose={() => setOpen(false)}
                    >
                        <Alert severity="success">
                            Siker
                        </Alert>
                    </Snackbar>
                </>
            }
        </div>
    )
}
