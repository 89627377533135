const makeFormToSend = (form) => {
    let formToSend = {}
    for (const [key, value] of Object.entries(form)) {
        if (typeof(value) === "string" && value.includes("/")) {
            const splittedValue = value.split("/");
            formToSend[key] = Number(splittedValue[splittedValue.length - 1]);
        } else if (typeof(value) === "object" && key !== "address") {
            formToSend[key] = value?.id || "";
        } else {
            formToSend[key] = value;
        }
    }

    return formToSend;
}

export default makeFormToSend;