import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import colors from "../../styles/colors";
import Form from "./Form";

const useStyle = makeStyles(() => ({
    title: {
        fontSize: "large",
        borderBottom: "1px solid " + colors.primaryBlue,
        fontWeight: "bold",
        color: colors.primaryBlue,
        marginBottom: "8px",
    },
    error: {
        color: "#f44336",
        fontSize: "0.75rem",
    },
}));

/**
 * @param title lesz a cím,
 * @param format a méretezést adja meg,
 */
export default function Title(props) {
    const classes = useStyle();

    return (
        <Form childComponent format={{ xs: 12 }} {...props}>
            <Typography className={classes.title} format={{ xs: 12 }}>
                {props.title}
            </Typography>
            {props.children}
            {props.error && (
                <Typography className={classes.error} format={{ xs: 12 }}>
                    {props.error}
                </Typography>
            )}
        </Form>
    );
}
