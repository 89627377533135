import { connect } from "react-redux";
import closedProcessesActions from "../../../../store/processes/closedProcesses/actions";
import enrollmentActions from '../../../../store/processes/newProcess/enrollment/actions';
import notificationActions from '../../../../store/notification/actions';
import ListPageTemplate from "../../../Common/ListPageTemplate";
import OperationButton from "../../../Common/OperationButton";
import TextField from "../../../Common/TextField";
import Select from '../../../Common/Select';
import EnrollmentTypeCellRenderer from '../../../../utils/cellRenderer/EnrollmentTypeCellRenderer';
import {
    PROCESS_TYPES,
    getProcessTypeLabel,
    getValue,
    PROCESS_TYPE_NAMES, isKep,
} from '../../../../utils/AppConst';
import { makeOptionsFromID } from '../../../../utils/listOptions/makeOptionsFromID';
import FormToPrint from './../../../Common/FormToPrint';
import userService from "../../../../services/login";
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { setInfo } from "../../../../utils/FilterHelper";
import { filterStorageSave } from "../../../../utils/FilterStorage";
import { isSystemAdmin } from "../../../../utils/User";

function Table(props) {
    const { filter, data, campuses, semesters, enrollment, filterStorageKey, tableInfo } = props;
    const ref = useRef();
    function BtnCellRenderer(params) {
        const handlePrint = useReactToPrint({
            content: () => ref.current,
            documentTitle: `${params.data.fullName}_${getProcessTypeLabel(params.data.type)}_${params.data.semester.name}`
        })

        if (params.data.type === PROCESS_TYPE_NAMES.ENROLLMENT) {
            return (
                <>
                    <OperationButton
                        type="info"
                        to={`/processes/details/${params.data.id}`}
                    />
                    <OperationButton
                        type="visibility"
                        tooltiptitle="Idővonal megtekintése"
                        to={`/processes/timeline/${params.data.id}`}
                    />
                    <OperationButton
                        type="print"
                        onClick={() => {
                            props.addNotification("info", "A nyomtatás előkészítése folyamatban...");
                            props.getEnrollment(params.data.id)
                                .then(() => {
                                    handlePrint();
                                    props.removeNotification();
                                })
                        }}
                    />
                </>
            );
        }

        if (params.data.type === PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE) {
            return (
                <OperationButton
                    type="info"
                    to={`/processes/student-allocation-preference/details/${params.data.id}`}
                />
            );
        }

        if (params.data.type === PROCESS_TYPE_NAMES.RELOCATION) {
            return (
                <OperationButton
                    type="info"
                    to={`/processes/relocation/details/${params.data.id}`}
                />
            )
        }

        if (params.data.type === PROCESS_TYPE_NAMES.TERMINATION) {
            return (
                <>
                    <OperationButton
                        type="info"
                        to={`/processes/termination/details/${params.data.id}`}
                    />
                    {isSystemAdmin() && params.data.status !== "done" && (
                        <OperationButton
                            type="delete"
                            confirmation={`Biztosan törli a "${params.data.displayId}" folyamatott a rendszerből?`}
                            submittext="Igen"
                            handleClick={() =>
                                props.handleDeleteStudentAllocationPreference(
                                    params.data.id
                                )
                            }
                            to={"#"}
                        />
                    )}
                </>
            );
        }

        if (params.data.type === PROCESS_TYPE_NAMES.MOVE_OUT) {
            return (
                <OperationButton
                    type="info"
                    to={`/processes/termination/details/${params.data.id}`}
                />
            );
        }
        
        return <></>
    }

    const filterForm = {
        submitaction: () => {
            setInfo(props, "filter", filter)
            filterStorageSave(filterStorageKey, filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            setInfo(props, "filter", filter)
            // props.filterDone({})
        },
        form: filter,
        changeform: props.changeForm
    }

    let columns = [
        { headerName: "Sorszám", field: "displayId" },
        { headerName: "Típus", field: "type", cellRenderer: "enrollmentTypeCellRenderer" },
        { headerName: "Szemeszter", field: "semester.name" },
        { headerName: "Név", field: "fullName", minWidth: 280 },
        { headerName: "E-mail cím", field: "email" },
        { headerName: "Campus", field: "campus.name" },
        {
            headerName: "Műveletek",
            field: "operations",
            cellRenderer: "btnCellRenderer",
            minWidth: 220,
            sortable: false
        }
    ];

    if(isKep()){
        columns = columns.filter(column => column.field !== "omId");
    }

    const table = {
        columnDefs: columns,
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            enrollmentTypeCellRenderer: EnrollmentTypeCellRenderer,
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            table={table}
        >
            <TextField
                label="Sorszám"
                name="displayId"
                value={filter.displayId || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Típus"
                name="@type"
                value={filter['@type'] || ""}
                optionList={PROCESS_TYPES}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Név"
                name="fullName"
                value={filter.fullName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                name="campus"
                value={filter.campus || []}
                selectLabel="Campus"
                optionList={makeOptionsFromID(campuses)}
                format={{ xs: 12, md: 4 }}
                multiple
            />
            <Select
                selectLabel="Szemeszter"
                name="semester"
                value={filter?.semester || []}
                optionList={makeOptionsFromID(semesters)}
                format={{ xs: 12, md: 4 }}
                multiple
            />
            <div style={{ display: "none" }}>
                <FormToPrint
                    ref={ref}
                    form={{ ...enrollment, city: userService?.getUserObject()?.campus?.address?.city || "Budapest" }}
                    getField={field => getValue(field, enrollment)}
                />
            </div>
        </ListPageTemplate>
    );
}

function mapState(state) {
    const campuses = state.campuses.data;
    const enrollment = state.enrollment.form;
    return { campuses, enrollment };
}

const actionCreators = {
    changeForm: closedProcessesActions.changeForm,
    defaultForm: closedProcessesActions.defaultForm,
    filterDone: enrollmentActions.filterDone,
    getEnrollment: enrollmentActions.get,
    addNotification: notificationActions.addNotification,
    removeNotification: notificationActions.removeNotification
};

export default connect(mapState, actionCreators)(Table);