import { emailLog } from "./emailLog/reducer";
import { emailTemplates } from "./emailTemplates/reducer";
import { permissions } from "./permissions/reducer";
import { systemLog } from "./systemLog/reducer";
import { translations } from "./translations/reducer";

export const administration = {
    emailTemplates,
    emailLog,
    permissions,
    systemLog,
    translations,
}