import {
    templateChangeForm,
    templateDefaultForm,
    templateCreate,
    templateGet,
} from "../..";
import approvalService from "../../../services/processes/approval";
import notificationTypes from "../../notification/types";
import { types } from "./types";

function saveEnrollment(form, id) {
    let formToSend = {};

    formToSend.enrollment = Number(id) ?? null;
    formToSend.reason = form.reason;
    formToSend.firstName = form.firstName;
    formToSend.lastName = form.lastName;

        formToSend.trainingLevel = typeof form.trainingLevel === 'number'
        ? form.trainingLevel
        : form.trainingLevel?.id
            ? form.trainingLevel?.id
            : form.trainingLevel?.split("/")?.[
                form.trainingLevel?.split("/")?.length - 1
            ];
    formToSend.trainingLevel = Number(formToSend.trainingLevel);

    formToSend.trainingProgram = typeof form.trainingProgram === 'number'
        ? form.trainingProgram
        : form.trainingProgram?.id
            ? form.trainingProgram?.id
            : form.trainingProgram?.split("/")?.[
                form.trainingProgram?.split("/")?.length - 1
            ];
    formToSend.trainingProgram = Number(formToSend.trainingProgram);

    formToSend.collegeYear = typeof form.collegeYear === 'number'
        ? form.collegeYear
        : form.collegeYear?.id
            ? form.collegeYear?.id
            : form.collegeYear?.split("/")?.[
                form.collegeYear?.split("/")?.length - 1
            ];
    formToSend.collegeYear = Number(formToSend.collegeYear);

    formToSend.membershipStatus = form.membershipStatus;
    formToSend.currentMembershipStatus = form.currentMembershipStatus;
    formToSend.semesterStatus = form.semesterStatus;
    formToSend.omId = form.omId ?? null;
    formToSend.neptun = form.neptun;
    formToSend.taxId = form.taxId ?? null;
    formToSend.address = form.address;
    formToSend.motherFirstName = form.motherFirstName ?? null;
    formToSend.motherLastName = form.motherLastName ?? null;
    formToSend.birthPlace = form.birthPlace ?? null;
    formToSend.birthDate = form.birthDate ?? null;
    formToSend.idCardNumber = form.idCardNumber ?? null;
    formToSend.kepIdCardNumber = form.kepIdCardNumber ?? null;
    formToSend.taj = form.taj ?? null;
    formToSend.nationality = form.nationality ?? null;
    formToSend.bankName = form.bankName ?? null;
    formToSend.bankAccountNumber = form.bankAccountNumber?.replace(/[-\s]/g, "") ?? null;
    formToSend.phoneNumber = form.phoneNumber;
    formToSend.university = form.university?.id || form.university;
    formToSend.universityMajor = form.universityMajor;
    formToSend.trainingForm = form.trainingForm;
    formToSend.financingForm = form.financingForm;
    formToSend.scholarshipStatus = form.scholarshipStatus;
    formToSend.identificationNumberCnp = form.identificationNumberCnp ?? null;

    if (
        formToSend.university === 1326 ||
        formToSend.university?.id === 1326 ||
        formToSend.university?.name === "Külföldi képzés"
    ) {
        formToSend.foreignUniversity = form.foreignUniversity;
    }

    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, formToSend });
        return approvalService.saveEnrollment(formToSend, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, formToSend });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres mentés" },
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel küldés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    create: templateCreate(types, approvalService),
    get: templateGet(types, approvalService),
    saveEnrollment,
};

export default actions;
