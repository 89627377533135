const comparator = (a, b) => (
    a.floor.building.name === b.floor.building.name ?
        (
            a.floor.name === b.floor.name ?
                a.name.localeCompare(b.name)
                : a.floor.name.localeCompare(b.floor.name)
        )
        : a.floor.building.name.localeCompare(b.floor.building.name)
);

function createRowData(rowData) {
    let dataToSort = rowData;

    const roomRows = dataToSort?.sort(comparator).map(x => {
        return {
            building: {
                '@id': x.floor?.building?.['@id'],
                name: x.floor?.building?.name,
                id: x.floor?.building?.id
            },
            floor: {
                '@id': x.floor?.['@id'],
                name: x.floor?.name,
                id: x.floor?.id
            },
            room: {
                '@id': x['@id'],
                name: x.name,
                id: x.id
            },
            space: (x.allocations?.length || 0) + " / " + x.space,
            studentAllocations: x.allocations
        }
    });

    return roomRows;
}

export default createRowData;