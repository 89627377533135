import { connect } from "react-redux";
import campusesActions from "../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../store/loading/actions";
import notificationActions from "../../../../store/notification/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import OperationButton from "../../../Common/OperationButton";
import { useEffect } from "react";
import buildingsActions from '../../../../store/masterData/buildings/actions';
import TextField from '../../../Common/TextField';
import AddressCellRenderer from '../../../../utils/cellRenderer/AddressCellRenderer';
import axios from "axios";
import { filterStorageGet, filterStorageSave } from "../../../../utils/FilterStorage";

function Buildings(props) {
    const { filter, data, campus, filterStorageKey } = props;
    const { campusId } = props.match.params;

    const searchOtherData = { campusId: campusId };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.search(storageFilter, searchOtherData),
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Épületek",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            "buildings": "Épületek"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({}, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }


    function BtnCellRenderer(params) {
        return (
            <>
            <OperationButton
                    type="visibility"
                    to={`/campuses/${campusId}/buildings/view/${params.data.id}`}
                />

                <OperationButton
                    tooltiptitle='Szintek'
                    type="house"
                    to={`/campuses/${campusId}/buildings/${params.data.id}/floors`}
                />
            </>
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Épület", field: "name" },
            { headerName: "Cím", field: "address", cellRenderer: "addressCellRenderer", sortable: false },
            { headerName: "Szintek száma", field: "floorCount", sortable: false },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            addressCellRenderer: AddressCellRenderer,
        },
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Campus"
                name="campus.name"
                value={campus.name || ""}
                disabled={true}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField
                label="Épület"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.buildings;
    const campus = state.campuses.form;
    return { filter, data, filterStorageKey, campus };
}

const actionCreators = {
    getCampus: campusesActions.get,
    search: buildingsActions.filter,
    delete: buildingsActions.delete,
    changeForm: buildingsActions.changeForm,
    defaultForm: buildingsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Buildings);