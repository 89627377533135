import {templateChangeForm, templateDefaultForm, templateCreate } from '../..';
import campusSelectorService from '../../../services/modules/campusSelector';
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setCampus: templateCreate(types, campusSelectorService)
}

export default actions;