export const types = {
    CHANGE_FORM: "@@campuses/CHANGE_FORM",
    DEFAULT_FORM: "@@campuses/DEFAULT_FORM",

    FETCH_REQUEST: "@@campuses/FETCH_REQUEST",
    FETCH_SUCCESS: "@@campuses/FETCH_SUCCESS",
    FETCH_FAILURE: "@@campuses/FETCH_FAILURE",

    GET_REQUEST: "@@campuses/GET_REQUEST",
    GET_SUCCESS: "@@campuses/GET_SUCCESS",
    GET_FAILURE: "@@campuses/GET_FAILURE",

}