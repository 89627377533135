import { Grid, makeStyles, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import colors from "../../../../styles/colors";
import { itemStatus } from "../../../../utils/AppConst";
import studentRoomTakeOverActions from "../../../../store/processes/relocation/studentRoomTakeOver/actions";
import loadingActions from "../../../../store/loading/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import PageTemplate from "../../../Common/PageTemplate";
import SmallTable from "../../../Common/SmallTable";
import SubmitButton from "../../../Common/SubmitButton";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store/index";

const useStyles = makeStyles(() => ({
    container: {
        background: "rgba(0,0,0,0.1)"
    },
    title: {
        fontSize: "1.5rem",
        marginBottom: "1rem"
    },
    textContainer: {
        padding: "8px 16px"
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify"
    },
    header: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
    body: {
        backgroundColor: "rgba(54,67,84,0.1)",
    },
    cell: {
        color: colors.primaryBlue
    },
    submitButton: {
        marginRight: "8px",
    },
}));

const columns = [
    { label: "Tárgy", value: "name" },
    { label: "Mennyiség", value: "amount" },
    { label: "Állapot", value: "status", transformer: value => itemStatus.find(entry => entry.value === value)?.label || '-' },
    { label: "Leltári szám", value: "inventoryNumber" },
];

function StudentRoomTakeOver(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();
    const newRoom = form?.newStudentAllocation?.room;

    const header = {
        title: "Szobaátvételi nyilatkozat",
        breadcrumbs: {
            "process": "Folyamatok",
            "relocation": "Átköltözés",
            "student-room-take-over": "Szobaátvételi nyilatkozat",
        }
    }

    const roomColumns = [
        { label: "Épület", value: "building" },
        { label: "Szint", value: "floor" },
        { label: "Szoba", value: "room" },
    ];

    const roomRow = [
        {
            building: newRoom?.floor?.building?.name || "-",
            floor: newRoom?.floor?.name || "-",
            room: newRoom?.name || "-"
        }
    ];

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => props.loadingOff());
    }, []);

    const handleSignature = () => {
        props.loadingOn();
        props.studentTakeOverRoom(form, id, "/")
            .finally(() => props.loadingOff());
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12} sm={10} md={8} xl={6}>
                <Paper className={classes.container} elevation={4}>
                    <Grid container className={classes.textContainer}>
                        <Grid item xs={12} container justify="center">
                            <div className={classes.title}>SZOBA ÁTADÁSI / ÁTVÉTELI JEGYZŐKÖNYV</div>
                        </Grid>
                        <Grid item xs={12} container justify="center">
                            <div className={classes.text}>
                                A mai napon ({new Date().toLocaleDateString("hu-HU")}) átvettem az MCC alapítvány munkatársától az alábbi szobát:
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable columns={roomColumns} rows={roomRow} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>A szobában lévő tárgyak, bútorok átvételkor:</div>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable columns={columns} rows={newRoom?.items || []} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                A szoba berendezési tárgyainak állagát megőrzöm, sérülés esetén anyagi felelősséggel tartozom az MCC Alapítvány-nak.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                Tudomásul veszem, hogy a szoba berendezési tárgyait a szobából kivinni TILOS! Minden tárgy leltári számmal rendelkezik és csak ehhez a szobához tartozik! A szoba és a hozzátartozó vizes helyiség takarítását saját magam végzem a higiéniai előírások maximális betartásával.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                A szoba berendezési tárgyait a szoba kulcsával együtt sérülésmentesen átvettem.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <SubmitButton className={classes.submitButton} onClick={handleSignature}>
                                Aláírás
                            </SubmitButton>
                            <CancelButton onClick={() => history.push("/")}>
                                Mégsem
                            </CancelButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </PageTemplate>
    )
}

function mapState(state) {
    const { form } = state.relocationStudentRoomTakeOver;
    return { form };
}

const actionCreators = {
    defaultForm: studentRoomTakeOverActions.defaultForm,
    get: studentRoomTakeOverActions.getForm,
    studentTakeOverRoom: studentRoomTakeOverActions.studentTakeOverRoom,
    changeForm: studentRoomTakeOverActions.changeForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
}

export default connect(mapState, actionCreators)(StudentRoomTakeOver);