import { templateChangeForm, templateDefaultForm, templateGet } from "../../..";
import relocationsService from "../../../../services/processes/relocation";
import { types } from "./types";
import notificationTypes from "../../../notification/types";
import { history } from "../../../../store";
import { roomCleaningOptions, roomKeyOptions } from "../../../../Components/Processes/Relocation/Forms/OperatorRoomTakeOver";

function operatorTakeOverRoom(form, id, url) {
    const originalItems = form?.oldStudentAllocation?.room?.items
    let formItems = form.items || {};

    /**
     * Here we need to iterate through the originalItems and find the the items that are not in the formItems object.
     * formItems look like {id: amount}.
     * After we found the missing items, we need to add them to the formItems object, like: {id: '0'}
    */
    if (originalItems) {
        Object.entries(originalItems).forEach(item => {
            const itemId = item[1]?.id;
            if (!formItems[itemId]) {
                formItems[itemId] = "0";
            }
        });
    }

    const formToSend = {
        relocation: Number(id),
        roomCleaningStatus: form.roomCleaningStatus || roomCleaningOptions[0].value,
        roomKeyStatus: form.roomKeyStatus || roomKeyOptions[0].value,
        items: formItems,
        operatorComment: form.operatorComment
    }

    return (dispatch) => {
        dispatch({ type: types.OPERATOR_TAKE_OVER_ROOM_REQUEST, formToSend});
        return relocationsService.operatorTakeOverRoom(id, formToSend).then(
            (data) => {
                dispatch({ type: types.OPERATOR_TAKE_OVER_ROOM_SUCCESS, data });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres mentés!"}
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.OPERATOR_TAKE_OVER_ROOM_FAILURE, error });
                dispatch({ 
                  type: notificationTypes.ADD_NOTIFICATION,
                  data: {type: "error", msg: "Hiba lépett fel mentés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return relocationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, relocationsService),
    getForm,
    operatorTakeOverRoom
}

export default actions;