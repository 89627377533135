import { templateGetNoApi } from '../..';
import API from '../../../utils/API';

function create(form, id) {
    let formToSend = Object.assign({}, form);

    for (const elem in formToSend) {
        if (elem[0] === "@") delete formToSend[elem];
    }

    formToSend.university = form.university?.id || form.university;

    return API.post(`/enrollments/${id}/approve-student-filling`, formToSend)
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function saveEnrollment(form, id) {
    return API.post(`/enrollments/${id}/save-enrollment`, form)
    .then(data => data)
    .catch((error) => Promise.reject(error));
}

const approvalService = {
    create,
    get: templateGetNoApi("enrollments"),
    saveEnrollment,
};

export default approvalService;