import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { HelmetProvider } from 'react-helmet-async';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import "moment/locale/hu";
import { pdfjs } from 'react-pdf';
import { SnackbarProvider } from 'notistack';
import Alert from '@material-ui/lab/Alert';
import Versions from './Components/Common/VersionModal';
import * as Sentry from '@sentry/react';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.REACT_APP_SENTRY_ENV_NAME,
      ignoreErrors: [
          'Request failed with status code 401',
          'Network request failed',
          'Failed to fetch',
          'NetworkError',
          'Network Error',
          'Request aborted',
          'timeout exceeded',
          'Request failed with status code 422',
          'Non-Error promise rejection captured',
          'Request failed with status code 500',
          "Cannot read properties of null (reading 'destroy')", // CKEditor
      ],
  });
  Sentry.setTag('host', process.env.REACT_APP_SENTRY_HOST);
}


ReactDOM.render(
  <SnackbarProvider
    maxSnack={10}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    content={(key, message) => (
      <Alert
        key={key} severity={message.variant}
      >
        {message.message}
      </Alert>
    )}
  >
    <MuiPickersUtilsProvider utils={DateMomentUtils} locale="hu">
      <Provider store={store}>
        <HelmetProvider>
          <App />
          <Versions />
        </HelmetProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </SnackbarProvider>,
  document.getElementById('root')
);

reportWebVitals();
