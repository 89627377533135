export const types = {
    CHANGE_FORM: "@@universities/CHANGE_FORM",
    DEFAULT_FORM: "@@universities/DEFAULT_FORM",

    FETCH_REQUEST: "@@universities/FETCH_REQUEST",
    FETCH_SUCCESS: "@@universities/FETCH_SUCCESS",
    FETCH_FAILURE: "@@universities/FETCH_FAILURE",

    GET_REQUEST: "@@universities/GET_REQUEST",
    GET_SUCCESS: "@@universities/GET_SUCCESS",
    GET_FAILURE: "@@universities/GET_FAILURE",

}