import React from "react";
import BasicAutocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";
import TextField from "./TextField";

const CustomAutocomplete = withStyles({
    tag: {
        backgroundColor: colors.primaryBlue,
        height: 24,
        position: "relative",
        zIndex: 0,
        "& .MuiChip-label": {
            color: "#fff",
        },
        "& .MuiChip-deleteIcon": {
            color: colors.primaryYellow,
        },
    },
})(BasicAutocomplete);

const Autocomplete = (props) => {
    const { functionToChild, formNameToChild, formToChild, ...rest } = props;

    const getOptionSelected =
        props.getOptionSelected === undefined
            ? (option, value) =>
                  option?.value === value?.value ||
                  option?.value === value ||
                  option === value?.value
            : props.getOptionSelected;

    const findLabelByValue = (value) =>
        props.options.find((opt) => opt.value === value)?.label || "";

    const getOptionLabel =
        props?.getOptionLabel !== undefined
            ? props?.getOptionLabel
            : (option) => option?.label || findLabelByValue(option) || "";

    return (
        <CustomAutocomplete
            multiple={props.multiple === undefined ? true : props.multiple}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            ChipProps={{
                name: props.name,
            }}
            id={props.name}
            filterSelectedOptions
            onChange={
                props.multiple
                    ? (_, v) => {
                          functionToChild(
                              {
                                  ...formToChild,
                                  [props.name]: v?.map(
                                      (obj) => obj.value || obj
                                  ),
                              },
                              formNameToChild
                          );
                      }
                    : (_, v) => {
                          functionToChild(
                              { ...formToChild, [props.name]: v?.value || v },
                              formNameToChild
                          );
                      }
            }
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    error={props.error}
                    helperText={props.error && props.helperText}
                    placeholder={props.placeholder}
                    name={props.name}
                />
            )}
            noOptionsText={props.noOptionsText || "Nincs találat"}
            {...rest}
        />
    );
};

export default Autocomplete;
