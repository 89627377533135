export const makeOptionsFromID = (data) => {
    return (data && data['hydra:member'])
        ? data['hydra:member'].map((elem) => {
            return {
                value: elem.id ?? elem["@id"] ,
                label: elem.name,
            };
        })
        : [];
};

export const makeOptionsFromIDWithArchive = (data) => {
    return (data && data['hydra:member'])
        ? data['hydra:member'].map((elem) => {
            return {
                value: elem["@id"] ? elem["@id"] : elem.id,
                label: elem.archive ? `${elem.name} (archív)` : elem.name,
            };
        })
        : [];
};

export const makeTrainingProgramOptionsFromIDWithArchive = (data) => {
    return (data && data['hydra:member'])
        ? data['hydra:member'].map((elem) => {
            const name = elem.trainingLevel ? `${elem.trainingLevel?.name} / ${elem.name}` : elem.name;
            return {
                value: elem["@id"],
                label: elem.archive ? `${name} (archív)` : name,
            };
        })
        : [];
};