import { types } from "./types";

const initialState = {
    filterStorageKey: "masterData.rooms",
    filter: {},
    form: {
        assignable: "1"
    },
    data: [],
    assignableRooms: [],
    assignableRoomsForFilter: [],
}

export function rooms(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                form: initialState.form,
                filter: initialState.filter
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: {
                    ...action.data?.data,
                    assignable: action.data?.data?.assignable ? "1" : "0",
                }
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };
        case types.GET_ASSIGNABLE_ROOMS_REQUEST:
            return {
                ...state
            };
        case types.GET_ASSIGNABLE_ROOMS_SUCCESS:
            return {
                ...state,
                assignableRooms: action.data.data,
            };
        case types.GET_ASSIGNABLE_ROOMS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_REQUEST:
            return {
                ...state
            };
        case types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_SUCCESS:
            return {
                ...state,
                assignableRoomsForFilter: action.data.data,
            };
        case types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default: 
            return {
                ...state
            }
    }
}