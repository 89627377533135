import { connect } from 'react-redux';
import loadingActions from '../../../store/loading/actions';
import studentAllocationPreferenceFormActions from '../../../store/processes/studentAllocationPreferenceForm/actions';
import FormPageTemplate from '../../Common/FormPageTemplate';
import * as yup from "yup";
import Title from '../../Common/Title';
import TextField from '../../Common/TextField';
import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import SubmitButton from '../../Common/SubmitButton';
import CancelButton from '../../Common/CancelButton';
import axios from 'axios';
import { Autocomplete as AutocompleteBasic } from "@material-ui/lab";
import { history } from '../../../store';

function StudentAllocationPreferenceForm(props) {
    const [searchRoommateLoading, setSearchRoommateLoading] = useState(false);
    const [roommateOptions, setRoommateOptions] = useState([]);
    const { form } = props;
    const id = props.match?.params?.id || null;
    const user = localStorage.getItem("user") || "";
    const userJSON = JSON.parse(user);
    const campus = userJSON.campus;

    const header = {
        title: "Szobatárspreferencia űrlap",
        breadcrumbs: {
            "processes": "Folyamatok",
            "room-allocation": "Szobatárspreferencia űrlap"
        }
    }

    useEffect(() => {
        props.defaultForm("form");
        props.loadingOn();
        axios.all([
            props.getStudentAllocationPreference(id),
        ])
        .then(() => props.loadingOff())
        .catch(() => props.loadingOff());
    }, []);

    const validationSchema = yup.object().shape({
        //TODO
    })

    const formProps = {
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        onsubmit: () => props.fillStudentAllocationPreferenceForm(form, id, "/"),
        isformikform: true,
    }

    const searchRoommate = (val) => {
        setSearchRoommateLoading(true);
        props.autocompleteFilter({ fullNameAndEmail: val }, id).then((response) => {
            setRoommateOptions(
                response.data["hydra:member"]
                    .map((val) => {
                        return {
                            value: val.id,
                            name: val.user.fullName,
                            key: val.id,
                        };
                    })
                    .sort((a, b) =>
                        a.name < b.name ? -1 : a.name === b.name ? 0 : 1
                    )
            );
            setSearchRoommateLoading(false);
        });
    };

    return (
        <FormPageTemplate header={{...header}} form={formProps}>
            <Title 
                title="Név"
                {...formProps}
                format={{xs: 12}}
            >
                <TextField
                    label="Vezetéknév"
                    name="student.user.lastName"
                    value={form.student?.user?.lastName || ""}
                    format={{xs: 12, md: 6}}
                    disabled
                />
                <TextField
                    label="Keresztnév"
                    name="student.user.firstName"
                    value={form.student?.user?.firstName || ""}
                    format={{xs: 12, md: 6}}
                    disabled
                />
            </Title>
            <Title
                title="Előző szoba"
                {...formProps}
                format={{xs: 12}}
            >
                <TextField 
                    name="campus"
                    value={form?.room?.floor?.building?.campus?.name || campus?.name || ""}
                    label="Campus"
                    format={{xs: 12, md: 6}}
                    disabled
                />
                <TextField 
                    name="building"
                    value={form?.room?.floor?.building?.name || ""}
                    label="Épület"
                    format={{xs: 12, md: 6}}
                    disabled
                />
                <TextField 
                    name="floor"
                    value={form?.room?.floor?.name || ""}
                    label="Szint"
                    format={{xs: 12, md: 6}}
                    disabled
                />
                <TextField 
                    name="room"
                    value={form?.room?.name || ""}
                    label="Szoba"
                    format={{xs: 12, md: 6}}
                    disabled
                />
            </Title>
            <Title
                title="Szobatárs"
                {...formProps}
                format={{xs: 12}}
            >
                <AutocompleteBasic
                    id="roomMates"
                    value={form.roomMates || ""}
                    options={roommateOptions}
                    getOptionLabel={(option) => option?.name || ""}
                    loading={searchRoommateLoading}
                    getOptionSelected={(option, value) =>
                        option.key === value.key
                    }
                    filterSelectedOptions
                    loadingText={`Betöltés...`}
                    onInputChange={(event, val, reason) => {
                        if (reason === "clear") {
                            props.changeForm(
                                { ...form, roomMates: {} },
                                "form"
                            );
                            setRoommateOptions([]);
                        }
                        if (val.length < 2) {
                            setRoommateOptions([]);
                        }
                        if (val.length > 2) {
                            searchRoommate(val);
                        }
                    }}
                    onChange={(event, values) => {
                        if (values) {
                            props.changeForm(
                                {
                                    ...form,
                                    roomMates: {
                                        value: values.value,
                                        name: values.name,
                                        key: values.key,
                                    },
                                },
                                "form"
                            );
                        }
                    }}
                    format={{ xs: 12 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Szobatárs"
                        />
                    )}
                    noOptionsText={"Nincs találat"}
                />
            </Title>
            <Title
                title="Megjegyzés"
                {...formProps}
                format={{xs: 12}}
            >
                <TextField
                    name="comment"
                    value={form.comment || ""}
                    format={{xs: 12}}
                />
            </Title>
            <Grid container format={{xs: 12}} spacing={1}>
                <Grid item>
                    <SubmitButton
                        type="submit"
                    >
                        Mentés
                    </SubmitButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={() => history.push("/")}>
                        Mégsem
                    </CancelButton>
                </Grid>
            </Grid>
        </FormPageTemplate>
    )
}

function mapState(state) {
    const { form, preferableRoommates } = state.studentAllocationPreferenceForm;
    return { form, preferableRoommates };
}

const actionCreators = {
    changeForm: studentAllocationPreferenceFormActions.changeForm,
    defaultForm: studentAllocationPreferenceFormActions.defaultForm,
    getStudentAllocationPreference: studentAllocationPreferenceFormActions.get,
    autocompleteFilter: studentAllocationPreferenceFormActions.autocompleteFilter,
    fillStudentAllocationPreferenceForm: studentAllocationPreferenceFormActions.fillForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
}

export default connect(mapState, actionCreators)(StudentAllocationPreferenceForm);