import { withStyles } from '@material-ui/core/styles';
import TooltipBasic from '@material-ui/core/Tooltip';
import colors from '../../styles/colors.js';

const Tooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: colors.white,
      color: colors.primaryBlue,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))(TooltipBasic);

export default Tooltip;