import { withStyles } from "@material-ui/core/styles";
import TooltipBasic from "@material-ui/core/Tooltip";
import colors from "../../styles/colors.js";

const LastRoomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: colors.white,
        color: colors.black,
        fontSize: theme.typography.pxToRem(18),
        border: "1px solid #dadde9",
    },
}))(TooltipBasic);

export default LastRoomTooltip;
