export const types = {
    CHANGE_FORM: "@@enrollment/CHANGE_FORM",
    DEFAULT_FORM: "@@enrollment/DEFAULT_FORM",
    CHANGE_EDITOR: "@@enrollment/CHANGE_EDITOR",
    CHANGE_ACTIVE_CLOSE_TAB: "@@enrollment/CHANGE_ACTIVE_CLOSE_TAB",

    FETCH_REQUEST: "@@enrollment/FETCH_REQUEST",
    FETCH_SUCCESS: "@@enrollment/FETCH_SUCCESS",
    FETCH_FAILURE: "@@enrollment/FETCH_FAILURE",

    GET_REQUEST: "@@enrollment/GET_REQUEST",
    GET_SUCCESS: "@@enrollment/GET_SUCCESS",
    GET_FAILURE: "@@enrollment/GET_FAILURE",

    START_REQUEST: "@@enrollment/START_REQUEST",
    START_SUCCESS: "@@enrollment/START_SUCCESS",
    START_FAILURE: "@@enrollment/START_FAILURE",

    FILL_STUDENT_FORM_REQUEST: "@@enrollment/FILL_STUDENT_FORM_REQUEST",
    FILL_STUDENT_FORM_SUCCESS: "@@enrollment/FILL_STUDENT_FORM_SUCCESS",
    FILL_STUDENT_FORM_FAILURE: "@@enrollment/FILL_STUDENT_FORM_FAILURE",

    FILTER_ACTIVE_REQUEST: "@@enrollment/FILTER_ACTIVE_REQUEST",
    FILTER_ACTIVE_SUCCESS: "@@enrollment/FILTER_ACTIVE_SUCCESS",
    FILTER_ACTIVE_FAILURE: "@@enrollment/FILTER_ACTIVE_FAILURE",

    FILTER_DONE_REQUEST: "@@enrollment/FILTER_DONE_REQUEST",
    FILTER_DONE_SUCCESS: "@@enrollment/FILTER_DONE_SUCCESS",
    FILTER_DONE_FAILURE: "@@enrollment/FILTER_DONE_FAILURE",
    
    GET_DATA_REQUEST: "@@enrollment/GET_DATA_REQUEST",
    GET_DATA_SUCCESS: "@@enrollment/GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "@@enrollment/GET_DATA_FAILURE",

    GET_ENROLLMENT_HISTORY_REQUEST: "@@enrollment/GET_ENROLLMENT_HISTORY_REQUEST",
    GET_ENROLLMENT_HISTORY_SUCCESS: "@@enrollment/GET_ENROLLMENT_HISTORY_SUCCESS",
    GET_ENROLLMENT_HISTORY_FAILURE: "@@enrollment/GET_ENROLLMENT_HISTORY_FAILURE",
    
    DELETE_REQUEST: "@@enrollment/DELETE_REQUEST",
    DELETE_SUCCESS: "@@enrollment/DELETE_SUCCESS",
    DELETE_FAILURE: "@@enrollment/DELETE_FAILURE",

    FETCH_SET_ACTIVE_INFO: "@@enrollment/FETCH_SET_ACTIVE_INFO",
    FETCH_SET_CLOSED_INFO: "@@enrollment/FETCH_SET_CLOSED_INFO",
    
    GET_STUDENT_ONGOING_REQUEST: "@@enrollment/GET_STUDENT_ONGOING_REQUEST",
    GET_STUDENT_ONGOING_SUCCESS: "@@enrollment/GET_STUDENT_ONGOING_SUCCESS",
    GET_STUDENT_ONGOING_FAILURE: "@@enrollment/GET_STUDENT_ONGOING_FAILURE",

    GET_STUDENT_DONE_REQUEST: "@@enrollment/GET_STUDENT_DONE_REQUEST",
    GET_STUDENT_DONE_SUCCESS: "@@enrollment/GET_STUDENT_DONE_SUCCESS",
    GET_STUDENT_DONE_FAILURE: "@@enrollment/GET_STUDENT_DONE_FAILURE",

    GET_FORM_REQUEST: "@@enrollment/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@enrollment/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@enrollment/GET_FORM_FAILURE",

    GET_ACTUAL_UNIVERSITY_STUDIES_REQUEST: "@@enrollment/GET_ACTUAL_UNIVERSITY_STUDIES_REQUEST",
    GET_ACTUAL_UNIVERSITY_STUDIES_SUCCESS: "@@enrollment/GET_ACTUAL_UNIVERSITY_STUDIES_SUCCESS",
    GET_ACTUAL_UNIVERSITY_STUDIES_FAILURE: "@@enrollment/GET_ACTUAL_UNIVERSITY_STUDIES_FAILURE",
}