import { makeStyles } from "@material-ui/core";
import colors from "../../../styles/colors";

export const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    reminder: {
        backgroundColor: colors.reminder,
        "&:hover": {
            backgroundColor: colors.reminderHover,
        },
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 0px",
        fontWeight: "bold",
    },
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    title: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
    },
    smallTitle: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue,
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

export const reasonsOfTerminationOptions = [
    {
        value: "temporary",
        label: "Ideiglenes (ösztöndíj, nyári kiköltözés időtartamára)",
    },
    {
        value: "external",
        label: "Külsősként folytatom tanulmányaimat",
    },
    {
        value: "permanent",
        label: "Végleg megszüntetem MCC-s tagságomat",
    },
];

export const donatingDepositOptions = [
    {
        value: "true",
        label: "Kauciómat felajánlom az alapítvány számára",
    },
    {
        value: "false",
        label: "Kauciómat nem kívánom felajánlani",
    },
];
