import LastRoomTooltip from "../../Common/LastRoomTooltip";
import Info from "../../Icons/Info";

function LastRoom(props){
    const tooltipDiv = <div>
        <strong>Előző épület: </strong>{`${props?.[0]?.previousBuildingName || "-"}`}<br/>
        <strong>Előző szint: </strong>{`${props?.[0]?.previousFloorName || "-"}`} <br/>
        <strong>Előző szoba: </strong>{`${props?.[0]?.previousRoomName || "-"}`} <br/>
        <strong>Előző szobatárs(ak): </strong>{`${props?.[0]?.previousRoommatesName ||  "-"}`} <br/>
    </div>

    return (
        <LastRoomTooltip title={tooltipDiv}>
            <div 
                style={{ 
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            >
                <Info style={{marginLeft: "4px"}}/>
            </div>
        </LastRoomTooltip>
    );
}

export default LastRoom;