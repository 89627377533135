import {
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import colors from '../../styles/colors';

const useStyle = makeStyles(() => ({
    title: {
        fontSize: 'large',
        borderBottom: '1px solid ' + colors.primaryBlue,
        fontWeight: 'bold',
        color: colors.primaryBlue,
        marginBottom: '8px',
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
    },
}));

export default function RawTitle(props) {
    const classes = useStyle();

    return (
        <Grid item xs={12}>
            <Typography className={classes.title} format={{ xs: 12 }}>
                {props.title}
            </Typography>
            {props.children}
        </Grid>
    );
}