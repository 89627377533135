import { templateChangeForm, templateDefaultForm } from "../../..";
import studentAllocationPreferenceService from "../../../../services/processes/studentAllocationPreference";
import notificationTypes from "../../../notification/types";
import { types } from "./types";

const changeEditor = content => dispatch => dispatch({ type: types.CHANGE_EDITOR, content });

function startStudentAllocationPreference(form) {
    const students = form.students?.map(student => {
        return parseInt(student.split("/")?.pop());
    });

    const formToSend = {
        students: students,
        emailSubject: form.emailSubject,
        emailContent: form.emailContent
    };

    return (dispatch) => {
        dispatch({ type: types.START_REQUEST, formToSend });
        return studentAllocationPreferenceService.startStudentAllocationPreference(formToSend).then(
            (data) => {
                dispatch({ type: types.START_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés" }
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "error", msg: "Hiba lépett fel küldés közben" }
                })
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    startStudentAllocationPreference
}

export default actions;