import { Typography } from '@material-ui/core';
import { history } from '../../store';
import OffLayoutTemplate from '../Common/OffLayoutTemplate';
import SubmitButton from '../Common/SubmitButton';
import loadingActions from '../../store/loading/actions';
import loginService from '../../services/login';
import { connect } from 'react-redux';
import { useEffect } from 'react';

function ErrorPageTemplate(props) {
    const { code, description } = props;

    useEffect(() => {
        props.loadingOff();
    }, []);

    return (
        <OffLayoutTemplate>
            <Typography
                align="center"
                variant="h1"
            >
                {code}
            </Typography>
            <Typography
                align="center"
                variant="h4"
            >
                {description}
            </Typography>
            <SubmitButton
                fullWidth
                style={{ marginTop: '10%', height: 56 }}
                onClick={() => history.push('/')}
            >
                Vissza a főoldalra
            </SubmitButton>
            <SubmitButton
                fullWidth
                style={{ marginTop: '10%', height: 56 }}
                onClick={() => loginService.logout()}
            >
                Kijelentkezés
            </SubmitButton>
        </OffLayoutTemplate>
    );
}

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(null, actionCreators)(ErrorPageTemplate);