import {
    faBed,
    faBuilding,
    faSignInAlt,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import StyledTooltip from "../../Common/Tooltip";
import semestersActions from "./../../../store/masterData/semesters/actions";
import newProcessActions from "./../../../store/processes/newProcess/actions";
import Accordion from "./../../Common/Accordion";
import NavMenuItem from "./../../Common/NavMenuItem";
import PageTemplate from "./../../Common/PageTemplate";

function NewProcessMenuItem({ to, icon, text, isActive }) {
    return isActive ? (
        <NavMenuItem to={to} icon={icon} text={text} />
    ) : (
        <StyledTooltip title="Nincs aktív szemeszter!">
            <div>
                <NavMenuItem to={to} icon={icon} text={text} disabled />
            </div>
        </StyledTooltip>
    );
}

const header = {
    title: "Új folyamat",
    breadcrumbs: {
        processes: "Folyamatok",
        newProcess: "Új folyamat",
    },
};

function NewProcess(props) {
    const {
        activeSemesterForEnrollment,
        activeSemesterForTermination,
        activeSemesterForMoveOut,
        activeSemesterForRoomAllocation,
    } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                props.getActiveSemesterForEnrollment(),
                props.getActiveSemesterForTermination(),
                props.getActiveSemesterForMoveOut(),
                props.getActiveSemesterForRoomAllocation(),
                props.getActiveSemesterForRelocation(),
            ])
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="Új folyamat" expanded>
                    <NewProcessMenuItem
                        to="/new-process/enrollment"
                        icon={faSignInAlt}
                        text="Beiratkozás"
                        isActive={activeSemesterForEnrollment}
                    />
                    <NewProcessMenuItem
                        to="/new-process/termination"
                        icon={faSignOutAlt}
                        text="Kiiratkozás"
                        isActive={activeSemesterForTermination}
                    />
                    <NewProcessMenuItem
                        to="/new-process/move-out"
                        icon={faSignOutAlt}
                        text="Kiköltözés"
                        isActive={activeSemesterForMoveOut}
                    />
                    <NewProcessMenuItem
                        to="/new-process/student-allocation-preference"
                        icon={faBuilding}
                        text="Szobabeosztás"
                        isActive={activeSemesterForRoomAllocation}
                    />
                    <NewProcessMenuItem
                        to="/new-process/relocation"
                        icon={faBed}
                        text="Átköltözés"
                        isActive={true}
                    />
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.newProcess;
    const activeSemesterForEnrollment =
        state.semesters.activeSemesterForEnrollment;
    const activeSemesterForTermination =
        state.semesters.activeSemesterForTermination;
    const activeSemesterForMoveOut = state.semesters.activeSemesterForMoveOut;
    const activeSemesterForRoomAllocation =
        state.semesters.activeSemesterForRoomAllocation;
    const activeSemesterForRelocation =
        state.semesters.activeSemesterForRelocation;
    return {
        form,
        activeSemesterForEnrollment,
        activeSemesterForTermination,
        activeSemesterForMoveOut,
        activeSemesterForRoomAllocation,
        activeSemesterForRelocation,
    };
}

const actionCreators = {
    changeForm: newProcessActions.changeForm,
    defaultForm: newProcessActions.defaultForm,
    getActiveSemesterForEnrollment:
        semestersActions.getActiveSemesterForEnrollment,
    getActiveSemesterForTermination:
        semestersActions.getActiveSemesterForTermination,
    getActiveSemesterForMoveOut: semestersActions.getActiveSemesterForMoveOut,
    getActiveSemesterForRoomAllocation:
        semestersActions.getActiveSemesterForRoomAllocation,
    getActiveSemesterForRelocation:
        semestersActions.getActiveSemesterForRelocation,
};

export default connect(mapState, actionCreators)(NewProcess);
