import PageTemplate from './PageTemplate';
import { Grid } from '@material-ui/core';
import Accordion from './Accordion';
import Form from './Form';

/**
 * 
 * @param {object} header - Header-nek átadandó objektum 
 * @param {object} form - Formnak átadandó objektum
 */
export default function FormPageTemplate(props) {
    return(
        <PageTemplate header={props.header}>
            <Grid item xs={props.xs ?? 12} sm={props.sm ?? 10} md={props.md ?? 8} lg={props.lg ?? 6}>
                <Accordion title={props.header?.title || ""} expanded onEditClick={props.onEditClick}>
                    <Form {...props.form}>
                        {props.children}
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}