import {AppBar, makeStyles, Tab, useMediaQuery, useTheme} from '@material-ui/core';
import React, {Children} from 'react';
import TabPanel from './TabPanel';
import Tabs from './Tabs';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

/**
 *
 * @param tabContents - objektumok tömbje, benne az egyes tabok megnevezésével (label) és
 * tartalmazott komponensével (value).
 */

export default function TabsCompleted(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.currentTab ?? 0);
    const th = useTheme();
    const matches = useMediaQuery(th.breakpoints.up('sm'));
    const changeTab = (tab) => {
        setValue(tab);
        props.onChange?.(tab);
    }
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} orientation={matches ? "horizontal" : "vertical"} onChange={(e, v) => changeTab(v)}>
                    {Children.toArray(props.tabContents?.map(e => <Tab label={e.label}/>))}
                </Tabs>
            </AppBar>
            {Children.toArray(props.tabContents?.map((e, i) => <TabPanel value={value} index={i}>{e.value}</TabPanel>))}
        </div>
    );
}