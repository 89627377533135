import { getFinancingFormLabelByValue } from "../Components/Administration/Forms/Enrollment/options";
import {
    createAddressStringFromObject,
    getDocumentField,
    getStudentStatusLabelByValue,
    getTrainingFormLabelByValue,
} from "./AppConst";
import { membershipStatusLabel } from "./cellRenderer/MemberShipStatusCellRenderer";
import { semesterStatusLabel } from "./cellRenderer/SemesterStatusCellRenderer";

export const defaultFieldsForEnrollmentPrint = [
    { label: "Típus", field: "type", disabled: true },
    { label: "Vezetéknév", field: "lastName" },
    { label: "Keresztnév", field: "firstName" },
    { label: "Képzési szint", field: "trainingLevel" },
    { label: "Képzési program", field: "trainingProgram" },
    { label: "Évfolyam", field: "collegeYear" },
    { label: "Státusz", field: "membershipStatus" },
    { label: "Aktuális státusz", field: "currentMembershipStatus" },
    { label: "Szemeszter státusz", field: "semesterStatus" },
    { label: "Oktatási azonosító", field: "omId" },
    { label: "Adóazonosító jel", field: "taxId" },
    { label: "Személyi szám (CNP)", field: "identificationNumberCnp" },
    { label: "Campus", field: "campus" },
    { label: "Állandó lakcím", field: "address" },
    { label: "Anyja vezetékneve", field: "motherLastName" },
    { label: "Anyja keresztneve", field: "motherFirstName" },
    { label: "Születési hely", field: "birthPlace" },
    { label: "Születési idő", field: "birthDate" },
    { label: "SZIG szám", field: "idCardNumber" },
    { label: "SZIG szám - KEP", field: "kepIdCardNumber" },
    { label: "Pénzintézet neve", field: "bankName" },
    { label: "Bankszámlaszám", field: "bankAccountNumber" },
    { label: "Mobil", field: "phoneNumber" },
    { label: "E-mail", field: "email", disabled: true },
    { label: "TAJ szám", field: "taj"},
    { label: "Állampolgárság", field: "nationality"},
    {
        label: "Elfogadott dokumentumok",
        field: "consentedDocuments",
        disabled: true,
    },
];

const makeValue = (form, field) => {
    if (field === "address") {
        return createAddressStringFromObject(form[field]);
    } else if (field === "membershipStatus" ) {
        return membershipStatusLabel(form[field]);
    } else if (field === "currentMembershipStatus") {
        return membershipStatusLabel(form[field]);
    } else if (field === "semesterStatus") {
        return semesterStatusLabel(form[field]);
    } else if (field === "consentedDocuments") {
        return form[field]?.map((element) => getDocumentField(element, form)).join(', ') ?? '';
    } else if (field === "nationality") {
        if (Array.isArray(form[field])) {
            return form[field].join(', ');
        }
    }

    if (typeof form[field] != "string") {
        return form[field]?.name;
    }

    return form[field];
}

/**
 * A function that returns the fields for the forms based on the university type.
 * We start with the default fields.
 * If the university is foreign
 * (university?.id === 1326 || university?.name === 'Külföldi képzés) || university === 1326)
 * then we add the field { label: "Külföldi egyetem neve", value: "foreignUniversity" } to the fields
 * right after the field { label: "Egyetem", value: "university" }
 */
export const fieldsForPrint = (form) => {
    let fields = defaultFieldsForEnrollmentPrint;
    const isUniversityForeign = form.foreignUniversity;

    if (
        fields.findIndex((field) => field.value === "foreignUniversity") !== -1
    ) {
        return fields;
    }

    if (isUniversityForeign) {
        fields.splice(
            fields.findIndex((field) => field.value === "university") + 1,
            0,
            {
                label: "Külföldi egyetem neve",
                value: "foreignUniversity",
            }
        );
    }

    const madeFields = fields
    .filter(element => Object.prototype.hasOwnProperty.call(form, element.field))
    .map((element) => {
        const value = makeValue(form, element.field);
        return ({...element, value: value});
    });

    return madeFields;
};

export const fieldsForPrintAus = (form) => {

    const transformAus = (form) => {
        let transformedList = [];

        form.actualUniversityStudies?.forEach((item, index) => {
            if (index <= 3) {
                transformedList.push({label: 'Egyetem ' + (index + 1), value: item.university.name});
                transformedList.push({label: 'Szak', value: item.major});
                transformedList.push({label: 'Képzés formája', value: getTrainingFormLabelByValue(item.trainingForm)});
                transformedList.push({label: 'Finanszírozás formája', value: getFinancingFormLabelByValue(item.financeForm)});
                transformedList.push({label: 'Hallgatói jogviszony', value: getStudentStatusLabelByValue(item.semesterStatus)});
            }
        })

        return transformedList;
    }

    return transformAus(form);
}
