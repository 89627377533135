import { FormControlLabel } from '@material-ui/core';
import CheckboxBasic from '@material-ui/core/Checkbox';
import React from 'react';
import colors from '../../styles/colors';


export default function Checkbox(props) {
    const {option, checked} = props;

    return(
        <FormControlLabel
            control={
                <CheckboxBasic 
                    name={option.name} 
                    checked={checked}
                    style={{
                        color: colors.primaryYellow
                    }}
                    {...props}
                />
            }
            label={option.label}
        />
    );
}