import {
    history,
    templateChangeForm,
    templateDefaultForm,
    templateGet,
} from "../../..";
import terminationsService from "../../../../services/processes/termination";
import { types } from "./types";
import notificationTypes from "../../../notification/types";

function fillStudentDeclaration(form, id, url) {
    const donatingDeposit = form.donatingDeposit !== "false";

    let consentToUseData = null;

    switch (form.consentToUseData) {
        case "true":
            consentToUseData = true;
            break;
        case "false":
            consentToUseData = false;
            break;
        default:
            break;
    }

    const formToSend = {
        terminationId: Number(id),
        reasonOfTermination: form.reasonOfTermination,
        terminationDate:
            form.terminationDate?.split("T")?.[0] ||
            new Date().toISOString().slice(0, 10).split("T")[0],
        donatingDeposit: donatingDeposit,
        consentToUseData: donatingDeposit ? consentToUseData : null,
        nameForDepositRefund: !donatingDeposit
            ? form.nameForDepositRefund
            : null,
        bankAccountNumberForDepositRefund: !donatingDeposit
            ? form.bankAccountNumberForDepositRefund?.replace(/[-\s]/g, "")
            : null,
    };

    return (dispatch) => {
        dispatch({ type: types.FILL_STUDENT_DECLARATION_REQUEST, formToSend });
        return terminationsService.fillStudentDeclaration(formToSend, id).then(
            (data) => {
                dispatch({
                    type: types.FILL_STUDENT_DECLARATION_SUCCESS,
                    data,
                });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés!" },
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FILL_STUDENT_DECLARATION_FAILURE,
                    error,
                });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel küldés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return terminationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    fillStudentDeclaration,
    get: templateGet(types, terminationsService),
    getForm
};

export default actions;
