import Dialog from "../../../Common/Dialog";
import SubmitButton from "../../../Common/SubmitButton";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField";
import OperationButton from "../../../Common/OperationButton";
import roomAllocationListActions from "../../../../store/processes/roomAllocationList/actions";
import loadingActions from "../../../../store/loading/actions";
import { makeBuildingOptions, makeFloorOptions, makeRoomOptions } from "../options";
import { connect } from "react-redux";
import Select from "../../../Common/Select";
import axios from "axios";

/**
 * TODO: 
 * 1.) az épület - szint - szoba itt select mezők kellenének, hogy legyenek
 * 2.) A mentés gombra kattintva a szobarendezés történjen meg
 */
function EditRoomDialog(props) {
    const { editForm, editFormInfo, campus, buildings, floors, rooms, allocationId} = props;

    const handleOpenDialog = () => {
        props.changeForm({
            ...editForm,
            fullName: editFormInfo.fullName,
            campus: campus,
            studentAllocation: editFormInfo.studentAllocationId,
            building: editFormInfo.buildingId,
            floor: editFormInfo.floorId,
            room: editFormInfo.roomId
        }, "editForm");
    }

    const handleSubmit = () => {
        props.assignAllocation(allocationId, editForm)
            .then(() => {
                props.loadingOn();
                axios.all([
                    props.filterStudentAllocations(allocationId, {}),
                    props.filterFreeRooms(allocationId, {}),
                ])
                
                    .then(() => props.loadingOff())
                    .finally(() => props.loadingOff())
            });
    }

    const formProps = {
        form: editForm,
        name: "editForm",
        changeform: props.changeForm,
        defaultForm: props.defaultForm,
        initialerrors: editForm['errors'] || {},
        initialvalues: editForm,
        onsubmit: handleSubmit,
        isformikform: true,
    }

    return (
        <Dialog
            opener={
                <OperationButton
                    type="edit"
                    to={'#'}
                    onClick={handleOpenDialog}
                />
            }
            title={"Hallgató szobaadatainak módosítása"}
            action={
                <SubmitButton onClick={handleSubmit}>
                    Mentés
                </SubmitButton>
            }
            cancelText="Mégsem"
        >
            <Form {...formProps}>
                <TextField
                    label="Név"
                    name="fullName"
                    value={editForm.fullName || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
                <Select
                    name="building"
                    value={editForm.building?.["@id"] || editForm.building || ""}
                    selectLabel="Épület"
                    optionList={makeBuildingOptions(buildings['hydra:member'], campus)}
                    format={{ xs: 12 }}
                />
                <Select
                    name="floor"
                    value={editForm.floor?.["@id"] || editForm.floor || ""}
                    selectLabel="Szint"
                    optionList={makeFloorOptions(floors['hydra:member'], editForm.building)}
                    format={{ xs: 12 }}
                />
                <Select
                    name="room"
                    value={editForm.room?.["@id"] || editForm.room || ""}
                    selectLabel="Szoba"
                    optionList={makeRoomOptions(rooms['hydra:member'], editForm.floor)}
                    format={{ xs: 12 }}
                />
            </Form>
        </Dialog>
    )
}

function mapState(state) {
    const { editForm } = state.roomAllocationList;
    const studentFilter = state.studentAllocationPreferenceList.filter;
    const roomFilter = state.roomAllocationList.filter;
    const buildings = state.buildings.data;
    const floors = state.floors.data;
    const rooms = state.rooms.assignableRooms;
    return { editForm, studentFilter, roomFilter, buildings, floors, rooms };
}

const actionCreators = {
    changeForm: roomAllocationListActions.changeForm,
    defaultForm: roomAllocationListActions.defaultForm,
    filterStudentAllocations: roomAllocationListActions.filterStudentAllocations,
    filterFreeRooms: roomAllocationListActions.getFreeRooms,
    assignAllocation: roomAllocationListActions.assignAllocation,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff
};

export default connect(mapState, actionCreators)(EditRoomDialog);