import { templateChangeForm, templateDefaultForm, templateGet } from "../../..";
import relocationsService from "../../../../services/processes/relocation";
import { types } from "./types";
import notificationTypes from "../../../notification/types";
import { history } from "../../../../store";

function approveFinancialCheck(form, id, url) {
    const formToSend = {
        relocation: Number(id),
        financialComment: form.financialComment
    }

    return (dispatch) => {
        dispatch({ type: types.APPROVE_FINANCIAL_CHECK_REQUEST, payload: { formToSend, id } });
        return relocationsService.approveFinancialCheck(formToSend, id).then(
            (data) => {
                dispatch({ type: types.APPROVE_FINANCIAL_CHECK_SUCCESS, payload: { data, id } });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres jóváhagyás"}
                });
                dispatch({ type: types.DEFAULT_FORM });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.APPROVE_FINANCIAL_CHECK_FAILURE, error });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "error", msg: "Hiba lépett fel jóváhagyás közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

function updateFinancialComment(form, id) {
    const formToSend = {
        relocation: Number(id),
        financialComment: form.financialComment
    }

    return (dispatch) => {
        dispatch({ type: types.UPDATE_FINANCIAL_COMMENT_REQUEST, payload: { formToSend, id } });
        return relocationsService.updateFinancialComment(formToSend, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_FINANCIAL_COMMENT_SUCCESS, payload: { data, id } });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FINANCIAL_COMMENT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function sendUnpaidDebtNotification(relocationId, form) {
    const formToSend = {
        relocation: Number(relocationId),
        emailContent: form.emailContent,
        emailSubject: form.emailSubject
    }

    return (dispatch) => {
        dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_REQUEST, payload: { relocationId, formToSend } });
        return relocationsService.sendUnpaidDebtNotification(relocationId, formToSend).then(
            (data) => {
                dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS, data});
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres küldés"}
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEND_UNPAID_DEBT_NOTIFICATION_FAILURE, error });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "error", msg: "Hiba lépett fel küldés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

const changeEditor = content => dispatch => dispatch({type: types.CHANGE_EDITOR, content});

function approveUnpaidDebt(form, id, url) {
    const formToSend = {
        relocation: Number(id),
    }

    return (dispatch) => {
        dispatch({ type: types.APPROVE_UNPAID_DEBT_REQUEST, formToSend});
        return relocationsService.approveUnpaidDebt(id, formToSend).then(
            (data) => {
                dispatch({ type: types.APPROVE_UNPAID_DEBT_SUCCESS, data });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres mentés!"}
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.APPROVE_UNPAID_DEBT_FAILURE, error });
                dispatch({ 
                  type: notificationTypes.ADD_NOTIFICATION,
                  data: {type: "error", msg: "Hiba lépett fel mentés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return relocationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    approveFinancialCheck,
    approveUnpaidDebt,
    changeForm: templateChangeForm(types),
    changeEditor,
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, relocationsService),
    getForm,
    sendUnpaidDebtNotification,
    updateFinancialComment
}

export default actions;