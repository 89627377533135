import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Accordion from "../../../Common/Accordion";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField"
import Title from "../../../Common/Title";
import {semesterStatus} from '../../../../utils/AppConst';
import { membershipStatusLabel } from '../../../../utils/cellRenderer/MemberShipStatusCellRenderer';

function Small(props) {
  const { form } = props;

  return (
      <Grid item>
        <Accordion title="Űrlap" expanded>
          <Form>
            <Title title="Típus" format={{xs: 6}}>
              <TextField
                  value={form["type"] === "enrollment" ? "Beiratkozás" : "Kiiratkozás" || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            <Title title="Szemeszter" format={{xs: 6}}>
              <TextField
                  value={form?.semester?.name || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            <Title title="Név" format={{xs: 12}}>
              <TextField
                  label="Vezetéknév"
                  value={form?.lastName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Keresztnév"
                  value={form?.firstName || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            <Title title="E-mail cím" format={{xs: 12}}>
              <TextField
                  value={form.email || ""}
                  format={{xs: 12}}
                  disabled
              />
            </Title>
            <Title title="Képzés" format={{xs: 12}}>
              <TextField
                  label="Képzési szint"
                  value={form.trainingLevel?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Képzési program"
                  value={form.trainingProgram?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Évfolyam"
                  value={form.collegeYear?.name || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Státusz"
                  value={membershipStatusLabel(form.membershipStatus || "")}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Aktuális státusz"
                  value={membershipStatusLabel(form.currentMembershipStatus || "")}
                  format={{xs: 12, md: 6}}
                  disabled
              />
              <TextField
                  label="Szemeszter státusz"
                  value={semesterStatus(form.semesterStatus) || ""}
                  format={{xs: 12, md: 6}}
                  disabled
              />
            </Title>
            <Title title="Campus" format={{xs: 12}}>
              <TextField
                  label="Campus"
                  value={form?.campus?.name || ""}
                  format={{xs: 3}}
                  disabled
              />

              <TextField
                  label="Épület"
                  value={form?.building?.name || ""}
                  format={{xs: 3}}
                  disabled
              />
              <TextField
                  label="Szint"
                  value={form?.floor?.name || ""}
                  format={{xs: 3}}
                  disabled
              />
              <TextField
                  label="Szoba"
                  value={form?.room?.name || ""}
                  format={{xs: 3}}
                  disabled
              />
            </Title>
          </Form>
        </Accordion>
      </Grid>
  );
}

export default connect(null, null)(Small);