import { Grid, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import usersActions from "../../../store/masterData/users/actions";
import campusesActions from "../../../store/masterData/campuses/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import Accordion from "../../Common/Accordion";
import PageTemplate from "../../Common/PageTemplate";
import TextField from "../../Common/TextField";
import Form from "../../Common/Form";
import Select from "../../Common/Select";
import CancelButton from "../../Common/CancelButton";
import { history } from "../../../store";
import { useEffect, useState } from "react";
import { makeOptionsFromID } from "../../../utils/listOptions/makeOptionsFromID";
import * as yup from "yup";
import { rolesOptions } from "../../../utils/AppConst";

const useStyles = makeStyles(() => ({
    title: {
        fontStyle: "italic",
        padding: "8px",
        fontSize: "17px"
    },
}));

function UsersForm(props) {
    const { form, id, campuses } = props;
    const title = "Felhasználó megtekintése";
    const isNew = id === undefined;
    const [firstLoad, setFirstLoad] = useState(true);
    const classes = useStyles();

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/users": "Felhasználók",
            "usersView": title,
            "fullName": form && form.lastName && form.firstName ? `${form.lastName} ${form.firstName}` : ''
        }
    }

    useEffect(() => {
        if (campuses.length <= 0) {
            props.loadingOn();
            props.fetchCampuses().then(() => props.loadingOff());
        }


        props.loadingOn();
        props.get(id).then(() => {
            setFirstLoad(false);
            props.loadingOff();
        });

        return props.defaultForm;
    }, []);

    useEffect(() => {
        if (!firstLoad && form.campus) {
            props.changeForm({ ...form, campus: form.campus["@id"] }, "form");
        }
    }, [firstLoad])

    const validationSchema = yup.object().shape({
        email: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        lastName: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        firstName: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
        campus: yup.mixed().required("Kötelező megadni")
    })

    const formProps = {
        changeform: props.changeForm,
        form: form,
        name: "form",
        initialvalues: form,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
        isformikform: true,
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        {isNew &&
                            <Typography format={{ xs: 12 }} className={classes.title}>
                                A felhasználó létrehozását követően a “Jogosultságok” menüpont alatt tudsz jogosultságot hozzárendelni a felhasználóhoz!
                            </Typography>
                        }
                        <TextField
                            label="Vezetéknév"
                            name="lastName"
                            value={form.lastName || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="Keresztnév"
                            name="firstName"
                            value={form.firstName || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <TextField
                            label="E-mail cím"
                            name="email"
                            value={form.email || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <Select
                            selectLabel="Jogosultság"
                            name="roles"
                            value={form.roles || []}
                            optionList={rolesOptions}
                            format={{ xs: 12, md: 6 }}
                            multiple
                            disabled={true}
                        />
                        <Select
                            selectLabel="Campus"
                            name="campus"
                            value={form.campus || ""}
                            optionList={makeOptionsFromID(campuses)}
                            format={{ xs: 12, md: 6 }}
                            disabled={true}
                        />
                        <Grid container spacing={1} format={{ xs: 12 }}>
                            <Grid item>
                                <CancelButton onClick={() => history.push("/users")}>
                                    Vissza
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.users;
    const campuses = state.campuses.data;
    return { form, campuses };
}

const actionCreators = {
    changeForm: usersActions.changeForm,
    defaultForm: usersActions.defaultForm,
    create: usersActions.create,
    get: usersActions.get,
    update: usersActions.update,
    fetchCampuses: () => campusesActions.filter({}),
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(UsersForm);