import {
    templateChangeForm,
    templateGet,
    templateDefaultForm,
    templateFetch,
} from '../..';
import { types } from "./types";
import roomsService from "../../../services/masterData/rooms";
import loadingTypes from "../../loading/types";

const filter = (form, otherData) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    dispatch({ type: loadingTypes.TABLE_LOADING_ON });
    return roomsService.filter(form, otherData).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.reject(error);
        }
    );
};

function getAssignableRoomsForFilter(allocationId) {
    return (dispatch) => {
        dispatch({ type: types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_REQUEST });
        return roomsService.getAssignableRoomsForFilter(allocationId).then(
            (data) => {
                dispatch({ type: types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ASSIGNABLE_ROOMS_FOR_FILTER_FAILURE, error });
                return Promise.reject(error);
            }
        );
    }
}

function getAssignableRooms() {
    return (dispatch) => {
        dispatch({ type: types.GET_ASSIGNABLE_ROOMS_REQUEST });
        return roomsService.getAssignableRooms().then(
            (data) => {
                dispatch({ type: types.GET_ASSIGNABLE_ROOMS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ASSIGNABLE_ROOMS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: filter,
    fetch: templateFetch(types, roomsService),
    get: templateGet(types, roomsService),
    getAssignableRooms,
    getAssignableRoomsForFilter
}

export default actions;
