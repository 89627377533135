export const types = {
    CHANGE_FORM: "@@room-acceptance-report/CHANGE_FORM",
    DEFAULT_FORM: "@@room-acceptance-report/DEFAULT_FORM",

    CREATE_REQUEST: "@@room-acceptance-report/CREATE_REQUEST",
    CREATE_SUCCESS: "@@room-acceptance-report/CREATE_SUCCESS",
    CREATE_FAILURE: "@@room-acceptance-report/CREATE_FAILURE",

    UPDATE_REQUEST: "@@room-acceptance-report/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@room-acceptance-report/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@room-acceptance-report/UPDATE_FAILURE",

    GET_REQUEST: "@@room-acceptance-report/GET_REQUEST",
    GET_SUCCESS: "@@room-acceptance-report/GET_SUCCESS",
    GET_FAILURE: "@@room-acceptance-report/GET_FAILURE",
}