export const types = {
    CHANGE_FORM: "@@termination-operator-check/CHANGE_FORM",
    DEFAULT_FORM: "@@termination-operator-check/DEFAULT_FORM",

    GET_REQUEST: "@@termination-operator-check/GET_REQUEST",
    GET_SUCCESS: "@@termination-operator-check/GET_SUCCESS",
    GET_FAILURE: "@@termination-operator-check/GET_FAILURE",

    APPROVE_OPERATOR_CHECK_REQUEST:
        "@@termination-operator-check/APPROVE_OPERATOR_CHECK_REQUEST",
    APPROVE_OPERATOR_CHECK_SUCCESS:
        "@@termination-operator-check/APPROVE_OPERATOR_CHECK_SUCCESS",
    APPROVE_OPERATOR_CHECK_FAILURE:
        "@@termination-operator-check/APPROVE_OPERATOR_CHECK_FAILURE",

    GET_FORM_REQUEST: "@@termination-operator-check/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@termination-operator-check/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@termination-operator-check/GET_FORM_FAILURE",
};
