import { templateChangeForm, templateDefaultForm } from "../..";
import { types } from "./types";

const changeEditor = content => dispatch => dispatch({type: types.CHANGE_EDITOR, content});

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor
}

export default actions;