import { templateChangeForm, templateDefaultForm, templateFilter, templateGet } from "../..";
import { types } from "./types";
import campusesService from "../../../services/masterData/campuses";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, campusesService),
    get: templateGet(types, campusesService),
}

export default actions;