import { PROCESS_TYPE_NAMES } from "../../../utils/AppConst";

const TypeCellRenderer = (params) => {
    const type = params;
    let result = "-";

    switch (type) {
        case PROCESS_TYPE_NAMES.ENROLLMENT:
            result = "Beiratkozás";
            break;
        case PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE:
            result = "Szobatárspreferencia";
            break;
        case PROCESS_TYPE_NAMES.RELOCATION:
            result = "Átköltözés";
            break;
    }
    
    return result;
}

export default TypeCellRenderer;