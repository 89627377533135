import { useEffect } from "react";
import { connect } from 'react-redux';
import OffLayoutTemplate from "./OffLayoutTemplate";
import { Button, Grid, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import loadingActions from '../../store/loading/actions';
import loginActions from '../../store/modules/login/actions';
import mainActions from '../../store/modules/main/actions';
import { School, Security } from "@material-ui/icons";
import { history } from '../../store';
import { LAYOUT_TYPES, PERMISSIONS } from "../../utils/AppConst";

const theme = createTheme({
    palette: {
      primary: {
        main: '#364354',
        contrastText: '#f3ca12',
      },
    },
  });


function SwitchLayout(props) {
    useEffect(() => {
        props.loadingOff();
    }, []);

    const user = JSON.parse(localStorage.getItem("user")) || "";

    return (
        <OffLayoutTemplate>
            <ThemeProvider theme={theme}>
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h4">
                            Nézet kiválasztása
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color='primary'
                            size="large"
                            fullWidth
                            onClick={() => {
                                localStorage.setItem('layout', JSON.stringify(LAYOUT_TYPES.ADMIN));
                                props.selectLayout(JSON.parse(localStorage.getItem('layout')));
                                props.setRoles(user.roles.filter((element) => element !== PERMISSIONS.STUDENT));
                                history.push('/');
                            }}
                            sx={{
                                padding: '8px',
                                height: 56,
                            }}
                            endIcon={<Security />}
                        >
                            Admin
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={() => {
                                localStorage.setItem('layout', JSON.stringify(LAYOUT_TYPES.STUDENT))
                                props.selectLayout(JSON.parse(localStorage.getItem('layout')));
                                props.setRoles([PERMISSIONS.STUDENT]);
                                history.push('/');
                            }}
                            sx={{
                                padding: '8px',
                                height: 56,
                            }}
                            endIcon={<School />}
                        >
                            Hallgató
                        </Button>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </OffLayoutTemplate>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    selectLayout: mainActions.selectLayout,
    setRoles: loginActions.setRoles,
};

export default connect(mapState, actionCreators)(SwitchLayout);