import colors from "../../styles/colors";

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{
                padding: "8px",
                backgroundColor: colors.tabBackground,
                borderTop: "unset"
            }}
            {...other}
        >
            {value === index && (children)}
        </div>
    );
}
