import API from "../../../utils/API";
import multiPartAPI from "../../../utils/multiPartAPI";
import SearchForm from "../../../utils/SearchForm";
import { templateDelete, templateGetNoApi } from "../..";
import { customReplacer } from "../../../utils/AppConst";

function startEnrollment(form) {
    return API.post(`/enrollments/start`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function fillStudentForm(form, id, selectedFile) {
    let formData = new FormData();

    for (const [key, value] of Object.entries(form)) {
        if (key === "id") {
            formData.append("enrollment", value);
        } else if (key[0] !== "@" && typeof value !== "object") {
            formData.append(`${key}`, value);
        } else if (key === "address") {
            //cím átalakítása a form datához
            for (const [k, v] of Object.entries(value)) {
                if (k[0] !== "@" && v !== null) {
                    formData.append(`${key}[${k}]`, v);
                }
            }
        }
    }

    if (form.universities) {
        formData.append("universities", JSON.stringify(form.universities, customReplacer));
    }

    if (selectedFile && selectedFile.length > 0) {
        formData.append("universityAdmissionDecisionFile", selectedFile[0]);
    }

    if (form.acceptedDocuments) {
        let index = 0;

        for (const documentId of form.acceptedDocuments) {
            formData.append(`acceptedDocuments[${index++}]`, documentId);
        }
    }

    if (form.consentedDocuments) {
        let jndex = 0;
        for (const consentedDocument of form.consentedDocuments) {
            formData.append(
                `consentedDocuments[${jndex++}]`,
                consentedDocument.id ?? consentedDocument
            );
        }
    }

    if (form.nationality) {
        let index = 0;
        for (const nationality of form.nationality) {
            formData.append(
                `nationality[${index++}]`,
                nationality.value ?? nationality
            );
        }
    }

    return multiPartAPI
        .post(`/enrollments/${id}/fill-student`, formData)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/enrollments", { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}
function filterActive(form) {
    const searchForm = new SearchForm(form);

    return API.get("/processes/campus/ongoing", {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filterDone(form) {
    const searchForm = new SearchForm(form);

    return API.get("/processes/campus/done", { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getStudentOngoingProcesses(id) {
    return API.get(`/processes/student/${id}/ongoing`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getStudentDoneProcesses(id) {
    return API.get(`/processes/student/${id}/done`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getAvailableDocuments(enrollmentId) {
    return API.get(`/enrollments/${enrollmentId}/available-documents`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getNationalities() {
    return API.get(`/enrollments/nationalities`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getEnrollmentHistory(enrollmentId) {
    return API.get(`/enrollments/${enrollmentId}/enrollment_history`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getForm(enrollmentId) {
    return API.get(`/enrollments/${enrollmentId}/form`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function getActualUniversityStudies(enrollmentId) {
    return API.get(`/enrollments/${enrollmentId}/fill-student/university-study`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const enrollmentService = {
    startEnrollment,
    filter,
    fillStudentForm,
    filterActive,
    filterDone,
    get: templateGetNoApi("enrollments"),
    delete: templateDelete("enrollments"),
    deleteStudentAllocationPreference: templateDelete(
        "student-allocation-preferences"
    ),
    deleteTermination: templateDelete("terminations"),
    getForm,
    getAvailableDocuments,
    getNationalities,
    getEnrollmentHistory,
    getStudentOngoingProcesses,
    getStudentDoneProcesses,
    getActualUniversityStudies,
};

export default enrollmentService;
