import { templateChangeForm, templateCreate, templateDefaultForm, templateDelete, templateFilter, templateGet, templateUpdate } from "../..";
import { types } from "./types";
import emailTemplatesService from "../../../services/administration/emailTemplates";

const changeEditor = (language, value) => dispatch => dispatch({type: types.CHANGE_EDITOR, language, value})

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeEditor,
    filter: templateFilter(types, emailTemplatesService),
    create: templateCreate(types, emailTemplatesService),
    get: templateGet(types, emailTemplatesService),
    update: templateUpdate(types, emailTemplatesService),
    delete: templateDelete(types, emailTemplatesService),
}

export default actions;