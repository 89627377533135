export const types = {
    CHANGE_FORM: "@@operationalControl/CHANGE_FORM",
    DEFAULT_FORM: "@@operationalControl/DEFAULT_FORM",

    CREATE_REQUEST: "@@operationalControl/CREATE_REQUEST",
    CREATE_SUCCESS: "@@operationalControl/CREATE_SUCCESS",
    CREATE_FAILURE: "@@operationalControl/CREATE_FAILURE",

    UPDATE_REQUEST: "@@operationalControl/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@operationalControl/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@operationalControl/UPDATE_FAILURE",

    GET_REQUEST: "@@operationalControl/GET_REQUEST",
    GET_SUCCESS: "@@operationalControl/GET_SUCCESS",
    GET_FAILURE: "@@operationalControl/GET_FAILURE",
}