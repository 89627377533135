import API from "../../utils/API";
import Cookies from "universal-cookie";
import * as Sentry from '@sentry/react';

const cookies = new Cookies();

function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');

    window.location.href = process.env.REACT_APP_AUTH_URL + '/logout';
}

export const setJWTToken = (token) => {
    cookies.set(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt', token, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
};

function getUser() {
    return API.get("/profile")
        .then((response) => {
            const data = response.data;
            setUser(data);
            Sentry.setUser({
                id: '' + data?.id,
                email: data?.email,
                roles: data?.roles,
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function loginIaAs(authId) {
    return API.post('/login-in-as', { authId: authId });
}

function setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
}

function getUserObject() {
    return JSON.parse(localStorage.getItem("user"));
}

const userService = {
    logout,
    getUser,
    setUser,
    getUserObject,
    loginIaAs,
    setJWTToken,
};

export default userService;