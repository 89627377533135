import {withStyles} from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import TxtField from "@material-ui/core/TextField";
import FormikTextField from "./FormikTextField.js";

const CssTextField = withStyles({
    root: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: "none"
        },
        '& label.Mui-focused': {
            color: colors.primaryBlue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.primaryBlue,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: colors.primaryBlue,
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: colors.primaryBlue,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.primaryBlue,
            },
            '&:hover fieldset': {
                borderColor: colors.primaryBlue,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.primaryBlue,
            },
        },
    },
})(TxtField);

export default function TextField (props) {
    const {functionToChild, formToChild, formNameToChild, ...rest} = props;

    const isValid = value => {
        if (props.rules) {
            for (const [key, val] of Object.entries(props.rules)) {
                switch(key) {
                    case "number":
                        return !isNaN(value);
                    case "length":
                        return value.length <= val; 
                    default:
                        break;
                }
            }
        }
        
        return true;
    }
 
    const handleChange = e => {
        let value = e.target.value;

        if (!isValid(value)) {
            return;
        }

        //ha az input típusa number akkor number típussá konvertálja az értéket
        if (e.target.type === 'number') {
            if (!isNaN(value)) {
                value = Number(value);
            }
        }

        if (e.target.name.includes(".")) {
            const innerForm =  e.target.name.split(".")[0];
            const name = e.target.name.split(".")[1];

            functionToChild({
                ...formToChild,
                [innerForm]: {
                    ...formToChild[innerForm],
                    [name]: value
                }
            }, formNameToChild)
                    
        } else {
            functionToChild({...formToChild, [e.target.name]: value}, formNameToChild)
        }
    }

    if (props.isFormikForm !== undefined && props.isFormikForm === true) {
        return <FormikTextField {...props} />
    }
    
    return (
        <CssTextField 
            margin={props.margin ? props.margin : "dense"}
            variant={props.variant ? props.variant : "outlined"}
            fullWidth={Object.prototype.hasOwnProperty.call(props, "fullWidth") ? props.fullWidth : true}
            onChange={functionToChild && handleChange}
            size="small"
            InputProps={{ inputProps: { tabIndex: 0 } }}
            {...rest}
        />
    );
}