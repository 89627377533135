const UNIT = 12;
const WIDTH = 34 * UNIT;
const HEIGHT = 5 * UNIT;
const ROOM_HEIGHT = 2 * UNIT;
const ROOM_WIDTH = 1 * UNIT;

const leftTop = [-WIDTH / 2, HEIGHT / 2];
const rightTop = [WIDTH / 2, HEIGHT / 2 + UNIT];
const leftBottom = [-WIDTH / 2, -HEIGHT / 2];

const BUILDING = [
    [-WIDTH / 2, -HEIGHT / 2],
    [WIDTH / 2, -HEIGHT / 2],
    [WIDTH / 2, HEIGHT / 2 + UNIT],
    [-WIDTH / 2 + 8 * UNIT, HEIGHT / 2 + UNIT],
    [-WIDTH / 2 + 8 * UNIT, HEIGHT / 2],
    [-WIDTH / 2, HEIGHT / 2],
    [-WIDTH / 2, -HEIGHT / 2],
];

const ROOMS = [];

for (let i = 0; i < 8; i++) {
    ROOMS.push({
        id: 7 - i,
        coordinates: [
            [
                [leftTop[0] + i * UNIT, leftTop[1] - ROOM_HEIGHT],
                [leftTop[0] + i * UNIT, leftTop[1]],
                [leftTop[0] + i * UNIT + ROOM_WIDTH, leftTop[1]],
                [leftTop[0] + i * UNIT + ROOM_WIDTH, leftTop[1] - ROOM_HEIGHT],
            ],
        ],
    });
}

for (let i = WIDTH / UNIT - 1; i >= 5; i--) {
    ROOMS.push({
        id: i + 6,
        coordinates: [
            [
                [leftBottom[0] + i * UNIT, leftBottom[1]],
                [leftBottom[0] + i * UNIT + ROOM_WIDTH, leftBottom[1]],
                [
                    leftBottom[0] + i * UNIT + ROOM_WIDTH,
                    leftBottom[1] + ROOM_HEIGHT,
                ],
                [leftBottom[0] + i * UNIT, leftBottom[1] + ROOM_HEIGHT],
            ],
        ],
    });
}

for (let i = 0; i < 5; i++) {
    if (i === 0) {
        ROOMS.push({
            id: 8,
            coordinates: [
                [
                    [leftBottom[0] + i * UNIT, leftBottom[1]],
                    [leftBottom[0] + i * UNIT + ROOM_WIDTH, leftBottom[1]],
                    [
                        leftBottom[0] + i * UNIT + ROOM_WIDTH,
                        leftBottom[1] + ROOM_HEIGHT,
                    ],
                    [leftBottom[0] + i * UNIT, leftBottom[1] + ROOM_HEIGHT],
                ],
            ],
        });
    } else if (i === 1 || i === 3) {
        ROOMS.push({
            id: Math.floor(i / 2) + 9,
            coordinates: [
                [
                    [leftBottom[0] + i * UNIT, leftBottom[1]],
                    [leftBottom[0] + i * UNIT + ROOM_WIDTH * 2, leftBottom[1]],
                    [
                        leftBottom[0] + i * UNIT + ROOM_WIDTH * 2,
                        leftBottom[1] + ROOM_HEIGHT,
                    ],
                    [leftBottom[0] + i * UNIT, leftBottom[1] + ROOM_HEIGHT],
                ],
            ],
        });
    }
}

for (let i = 0; i < 22; i++) {
    if (i < 21) {
        ROOMS.push({
            id: 60 - i,
            coordinates: [
                [
                    [rightTop[0] - i * UNIT, rightTop[1]],
                    [rightTop[0] - i * UNIT - ROOM_WIDTH, rightTop[1]],
                    [
                        rightTop[0] - i * UNIT - ROOM_WIDTH,
                        rightTop[1] - ROOM_HEIGHT,
                    ],
                    [rightTop[0] - i * UNIT, rightTop[1] - ROOM_HEIGHT],
                ],
            ],
        });
    } else {
        ROOMS.push({
            id: 60 - i,
            coordinates: [
                [
                    [rightTop[0] - i * UNIT, rightTop[1]],
                    [rightTop[0] - i * UNIT - ROOM_WIDTH * 5, rightTop[1]],
                    [
                        rightTop[0] - i * UNIT - ROOM_WIDTH * 5,
                        rightTop[1] - ROOM_HEIGHT,
                    ],
                    [rightTop[0] - i * UNIT, rightTop[1] - ROOM_HEIGHT],
                ],
            ],
        });
    }
}

export const buildingData = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'Line',
                coordinates: BUILDING,
            },
        },
    ],
};

export const staircaseData = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                name: 'Lépcsőház',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [rightTop[0] - 21 * UNIT, rightTop[1]],
                            [
                                rightTop[0] - 21 * UNIT - ROOM_WIDTH * 5,
                                rightTop[1],
                            ],
                            [
                                rightTop[0] - 21 * UNIT - ROOM_WIDTH * 5,
                                rightTop[1] - ROOM_HEIGHT,
                            ],
                            [
                                rightTop[0] - 21 * UNIT,
                                rightTop[1] - ROOM_HEIGHT,
                            ],
                        ],
                    ],
                    [
                        [
                            [
                                leftTop[0] + 11 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [
                                leftTop[0] + 12 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [leftTop[0] + 12 * UNIT, leftTop[1] - ROOM_HEIGHT],
                            [leftTop[0] + 11 * UNIT, leftTop[1] - ROOM_HEIGHT],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Lépcsőház',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [rightTop[0] - 3 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 7 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 7 * UNIT, rightTop[1] - 3.5 * UNIT],
                            [rightTop[0] - 3 * UNIT, rightTop[1] - 3.5 * UNIT],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Lépcsőház',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [rightTop[0] - 11 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 17 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 17 * UNIT, rightTop[1] - 3.5 * UNIT],
                            [rightTop[0] - 11 * UNIT, rightTop[1] - 3.5 * UNIT],
                        ],
                    ],
                ],
            },
        },
    ],
};

export const liftsData = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                name: 'Lift',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [rightTop[0] - 12 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 13 * UNIT, rightTop[1] - 2.5 * UNIT],
                            [rightTop[0] - 13 * UNIT, rightTop[1] - 3.5 * UNIT],
                            [rightTop[0] - 12 * UNIT, rightTop[1] - 3.5 * UNIT],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Lift',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [
                                leftTop[0] + 10 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [
                                leftTop[0] + 11 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [leftTop[0] + 11 * UNIT, leftTop[1] - ROOM_HEIGHT],
                            [leftTop[0] + 10 * UNIT, leftTop[1] - ROOM_HEIGHT],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Lift',
            },
            geometry: {
                type: 'Line',
                coordinates: [
                    [
                        [
                            [
                                leftTop[0] + 8 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [
                                leftTop[0] + 9 * UNIT,
                                leftTop[1] - ROOM_HEIGHT + UNIT,
                            ],
                            [leftTop[0] + 9 * UNIT, leftTop[1] - ROOM_HEIGHT],
                            [leftTop[0] + 8 * UNIT, leftTop[1] - ROOM_HEIGHT],
                        ],
                    ],
                ],
            },
        },
    ],
};

const createLabel = (roomId, data) => {
    const room = data.find((elem) => elem.name === roomId + '');
    const allocations = room?.allocations || [];

    return [
        roomId,
        [
            ...allocations.map(
                (allocation) =>
                    allocation.student.user.fullName.split(' ')[0] +
                    ' ' +
                    allocation.student.user.fullName.split(' ')[1][0] +
                    '.'
            ),
        ].join('\n'),
    ].join('\n');
};

export const roomsData = (floor, data) => ({
    type: 'FeatureCollection',
    features: ROOMS.map((room) => ({
        type: 'Feature',
        properties: {
            name: createLabel(floor * 100 + room.id, data),
            id: floor * 100 + room.id,
        },
        geometry: {
            type: 'Polygon',
            coordinates: room.coordinates,
        },
    })),
});
