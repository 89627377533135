import { templateChangeForm, templateDefaultForm } from "../..";
import { types } from "./types";
import allocationService from "../../../services/processes/allocation";
import notificationTypes from "../../notification/types";

function assignAllocation(id, form) {
    const formToSend = {
        room: form.room,
        studentAllocation: form.studentAllocation,
        student: form.student,
    };

    return (dispatch) => {
        dispatch({ type: types.ASSIGN_ALLOCATION_REQUEST, formToSend });
        return allocationService.assignAllocation(id, formToSend).then(
            (data) => {
                dispatch({ type: types.ASSIGN_ALLOCATION_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres mentés" },
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.ASSIGN_ALLOCATION_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel mentés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

function assignAllStudents(id) {
    return (dispatch) => {
        dispatch({ type: types.ASSIGN_ALL_STUDENTS_REQUEST, id });
        return allocationService.assignAllStudents(id).then(
            (data) => {
                dispatch({ type: types.ASSIGN_ALL_STUDENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.ASSIGN_ALL_STUDENTS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function assignNewStudents(id) {
    return (dispatch) => {
        dispatch({ type: types.ASSIGN_NEW_STUDENTS_REQUEST, id });
        return allocationService.assignNewStudents(id).then(
            (data) => {
                dispatch({ type: types.ASSIGN_NEW_STUDENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.ASSIGN_NEW_STUDENTS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function checkAssignmentStatus(allocationId, sessionId) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_ASSIGNMENT_STATUS_REQUEST, allocationId });
        return allocationService
            .checkAssignmentStatus(allocationId, sessionId)
            .then(
                (data) => {
                    dispatch({
                        type: types.CHECK_ASSIGNMENT_STATUS_SUCCESS,
                        data,
                    });
                    return Promise.resolve(data);
                },
                (error) => {
                    dispatch({
                        type: types.CHECK_ASSIGNMENT_STATUS_FAILURE,
                        error,
                    });
                    return Promise.reject(error);
                }
            );
    };
}

function resetStudentAssignment(id) {
    return (dispatch) => {
        dispatch({ type: types.RESET_STUDENT_ASSIGNMENT_REQUEST });
        return allocationService.resetStudentAssignment(id).then(
            (data) => {
                dispatch({
                    type: types.RESET_STUDENT_ASSIGNMENT_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.RESET_STUDENT_ASSIGNMENT_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function filterStudentAllocations(id, form) {
    let formToSend = {};

    if (form) {
        formToSend["floor.building"] = form.building;
        formToSend["floor"] = form.floor;
        formToSend["id"] = form.room;
        formToSend["space"] = form.space;

        formToSend["allocations.student.user.email"] = form.email;
        formToSend["allocations.student.user.lastName"] = form.lastName;
        formToSend["allocations.student.user.firstName"] = form.firstName;
        formToSend["allocations.student.currentTrainingLevel"] =
            form.trainingLevel;
        formToSend["allocations.student.currentTrainingProgram"] =
            form.trainingProgram;
        formToSend["allocations.student.currentCollegeYear"] = form.collegeYear;
        formToSend["allocations.student.currentMembershipStatus"] =
            form.currentMembershipStatus;
        formToSend["allocations.student.university"] = form.university;
        formToSend["allocations.student.universityMajor"] =
            form.universityMajor;
    }

    return (dispatch) => {
        dispatch({
            type: types.FILTER_STUDENT_ALLOCATIONS_REQUEST,
            formToSend,
        });
        return allocationService.filterRooms(id, formToSend).then(
            (data) => {
                dispatch({
                    type: types.FILTER_STUDENT_ALLOCATIONS_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FILTER_STUDENT_ALLOCATIONS_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function filterUnassignedStudents(id, form) {
    let formToSend = {};

    if (form) {
        formToSend["user.email"] = form.email;
        formToSend["user.lastName"] = form.lastName;
        formToSend["user.firstName"] = form.firstName;
        formToSend["currentTrainingLevel"] = form.trainingLevel;
        formToSend["currentTrainingProgram"] = form.trainingProgram;
        formToSend["currentCollegeYear"] = form.collegeYear;
        formToSend["currentMembershipStatus"] = form.currentMembershipStatus;
        formToSend["university"] = form.university;
        formToSend["universityMajor"] = form.universityMajor;
    }

    return (dispatch) => {
        dispatch({ type: types.FILTER_UNASSIGNED_STUDENTS_REQUEST });
        return allocationService.filterUnassignedStudents(id, formToSend).then(
            (data) => {
                dispatch({
                    type: types.FILTER_UNASSIGNED_STUDENTS_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FILTER_UNASSIGNED_STUDENTS_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function getAllocation(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_ALLOCATION_REQUEST });
        return allocationService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_ALLOCATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ALLOCATION_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getAllocations() {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST });
        return allocationService.fetch().then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getFreeRooms(id, form) {
    let formToSend = {};

    if (form) {
        formToSend["floor.building"] = form.building;
        formToSend["floor"] = form.floor;
        formToSend["id"] = form.room;
        formToSend["space"] = form.space;

        if (
            form.email ||
            form.lastName ||
            form.firstName ||
            form.trainingLevel ||
            form.trainingProgram ||
            form.collegeYear ||
            form.currentMembershipStatus ||
            form.university ||
            form.universityMajor
        ) {
            formToSend["id"] = 0;
        }
    }

    return (dispatch) => {
        dispatch({ type: types.GET_FREE_ROOMS_REQUEST, formToSend });
        return allocationService.getFreeRooms(id, formToSend).then(
            (data) => {
                dispatch({ type: types.GET_FREE_ROOMS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FREE_ROOMS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function checkIfRelocationExistsForAllocation(id) {
    return (dispatch) => {
        dispatch({
            type: types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_REQUEST,
        });
        return allocationService.checkIfRelocationExistsForAllocation(id).then(
            (data) => {
                dispatch({
                    type: types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function checkIfTerminationExistsForAllocation(id) {
    return (dispatch) => {
        dispatch({
            type: types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_REQUEST,
        });
        return allocationService.checkIfTerminationExistsForAllocation(id).then(
            (data) => {
                dispatch({
                    type: types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

const roomAllocationListActions = {
    assignAllocation,
    assignAllStudents,
    assignNewStudents,
    checkAssignmentStatus,
    checkIfRelocationExistsForAllocation,
    checkIfTerminationExistsForAllocation,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filterUnassignedStudents,
    filterStudentAllocations,
    getAllocation,
    getAllocations,
    getFreeRooms,
    resetStudentAssignment,
};

export default roomAllocationListActions;
