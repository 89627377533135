import { useEffect } from "react";
import Loading from "../../../Common/Loading";
import loadingActions from "../../../../store/loading/actions";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import Form from "../../../Common/Form";
import { connect } from "react-redux";
import terminationActions from "../../../../store/processes/newProcess/termination/actions";
import { Grid } from "@material-ui/core";
import Accordion from "../../../Common/Accordion";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store";
import { membershipStatusLabel } from "../../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import { semesterStatus } from "../../../../utils/AppConst";
import Address from "../../../Common/Address";

function TerminationDetails(props) {
    const { form, loading } = props;
    const { id } = props.match?.params;

    useEffect(() => {
        if (id) {
            props.loadingOn();
            props.getData(id)
                .finally(() => {props.loadingOff();});
        }
    }, []);

    if (loading) return <Loading />

    return (
        <Grid item>
            <Accordion title="Űrlap" expanded>
                <Form>
                    <Title title="Típus" format={{ xs: 12 }} >
                        <TextField
                            value={form?.isMoveOutProcess ? "Kiköltözés" : "Kiiratkozás"}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Név" format={{xs: 12}}>
                        <TextField
                            label="Vezetéknév"
                            value={form?.lastName || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Keresztnév"
                            value={form?.firstName || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                    </Title>
                    <Title title="Elérhetőségek" format={{ xs: 12 }}>
                        <TextField
                            label="Email cím"
                            name="email"
                            value={form?.email || ""}
                            format={{ xs: 6 }}
                            disabled
                        />
                        <TextField
                            label="Telefonszám"
                            name="phoneNumber"
                            value={form?.phoneNumber || ""}
                            format={{ xs: 6 }}
                            disabled
                        />
                    </Title>
                    <Title title="Képzés" format={{xs: 12}}>
                        <TextField
                            label="Képzési szint"
                            value={form?.trainingLevel?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Képzési program"
                            value={form?.trainingProgram?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Évfolyam"
                            value={form?.collegeYear?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Státusz"
                            value={membershipStatusLabel(form?.membershipStatus || "")}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Aktuális státusz"
                            value={membershipStatusLabel(form?.currentMembershipStatus || "")}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Szemeszter státusz"
                            value={semesterStatus(form?.semesterStatus) || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                    </Title>
                    <Title title="Campus" format={{xs: 12}}>
                        <TextField
                            label="Campus"
                            value={form?.campus?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Épület"
                            value={form?.building?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Szint"
                            value={form?.floor?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                        <TextField
                            label="Szoba"
                            value={form?.room?.name || ""}
                            format={{xs: 12, md: 6}}
                            disabled
                        />
                    </Title>
                    <Address
                        title="Állandó lakcím"
                        form={form ?  form : {}}
                        detailedAddress
                        disabled={true}
                        format={{xs: 12}}
                    />
                    <CancelButton onClick={() => history.push("/processes")}>
                        Vissza
                    </CancelButton>
                </Form>
            </Accordion>
        </Grid>
    )
}

function mapState(state) {
    const form  = state.termination.getData;
    const loading = state.loading.loading;
    return { form, loading };
}

const actionCreators = {
    getData: terminationActions.getData,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(TerminationDetails)