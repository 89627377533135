import enrollmentActions from "../../../../store/processes/newProcess/enrollment/actions";
import loadingActions from "../../../../store/loading/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import PageTemplate from "../../../Common/PageTemplate";
import { Grid } from "@material-ui/core";
import Accordion from "../../../Common/Accordion";
import Timeline from "../../../Common/Timeline";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store";

function EnrollmentTimeline(props) {
    const { enrollmentHistory } = props;
    const { id } = props.match.params;

    const header = {
        title: "Folyamat idővonala",
        breadcrumbs: {
            home: "Főoldal",
            "/processes": "Folyamatok",
            processesTabs: "Folyamat idővonala",
        },
    };

    useEffect(() => {
        props.defaultForm("enrollmentHistory");
        props.loadingOn();
        props.getEnrollmentHistory(id).then(() => props.loadingOff());
    }, []);

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title="Folyamat idővonala" expanded={true}>
                    <Timeline enrollmentHistory={enrollmentHistory} />
                    <CancelButton onClick={() => history.push("/processes")}>
                        Vissza
                    </CancelButton>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { enrollmentHistory } = state.enrollment;
    return { enrollmentHistory };
}

const actionCreators = {
    defaultForm: enrollmentActions.defaultForm,
    getEnrollmentHistory: enrollmentActions.getEnrollmentHistory,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(EnrollmentTimeline);
