import {withStyles} from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import Button from "@material-ui/core/Button";

const StyledCancelButton = withStyles({
    root: {
        backgroundColor:     colors.white,
        borderRadius:   0,
        border:         '2px solid',
        borderColor:    colors.primaryYellow,
        color:          colors.primaryYellow,
        height:         35,
        padding:        '0 20px',
        boxShadow:      colors.black,
        '&:hover': {
            color: colors.primaryYellowHover,
            backgroundColor: colors.tabBackground,
            borderColor:    colors.primaryYellowHover
        }
    },
    label: {
        textTransform:  'capitalize',
    },
})(Button);

export default StyledCancelButton;
