import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateGet, templateUpdate } from '../..';
import { fixDateRangeFields } from '../../../utils/AppConst';

function filter(form) {
    const searchForm = new SearchForm(form);
    const fixedForm = fixDateRangeFields(searchForm.getSend());

    return API.get('/semesters', { params: fixedForm })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function filterJSON(form) {
    const searchForm = new SearchForm(form);
    const fixedForm = fixDateRangeFields(searchForm.getSend());

    return API.get('/semesters.json', { params: fixedForm })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const semestersService = {
    filter,
    filterJSON,
    create: templateCreate('semesters'),
    get: templateGet('semesters'),
    update: templateUpdate('semesters'),
};

export default semestersService;