import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import campusesActions from "../../../store/masterData/campuses/actions";
import semestersActions from "../../../store/masterData/semesters/actions";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import mainActions from "../../../store/modules/main/actions";
import { PERMISSIONS } from "../../../utils/AppConst";
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageGet } from "../../../utils/FilterStorage";
import isEmpty from "../../../utils/isEmpty";
import { hasOnlyUser, hasRole } from "../../../utils/User";
import Loading from "../../Common/Loading";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import Processes from "./Processes";

const header = {
    title: "Főoldal",
    breadcrumbs: {
        home: "Főoldal",
    },
};


export default function Main() {
    if (hasOnlyUser()) {
        return (<PageTemplate header={header}></PageTemplate>)
    } else {
        return <RoledMainConnect />
    }
}

function RoledMain(props) {
    const {
        ongoingData,
        closedData,
        ongoing,
        closed,
        campuses,
        semesters,
        currentTab,
        ongoingStorageKey,
        closedStorageKey,
        trainingLevels,
        tableInfoOngoing,
        tableInfoClosed,
    } = props;
    const [loading, setLoading] = useState(false);

    const ongoingInfos = {
        search: props.searchOngoing,
        info: tableInfoOngoing,
        setInfo: props.setOngoingInfo,
    };

    const closedInfos = {
        search: props.searchClosed,
        info: tableInfoClosed,
        setInfo: props.setClosedInfo,
    };

    function fetchData() {
        setLoading(true);
        let activeSemesters = [];

        let ongoingStorageFilter = filterStorageGet(ongoingStorageKey);

        if (isEmpty(ongoingStorageFilter) || hasRole(PERMISSIONS.STUDENT)) {
            ongoingStorageFilter = {};
        } else {
            delete ongoingStorageFilter.status;
        }

        if (
            !(
                hasRole(PERMISSIONS.OPERATOR_ADMIN) ||
                hasRole(PERMISSIONS.FINANCIAL_ADMIN)
            )
        ) {
            delete ongoingStorageFilter.currentMembershipStatus;
            delete ongoingStorageFilter.trainingLevel;
        }

        const closedStorageFilter = filterStorageGet(closedStorageKey);
        props.fetchSemesters().then((response) => {
            const active = response.data["hydra:member"].filter(
                (elem) => elem.active
            );
            if (active.length) {
                activeSemesters = active.map((elem) => {
                    return elem["id"];
                });
            } else if (response.data["hydra:member"].length && "id" in response.data["hydra:member"][0]) {
                activeSemesters = [response.data["hydra:member"][0]["id"] ?? null];
            }

            props.changeForm(
                !isEmpty(ongoingStorageFilter)
                    ? ongoingStorageFilter
                    : {
                          semester: hasRole(PERMISSIONS.STUDENT)
                              ? []
                              : activeSemesters,
                      },
                "ongoing"
            );
            props.changeForm(
                !isEmpty(closedStorageFilter)
                    ? closedStorageFilter
                    : { semester: activeSemesters },
                "closed"
            );

            axios
                .all([
                    props.fetchCampuses(),
                    setInfo(
                        ongoingInfos,
                        "order", { displayId: "asc" },
                        "filter",
                        !isEmpty(ongoingStorageFilter)
                            ? ongoingStorageFilter
                            : {
                                  semester: hasRole(PERMISSIONS.STUDENT)
                                      ? []
                                      : activeSemesters,
                              }
                    ),
                    setInfo(
                        closedInfos,
                        "filter",
                        !isEmpty(closedStorageFilter)
                            ? closedStorageFilter
                            : { semester: activeSemesters }
                    ),
                    props.fetchTrainingLevels(),
                ])
                .finally(() => setLoading(false));
        });
    }

    function changeTab(tab) {
        if (tab !== currentTab) {
            props.changeTab(tab);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(fetchData, [currentTab]);

    if (loading) {
        return <Loading />;
    }

    const tabs = [
        {
            label: "Teendők",
            value: (
                <Processes
                    name="ongoing"
                    data={ongoingData}
                    filter={ongoing}
                    filterStorageKey={ongoingStorageKey}
                    search={props.searchOngoing}
                    changeForm={props.changeForm}
                    defaultForm={props.defaultForm}
                    campuses={campuses}
                    semesters={semesters}
                    trainingLevels={trainingLevels}
                    setInfo={props.setOngoingInfo}
                    tableInfo={tableInfoOngoing}
                />
            ),
        },
        {
            label: "Lezárult teendők",
            value: (
                <Processes
                    name="closed"
                    data={closedData}
                    filter={closed}
                    filterStorageKey={closedStorageKey}
                    search={props.searchClosed}
                    changeForm={props.changeForm}
                    defaultForm={props.defaultForm}
                    campuses={campuses}
                    semesters={semesters}
                    setInfo={props.setClosedInfo}
                    tableInfo={tableInfoClosed}
                />
            ),
        },
    ];

    return (
        <PageTemplate header={header}>
            <TabsCompleted
                tabContents={tabs}
                currentTab={currentTab}
                onChange={changeTab}
            />
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        ongoingData,
        closedData,
        ongoing,
        closed,
        currentTab,
        ongoingStorageKey,
        closedStorageKey,
        tableInfoOngoing,
        tableInfoClosed,
        layout,
    } = state.main;
    const campuses = state.campuses.data;
    const semesters = state.semesters.data;
    const trainingLevels = state.trainingLevels.data;
    return {
        ongoingData,
        closedData,
        ongoing,
        closed,
        campuses,
        semesters,
        currentTab,
        ongoingStorageKey,
        closedStorageKey,
        trainingLevels,
        tableInfoOngoing,
        tableInfoClosed,
        layout
    };
}

const actionCreators = {
    changeForm: mainActions.changeForm,
    defaultForm: mainActions.defaultForm,
    changeTab: mainActions.changeTab,
    searchOngoing: mainActions.filterOngoing,
    searchClosed: mainActions.filterClosed,
    setOngoingInfo: mainActions.setOngoingInfo,
    setClosedInfo: mainActions.setClosedInfo,
    fetchCampuses: () => campusesActions.filter({}),
    fetchSemesters: () => semestersActions.filter({}),
    fetchTrainingLevels: trainingLevelsActions.filter,
};

const RoledMainConnect = connect(mapState, actionCreators)(RoledMain);
