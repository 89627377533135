import EditRoomDialog from "./EditRoomDialog";
import ViewStudentAllocationPreferenceDialog from "../ViewStudentAllocationPreferenceDialog";

function BtnCellRenderer(props, allocationId) {
    const user = localStorage.getItem("user") || "";
    const userJSON = JSON.parse(user);
    const campus = userJSON.campus;
    const preference = props.data?.preference;

    const preferenceDialogInfo = {
        fullName: props.data?.fullName || "-",
        email: props.data?.email || "-",
        displayId: preference?.displayId || "-",
        roomMates: preference?.roomMates?.[0]?.user?.fullName || "-",
        comment: preference?.comment || "-",
        building: preference?.room?.floor?.building?.name || "-",
        floor: preference?.room?.floor?.name || "-",
        room: preference?.room?.name || "-"
    }
    
    const editFormInfo = {
        fullName: props.data?.fullName || "-",
        studentAllocationId: props.data?.studentAllocationId || null,
        studentId: props.data?.id
    }

    return (
        <>
            {preference && <ViewStudentAllocationPreferenceDialog {...preferenceDialogInfo} />}
            <EditRoomDialog
                campus={campus}
                allocationId={allocationId}
                editFormInfo={editFormInfo}
                {...props}
            />
        </>
    );
}

export default BtnCellRenderer;