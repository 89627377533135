import React from 'react';
import { useSelector } from 'react-redux';
import {Route} from 'react-router-dom';
import { grantPermissionForUser } from '../../utils/grantPermission';
import Layout from '../Layout';
import { history } from '../../store';

export default function PrivateRoute({ component: Component, roles, ...rest }) {
    const user = useSelector(state => state.login.user);

    if (roles === undefined) {
        roles = ['ROLE_SYSTEM_ADMIN'];
    }

    return (
        <>
        { grantPermissionForUser(user, roles) ?
            (
                <Route {...rest} exact render={props => 
                    <Layout>
                        <Component {...props} />
                    </Layout> 
                    } 
                />
            ):
            history.push('/403')
        }
        </>
    )
}