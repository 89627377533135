import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import trainingProgramsActions from "../../../../store/masterData/trainingPrograms/actions";
import trainingLevelsActions from "../../../../store/masterData/trainingLevels/actions";
import collegeYearsActions from "../../../../store/masterData/collegeYears/actions";
import loadingActions from "../../../../store/loading/actions";
import notificationActions from "../../../../store/notification/actions";
import Accordion from "../../../Common/Accordion";
import PageTemplate from "../../../Common/PageTemplate";
import TextField from "../../../Common/TextField";
import Select from "../../../Common/Select";
import Form from "../../../Common/Form";
import CancelButton from "../../../Common/CancelButton";
import { history } from "../../../../store";
import { useEffect } from "react";
import * as yup from 'yup';
import { YEAR_OPTIONS } from "../../../../utils/AppConst";
import { makeOptionsFromHydra } from "../../../../utils/listOptions/makeOptionsFromHydra";
import axios from "axios";

function TrainingProgramsForm(props) {
    const { form, id, trainingLevelForm, trainingLevelId, collegeYears } = props;
    const title = "Képzési program megtekintése";

    const URL = `/training-levels/${trainingLevelId}/training-programs`;

    const header = {
        title: title,
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/training-levels": "Képzési szintek",
            [`/training-levels/${trainingLevelId}/training-programs`]: "Képzési programok",
            "trainingProgramsView": title,
            "name": form.name ?? ""
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.getCollegeYears(),
            props.get(id),
            props.getTrainingLevel(trainingLevelId)
        ])
            .finally(() => props.loadingOff());

        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, collegeYears: form.collegeYearIRIs }, "form");
    }, [form.collegeYearIRIs])

    useEffect(() => {
        props.changeForm({ ...form, trainingLevel: trainingLevelForm['@id'] }, "form");
    }, [trainingLevelForm])

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Név túl hosszú").required("Kötelező megadni"),
        duration: yup.mixed().required("Kötelező megadni"),
        collegeYears: yup.mixed().required("Kötelező megadni")
    })

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form['errors'] || {},
        initialvalues: form,
        isformikform: true,
        onsubmit: () => console.log('Nem lehet már szerkeszteni és létrehozni'),
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form
                        {...formProps}
                    >
                        <TextField
                            label="Név"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 6, lg: 3 }}
                            disabled={true}
                        />
                        <Select
                            selectLabel="Időtartam (év)"
                            name="duration"
                            value={form.duration || ""}
                            optionList={YEAR_OPTIONS}
                            format={{ xs: 12, md: 6, lg: 3 }}
                            disabled={true}
                        />
                        <Select
                            selectLabel="Évfolyam"
                            name="collegeYears"
                            value={form.collegeYears || []}
                            optionList={makeOptionsFromHydra(collegeYears, "college-years")}
                            format={{ xs: 12, md: 6, lg: 3 }}
                            multiple
                            disabled={true}
                        />
                        <TextField
                            label="Archiválva?"
                            name="archive"
                            id="archive"
                            value={form.archive ? "Igen" : "Nem"}
                            format={{ xs: 12, md: 6, lg: 3 }}
                            disabled={true}
                        />
                        <CancelButton onClick={() => history.push(URL)}>
                            Vissza
                        </CancelButton>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.trainingPrograms;
    const trainingLevelForm = state.trainingLevels.form;
    const collegeYears = state.collegeYears.data;
    return { form, trainingLevelForm, collegeYears };
}

const actionCreators = {
    changeForm: trainingProgramsActions.changeForm,
    defaultForm: trainingProgramsActions.defaultForm,
    create: trainingProgramsActions.create,
    get: trainingProgramsActions.get,
    getTrainingLevel: trainingLevelsActions.get,
    getCollegeYears: collegeYearsActions.filter,
    update: trainingProgramsActions.update,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(TrainingProgramsForm);