export const types = {
    CHANGE_FORM: "@@documents/CHANGE_FORM",
    DEFAULT_FORM: "@@documents/DEFAULT_FORM",
    CHANGE_EDITOR: "@@documents/CHANGE_EDITOR",

    FETCH_REQUEST: "@@documents/FETCH_REQUEST",
    FETCH_SUCCESS: "@@documents/FETCH_SUCCESS",
    FETCH_FAILURE: "@@documents/FETCH_FAILURE",

    CREATE_REQUEST: "@@documents/CREATE_REQUEST",
    CREATE_SUCCESS: "@@documents/CREATE_SUCCESS",
    CREATE_FAILURE: "@@documents/CREATE_FAILURE",

    GET_REQUEST: "@@documents/GET_REQUEST",
    GET_SUCCESS: "@@documents/GET_SUCCESS",
    GET_FAILURE: "@@documents/GET_FAILURE",

    UPDATE_REQUEST: "@@documents/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@documents/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@documents/UPDATE_FAILURE",

    DELETE_REQUEST: "@@documents/DELETE_REQUEST",
    DELETE_SUCCESS: "@@documents/DELETE_SUCCESS",
    DELETE_FAILURE: "@@documents/DELETE_FAILURE",

    DOWNLOAD_REQUEST: "@@documents/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@documents/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@documents/DOWNLOAD_FAILURE",
}