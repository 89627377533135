import { connect } from "react-redux";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import translationsActions from "../../../store/administration/translations/actions";
import BtnCellRenderer from "./BtnCellRenderer";

function Translations(props) {
    const { filter } = props;

    const header = {
        title: "Nyelvesítések",
        breadcrumbs: {
            "administration": "Adminisztráció",
            "translations": "Nyelvesítések"
        }
    };

    const filterForm = {
        onSubmit: () => console.log("szűrés"),
        defaultForm: props.defaultForm,
        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            { headerName: "Kulcs", field: "key" },
            { headerName: "Érték (hu)", field: "hu", editable: true },
            { headerName: "Érték (en)", field: "en", editable: true },
            { headerName: "Műveletek", field: "operations", cellRenderer: "btnCellRenderer", sortable: false }
        ],
        rowData: [
            { key: "dog", hu: "Kutya", en: "Dog" }
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
        },
        editType: "fullRow"
    }

    return(
        <ListPageTemplate
            header={header}
            filter={filterForm}
            table={table}
            loading={false}
        >
            <TextField
                name="key"
                label="Kulcs"
                value={filter.key || ""}
                format={{xs: 12, md: 4}}
            />
            <TextField
                name="hu"
                label="Érték (hu)"
                value={filter.hu || ""}
                format={{xs: 12, md: 4}}
            />
            <TextField
                name="en"
                label="Érték (en)"
                value={filter.en || ""}
                format={{xs: 12, md: 4}}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter } = state.translations;
    return { filter };
}

const actionCreators = {
    changeForm: translationsActions.changeForm,
    defaultForm: translationsActions.defaultForm
};

export default connect(mapState, actionCreators)(Translations);