import { connect } from "react-redux";
import campusesActions from "../../../../store/masterData/campuses/actions";
import semestersActions from "../../../../store/masterData/semesters/actions";
import loadingActions from "../../../../store/loading/actions";
import notificationActions from "../../../../store/notification/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import OperationButton from "../../../Common/OperationButton";
import { useEffect } from "react";
import documentsActions from '../../../../store/masterData/documents/actions';
import TextField from '../../../Common/TextField';
import axios from "axios";
import Select from "../../../Common/Select";
import { makeOptionsFromID } from "../../../../utils/listOptions/makeOptionsFromID";
import { filterStorageGet, filterStorageSave } from "../../../../utils/FilterStorage";

function Documents(props) {
    const { filter, data, campus, semesters, filterStorageKey } = props;
    const { campusId } = props.match.params;

    const searchOtherData = { campusId: campusId };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.fetchSemesters(),
            props.search(storageFilter, searchOtherData),
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Dokumentumok",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            "documents": "Dokumentumok"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({}, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }

    const handleDownload = (params) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .download(params.data.id, params.data.fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch((error) => {
                const errorMessage = error.response.status === 404 ? "A fájl nem található" : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
    };

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton type="download" onClick={() => handleDownload(params)} />
            </>
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Név", field: "name" },
            { headerName: "Szemeszter", field: "semester.name" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
        },
        to: `/campuses/${campusId}/documents/new`
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Campus"
                name="campus.name"
                value={campus.name || ""}
                disabled={true}
                format={{ xs: 12, md: 4, lg: 3 }}
            />
            <TextField
                label="Dokumentum"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
            />
            <Select
                selectLabel="Szemeszter"
                name="semester"
                value={filter.semester || []}
                format={{ xs: 12, md: 4, lg: 3 }}
                optionList={makeOptionsFromID(semesters)}
                multiple
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.documents;
    const campus = state.campuses.form;
    const semesters = state.semesters.data;
    return { filter, data, filterStorageKey, campus, semesters };
}

const actionCreators = {
    getCampus: campusesActions.get,
    fetchSemesters: semestersActions.filter,
    search: documentsActions.filter,
    download: documentsActions.download,
    changeForm: documentsActions.changeForm,
    defaultForm: documentsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Documents);