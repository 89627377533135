import { types } from "./types";
import usersService from '../../../services/masterData/users';

function getUsers() {

    return (dispatch) => {
        dispatch({ type: types.FETCH_USER_REQUEST});
        return usersService.filter({}).then(
            (data) => {
                dispatch({ type: types.FETCH_USER_SUCCESS, data});
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_USER_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function getImpersonate() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USER_REQUEST});
        return usersService.impersonate().then(
            (data) => {
                dispatch({ type: types.FETCH_USER_SUCCESS, data});
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_USER_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    getUsers,
    getImpersonate,
}

export default actions;