import TextField from "../../Common/TextField";
import Select from "../../Common/Select";
import EnrollmentTypeCellRenderer from "../../../utils/cellRenderer/EnrollmentTypeCellRenderer";
import MemberShipStatusCellRenderer from "../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import ProcessTypeCellRenderer from "../../../utils/cellRenderer/Processes/ProcessTypeCellRenderer";
import { makeOptionsFromID } from "../../../utils/listOptions/makeOptionsFromID";
import { hasRole, isStudent } from "../../../utils/User";
import {
    isKep,
    options,
    PERMISSIONS,
    PROCESS_STATUS_OPTIONS_DETAILED,
    PROCESS_TYPES,
} from "../../../utils/AppConst";
import { filterStorageSave } from "../../../utils/FilterStorage";
import EnrollmentStatusCellRenderer from "../../../utils/cellRenderer/EnrollmentStatusCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import { BtnCellRenderer } from "./BtnCellRenderer";
import Autocomplete from "../../Common/Autocomplete";
import ListPageTemplate from "../../Common/ListPageTemplate";
import { useEffect } from "react";

export default function Processes(props) {
    const {
        name,
        filter,
        data,
        semesters,
        trainingLevels,
        filterStorageKey,
        tableInfo,
    } = props;

    const filterForm = {
        name: name,
        submitaction: () => {
            setInfo(props, "filter", filter);
            filterStorageSave(filterStorageKey, filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm(name);
            setInfo(props, "filter", {});
        },
        defaultFormKey: name,
        form: filter,
        changeform: props.changeForm,
    };

    let columns =
        hasRole(PERMISSIONS.OPERATOR_ADMIN) ||
        hasRole(PERMISSIONS.FINANCIAL_ADMIN)
            ? columnDefsForOperatorAdmins
            : columnDefs;

    if(isKep()){
        columns = columns.filter(column => column.field !== "omId");
    }

    const table = {
        columnDefs: columns,
        rowData: data["hydra:member"],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            enrollmentTypeCellRenderer: EnrollmentTypeCellRenderer,
            typeCellRenderer: ProcessTypeCellRenderer, //TODO: FIXME
            statusCellRenderer: EnrollmentStatusCellRenderer,
            memberShipStatusCellRenderer: MemberShipStatusCellRenderer,
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false,
    };

    useEffect(() => {
        props.changeForm(
            {
                ...filter,
                status: []
            },
            "ongoing"
        );
    }, [filter["@type"]])

    return (
        <ListPageTemplate filter={filterForm} table={table}>
            <TextField
                    label="Sorszám"
                    name="displayId"
                    value={filter?.displayId || ""}
                    format={{ xs: 12, md: 4 }}
                />
                <Select
                    selectLabel="Típus"
                    name="@type"
                    value={filter["@type"] || ""}
                    optionList={PROCESS_TYPES}
                    format={{ xs: 12, md: 4 }}
                />
                {name === "ongoing" && (
                    <Autocomplete
                        id="status"
                        name="status"
                        label="Folyamatstátusz"
                        value={filter?.status || []}
                        options={PROCESS_STATUS_OPTIONS_DETAILED || []}
                        filterOptions={(options) => options.filter(
                            (option) => option.type_en === filter["@type"]
                        )}
                        groupBy={(option) => option.type_hu}
                        format={{ xs: 12, md: 4 }}
                        multiple={true}
                        disabled={!filter["@type"]}
                    />
                )}
                <TextField
                    label="Név"
                    name="fullName"
                    value={filter?.fullName || ""}
                    format={{ xs: 12, md: 4 }}
                />
                {!isStudent() && (
                    <TextField
                        label="E-mail cím"
                        name="email"
                        value={filter?.email || ""}
                        format={{ xs: 12, md: 4 }}
                    />
                )}
                <Select
                    selectLabel="Szemeszter"
                    name="semester"
                    value={filter?.semester || []}
                    optionList={makeOptionsFromID(semesters)}
                    format={{ xs: 12, md: 4 }}
                    multiple
                />
                {(hasRole(PERMISSIONS.OPERATOR_ADMIN) ||
                    hasRole(PERMISSIONS.FINANCIAL_ADMIN)) && (
                    <Select
                        selectLabel="Aktuális státusz"
                        name="currentMembershipStatus"
                        value={filter?.currentMembershipStatus || []}
                        optionList={options.statusOptions}
                        format={{ xs: 12, md: 4 }}
                        multiple
                    />
                )}
                {(hasRole(PERMISSIONS.OPERATOR_ADMIN) ||
                    hasRole(PERMISSIONS.FINANCIAL_ADMIN)) && (
                    <Select
                        selectLabel="Képzési szint"
                        name="trainingLevel"
                        value={filter?.trainingLevel || []}
                        optionList={makeOptionsFromID(trainingLevels) || []}
                        format={{ xs: 12, md: 4 }}
                        multiple
                    />
                )}
        </ListPageTemplate>
    );
}

const columnDefs = [
    { headerName: "Sorszám", field: "displayId" },
    { headerName: "Típus", field: "type", cellRenderer: "typeCellRenderer" },
    {
        headerName: "Folyamatstátusz",
        field: "status",
        cellRenderer: "statusCellRenderer",
    },
    { headerName: "Név", field: "fullName", minWidth: 250 },
    { headerName: "E-mail cím", field: "email" },
    { headerName: "Campus", field: "campus.name" },
    { headerName: "Szemeszter", field: "semester.name" },
    {
        headerName: "Műveletek",
        field: "operations",
        cellRenderer: "btnCellRenderer",
        minWidth: 220,
        sortable: false,
    },
];

const columnDefsForOperatorAdmins = [
    { headerName: "Sorszám", field: "displayId" },
    { headerName: "Típus", field: "type", cellRenderer: "typeCellRenderer" },
    {
        headerName: "Folyamatstátusz",
        field: "status",
        cellRenderer: "statusCellRenderer",
    },
    { headerName: "Név", field: "fullName", minWidth: 250 },
    { headerName: "E-mail cím", field: "email" },
    { headerName: "Campus", field: "campus.name" },
    { headerName: "Épület", field: "building.name" },
    { headerName: "Szint", field: "floor.name" },
    { headerName: "Szoba", field: "room.name" },
    { headerName: "Szemeszter", field: "semester.name" },
    {
        headerName: "Aktuális státusz",
        field: "currentMembershipStatus",
        cellRenderer: "memberShipStatusCellRenderer",
    },
    { headerName: "Képzési szint", field: "trainingLevel.name" },
    {
        headerName: "Műveletek",
        field: "operations",
        cellRenderer: "btnCellRenderer",
        minWidth: 220,
        sortable: false,
    },
];
