import {Grid} from '@material-ui/core';
import React from 'react';
import CollapsibleTable from '../Processes/RoomAllocationList/Rooms/CollapsibleTable';
import Filter from './Filter';
import PageTemplate from './PageTemplate';
import Table from './Table';

/**
 *
 * @param header - objektum, amit a Header vár (lásd Header dok.)
 * @param filter - objektum, amit a Filter vár (lásd Filter dok.)
 * @param table - objektum, amit a Table vár (lásd Table dok.)
 */
export default function ListPageTemplate(props) {

    return (
        <PageTemplate header={props.header}>
            {props.filter !== undefined ?
                <Filter {...props.filter}>
                    {props.children}
                </Filter> : null}
            {!props.hideTable &&
            <Grid item xs={12}>
                <Table {...props.table} actionButtons={props.actionButtons}/>
            </Grid>
            }
            {props.unassignedStudentsTable && 
            <Grid item xs={12}>
                <Table {...props.unassignedStudentsTable} />
            </Grid>
            }
            {props.customTable && 
            <Grid item xs={12}>
                <CollapsibleTable {...props.customTable} />
            </Grid>
            }
        </PageTemplate>
    );
}