import { useEffect } from "react";
import { connect } from "react-redux";
import enrollmentActions from "../../../../store/processes/newProcess/enrollment/actions";
import loadingActions from '../../../../store/loading/actions';
import Loading from "../../../Common/Loading";
import Big from './big';
import Small from './small';

function Index(props) {
    const { form, loading } = props;
    const { id } = props.match?.params;


    useEffect(() => {
        if (id) {
            props.loadingOn();
            props.getEnrollment(id).then(() => props.loadingOff())
                .catch(() => props.loadingOff());
        }
    }, []);

    if (loading) return <Loading />

    const statusSmall = ["new", "student_filling"];
    if (statusSmall.includes(form.status)) return <Small form={form} />

    return <Big form={form} />
}



function mapState(state) {
    const form = state.enrollment.form;
    const universities = state.universities.data;
    const loading = state.loading.loading;
    return { form, universities, loading };
}

const actionCreators = {
    getEnrollment: enrollmentActions.getData,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Index);