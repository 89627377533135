import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateGet, templateUpdate } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/campuses/${otherData.campusId}/buildings/${otherData.buildingId}/floors/${otherData.floorId}/rooms/${otherData.roomId}/items`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}


const buildingsService = {
    filter,
    create: templateCreate('items'),
    get: templateGet('items'),
    update: templateUpdate('items'),
    delete: templateDelete('items')
};

export default buildingsService;