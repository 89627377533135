import React from "react";
import BasicAutocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";
import TextField from "./TextField";
import { useField, useFormikContext } from "formik";

const CustomAutocomplete = withStyles({
    tag: {
        backgroundColor: colors.primaryBlue,
        height: 24,
        position: "relative",
        zIndex: 0,
        "& .MuiChip-label": {
            color: "#fff"
    },
        "& .MuiChip-deleteIcon": {
            color: colors.primaryYellow
        }
    }
})(BasicAutocomplete);

const FormikAutocomplete = props => {
    const {name, functionToChild, formNameToChild, formToChild, ...rest} = props;
    const { initialErrors } = useFormikContext();
    const [field, meta] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    const getOptionSelected = 
        props.getOptionSelected === undefined ? 
            (option, value) => (
                option.value === value.value 
                || option.value === value
                || option === value.value
            ) 
            : props.getOptionSelected
  
    const findLabelByValue = value => props.options.find(opt => opt.value === value)?.label || ""

    const getOptionLabel = 
        props.getOptionLabel !== undefined ? 
            props.getOptionLabel 
            : (option) => option.label || findLabelByValue(option) || ""

    const handleBlur = (e) => {
        delete initialErrors[field.name];
        field.onBlur(e);
    }

    return (
        <CustomAutocomplete 
            multiple={props.multiple === undefined ? true : props.multiple} 
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            ChipProps={{
                name: name
            }}
            id={name}
            filterSelectedOptions
            onChange={(_, v) => functionToChild({...formToChild, [name]: v.map(obj => (obj.value || obj))}, formNameToChild)}
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    placeholder={props.placeholder}
                    name={name}
                    onBlur={handleBlur}
                    helperText={errorText || (props.error && props.helperText)}
                    error={!!errorText || props.error}
                />
            )}
            noOptionsText={props.noOptionsText || "Nincs találat"}
            {...rest} 
        />
    );
}

export default FormikAutocomplete;