import types from "./types";

const loadingOn = () => dispatch => dispatch({type: types.LOADING_ON});
const loadingOff = () => dispatch => dispatch({type: types.LOADING_OFF});

const tableLoadingOn = () => dispatch => dispatch({type: types.TABLE_LOADING_ON});
const tableLoadingOff = () => dispatch => dispatch({type: types.TABLE_LOADING_OFF});

const actions = {
    loadingOn,
    loadingOff,
    tableLoadingOn,
    tableLoadingOff,
}

export default actions;