import React, { memo } from "react";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import PhoneInputField from "../../../Common/PhoneInputField";
import TextField from "../../../Common/TextField";
import Grid from "@material-ui/core/Grid";

const ReadOnlyForm = memo(function ReadOnlyForm(props) {
    const { form } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    name="@type"
                    label="Típus"
                    value={form.isMoveOutProcess ? "Kiköltözés" : "Kiiratkozás"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="lastName"
                    label="Vezetéknév"
                    value={form["lastName"] || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="firstName"
                    label="Keresztnév"
                    value={form["firstName"] || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="address"
                    label="Állandó lakcím"
                    value={
                        createAddressStringFromObject(form["address"]) || "-"
                    }
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="email"
                    label="E-mail cím"
                    value={form["email"] || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <PhoneInputField
                    name="phoneNumber"
                    label="Telefonszám"
                    value={form["phoneNumber"] || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="trainingLevel"
                    label="Képzési szint"
                    value={form?.trainingLevel?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="trainingProgram"
                    label="Képzési program"
                    value={form?.trainingProgram?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="collegeYear"
                    label="Évfolyam"
                    value={form?.collegeYear?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="currentMembershipStatus"
                    label="Státusz"
                    value={
                        form?.currentMembershipStatus === "internal"
                            ? "Bentlakó"
                            : form?.currentMembershipStatus === "external"
                            ? "Külsős"
                            : "-"
                    }
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="campus"
                    label="Campus"
                    value={form?.campus?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="building"
                    label="Épület"
                    value={form?.building?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="floor"
                    label="Szint"
                    value={form?.floor?.name || "-"}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="room"
                    label="Szoba"
                    value={form?.room?.name || "-"}
                    disabled
                />
            </Grid>
        </Grid>
    );
});

export default ReadOnlyForm;
