import { connect } from "react-redux";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import { useEffect } from "react";
import AddressCellRenderer from "../../../utils/cellRenderer/AddressCellRenderer";
import ActiveCellRenderer from "../../../utils/cellRenderer/ActiveCellRenderer";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import { ArchiveCellRenderer } from "../../../utils/cellRenderer/ArchiveCellRenderer";
import Select from "../../Common/Select";
import { ARCHIVE_OPTIONS } from "../../../utils/AppConst";
import { initialState } from "../../../store/masterData/trainingLevels/reducer";

function TrainingLevels(props) {
    const { filter, data, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        props.search(storageFilter).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Képzési szintek",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "trainings": "Képzések",
            "trainingLevels": "Képzési szintek"
        }
    }

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={"/training-levels/view/" + params.data.id}
                />
                <OperationButton
                    tooltiptitle='Képzési programok'
                    type="house"
                    to={`training-levels/${params.data.id}/training-programs`}
                />
            </>
        );
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, initialState.filter);
            props.defaultForm();
            props.search(initialState.filter);
        },
        form: filter,
        changeform: props.changeForm
    }

    const table = {
        columnDefs: [
            { headerName: "Képzési szint", field: "name" },
            { headerName: "Archiválva?", field: "archive", cellRenderer: "archiveCellRenderer", minWidth: 100 },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            addressCellRenderer: AddressCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
            archiveCellRenderer: ArchiveCellRenderer
        },
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Képzési szint"
                name="name"
                value={filter.name || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <Select
                selectLabel="Archiválva?"
                name="archive"
                value={filter.archive || []}
                optionList={ARCHIVE_OPTIONS}
                format={{ xs: 12, md: 6, lg: 3 }}
                multiple
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.trainingLevels;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: trainingLevelsActions.changeForm,
    defaultForm: trainingLevelsActions.defaultForm,
    search: trainingLevelsActions.filter,
    delete: trainingLevelsActions.delete,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(TrainingLevels);