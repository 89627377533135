import OperationButton from "../../Common/OperationButton";
import { hasRole } from "../../../utils/User";
import { PERMISSIONS, PROCESS_TYPE_NAMES } from "../../../utils/AppConst";

const PROCESS_HANDLERS = {
    [PROCESS_TYPE_NAMES.ENROLLMENT]: handleEnrollment,
    [PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE]:
        handleStudentAllocationPreference,
    [PROCESS_TYPE_NAMES.RELOCATION]: handleRelocation,
    [PROCESS_TYPE_NAMES.TERMINATION]: handleTermination,
    [PROCESS_TYPE_NAMES.MOVE_OUT]: handleTermination,
};

const ROLE_PERMISSIONS = {
    [PERMISSIONS.STUDENT]: [
        "student_filling",
        "room_take_over",
        "move_out_form",
        "student_room_take_over",
        "student_declaration",
    ],
    [PERMISSIONS.FINANCIAL_ADMIN]: ["financial_check", "unpaid_debt"],
    [PERMISSIONS.UNIVERSITY_ADMIN]: [
        "filling_approval",
        "room_approval",
    ],
    [PERMISSIONS.OPERATOR_ADMIN]: [
        "operator_check",
        "operator_room_take_over",
        "approve_room_take_over",
    ],
    [PERMISSIONS.SYSTEM_ADMIN]: [
        "operator_check",
        "operator_room_take_over",
        "approve_room_take_over",
        "filling_approval",
        "room_approval",
        "financial_check",
        "operator_check",
        "unpaid_debt",
    ],
};

function handleEnrollment(status, id) {
    const enrollmentRoutes = {
        student_filling: `/forms/enrollment/${id}`,
        financial_check: `/financial-control/edit/${id}`,
        filling_approval: `/forms/approval/${id}`,
        room_approval: `/forms/room-approval/${id}`,
        room_take_over: `/forms/room-acceptance-report/${id}`,
        operator_check: `/forms/operational-control/${id}`,
    };

    return enrollmentRoutes[status];
}

function handleStudentAllocationPreference(status, id) {
    const studentAllocationPreferenceRoutes = {
        student_filling: `/student-allocation-preference-form/${id}`,
    };

    return studentAllocationPreferenceRoutes[status];
}

function handleRelocation(status, id) {
    const relocationRoutes = {
        student_filling: `/forms/relocation/student-filling/${id}`,
        financial_check: `/forms/relocation/financial-control/${id}`,
        move_out_form: `/forms/relocation/move-out-form/${id}`,
        operator_room_take_over: `/forms/relocation/operator-room-take-over/${id}`,
        student_room_take_over: `/forms/relocation/student-room-take-over/${id}`,
        approve_room_take_over: `/forms/relocation/approve-room-take-over/${id}`,
        unpaid_debt: `/forms/relocation/approve-unpaid-debt/${id}`,
    };

    return relocationRoutes[status];
}

function handleTermination(status, id) {
    const terminationRoutes = {
        student_declaration: `/forms/termination/student-declaration/${id}`,
        operator_check: `/forms/termination/operator-check/${id}`,
        financial_check: `/forms/termination/financial-check/${id}`,
    };

    return terminationRoutes[status];
}

export function BtnCellRenderer(params) {
    const { data } = params;
    const { type, status, id } = data;
    const processHandler = PROCESS_HANDLERS[type];

    if (!processHandler) {
        return "Jelenleg nincs teendőd";
    }

    const to = processHandler(status, id);

    if (!to) {
        return "Jelenleg nincs teendőd";
    }

    const userPermissions = Object.entries(ROLE_PERMISSIONS).find(([role]) =>
        hasRole(role)
    );

    if (!userPermissions) {
        return "Jelenleg nincs teendőd";
    }

    const [, allowedStatuses] = userPermissions;

    if (allowedStatuses.includes(status)) {
        return <OperationButton type="edit" to={to} />;
    }

    return "Jelenleg nincs teendőd";
}
