import TooltipRenderer from "./TooltipRenderer";

export default function CollegeYearsCellRenderer(params) {
    const collegeYears = params?.data?.collegeYears;

    let result = [];

    collegeYears.map(collegeYear => {
        result.push(collegeYear.name);
        result.push(", ");
    });
    
    result.pop();

    return TooltipRenderer(result);
}