import React from 'react';
import {withStyles, makeStyles} from "@material-ui/core/styles";
import AccordionSum from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import colors from "../../styles/colors.js";

const StyledAccordionSummary = withStyles({
    root: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
        height: 0,
        minHeight: "35px",
        '&$expanded': {
            minHeight: "35px",
        },
    },
    content: {
        '&$expanded': {
            margin: '6px 0',
        },
    },
    expanded: {},
})(AccordionSum);

const useStyles = makeStyles(() => ({
    heading: {
        color: colors.primaryYellow,
        fontSize: "large",
    }
}));

export default function AccordionSummary(props) {
    const classes = useStyles();

    return(
        <StyledAccordionSummary expandIcon={props.expandIcon} {...props}>
            <Typography component="span" className={classes.heading}>{props.text}</Typography>
        </StyledAccordionSummary>
    );
}