import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import loginService from '../../services/login';
import loadingActions from '../../store/loading/actions';
import notificationActions from '../../store/notification/actions';
import switchUserActions from '../../store/layout/switchUser/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Skeleton} from '@material-ui/lab';
import SubmitButton from "./SubmitButton";
import CancelButton from "./CancelButton";
import {DialogTitle, makeStyles} from "@material-ui/core";
import colors from "../../styles/colors";

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
}));

function SwitchUserDialog(props) {
    const {users, loading} = props;
    const [selectedAuthId, setSelectedAuthId] = React.useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (props.open) {
            setSelectedAuthId(null);
            props.getUsers();
        }
    }, [props.open]);

    const handleSubmit = () => {
        props.loadingOn();
        localStorage.setItem('layout', null);
        loginService.loginIaAs(selectedAuthId).then((response) => {

            loginService.setJWTToken(response.data.token);
            window.location.href = `${process.env.REACT_APP_AUTH_URL || 'https://auth01.mcc.hu/'}`;
        });
    };

    const handleClose = () => {
        setSelectedAuthId(null);
        props.closeAction();
    };

    return (
        <div>
            <Dialog open={props.open} aria-labelledby="form-dialog-title">
                <DialogTitle className={classes.title} id="form-dialog-title">
                    Megszemélyesítés
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A kiválasztott felhasználónál megjelenő adatokat fogja látni.
                    </DialogContentText>
                    {loading ? <Skeleton height={40} variant="rect"/> :
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setSelectedAuthId(newValue?.authId || 0);
                            }}
                            options={users?.['hydra:member'] ?? []}
                            getOptionLabel={(option) => `${option.fullName} (${option.email})`}
                            renderInput={(params) => <TextField {...params} label="Felhasználó" variant="outlined"/>}
                        />}
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleClose} color="primary">
                        Mégsem
                    </CancelButton>
                    <SubmitButton onClick={handleSubmit} color="primary" disabled={!selectedAuthId}>
                        Belépés
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function mapState(state) {
    const {users, loading} = state.switchUser;
    return {users, loading};
}

const actionCreators = {
    getUsers: switchUserActions.getImpersonate,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(SwitchUserDialog);