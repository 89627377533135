import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Copy from '../../Icons/Copy';

export default function EmailTemplatesCopy(props) {
  const {form, language} = props;

  const handleClick = () => {
    let newForm = {};
    Object.assign(newForm, {
      ...form, 'translations': {
        ...form.translations, [language]: {
          ...form.translations.hu,
          locale: language,
        },
      },
    });

    props.changeForm(newForm, 'form');

    props.changeEditor(language, form.translations.hu.content);
  };

  return (
      <Grid item container justify="flex-end">
        <IconButton onClick={handleClick}>
          <Copy/>
        </IconButton>
      </Grid>
  );
}