import React from 'react';
import { DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import DialogBasic from '@material-ui/core/Dialog';
import colors from '../../styles/colors';
import CancelButton from './CancelButton';

export const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
}));

export default function Dialog(props) {
    const { title, ...rest } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (props.handleClose) props.handleClose();
        setOpen(false);
    };
    
    return(
        <>
            {React.cloneElement(props.opener, {
                onClick: () => {
                    if(props.opener.props.onClick){
                        props.opener.props.onClick();
                    }
                    handleOpen();
                }
            })}
            <DialogBasic
                open={open}
                onClose={handleClose}
                fullWidth
                {...rest}
            >
                <DialogTitle className={classes.title}>
                    {title}
                </DialogTitle>
                <div style={{...props.contentStyle}}>
                    <DialogContent>
                        {props.children}
                    </DialogContent>
                    <DialogActions>
                        {props.action && React.cloneElement(props.action, {
                            onClick: () => {
                                if(props.action.props.onClick){
                                    props.action.props.onClick();
                                }
                                if(!props.action.props.preventClose){
                                    handleClose();
                                }
                            }
                        })}
                        {props.download}
                        <CancelButton onClick={handleClose}>
                            {props.cancelText || "Bezár"}
                        </CancelButton>
                    </DialogActions>
                </div>
            </DialogBasic>
        </>
    );
}