import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import enrollmentActions from '../../../../store/processes/newProcess/enrollment/actions';
import {isKep, options} from '../../../../utils/AppConst';
import { universityOptions } from '../../../../utils/listOptions/universityOptions';
import Autocomplete from '../../../Common/Autocomplete';
import StyledCancelButton from '../../../Common/CancelButton';
import SubmitButton from '../../../Common/SubmitButton';
import TextField from '../../../Common/TextField';
import ForeignUniversitiesAutocomplete from './ForeignUniversitiesAutocomplete';
import Title from '../../../Common/RawTitle';
import instance from '../../../../utils/API';
import { useParams } from 'react-router-dom';
import { downloadFile } from './utils';
import {withStyles} from "@material-ui/core/styles";
import BasicButton from "@material-ui/core/Button";
import colors from '../../../../styles/colors';

const StyledButton = withStyles({
    root: {
        background: colors.error,
        borderRadius: 0,
        border: 0,
        color: colors.white,
        height: 35,
        padding:'0 20px',
        boxShadow: 'black',
        '&:hover': {
            background: colors.errorHover,
        },
        '&:disabled': {
            background: colors.disabledBackground,
            color: colors.disabledColor,
        }
    },
    label: {
        textTransform: 'none'
    },
})(BasicButton);

function UniversityDetails(props) {
    const { universities } = props;
    const [form, setForm] = useState(props.universityForm);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setForm(props.universityForm);
    }, [props.universityForm]);

    const handleSubmit = () => {
        setErrors({});
        const formData = new FormData();
        formData.append('university', form.university?.value || form.university?.id || form.university);
        formData.append('foreignUniversity', form.foreignUniversity);
        formData.append('major', form.major);
        formData.append('trainingForm', form.trainingForm?.value || form.trainingForm);
        formData.append('financingForm', form.financeForm?.value || form.financeForm);
        formData.append('semesterStatus', form.semesterStatus?.value || form.semesterStatus);
        if(isKep()){
            formData.append('pedigreeNumber', form.pedigreeNumber || '')
        }else{
            formData.append('neptun', form.neptun || '');
        }
        if (form.file) {
            formData.append('file', form.file);
        } else {
            formData.append('fileName', form.fileName);
            formData.append('filePath', form.filePath);
        }

        setLoading(true);
        instance.post(
            `/enrollments/${id}/fill-student/university-study${props.universityForm.id ? '/' + props.universityForm.id : ''}`, 
            formData
        ).then(() => {
            props.getActualUniversityStudies(id).then(() => {
                setForm(null);
                setLoading(false);
            })
        })
        .catch((error) => {
            const violations = error.response?.data?.violations;

            let tmpErrors = {};
            violations?.forEach((violation) => {
                tmpErrors[violation.propertyPath] = violation.message;
            });

            setErrors(tmpErrors);
            setLoading(false);
        })
    }

    const handleDownload = () => {
        instance.get(`/enrollments/${id}/fill-student/university-study/${form.id}/download-file`, {responseType: 'blob'})
            .then((response) => {
                downloadFile(response.data, form.fileName);
            });
    }

    return (
        <Dialog
            open={!!form}
            onClose={() => {
                props.setUniversityForm(null);
                setErrors({});
            }}
            maxWidth='md'
            fullWidth
        >
            {form && (
                <>
                    <DialogContent>
                        <Typography variant='h5' paragraph={true}>Aktuális egyetem {form.id ? "szerkesztése" : "hozzáadása"}</Typography>
                        <Title title='Egyetem'>
                            <Autocomplete
                                name='university'
                                value={form.university?.id || form.university || ''}
                                options={universityOptions(universities)}
                                format={{ xs: 12 }}
                                multiple={false}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        university: v,
                                    }));
                                }}
                                error={errors.university}
                                helperText={errors.university}
                            />
                        </Title>
                        {(form.university === 1326 ||
                            form.university?.value === 1326 ||
                            form.university?.label === 'Külföldi képzés'|| 
                            form.university?.id === 1326) && ( // FIXME: itt máshogy kellene ellenőrizni
                            <ForeignUniversitiesAutocomplete
                                value={form.foreignUniversity || ''}
                                errorMessage={errors.foreignUniversity}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        foreignUniversity: v,
                                    }));
                                }}
                            />
                        )}
                        <Title title='Szak'>
                            <TextField
                                name='major'
                                value={form.major || ''}
                                onChange={handleChange}
                                error={errors.major}
                                helperText={errors.major}
                            />
                        </Title>
                        <Title title='Képzés formája'>
                            <Autocomplete
                                name='trainingForm'
                                value={form.trainingForm || ''}
                                options={options.trainingFormOptions}
                                multiple={false}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        trainingForm: v,
                                    }));
                                }}
                                error={errors.trainingForm}
                                helperText={errors.trainingForm}
                            />
                        </Title>
                        <Title title='Finanszírozás formája'>
                            <Autocomplete
                                name='financeForm'
                                value={form.financeForm || ''}
                                options={options.financingFormOptions}
                                multiple={false}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        financeForm: v,
                                    }));
                                }}
                                error={errors.financeForm}
                                helperText={errors.financeForm}
                            />
                        </Title>
                        <Title title='Hallgatói jogviszony'>
                            <Autocomplete
                                name='semesterStatus'
                                value={form.semesterStatus || ''}
                                options={options.studentStatusOptions}
                                multiple={false}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        semesterStatus: v,
                                    }));
                                }}
                                error={errors.semesterStatus}
                                helperText={errors.semesterStatus}
                            />
                        </Title>
                        {!isKep() &&
                            <Title title='Neptun kód'>
                                <TextField
                                    name='neptun'
                                    value={form.neptun || ''}
                                    onChange={handleChange}
                                    error={errors.neptun}
                                    helperText={errors.neptun}
                                />
                            </Title>
                        }
                        {isKep() &&
                            <Title title='Törzskönyvi szám (Număr matricol)'>
                                <TextField
                                    name='pedigreeNumber'
                                    value={form.pedigreeNumber || ''}
                                    onChange={handleChange}
                                    error={errors.pedigreeNumber}
                                    helperText={errors.pedigreeNumber}
                                />
                            </Title>
                        }
                        <Title title='Egyetemi felvételi határozat'>
                        {form.fileName && form.filePath ? 
                        (   
                            <Grid container spacing={2}>
                                <Grid item>
                                    <SubmitButton
                                        onClick={handleDownload}
                                        >
                                        Letöltés
                                    </SubmitButton>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        color="error"
                                        variant="contained"
                                        onClick={() => setForm((prev) => {
                                            return({
                                                ...prev,
                                                fileName: '',
                                                filePath: '',
                                            })
                                        })}
                                    >
                                        Törlés
                                    </StyledButton>
                                </Grid>
                                <Grid item align="center" alignItems = "center" >{form.fileName}</Grid>
                            </Grid>
                        ) : 
                        (<TextField
                                name='file'
                                type='file'
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ accept: '.pdf' }}
                                onChange={(e) => {
                                    setForm((prev) => {
                                        return ({
                                            ...prev,
                                            file:
                                                e.target.files[0],
                                        })
                                    });
                                }}
                                error={errors.file}
                                helperText={errors.file || 'Juniorképzést kezdő hallgatók számára kötelező!'}
                            />)}
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <StyledCancelButton onClick={() => {
                            props.setUniversityForm(null);
                            setErrors({});
                        }} color='primary'>
                            Mégse
                        </StyledCancelButton>
                        <SubmitButton
                            onClick={handleSubmit}
                            color='secondary'
                            disabled={
                                loading
                            }
                        >
                            Mentés
                        </SubmitButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    const universities = state.universities.data;
    return { form, universities };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    defaultForm: enrollmentActions.defaultForm,
    getActualUniversityStudies: enrollmentActions.getActualUniversityStudies
};

export default connect(mapState, actionCreators)(UniversityDetails);
