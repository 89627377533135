import { connect } from "react-redux";
import universitiesActions from "../../../store/masterData/universities/actions";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import Address from "../../Common/Address";
import TextField from "../../Common/TextField";
import { useEffect } from "react";
import AddressCellRenderer from "../../../utils/cellRenderer/AddressCellRenderer";
import ActiveCellRenderer from "../../../utils/cellRenderer/ActiveCellRenderer";
import Select from "../../Common/Select";
import { universityNameOptions } from "../../../utils/listOptions/universityNameOptions";
import { COUNTRIES } from "../../../utils/AppConst";
import {
    filterStorageGet,
    filterStorageSave,
} from "../../../utils/FilterStorage";

function Universities(props) {
    const { filter, data, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        props.search(storageFilter).then(() => props.loadingOff());
    }, []);

    const header = {
        title: "Egyetemek",
        breadcrumbs: {
            masterData: "Törzsadatok",
            universities: "Egyetemek",
        },
    };

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({});
        },
        form: filter,
        name: "filter",
        changeform: props.changeForm,
    };

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={"/universities/view/" + params.data.id}
                />
            </>
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Név", field: "name" },
            { headerName: "Rövid név", field: "shortName" },
            {
                headerName: "Ország",
                field: "country",
                cellRenderer: (params) =>
                    params.value === "Unknown" ? "-" : params.value,
            },
            {
                headerName: "Cím",
                field: "address",
                cellRenderer: "addressCellRenderer",
                comparator: (a, b) => a?.postCode?.localeCompare(b?.postCode),
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data["hydra:member"],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            addressCellRenderer: AddressCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
    };

    return (
        <ListPageTemplate filter={filterForm} header={header} table={table}>
            <Select
                selectLabel="Név"
                name="name"
                value={filter.name || []}
                optionList={universityNameOptions(data)}
                format={{ xs: 12, md: 4, lg: 4 }}
                multiple
            />
            <TextField
                label="Rövid név"
                name="shortName"
                value={filter.shortName || ""}
                format={{ xs: 12, md: 4, lg: 4 }}
            />
            <Select
                selectLabel="Ország"
                name="country"
                value={filter.country || []}
                format={{ xs: 12, md: 4, lg: 4 }}
                optionList={COUNTRIES}
                multiple
            />
            <Address {...filterForm} format={{ xs: 12 }} />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey } = state.universities;
    return { filter, data, filterStorageKey };
}

const actionCreators = {
    changeForm: universitiesActions.changeForm,
    defaultForm: universitiesActions.defaultForm,
    search: universitiesActions.filter,
    delete: universitiesActions.delete,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Universities);
