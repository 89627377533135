import { connect } from "react-redux";
import campusesActions from "../../store/masterData/campuses/actions";
import buildingsActions from "../../store/masterData/buildings/actions";
import floorsActions from "../../store/masterData/floors/actions";
import roomsActions from "../../store/masterData/rooms/actions";
import Form from "./Form";
import Select from "./Select";
import { makeBuildingOptions, makeCampusOptions, makeFloorOptions, makeRoomOptions } from "../MasterData/Students/options";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Campus(props) {
    const { formProps, campuses, buildings, floors, rooms, hideBuilding, hideFloor, hideRoom, assignableRooms, onlyAssignableRooms } = props;
    const { form, changeform } = formProps;
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            axios.all([
                props.fetchCampuses(),
                props.fetchBuildings(),
                props.fetchFloors(),
                onlyAssignableRooms ? props.getAssignableRooms() : props.fetchRooms(),
            ])
                .then(() => {
                    if (props.initialRoom) {
                        changeform({
                            ...form,
                            room: props.initialRoom.room || "",
                            floor: props.initialRoom.floor || "",
                            building: props.initialRoom.building || "",
                            campus: props.initialRoom.campus || "",
                        }, "form")
                    } else {
                        changeform({
                            ...form,
                            campus: form?.campus?.["id"] || form?.campus || "",
                            building: form?.building?.["id"] || form?.building || "",
                            floor: form?.floor?.["id"] || form?.floor || "",
                            room: form?.room?.["id"] || form?.room || "",
                        }, "form")
                    }
                })
                .finally(() => setFirstLoad(false));
        }
    }, []);

    useEffect(() => {
        if (!firstLoad && !props.campusDisabled) {
            changeform({ ...form, building: undefined }, "form")
        }
    }, [form.campus])

    useEffect(() => {
        if (!firstLoad) {
            changeform({ ...form, floor: undefined }, "form")
        }
    }, [form.building])

    useEffect(() => {
        if (!firstLoad) {
            changeform({ ...form, room: undefined }, "form")
        }
    }, [form.floor])

    return (
        <Form
            childComponent="true"
            {...formProps}
        >
            <Select
                name="campus"
                value={form.campus?.["id"] || form.campus || ""}
                selectLabel="Campus"
                optionList={makeCampusOptions(campuses)}
                format={{ xs: 12, md: 6 }}
                loading={firstLoad}
                disabled={props.campusDisabled || props.readOnly}
            />
            {!hideBuilding &&
                <Select
                    name="building"
                    value={form.building?.["id"] || form.building || ""}
                    selectLabel="Épület"
                    optionList={makeBuildingOptions(buildings['hydra:member'],
                        form.campus)}
                    format={{ xs: 12, md: 6 }}
                    disabled={!form.campus || props.readOnly}
                    loading={firstLoad}
                />
            }
            {!hideFloor &&
                <Select
                    name="floor"
                    value={form.floor?.["id"] || form.floor || ""}
                    selectLabel="Szint"
                    optionList={makeFloorOptions(floors['hydra:member'],
                        form.building)}
                    format={{ xs: 12, md: 6 }}
                    disabled={!form.building || !form.campus || props.readOnly}
                    loading={firstLoad}
                />
            }
            {!hideRoom &&
                <Select
                    name="room"
                    value={form.room?.["id"] || form.room || ""}
                    selectLabel="Szoba"
                    optionList={onlyAssignableRooms ? makeRoomOptions(assignableRooms['hydra:member'], form.floor) : makeRoomOptions(rooms['hydra:member'], form.floor)}
                    format={{ xs: 12, md: 6 }}
                    disabled={!form.floor || !form.building || !form.campus ||
                        props.readOnly}
                    loading={firstLoad}
                />
            }
        </Form>
    );
}

function mapState(state) {
    const campuses = state.campuses.data;
    const buildings = state.buildings.data;
    const floors = state.floors.data;
    const rooms = state.rooms.data;
    const assignableRooms = state.rooms.assignableRooms;
    return { campuses, buildings, floors, rooms, assignableRooms };
}

const actionCreators = {
    fetchCampuses: campusesActions.filter,
    fetchBuildings: buildingsActions.fetch,
    fetchFloors: floorsActions.fetch,
    fetchRooms: roomsActions.fetch,
    getAssignableRooms: roomsActions.getAssignableRooms,
};

export default connect(mapState, actionCreators)(Campus);