import { mainMenus, PERMISSIONS, subMenus } from "../../../utils/AppConst";
import { hasRole } from "../../../utils/User";

export default class RolesRules {
    constructor(roles) {
        this.roles = roles;
    }

    getMenu() {
        const roles = this.roles || [];
        let result = [];
        let tmp = [];

        if (hasRole(PERMISSIONS.SYSTEM_ADMIN)) {
            return Object.values(mainMenus);
        }

        roles.map((role) => {
            switch (role) {
                case PERMISSIONS.UNIVERSITY_ADMIN:
                    {
                        tmp = [
                            {
                                name: "Folyamatok",
                                subMenus: [subMenus.processesTabs, subMenus.newProcess],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                case PERMISSIONS.OPERATOR_ADMIN:
                    {
                        tmp = [
                            {
                                name: "Törzsadatok",
                                subMenus: [subMenus.campuses],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    mergeObjects(dest, src) {
        const result = [];
        let i = 0;
        for (const value of Object.values(src)) {
            const findPair = Object.values(dest).filter((d) => d.name === value.name);
            const pair = findPair.length > 0 ? findPair[0] : { name: value.name, subMenus: [] };
            result[i] = {
                name: value.name,
                subMenus: [
                    ...value.subMenus,
                    ...pair.subMenus.filter((fmenu) => !value.subMenus.map((vmenu) => vmenu.to).includes(fmenu.to)),
                ].sort((a, b) => this.findIndexInSubMenus(a.to) - this.findIndexInSubMenus(b.to)),
            };
            i++;
        }

        return [...dest.filter((d) => src.filter((s) => s.name === d.name).length === 0), ...result].sort(
            (a, b) => this.findIndexInMainMenus(a.name) - this.findIndexInMainMenus(b.name)
        );
    }

    findIndexInSubMenus(to) {
        return Object.values(subMenus).findIndex((menu) => menu.to === to);
    }

    findIndexInMainMenus(name) {
        return Object.values(mainMenus).findIndex((menu) => menu.name === name);
    }
}
