export const types = {
    CHANGE_FORM: "@@relocation_financial_control/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation_financial_control/DEFAULT_FORM",
    CHANGE_EDITOR: "@@relocation_financial_control/CHANGE_EDITOR",

    GET_REQUEST: "@@relocation_financial_control/GET_REQUEST",
    GET_SUCCESS: "@@relocation_financial_control/GET_SUCCESS",
    GET_FAILURE: "@@relocation_financial_control/GET_FAILURE",
    
    APPROVE_FINANCIAL_CHECK_REQUEST: "@@relocation_financial_control/APPROVE_FINANCIAL_CHECK_REQUEST",
    APPROVE_FINANCIAL_CHECK_SUCCESS: "@@relocation_financial_control/APPROVE_FINANCIAL_CHECK_SUCCESS",
    APPROVE_FINANCIAL_CHECK_FAILURE: "@@relocation_financial_control/APPROVE_FINANCIAL_CHECK_FAILURE",

    UPDATE_FINANCIAL_COMMENT_REQUEST: "@@relocation_financial_control/UPDATE_FINANCIAL_COMMENT_REQUEST",
    UPDATE_FINANCIAL_COMMENT_SUCCESS: "@@relocation_financial_control/UPDATE_FINANCIAL_COMMENT_SUCCESS",
    UPDATE_FINANCIAL_COMMENT_FAILURE: "@@relocation_financial_control/UPDATE_FINANCIAL_COMMENT_FAILURE",

    SEND_UNPAID_DEBT_NOTIFICATION_REQUEST: "@@relocation_financial_control/SEND_UNPAID_DEBT_NOTIFICATION_REQUEST",
    SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS: "@@relocation_financial_control/SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS",
    SEND_UNPAID_DEBT_NOTIFICATION_FAILURE: "@@relocation_financial_control/SEND_UNPAID_DEBT_NOTIFICATION_FAILURE",

    APPROVE_UNPAID_DEBT_REQUEST: "@@relocation_approve_unpaid_debt/APPROVE_UNPAID_DEBT_REQUEST",
    APPROVE_UNPAID_DEBT_SUCCESS: "@@relocation_approve_unpaid_debt/APPROVE_UNPAID_DEBT_SUCCESS",
    APPROVE_UNPAID_DEBT_FAILURE: "@@relocation_approve_unpaid_debt/APPROVE_UNPAID_DEBT_FAILURE",

    GET_FORM_REQUEST: "@@relocation_financial_control/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@relocation_financial_control/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@relocation_financial_control/GET_FORM_FAILURE",
}