import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { Children } from "react";
import colors from "./../../styles/colors";

const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: colors.primaryBlue,
        color: colors.primaryYellow,
    },
    body: {
        backgroundColor: "rgba(54,67,84,0.1)",
    },
    cell: {
        color: colors.primaryBlue,
    },
}));

export default function SmallTable(props) {
    const { columns, rows } = props;
    const classes = useStyles();
    const defaultTransformer = (value) => value || "-";

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {Children.toArray(
                            columns.map((column) => (
                                <TableCell className={classes.header}>
                                    {column.label}
                                </TableCell>
                            ))
                        )}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.body}>
                    {rows?.length === 0 ? (
                        <TableRow>
                            <TableCell
                                style={{
                                    textAlign: "center",
                                    margin: "8px",
                                    fontWeight: "bold",
                                }}
                                colSpan={columns.length}
                            >
                                Nincs megjeleníthető adat.
                            </TableCell>
                        </TableRow>
                    ) : (
                        Children.toArray(
                            rows?.map((row) => (
                                <TableRow>
                                    {Children.toArray(
                                        columns.map((column) => {
                                            const colName = column.value;
                                            return (
                                                <TableCell
                                                    className={classes.cell}
                                                    style={{
                                                        minWidth:
                                                            props.minWidth || 0,
                                                        width:
                                                            colName ===
                                                            "operations"
                                                                ? 200
                                                                : "auto",
                                                    }}
                                                >
                                                    {(
                                                        column.transformer ||
                                                        defaultTransformer
                                                    )(row[column.value])}
                                                </TableCell>
                                            );
                                        })
                                    )}
                                </TableRow>
                            ))
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
