import { options } from "../AppConst";
import TooltipRenderer from "./TooltipRenderer";

export default function GenderCellRenderer(params) {
    let result = "-";
    
    options.genderOptions.forEach(x => {
        if (x.value === params.value) {
            result = x.label;
        }
    });

    return TooltipRenderer(result);
}