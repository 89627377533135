export const makeTrainingProgramOptions = (trainingPrograms, trainingLevel) => {
    let options = [];

    if (trainingPrograms && trainingLevel) {
        trainingPrograms?.map((elem) => {
            if (elem.trainingLevel.id === trainingLevel) {
                options.push({
                    value: elem.id,
                    label: elem.name,
                });
            }
        });
    }

    return options;
};

export const makeTrainingProgramOptionsMultiple = (
    trainingPrograms,
    trainingLevels
) => {
    let options = [];
    if (trainingPrograms && trainingLevels && Array.isArray(trainingLevels) && Array.isArray(trainingPrograms)) {
        trainingLevels?.forEach((trainingLevel) => {
            trainingPrograms?.map((elem) => {
                if (elem.trainingLevel["id"] === trainingLevel) {
                    options.push({
                        value: elem["id"],
                        label: elem.name,
                    });
                }
            });
        });
    }

    return options;
};

export const makeTrainingProgramOptionsMultipleHydra = (
    trainingPrograms,
    trainingLevels
) => {
    let options = [];

    if (trainingPrograms && trainingLevels) {
        trainingLevels?.forEach((trainingLevel) => {
            trainingPrograms?.map((elem) => {
                if (elem.trainingLevel["@id"] === trainingLevel) {
                    options.push({
                        value: elem["@id"],
                        label: elem.name,
                    });
                }
            });
        });
    }

    return options;
};

export const makeCollegeYearOptions = (collegeYears, trainingProgram) => {
    let options = [];

    if (collegeYears && trainingProgram) {
        collegeYears?.forEach((collegeYear) => {
            collegeYear?.trainingPrograms?.forEach((elem) => {
                if (typeof elem === 'string') {
                    const match = elem.match(/\/training-programs\/(\d+)$/);
                    if (match && match[1]) {
                        const elemId = Number(match[1]);
                        if (elemId === trainingProgram) {
                            options.push({
                                value: collegeYear.id,
                                label: collegeYear.name,
                            });
                        }
                    }
                } else if (typeof elem === 'object' && elem.id) {
                    if (elem.id === trainingProgram) {
                        options.push({
                            value: collegeYear.id,
                            label: collegeYear.name,
                        });
                    }
                }
            });
        });
    }

    return options;
};

export const makeCollegeYearOptionsMultiple = (
    collegeYears,
    trainingPrograms
) => {
    const options = [];
    const uniqueOptionIds = new Set();

    if (collegeYears && trainingPrograms) {
        trainingPrograms.forEach((trainingProgram) => {
            collegeYears.forEach((collegeYear) => {
                collegeYear?.trainingPrograms?.forEach((elem) => {
                    if (elem.id === trainingProgram) {
                        const optionId = collegeYear["id"];
                        if (!uniqueOptionIds.has(optionId)) {
                            options.push({
                                value: optionId,
                                label: collegeYear.name,
                            });
                            uniqueOptionIds.add(optionId);
                        }
                    }
                });
            });
        });
    }

    return options;
};

export const makeCampusOptions = (data) => {
    return (data && data['hydra:member'])
        ? data['hydra:member'].map((elem) => {
            return {
                value: elem["@id"] ? elem["@id"].match(/\d+/)[0] : elem.id,
                label: elem.name,
            };
        })
        : [];
};

export const makeBuildingOptions = (buildings, campus) => {
    let options = [];

    if (typeof campus === "object" && "id" in campus) {
        campus = campus["id"];
    }

    if (buildings && campus) {
        buildings.map((elem) => {
            if (elem.campus["id"] === campus) {
                options.push({
                    value: elem["id"],
                    label: elem.name,
                });
            }
        });
    }

    return options;
};

export const makeFloorOptions = (floors, building) => {
    let options = [];

    if (typeof building === "object" && "id" in building) {
        building = building["id"];
    }

    if (floors && building) {
        floors.map((elem) => {
            if (elem.building["id"] === building) {
                options.push({
                    value: elem["id"],
                    label: elem.name,
                });
            }
        });
    }

    return options;
};

export const makeRoomOptions = (rooms, floor) => {
    let options = [];

    if (typeof floor === "object" && "id" in floor) {
        floor = floor["id"];
    }

    if (rooms && floor) {
        rooms.map((elem) => {
            if (elem.floor["id"] === floor) {
                options.push({
                    value: elem["id"],
                    label: elem.name,
                });
            }
        });
    }

    return options;
};

export const renderArchiveOption = (option) =>
    option.label?.includes("(archív)") ? (
        <div style={{ color: "#999" }}>{option.label}</div>
    ) : (
        option.label
    );
