import API from "../utils/API";

export default async function fetchTenantData() {

    try {
        const response = await API.get('/current-tenant');
        console.log('API HEADEREK', API.defaults.headers);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching tenant data:', error);
        return null;
    }
}