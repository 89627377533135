import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateFetch, templateGet, templateUpdate } from '../..';

function filter(form, otherData) {
    const searchForm = new SearchForm(form);

    return API.get(`/campuses/${otherData.campusId}/buildings`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const buildingsService = {
    filter,
    fetch: templateFetch('buildings'),
    create: templateCreate('buildings'),
    get: templateGet('buildings'),
    update: templateUpdate('buildings'),
    delete: templateDelete('buildings')
};

export default buildingsService;