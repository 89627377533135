export const types = {
    CHANGE_FORM: "@@relocation-student-filling/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation-student-filling/DEFAULT_FORM",

    GET_REQUEST: "@@relocation-student-filling/GET_REQUEST",
    GET_SUCCESS: "@@relocation-student-filling/GET_SUCCESS",
    GET_FAILURE: "@@relocation-student-filling/GET_FAILURE",

    FILL_STUDENT_FORM_REQUEST: "@@relocation-student-filling/FILL_STUDENT_FORM_REQUEST",
    FILL_STUDENT_FORM_SUCCESS: "@@relocation-student-filling/FILL_STUDENT_FORM_SUCCESS",
    FILL_STUDENT_FORM_FAILURE: "@@relocation-student-filling/FILL_STUDENT_FORM_FAILURE",

    GET_FORM_REQUEST: "@@relocation-student-filling/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@relocation-student-filling/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@relocation-student-filling/GET_FORM_FAILURE",
}