import API from "../../../utils/API";
import { templateFetch } from "../..";
import SearchForm from "../../../utils/SearchForm";
import downloadAPI from "../../../utils/downloadAPI";

function assignAllocation(id, form) {
    return API.post(`/student-allocations/${id}/assign`, form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function assignAllStudents(id) {
    return API.post(`/allocations/${id}/assign-students/all`, {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function assignNewStudents(id) {
    return API.post(`/allocations/${id}/assign-students/new`, {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function checkAssignmentStatus(allocationId, sessionId) {
    return API.get(
        `/allocations/${allocationId}/check-assignment-status/${sessionId}`
    )
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function downloadExport(filter, allocationId) {
    const searchForm = new SearchForm(filter);

    return downloadAPI
        .get(`/student-allocations/${allocationId}/students/export`, {
            params: searchForm.getSend(),
        })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getActiveAllocationByCampus(campusId) {
    return API.get(`/active-allocations/${campusId}`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filterRooms(id, form) {
    const searchForm = new SearchForm(form);

    return API.get(`/student-allocations/${id}/rooms`, {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filterStudents(id, form) {
    const searchForm = new SearchForm(form);

    return API.get(`/student-allocations/${id}/students`, {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filterUnassignedStudents(id, form) {
    const searchForm = new SearchForm(form);

    return API.get(`/student-allocations/${id}/unassigned-students`, {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function get(id) {
    return API.get(`/allocations/${id}/basic`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function resetStudentAssignment(allocationId) {
    return API.post(`/allocations/${allocationId}/reset-student-assignment`, {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getSemesterNameForDownload(allocationId) {
    return API.get(
        `/allocations/${allocationId}/get-semester-name-for-download`
    )
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getFreeRooms(allocationId, form) {
    const searchForm = new SearchForm(form);

    return API.get(`/student-allocations/${allocationId}/free-rooms`, {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function checkIfRelocationExistsForAllocation(id) {
    return API.get(
        `/allocations/${id}/check-if-relocation-exists-for-allocation`
    )
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function checkIfTerminationExistsForAllocation(id) {
    return API.get(
        `/allocations/${id}/check-if-termination-exists-for-allocation`
    )
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const allocationService = {
    assignAllocation,
    assignAllStudents,
    assignNewStudents,
    checkAssignmentStatus,
    checkIfRelocationExistsForAllocation,
    checkIfTerminationExistsForAllocation,
    downloadExport,
    fetch: templateFetch("allocations"),
    filterRooms,
    filterStudents,
    filterUnassignedStudents,
    get,
    getActiveAllocationByCampus,
    resetStudentAssignment,
    getSemesterNameForDownload,
    getFreeRooms,
};

export default allocationService;
