import { templateChangeForm, templateDefaultForm, templateGet } from "../../..";
import relocationsService from "../../../../services/processes/relocation";
import { types } from "./types";
import notificationTypes from "../../../notification/types";
import { history } from "../../../../store";

function approveTakeOverRoom(form, id, url) {
    const formToSend = {
        relocation: Number(id),
    }

    return (dispatch) => {
        dispatch({ type: types.APPROVE_TAKE_OVER_ROOM_REQUEST, formToSend});
        return relocationsService.approveTakeOverRoom(id, formToSend).then(
            (data) => {
                dispatch({ type: types.APPROVE_TAKE_OVER_ROOM_SUCCESS, data });
                dispatch({ 
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {type: "success", msg: "Sikeres mentés!"}
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.APPROVE_TAKE_OVER_ROOM_FAILURE, error });
                dispatch({ 
                  type: notificationTypes.ADD_NOTIFICATION,
                  data: {type: "error", msg: "Hiba lépett fel mentés közben"}
                })
                return Promise.reject(error);
            }
        )
    }
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return relocationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, relocationsService),
    getForm,
    approveTakeOverRoom
}

export default actions;