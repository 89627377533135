import { withStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from "../../styles/colors.js";

const Expand = withStyles({
    root: {
        color: colors.primaryYellow,
        fontSize: "xx-large",
    }
})(ExpandMoreIcon);

export default Expand;