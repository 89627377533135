import { useEffect } from 'react';
import { connect } from 'react-redux';
import loadingActions from '../../../store/loading/actions';
import enrollmentActions from '../../../store/processes/newProcess/enrollment/actions';
import roomApprovalActions from '../../../store/processes/roomApproval/actions';
import Campus from '../../Common/Campus';
import FormPageTemplate from '../../Common/FormPageTemplate';
import SubmitButton from './../../Common/SubmitButton';
import CancelButton from './../../Common/CancelButton';
import TextField from './../../Common/TextField';
import { history } from '../../../store';
import { makeStyles } from '@material-ui/core';
import colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        }
    }
}))

function RoomApproval(props) {
    const { form, enrollment } = props;
    const { id } = props.match.params;
    const classes = useStyles();

    const header = {
        title: "Szoba jóváhagyása",
        breadcrumbs: {
            "room-approval": "Szoba jóváhagyása"
        }
    }

    useEffect(() => {
        props.defaultForm("form");
        props.loadingOn();
        props.getEnrollment(id)
        .then(() => props.loadingOff())
        .catch(() => props.loadingOff());
    }, [])

    const handleAccept = () => {
        props.loadingOn();
        props.accept(form, id).then(() => props.loadingOff());
    }

    const handleAcceptAndClose = () => {
        props.loadingOn();
        props.acceptAndClose(form, id).then(() => props.loadingOff());
    }

    const formProps = {
        form: form,
        changeform: props.changeForm
    }

    return (
        <FormPageTemplate
            header={header}
            form={formProps}
        >
            <TextField
                label="Típus"
                name="type"
                value={enrollment["type"] === "enrollment" ? "Beiratkozás" : "Kiiratkozás" || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={enrollment.lastName || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={enrollment.firstName || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Képzési szint"
                name="trainingLevel"
                value={enrollment.trainingLevel?.name || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Képzési program"
                name="trainingProgram"
                value={enrollment.trainingProgram?.name || ""}
                format={{xs: 12}}
                disabled
            />
            <TextField
                label="Évfolyam"
                name="collegeYear"
                value={enrollment.collegeYear?.name || ""}
                format={{xs: 12}}
                disabled
            />
            <Campus 
                formProps={formProps} 
                format={{xs: 12}} 
                campusDisabled
                onlyAssignableRooms
                initialRoom={{
                    room: enrollment?.room?.["id"] ?? "",
                    floor: enrollment?.floor?.["id"] ?? "",
                    building: enrollment?.building?.["id"] ?? "",
                    campus: enrollment?.campus?.["id"] ?? "",
                }}
            />
            <SubmitButton 
                onClick={handleAccept}
                disabled={!form.room}
            >
                Elfogadás és tovább a szobaátvételre
            </SubmitButton>
            <SubmitButton 
                onClick={handleAcceptAndClose}
                disabled={!form.room}
                className={classes.submit}
            >
                Elfogadás és lezárás
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Vissza
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.roomApproval;
    const enrollment = state.enrollment.form;
    return { form, enrollment };
}

const actionCreators = {
    changeForm: roomApprovalActions.changeForm,
    defaultForm: roomApprovalActions.defaultForm,
    accept: roomApprovalActions.accept,
    acceptAndClose: roomApprovalActions.acceptAndClose,
    getEnrollment: enrollmentActions.getForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(RoomApproval);