import React from "react";
import MenuList from "@material-ui/core/MenuList";
import NavMenuItem from "../../Common/NavMenuItem";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Children } from "react";
import Topic from "../../Common/Topic";
import RolesRules from "./RolesRules";

/**
 * Tudnivalók:
 * * Menüpontok az AppConsts.js-ben
 * @returns Oldalmenü
 */

export default function SidebarMenu({ roles, onClick }) {
    const getMenu = () => new RolesRules(roles).getMenu();

    return (
        <MenuList style={{padding: 0}}>
            <NavMenuItem to="/" text="Főoldal" exact icon={faHome} onClick={onClick} />
            {
                Children.toArray(
                    getMenu().map(topic => (
                        <Topic text={topic.name}>
                            {
                                Children.toArray(topic.subMenus.map(subMenu =>(
                                    <NavMenuItem to={subMenu.to} icon={subMenu.icon} text={subMenu.text} onClick={onClick} />
                                )))
                            }
                        </Topic>
                    ))
                )
            }
        </MenuList>
    );
}
