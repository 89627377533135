import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import loadingActions from '../../store/loading/actions';
import colors from '../../styles/colors';
import Accordion from './Accordion';
import CancelButton from './CancelButton';
import Form from './Form';
import SubmitButton from './SubmitButton';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block"
    },
    filterButton: {
        backgroundColor: colors.filter,
        '&:hover': {
            background: colors.filterHover,
            color: colors.white
        },
    },
    danger: {
        backgroundColor: colors.error,
        '&:hover': {
            backgroundColor: colors.errorHover,
        }
    },
    fixWidth: {
        minWidth: "40%",
    },
}));

/**
 * 
 * @param submitaction - szűréskor meghívandó függvény
 * @param defaultForm - alapértelmezettre visszaállításkor meghívandó függvény 
 */

function Filter(props) {
    const { defaultform: defaultForm, defaultFormKey, ...rest } = props;
    const classes = useStyles();

    const handleDefault = () => {
        if (props.defaultFormAction) {
            props.defaultFormAction();
        } else if (defaultForm) {
            defaultForm(defaultFormKey ? defaultFormKey : "filter");
            try {
                props.loadingOn();
                props.submitaction({}, props?.searchotherdata).then(() => props.loadingOff());
            } catch (e) {
                console.error("Ez a funkció még nem lett bekötve.");
                props.loadingOff();
            }
        }
    }

    return (
        <Grid item xs={12}>
            <Accordion title="Szűrő" defaultExpanded={props.collapseFilter ? false : true}>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Form
                            name={props.name || "filter"}
                            onSubmit={() => props.submitaction(props.form, props?.searchotherdata)}
                            {...rest}
                        >
                            {props.children}
                            <Grid item container format={{ xs: 12 }} spacing={1}>
                                <Grid item xs={6} sm={5} md={3} lg={2} xl={1}>
                                    <SubmitButton
                                        type="submit"
                                        classes={{
                                            root: classes.filterButton
                                        }}
                                        disabled={props.onSubmitDisabled === undefined ? false : props.onSubmitDisabled}
                                        fullWidth
                                    >
                                        Szűrés
                                    </SubmitButton>
                                </Grid>
                                <Grid item xs={6} sm={5} md={3} lg={2} xl={1}>
                                    <CancelButton
                                        type="button"
                                        onClick={handleDefault}
                                        fullWidth
                                    >
                                        Alapértelmezett
                                    </CancelButton>
                                </Grid>
                                {props.filterButtons && props.filterButtons()}
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            </Accordion>
        </Grid>
    );
}

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(null, actionCreators)(Filter);