import { withStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import colors from "../../styles/colors.js";

const Edit = withStyles({
    root: {
        backgroundColor: colors.primaryYellow,
        borderRadius: 0,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        '&:hover': {
            backgroundColor: colors.primaryYellowHover,
        }
    }
})(EditIcon);

export default Edit;