import { connect } from "react-redux";
import ListPageTemplate from "../../Common/ListPageTemplate";
import Select from "../../Common/Select";
import TextField from "../../Common/TextField";
import studentAllocationPreferenceListActions from "../../../store/reports/studentAllocationPreferenceList/actions";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../store/masterData/trainingPrograms/actions";
import collegeYearsActions from "../../../store/masterData/collegeYears/actions";
import buildingsActions from "../../../store/masterData/buildings/actions";
import floorsActions from "../../../store/masterData/floors/actions";
import roomsActions from "../../../store/masterData/rooms/actions";
import notificationActions from "../../../store/notification/actions";
import { useEffect, useState } from "react";
import axios from "axios";
import loadingActions from "../../../store/loading/actions";
import {
    makeBuildingOptions,
    makeFloorOptions,
    makeRoomOptions,
} from "../../Processes/RoomAllocationList/options";
import EmailHoverCellRenderer from "../../../utils/cellRenderer/EmailHoverCellRenderer";
import Title from "../../Common/Title";
import BtnCellRenderer from "./BtnCellRenderer";
import {
    filterStorageGet,
    filterStorageSave,
} from "../../../utils/FilterStorage";
import RoomMateCellRenderer from "../../../utils/cellRenderer/RoomMateCellRenderer";
import MemberShipStatusCellRenderer from "../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import { MEMBERSHIP_STATUS_OPTIONS } from "../../../utils/AppConst";
import {
    makeOptionsFromIDWithArchive,
    makeTrainingProgramOptionsFromIDWithArchive,
} from "../../../utils/listOptions/makeOptionsFromID";
import { renderArchiveOption } from "../../MasterData/Students/options";
import Loading from "../../Common/Loading";
import roomAllocationListActions from "../../../store/processes/roomAllocationList/actions";

function StudentAllocationPreferenceList(props) {
    const {
        filter,
        data,
        filterStorageKey,
        semesterNameForDownload,
        trainingLevels,
        trainingPrograms,
        collegeYears,
        buildings,
        floors,
        rooms,
        allocation,
    } = props;
    const allocationId = props.match?.params?.id;
    const user = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setLoading(true);
        axios
            .all([
                props.getAllocation(allocationId),
                props.fetchTrainingLevels(),
                props.fetchTrainingPrograms(),
                props.fetchCollegeYears(),
                props.fetchBuildings(),
                props.fetchFloors(),
                props.getAssignableRooms(),
                props.getSemesterNameForDownload(allocationId),
                props.search(allocationId, storageFilter),
            ])
            .finally(() => setLoading(false));
    }, []);

    const header = {
        title: "Szobabeosztás (diák)",
        breadcrumbs: {
            reports: "Riportok",
            studentAllocationPreferenceList: "Szobabeosztás (diák)",
            semester: allocation?.semester?.name,
        },
    };

    if (loading) {
        return <Loading />;
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(allocationId, filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            props.search(allocationId, {});
        },
        form: filter,
        changeform: props.changeForm,
    };

    const downloadExportAction = () => {
        let date = new Date().toISOString().split("T")[0];
        let fileName = `Szobabeosztas_${semesterNameForDownload}_${date}.xlsx`;
        props.addNotification("info", "Fájl letöltése folyamatban...");
        props
            .downloadExport(filter, allocationId, fileName)
            .then(() =>
                props.addNotification("success", "Fájl letöltése sikeres!")
            )
            .catch(() =>
                props.addNotification(
                    "error",
                    "Hiba lépett fel a fájl letöltése közben."
                )
            );
    };

    const table = {
        columnDefs: [
            {
                headerName: "Név",
                field: "fullName",
                minWidth: 280,
                cellRenderer: "emailHoverCellRenderer",
            },
            { headerName: "Képzési szint", field: "trainingLevel.name" },
            {
                headerName: "Kézpési program",
                field: "trainingProgram.name",
                minWidth: 150,
            },
            { headerName: "Évfolyam", field: "collegeYear.name" },
            {
                headerName: "Aktuális státusz",
                field: "currentMembershipStatus",
                cellRenderer: "membershipStatusCellRenderer",
            },
            { headerName: "Épület", field: "building.name" },
            { headerName: "Szint", field: "floor.name" },
            { headerName: "Szoba", field: "room.name" },
            {
                headerName: "Választott szobatárs",
                field: "preference.roomMates",
                cellRenderer: "RoomMateCellRenderer",
                sortable: false,
            },
            { headerName: "Megjegyzés", field: "preference.comment" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: (params) => BtnCellRenderer(params, allocationId),
            emailHoverCellRenderer: EmailHoverCellRenderer,
            membershipStatusCellRenderer: MemberShipStatusCellRenderer,
            RoomMateCellRenderer: RoomMateCellRenderer,
        },
        rowClassRules: {
            "green-row": (params) => params.data.preferenceGranted,
        },
        download: true,
        onClick: downloadExportAction,
    };

    return (
        <ListPageTemplate table={table} filter={filterForm} header={header}>
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={filter.lastName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={filter.firstName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Képzési szint"
                name="trainingLevel"
                value={filter.trainingLevel || []}
                optionList={makeOptionsFromIDWithArchive(trainingLevels) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Képzési program"
                name="trainingProgram"
                value={filter.trainingProgram || []}
                optionList={
                    makeTrainingProgramOptionsFromIDWithArchive(
                        trainingPrograms
                    ) || []
                }
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Évfolyam"
                name="collegeYear"
                value={filter.collegeYear || []}
                optionList={makeOptionsFromIDWithArchive(collegeYears) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Aktuális státusz"
                name="currentMembershipStatus"
                value={filter.currentMembershipStatus || []}
                optionList={MEMBERSHIP_STATUS_OPTIONS}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Title title="Szoba" {...filterForm} format={{ xs: 12 }} />
            <TextField
                label="Campus"
                name="campus"
                value={filter.campus || user?.campus?.name || "Campus neve"}
                format={{ xs: 12, md: 3 }}
                disabled
            />
            <Select
                selectLabel="Épület"
                name="building"
                value={filter.building || ""}
                format={{ xs: 12, md: 3 }}
                optionList={makeBuildingOptions(
                    buildings["hydra:member"],
                    user?.campus
                )}
            />
            <Select
                name="floor"
                value={filter.floor || ""}
                selectLabel="Szint"
                optionList={makeFloorOptions(
                    floors["hydra:member"],
                    filter.building
                )}
                format={{ xs: 12, md: 3 }}
                disabled={!filter.building}
            />
            <Select
                name="room"
                value={filter.room || ""}
                selectLabel="Szoba"
                optionList={makeRoomOptions(
                    rooms["hydra:member"],
                    filter.floor
                )}
                format={{ xs: 12, md: 3 }}
                disabled={!filter.floor || !filter.building}
            />
            <Select
                selectLabel="Szobatársak"
                name="roomMates"
                value={filter.roomMates || []}
                optionList={[]}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey, semesterNameForDownload } =
        state.studentAllocationPreferenceList;
    const { allocation } = state.roomAllocationList;
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const buildings = state.buildings.data;
    const floors = state.floors.data;
    const rooms = state.rooms.assignableRooms;
    return {
        filter,
        data,
        filterStorageKey,
        semesterNameForDownload,
        trainingLevels,
        trainingPrograms,
        collegeYears,
        buildings,
        floors,
        rooms,
        allocation,
    };
}

const actionCreators = {
    changeForm: studentAllocationPreferenceListActions.changeForm,
    defaultForm: studentAllocationPreferenceListActions.defaultForm,
    search: studentAllocationPreferenceListActions.filter,
    downloadExport: studentAllocationPreferenceListActions.downloadExport,
    fetchTrainingLevels: trainingLevelsActions.filter,
    fetchTrainingPrograms: trainingProgramsActions.fetch,
    fetchCollegeYears: collegeYearsActions.fetch,
    fetchBuildings: buildingsActions.fetch,
    fetchFloors: floorsActions.fetch,
    getAssignableRooms: roomsActions.getAssignableRooms,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
    getSemesterNameForDownload:
        studentAllocationPreferenceListActions.getSemesterNameForDownload,
    getAllocation: roomAllocationListActions.getAllocation,
};

export default connect(
    mapState,
    actionCreators
)(StudentAllocationPreferenceList);
