import { PROCESS_TYPE_NAMES } from "../AppConst";
import TooltipRenderer from "./TooltipRenderer";

export default function EnrollmentTypeCellRenderer(params) {
    const type = params.data["type"];

    let translatedType = "";

    switch (type) {
        case PROCESS_TYPE_NAMES.ENROLLMENT:
            translatedType = "Beiratkozás";
            break;
        case PROCESS_TYPE_NAMES.STUDENT_ALLOCATION_PREFERENCE:
            translatedType = "Szobatárspreferencia";
            break;
        case PROCESS_TYPE_NAMES.RELOCATION:
            translatedType = "Átköltözés";
            break;
        case PROCESS_TYPE_NAMES.TERMINATION:
            translatedType = "Kiiratkozás";
            break;
        case PROCESS_TYPE_NAMES.MOVE_OUT:
            translatedType = "Kiköltözés";
            break;
    }

    return TooltipRenderer(translatedType);
}
