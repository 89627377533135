export const types = {
    CHANGE_FORM: "@@relocation_move_out_form/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation_move_out_form/DEFAULT_FORM",

    GET_REQUEST: "@@relocation_move_out_form/GET_REQUEST",
    GET_SUCCESS: "@@relocation_move_out_form/GET_SUCCESS",
    GET_FAILURE: "@@relocation_move_out_form/GET_FAILURE",
    
    FILL_MOVE_OUT_FORM_REQUEST: "@@relocation_move_out_form/FILL_MOVE_OUT_FORM_REQUEST",
    FILL_MOVE_OUT_FORM_SUCCESS: "@@relocation_move_out_form/FILL_MOVE_OUT_FORM_SUCCESS",
    FILL_MOVE_OUT_FORM_FAILURE: "@@relocation_move_out_form/FILL_MOVE_OUT_FORM_FAILURE",

    GET_FORM_REQUEST: "@@relocation_move_out_form/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@relocation_move_out_form/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@relocation_move_out_form/GET_FORM_FAILURE",
}