import { types } from "./types";

const initialState = {
    filterStorageKey: "reports.roomAllocationList",
    filter: {},
    data: [],
    unassignedStudents: [],
    allocations: [],
    editForm: {},
    assignmentStatus: null,
    allocation: null,
    freeRooms: [],
    relocationExistsForAllocation: false,
    terminationExistsForAllocation: false,
};

export function roomAllocationList(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
            };
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                allocations: action.data.data?.["hydra:member"],
            };
        case types.GET_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_ALLOCATION_REQUEST:
            return {
                ...state,
            };
        case types.GET_ALLOCATION_SUCCESS:
            return {
                ...state,
                allocation: action.data.data,
            };
        case types.GET_ALLOCATION_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.FILTER_STUDENT_ALLOCATIONS_REQUEST:
            return {
                ...state,
            };
        case types.FILTER_STUDENT_ALLOCATIONS_SUCCESS:
            return {
                ...state,
                data: action.data.data,
            };
        case types.FILTER_STUDENT_ALLOCATIONS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.FILTER_UNASSIGNED_STUDENTS_REQUEST:
            return {
                ...state,
            };
        case types.FILTER_UNASSIGNED_STUDENTS_SUCCESS:
            return {
                ...state,
                unassignedStudents: action.data.data?.["hydra:member"],
            };
        case types.FILTER_UNASSIGNED_STUDENTS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.ASSIGN_ALLOCATION_REQUEST:
            return {
                ...state,
            };
        case types.ASSIGN_ALLOCATION_SUCCESS:
            return {
                ...state,
            };
        case types.ASSIGN_ALLOCATION_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.ASSIGN_ALL_STUDENTS_REQUEST:
            return {
                ...state,
            };
        case types.ASSIGN_ALL_STUDENTS_SUCCESS:
            return {
                ...state,
            };
        case types.ASSIGN_ALL_STUDENTS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.ASSIGN_NEW_STUDENTS_REQUEST:
            return {
                ...state,
            };
        case types.ASSIGN_NEW_STUDENTS_SUCCESS:
            return {
                ...state,
            };
        case types.ASSIGN_NEW_STUDENTS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.CHECK_ASSIGNMENT_STATUS_REQUEST:
            return {
                ...state,
            };
        case types.CHECK_ASSIGNMENT_STATUS_SUCCESS:
            return {
                ...state,
                assignmentStatus: action.data.data,
            };
        case types.CHECK_ASSIGNMENT_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.RESET_STUDENT_ASSIGNMENT_REQUEST:
            return {
                ...state,
            };
        case types.RESET_STUDENT_ASSIGNMENT_SUCCESS:
            return {
                ...state,
            };
        case types.RESET_STUDENT_ASSIGNMENT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_FREE_ROOMS_REQUEST:
            return {
                ...state,
            };
        case types.GET_FREE_ROOMS_SUCCESS:
            return {
                ...state,
                freeRooms: action.data.data,
            };
        case types.GET_FREE_ROOMS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_REQUEST:
            return {
                ...state,
            };
        case types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_SUCCESS:
            return {
                ...state,
                relocationExistsForAllocation: action.data.data,
            };
        case types.CHECK_IF_RELOCATION_EXISTS_FOR_ALLOCATION_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_REQUEST:
            return {
                ...state,
            };
        case types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_SUCCESS:
            return {
                ...state,
                terminationExistsForAllocation: action.data.data,
            };
        case types.CHECK_IF_TERMINATION_EXISTS_FOR_ALLOCATION_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return {
                ...state,
            };
    }
}
