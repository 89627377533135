/**        return filterBase({});

 * Keresési űrlapokat feldolgozó osztály ami módosítja a szervernek megfelelő formára a paramétereket
 */

class SearchForm {
    /**
     * @param formData A form adatok objektum formában
     * @param mapping Mappelési objektum. Amennyiben a field neve az űrlapon eltér a szűrési névtől, úgy használható. Pl: {chamber: 'chamber_id'}
     */
    constructor(formData, mapping, fieldTypes) {
        this.formData = formData ? Object.assign({}, formData) : {};
        this.mapping = mapping ?? {};
        this.fieldTypes = fieldTypes ?? {};
    }

    /**
     * Elküldendő form data generálása
     */
    getSend() {
        let send = {};

        for (const [field, value] of Object.entries(this.formData)) {
            const fieldToSend = this.mapping[field] ?? field;

            if (this.fieldTypes[fieldToSend] === "date") {
                const dateFilterName = this.getDateFilter(field, fieldToSend);
                send[dateFilterName] = value;
            } else if (Array.isArray(value)) {
                // tömb kezelése
                if (value.length > 0) {
                    if (this.fieldTypes[fieldToSend] === "comma") {
                        send[fieldToSend] = value.map((object) => object.value);
                    } else {
                        send[fieldToSend] = value.map((object) => object?.value);
                    }
                }
                send[fieldToSend] = value;
            } else {
                send[fieldToSend] = value;
            }
        }

        if (send.metadata) {
            delete send.metadata;
        }

        for (const [field, value] of Object.entries(send)) {
            if (typeof value === "object" && value) {
                for (const [field2, value2] of Object.entries(value)) {
                    send[field + "[" + field2 + "]"] = value2;
                }
                delete send[field];
            }
        }

        return send;
    }

    getDateFilter(fieldName, fieldToSend) {
        if (fieldName.substr(fieldName.length - 5) === "Until") {
            return fieldToSend + "[before]";
        } else if (fieldName.substr(fieldName.length - 4) === "From") {
            return fieldToSend + "[after]";
        }

        return null;
    }

    getDateFilterValue(value, fieldName) {
        let retVal = value;

        if (retVal) {
            if (fieldName.substr(fieldName.length - 5) === "Until") {
                retVal = value.set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss");
            } else if (fieldName.substr(fieldName.length - 4) === "From") {
                retVal = value.set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss");
            }
        }

        return retVal;
    }
}

export default SearchForm;
