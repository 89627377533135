import { Grid } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { history } from "../../../../store";
import loadingActions from "../../../../store/loading/actions";
import campusesActions from "../../../../store/masterData/campuses/actions";
import documentsActions from "../../../../store/masterData/documents/actions";
import semestersActions from "../../../../store/masterData/semesters/actions";
import notificationActions from "../../../../store/notification/actions";
import { makeOptionsFromID } from "../../../../utils/listOptions/makeOptionsFromID";
import Accordion from "../../../Common/Accordion";
import CancelButton from "../../../Common/CancelButton";
import Checkbox from "../../../Common/Checkbox";
import Form from "../../../Common/Form";
import PageTemplate from "../../../Common/PageTemplate";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";

function DocumentsForm(props) {
    const { edit, form, campusForm, id, campusId, semesters, originalName } =
        props;
    const title = edit ? "Dokumentum szerkesztése" : "Dokumentum hozzáadása";
    const isNew = id === undefined;
    const [selectedFile, setSelectedFile] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    //FIXME: itt a true-false értékek "1"-"0"-t vesznek fel - backenden is így van
    const [isRequired, setIsRequired] = useState("0");
    const URL = `/campuses/${campusId}/documents`;

    const header = {
        title: title,
        breadcrumbs: {
            masterData: "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/documents`]: "Dokumentumok",
            [`edit ? "documentsEdit" : "documentsNew"`]: title,
        },
    };

    if (!isNew) {
        header.breadcrumbs["documentName"] = originalName;
    }

    const changeHandler = (event) => {
        setSelectedFile([...event.target.files]);
    };

    useEffect(() => {
        props.loadingOn();

        axios
            .all([
                isNew ? "" : props.get(id),
                props.fetchSemesters(),
                props.getCampus(campusId),
            ])
            .then(() => {
                setFirstLoad(false);
                props.loadingOff();
            });
        return props.defaultForm;
    }, []);

    useEffect(() => {
        props.changeForm({ ...form, campus: campusForm }, "form");
    }, [campusForm]);

    useEffect(() => {
        if (!firstLoad && form.semester) {
            props.changeForm(
                { ...form, semester: form.semester["@id"] },
                "form"
            );
        }
    }, [firstLoad]);

    const handleChange = () => {
        setIsRequired(isRequired === "0" ? "1" : "0");

        props.changeForm(
            { ...form, required: isRequired === "0" ? "1" : "0" },
            "form"
        );
    };

    const validationSchema = yup.object().shape({
        name: yup.string().max(128, "Túl hosszú").required("Kötelező megadni"),
    });

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validationschema: validationSchema,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        isformikform: true,
        onsubmit: isNew
            ? () => props.create(form, selectedFile, URL)
            : () => props.update(id, form, URL),
    };

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion title={title} expanded={true}>
                    <Form {...formProps}>
                        <TextField
                            label="Campus"
                            name="campus"
                            disabled={true}
                            value={
                                edit
                                    ? campusForm?.name || ""
                                    : form.campus?.name || ""
                            }
                            format={{ xs: 12, md: 4 }}
                        />
                        <TextField
                            label="Dokumentum neve"
                            name="name"
                            value={form.name || ""}
                            format={{ xs: 12, md: 4 }}
                        />
                        <Select
                            selectLabel="Szemeszter"
                            name="semester"
                            value={form.semester || ""}
                            optionList={makeOptionsFromID(semesters)}
                            format={{ xs: 12, md: 4 }}
                        />
                        {isNew && (
                            <TextField
                                name="file"
                                type="file"
                                onChange={changeHandler}
                                inputProps={{ accept: ".pdf" }}
                                format={{ xs: 12 }}
                            />
                        )}
                        <Checkbox
                            option={{ name: "required", label: "Kötelező" }}
                            checked={form.required === "1"}
                            onChange={handleChange}
                            format={{ xs: 12 }}
                        />
                        {form.required === "0" && (
                            <TextField
                                label="Szöveg hozzájáruláshoz"
                                name="consentText"
                                value={form.consentText || ""}
                                format={{ xs: 12 }}
                                multiline
                                rows={4}
                            />
                        )}
                        <TextField
                            label="Szöveg elfogadáshoz"
                            name="acceptText"
                            value={form.acceptText || ""}
                            format={{ xs: 12 }}
                            multiline
                            rows={4}
                        />
                        <Grid container format={{ xs: 12 }} spacing={1}>
                            <Grid item>
                                <SubmitButton type="submit">
                                    Mentés
                                </SubmitButton>
                            </Grid>
                            <Grid item>
                                <CancelButton onClick={() => history.push(URL)}>
                                    Vissza
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </Form>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, originalName } = state.documents;
    const campusForm = state.campuses.form;
    const semesters = state.semesters.data;
    return { form, campusForm, semesters, originalName };
}

const actionCreators = {
    changeForm: documentsActions.changeForm,
    defaultForm: documentsActions.defaultForm,
    changeEditor: documentsActions.changeEditor,
    create: documentsActions.create,
    getCampus: campusesActions.get,
    fetchSemesters: semestersActions.filter,
    get: documentsActions.get,
    update: documentsActions.update,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(DocumentsForm);
