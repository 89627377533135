import { connect } from "react-redux";
import permissionsActions from "../../../store/administration/permissions/actions";
import PageTemplate from "./../../Common/PageTemplate";
import Table from "./../../Common/Table";
import { Chip, Grid } from "@material-ui/core";
import OperationButton from "./../../Common/OperationButton";
import Accordion from "../../Common/Accordion";
import Form from "./../../Common/Form";
import { rolesOptions } from "../../../utils/AppConst";
import SubmitButton from "./../../Common/SubmitButton";
import { useEffect, useState } from "react";
import { Autocomplete as AutocompleteBasic } from "@material-ui/lab";
import TextField from "./../../Common/TextField";
import loadingActions from "../../../store/loading/actions";
import notificationActions from "../../../store/notification/actions";
import Autocomplete from "../../Common/Autocomplete";

function BtnCellRenderer(params) {
    const { form, changeForm } = params.context;

    return (
        <OperationButton
            type="edit"
            onClick={(e) => {
                e.preventDefault();
                changeForm(
                    {
                        ...form,
                        user: {
                            value: params.data,
                            name:
                                params.data.fullName +
                                " / " +
                                params.data.email,
                            key: params.data.email,
                        },
                        id: params.data.id,
                        role: params.data.roles || [],
                    },
                    "form"
                );
            }}
        />
    );
}

function RoleCellRenderer(params) {
    const roles = params.value;
    return (
        <>
            {roles
                .map(
                    (role) =>
                        rolesOptions.find((element) => element.value === role)
                            ?.label
                )
                .join(", ")}
        </>
    );
}

function Permissions(props) {
    const { form, data } = props;
    const [options, setOptions] = useState([]);
    const [searchUserLoading, setSearchUserLoading] = useState(false);
    const [idError, setIdError] = useState("");
    const [rolesError, setRolesError] = useState("");

    useEffect(() => {
        props.loadingOn();
        props.search({}).then(() => {
            props.loadingOff();
        });
        return () => {
            props.defaultForm("filter");
        };
    }, []);

    const header = {
        title: "Jogosultságok",
        breadcrumbs: {
            administration: "Adminisztráció",
            translations: "Jogosultságok",
        },
    };

    const table = {
        columnDefs: [
            { headerName: "Név", field: "fullName", minWidth: 280 },
            { headerName: "E-mail cím", field: "email" },
            {
                headerName: "Jogosultságok",
                field: "roles",
                cellRenderer: "roleCellRenderer",
                comparator: (a, b) => a[0]?.localeCompare(b[0]),
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data["hydra:member"],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            roleCellRenderer: RoleCellRenderer,
        },
        context: {
            changeForm: props.changeForm,
            form: form,
        },
    };

    const submitAction = () => {
        props.loadingOn();
        props
            .save(form)
            .then(() => {
                const lastUser = props.form.user;
                props.changeForm(
                    {
                        ...form,
                        user: "",
                        email: null,
                        role: [],
                    },
                    "form"
                );

                props
                    .search({})
                    .then(() => {
                        props.addNotification("success", "Sikeres mentés");
                        if (lastUser.value['@id'] === props.user['@id']) {
                            location.reload();
                        }
                        props.loadingOff();
                    })
                    .catch(() => props.loadingOff());
            })
            .catch((error) => {
                if (error?.response?.status === 422) {
                    //validációs hiba
                    error.response.data.violations.map((violation) => {
                        if (violation.propertyPath === "id") {
                            setIdError(violation.message);
                        }
                        if (violation.propertyPath === "role") {
                            setRolesError(violation.message);
                        }
                    });
                }

                if (error?.response?.status === 404) {
                    //validációs hiba
                }
                props.addNotification("error", "Nem sikerült menteni");
                props.loadingOff();
            });
    };

    const searchUser = (val) => {
        setSearchUserLoading(true);
        props.autocompleteFilter({ fullNameAndEmail: val }).then((response) => {
            setOptions(
                response.data["hydra:member"]
                    .map((val) => {
                        return {
                            value: val,
                            name: val.fullName + " / " + val.email,
                            key: val.id,
                        };
                    })
                    .sort((a, b) =>
                        a.name < b.name ? -1 : a.name === b.name ? 0 : 1
                    )
            );

            setSearchUserLoading(false);
        });
    };

    return (
        <PageTemplate header={header}>
            <Grid item xs={12}>
                <Accordion expanded title="Jogosultságok">
                    <Form form={form} changeform={props.changeForm}>
                        <AutocompleteBasic
                            id="user"
                            value={form.user || ""}
                            options={options}
                            getOptionLabel={(option) => option?.name || ""}
                            loading={searchUserLoading}
                            getOptionSelected={(option, value) =>
                                option.key === value.key
                            }
                            filterSelectedOptions
                            loadingText={`Betöltés...`}
                            onInputChange={(event, val, reason) => {
                                if (reason === "clear") {
                                    props.changeForm(
                                        { ...form, user: {} },
                                        "form"
                                    );
                                    setOptions([]);
                                }
                                if (val.length < 2) {
                                    setOptions([]);
                                }
                                if (val.length > 2) {
                                    searchUser(val);
                                }
                            }}
                            onChange={(event, values) => {
                                if (values) {
                                    props.changeForm(
                                        {
                                            ...form,
                                            user: {
                                                value: values.value,
                                                name:
                                                    values.value.fullName +
                                                    " / " +
                                                    values.value.email,
                                                key: values.value.id,
                                            },
                                            id: values.value.id,
                                            role: values.value.roles || [],
                                        },
                                        "form"
                                    );
                                }
                            }}
                            format={{ xs: 12, sm: 6 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Felhasználó"
                                    error={idError !== "" || false}
                                    helperText={idError}
                                />
                            )}
                            noOptionsText={"Nincs találat"}
                        />
                        <Autocomplete
                            label="Jogosultságok"
                            name="role"
                            id="role"
                            multiple={true}
                            options={rolesOptions}
                            filterOptions={(options) => options.filter(
                                (option) => option.selectable
                            )}
                            onChange={(event, newValue) => {
                                props.changeForm(
                                    {
                                        ...form,
                                        role: newValue.length != 0 ? newValue.map((element) => typeof element === 'string' ? element : element.value) : 
                                            form.role.filter((a) => rolesOptions.filter((e) => !e.selectable).map((e) => e.value).includes(a)),
                                    },
                                    "form"
                                );
                            }}
                            renderTags={(tagValue, getTagProps) =>{
                                return (
                                    tagValue.map((option, index) => {
                                        const foundOption = rolesOptions.find((element) => element.value == option);
                                        return (
                                            <Chip
                                                label={foundOption?.label}
                                                {...getTagProps({ index })}
                                                disabled={!foundOption?.selectable}
                                            />
                                        );
                                    })
                                );
                            }}
                            format={{ xs: 12, sm: 6 }}
                            value={form.role || []}
                            error={rolesError !== "" || false}
                            helperText={rolesError}
                        />
                        <SubmitButton onClick={submitAction}>
                            Mentés
                        </SubmitButton>
                    </Form>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Table {...table} />
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { user } = state.login
    const { form, data } = state.permissions;
    return { form, data, user};
}

const actionCreators = {
    defaultForm: permissionsActions.defaultForm,
    changeForm: permissionsActions.changeForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    search: permissionsActions.filter,
    save: permissionsActions.save,
    autocompleteFilter: permissionsActions.autocompleteFilter,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Permissions);
