import {
    templateChangeForm,
    templateGet,
    templateDefaultForm,
    templateFetch,
} from '../..';
import { types } from "./types";
import floorsService from "../../../services/masterData/floors";
import loadingTypes from "../../loading/types";

const filter = (form, otherData) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    dispatch({ type: loadingTypes.TABLE_LOADING_ON });
    return floorsService.filter(form, otherData).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
             dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
             dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: filter,
    fetch: templateFetch(types, floorsService),
    get: templateGet(types, floorsService),

}

export default actions;