export const types = {
    CHANGE_FORM: "@@students_list/CHANGE_FORM",
    DEFAULT_FORM: "@@students_list/DEFAULT_FORM",

    FETCH_REQUEST: "@@students_list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@students_list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@students_list/FETCH_FAILURE",

    DOWNLOAD_EXPORT_REQUEST: "@@students_list/DOWNLOAD_EXPORT_REQUEST",
    DOWNLOAD_EXPORT_SUCCESS: "@@students_list/DOWNLOAD_EXPORT_SUCCESS",
    DOWNLOAD_EXPORT_FAILURE: "@@students_list/DOWNLOAD_EXPORT_FAILURE",

    DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_REQUEST: "@@students_list/DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_REQUEST",
    DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_SUCCESS: "@@students_list/DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_SUCCESS",
    DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_FAILURE: "@@students_list/DOWNLOAD_UNIVERSITY_ADMISSION_DECISION_FAILURE",

    FETCH_SET_INFO: "@@students_list/FETCH_SET_INFO",
}