
import { templateGet } from '../..';
import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';

function startStudentAllocationPreference(form) {
    return API.post(`/student-allocation-preferences/start`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function fillForm(form, id) {
    return API.post(`/student-allocation-preferences/${id}/fill`, form)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/student-allocation-preferences', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getPreferableRoommates(id) {
    return API.get(`/student-allocation-preferences/${id}/preferable-roommates`)
        .then(data => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function autocompleteFilter(form, id) {
    const searchForm = new SearchForm(form);

    return API.get(`/student-allocation-preferences/${id}/preferable-roommates`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const studentAllocationPreferenceService = {
    startStudentAllocationPreference,
    fillForm,
    filter,
    getPreferableRoommates,
    get: templateGet("student-allocation-preferences"),
    autocompleteFilter
};

export default studentAllocationPreferenceService;