export const types = {
    CHANGE_CURRENT_TAB: "@@main/CHANGE_CURRENT_TAB",
    CHANGE_FORM: "@@main/CHANGE_FORM",
    DEFAULT_FORM: "@@main/DEFAULT_FORM",

    SELECT_LAYOUT: "@@main/SELECT_LAYOUT",

    FETCH_ONGOING_REQUEST: "@@main/FETCH_ONGOING_REQUEST",
    FETCH_ONGOING_SUCCESS: "@@main/FETCH_ONGOING_SUCCESS",
    FETCH_ONGOING_FAILURE: "@@main/FETCH_ONGOING_FAILURE",

    FETCH_CLOSED_REQUEST: "@@main/FETCH_CLOSED_REQUEST",
    FETCH_CLOSED_SUCCESS: "@@main/FETCH_CLOSED_SUCCESS",
    FETCH_CLOSED_FAILURE: "@@main/FETCH_CLOSED_FAILURE",

    FETCH_SET_ONGOING_INFO: "@@main/FETCH_SET_ONGOING_INFO",
    FETCH_SET_CLOSED_INFO: "@@main/FETCH_SET_CLOSED_INFO",
}