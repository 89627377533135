import {connect} from "react-redux";
import campusesActions from "../../../../../../../store/masterData/campuses/actions";
import loadingActions from "../../../../../../../store/loading/actions";
import notificationActions from "../../../../../../../store/notification/actions";
import ListPageTemplate from "../../../../../../Common/ListPageTemplate";
import OperationButton from "../../../../../../Common/OperationButton";
import {useEffect} from "react";
import buildingsActions from "../../../../../../../store/masterData/buildings/actions";
import floorsActions from "../../../../../../../store/masterData/floors/actions";
import roomsActions from "../../../../../../../store/masterData/rooms/actions";
import itemsActions from "../../../../../../../store/masterData/items/actions";
import TextField from "../../../../../../Common/TextField";
import {itemStatus} from "../../../../../../../utils/AppConst";
import axios from "axios";
import { filterStorageGet, filterStorageSave } from "../../../../../../../utils/FilterStorage";

function Items(props) {
    const {filter, data, campus, building, floor, room, filterStorageKey} = props;
    const {campusId, buildingId, floorId, roomId} = props.match.params;

    const searchOtherData = {campusId: campusId, buildingId: buildingId, floorId: floorId, roomId: roomId};

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.getCampus(campusId),
            props.getBuilding(buildingId),
            props.getFloor(floorId),
            props.getRoom(roomId),
            props.search(storageFilter, searchOtherData),
        ])
            .then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Tárgyak",
        breadcrumbs: {
            "masterData": "Törzsadatok",
            "/campuses": "Campusok",
            [`/campuses/${campusId}/buildings`]: "Épületek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors`]: "Emeletek",
            [`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms`]: "Szobák",
            "items": "Tárgyak",
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            props.search(filter, searchOtherData);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({}, searchOtherData);
        },
        form: filter,
        changeform: props.changeForm
    }



    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="visibility"
                    to={`/campuses/${campusId}/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}/items/view/${params.data.id}`}
                />

            </>
        );
    }

    function StatusCellRenderer(params) {
        return (
            <>{itemStatus.filter(status => status.value === params.value)?.[0]?.label || '-'}</>
        );
    }

    const table = {
        columnDefs: [
            {headerName: "Név", field: "name"},
            {headerName: "Mennyiség", field: "amount", sortable: false},
            {headerName: "Állapot", field: "status", cellRenderer: "statusCellRenderer", sortable: false},
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 220,
            }
        ],
        rowData: data['hydra:member'],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Campus"
                name="campus.name"
                value={campus?.name || ""}
                disabled={true}
                format={{xs: 12, md: 6, lg: 3}}
            />
            <TextField
                label="Épület"
                name="building.name"
                value={building?.name || ""}
                disabled={true}
                format={{xs: 12, md: 6, lg: 3}}
            />
            <TextField
                label="Emelet"
                name="floor.name"
                value={floor?.name || ""}
                disabled={true}
                format={{xs: 12, md: 6, lg: 3}}
            />
            <TextField
                label="Szoba"
                name="room.name"
                value={room?.name || ""}
                disabled={true}
                format={{xs: 12, md: 6, lg: 3}}
            />
            <TextField
                label="Tárgy"
                name="name"
                value={filter.name || ""}
                format={{xs: 12, md: 6, lg: 3}}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const {filter, data, filterStorageKey} = state.items;
    const campus = state.campuses.form;
    const building = state.buildings.form;
    const floor = state.floors.form;
    const room = state.rooms.form;
    return {filter, data, filterStorageKey, campus, building, floor, room};
}

const actionCreators = {
    getCampus: campusesActions.get,
    getBuilding: buildingsActions.get,
    getFloor: floorsActions.get,
    getRoom: roomsActions.get,
    search: itemsActions.filter,
    changeForm: itemsActions.changeForm,
    defaultForm: itemsActions.defaultForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Items);