import { setInfo } from "../../utils/FilterHelper";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { makeStyles } from "@material-ui/core";
import colors from "../../styles/colors";

const useStyles = makeStyles(() => ({
    tableHeaderDiv: {
        width: "100%",
        cursor: "pointer",
        color: colors.black,
    },
    notSortableTableHeaderDiv: {
        width: "100%",
    },
    tableHeaderIcon: {
        fontSize: "small",
        width: "18px",
        height: "18px",
        marginLeft: "10px",
        transform: "translateY(3px)",
    },
    clickableDiv: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
}));

const TableCustomHeader = (props) => {
    const classes = useStyles();

    const onSortRequested = (order) => {
        setInfo(
            {
                setInfo: props.agGridReact.props.frameworkComponents.setInfo,
                info: props.agGridReact.props.info,
                search: props.agGridReact.props.frameworkComponents.searchfunc,
            },
            "order",
            { [props.column.colId]: order },
            "page",
            1
        );
    };

    if (props?.enableSorting === false) {
        return <div className={classes.notSortableTableHeaderDiv}>{props.displayName}</div>;
    }

    return (
        <>
            {props.agGridReact?.props?.frameworkComponents?.info?.order[props.column.colId] === undefined ? (
                <>
                    <div
                        className={classes.clickableDiv}
                        onClick={(event) => onSortRequested("asc", event)}
                        onTouchEnd={(event) => onSortRequested("asc", event)}
                    ></div>
                    <div className={classes.tableHeaderDiv}>{props.displayName}</div>
                </>
            ) : props.agGridReact.props.frameworkComponents.info.order[props.column.colId] === "asc" ? (
                <>
                    <div
                        className={classes.clickableDiv}
                        onClick={(event) => onSortRequested("desc", event)}
                        onTouchEnd={(event) => onSortRequested("desc", event)}
                    ></div>
                    <div
                        className={classes.tableHeaderDiv}
                        onClick={(event) => onSortRequested("desc", event)}
                        onTouchEnd={(event) => onSortRequested("desc", event)}
                    >
                        {props.displayName}
                        <ArrowUpwardIcon className={classes.tableHeaderIcon} />
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={classes.clickableDiv}
                        onClick={(event) => onSortRequested(undefined, event)}
                        onTouchEnd={(event) => onSortRequested(undefined, event)}
                    ></div>
                    <div
                        className={classes.tableHeaderDiv}
                        onClick={(event) => onSortRequested(undefined, event)}
                        onTouchEnd={(event) => onSortRequested(undefined, event)}
                    >
                        {props.displayName}
                        <ArrowDownwardIcon className={classes.tableHeaderIcon} />
                    </div>
                </>
            )}
        </>
    );
};

TableCustomHeader.displayName = "TableCustomHeader";

export default TableCustomHeader;
