import Dialog from "../../Common/Dialog";
import Form from "../../Common/Form";
import TextField from "../../Common/TextField";
import OperationButton from "../../Common/OperationButton";
import { DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        paddingLeft: "0"
    },
}));

function ViewStudentAllocationPreferenceDialog(props) {
    const classes = useStyles();

    return (
        <Dialog
            opener={
                <OperationButton
                    type="visibility"
                    to={'#'}
                />
            }
            title={"Hallgató által kitöltött szobatárspreferencia lap"}
            cancelText="Bezár"
        >
            <Form>
                <TextField
                    label="Sorszám"
                    name="displayId"
                    value={props.displayId || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
                <TextField
                    label="Név"
                    name="name"
                    value={props.fullName || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
                <TextField
                    label="E-mail"
                    name="email"
                    value={props.email || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
                <TextField
                    label="Szobatárs"
                    name="roomMates"
                    value={props.roomMates || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
                <TextField
                    label="Megjegyzés"
                    name="comment"
                    value={props.comment || "-"}
                    format={{ xs: 12 }}
                    disabled
                />
            </Form>
            {props.room && <div>
                <DialogTitle className={classes.title}>Előző szoba</DialogTitle>
                <Form>
                    <TextField
                        label="Épület"
                        name="building"
                        value={props.building || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <TextField
                        label="Szint"
                        name="floor"
                        value={props.floor || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                    <TextField
                        label="Szoba"
                        name="room"
                        value={props.room || "-"}
                        format={{ xs: 12 }}
                        disabled
                    />
                </Form>
            </div>
            }
        </Dialog>
    )

}

export default ViewStudentAllocationPreferenceDialog;