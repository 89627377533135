import { templateChangeForm, templateDefaultForm, templateFilter, templateGet } from "../..";
import { types } from "./types";
import trainingLevelsService from "../../../services/masterData/trainingLevels";

function getForEnrollment(form) {
    return (dispatch) => {
        dispatch({ type: types.GET_FOR_ENROLLMENT_REQUEST, form });
        return trainingLevelsService.getForEnrollment(form).then(
            (data) => {
                dispatch({ type: types.GET_FOR_ENROLLMENT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FOR_ENROLLMENT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    getForEnrollment,
    filter: templateFilter(types, trainingLevelsService),
    get: templateGet(types, trainingLevelsService),
}

export default actions;