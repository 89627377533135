import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';
import { templateCreate, templateDelete, templateGet, templateUpdate } from '../..';

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/training-levels', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getForEnrollment(form) {
    const searchForm = new SearchForm(form);

    return API.get('/training-levels/get-for-enrollment', { params: searchForm.getSend() })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const trainingLevelsService = {
    filter,
    getForEnrollment,
    create: templateCreate('training-levels'),
    get: templateGet('training-levels'),
    update: templateUpdate('training-levels'),
    delete: templateDelete('training-levels'),
};

export default trainingLevelsService;