import { useEffect } from "react";
import { connect } from "react-redux";
import emailLogActions from "../../../store/administration/emailLog/actions";
import loadingActions from "../../../store/loading/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import TextField from "../../Common/TextField";
import Select from '../../Common/Select';
import { EMAIL_LOG_STATUS_OPTIONS, } from '../../../utils/AppConst';
import DatePicker from '../../Common/DatePicker';
import emailTemplatesActions from '../../../store/administration/emailTemplates/actions';
import axios from 'axios';
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import { formatDate } from "../../../utils/timezoneConverter";
import { useTenant } from "../../../utils/TenantContext";

function EmailLog(props) {
    const { filter, data, dataEmailTemplates, filterStorageKey, tableInfo } = props;
    const tenant = useTenant();
    const header = {
        title: "E-mail napló",
        breadcrumbs: {
            "administration": "Adminisztráció",
            "emailTemplates": "E-mail napló"
        }
    }
    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.loadingOn();
        axios.all([
            props.searchEmailTemplates({}),
            props.search(storageFilter),
            setInfo({ ...props, search: props.search }, "order", { sentAt: "desc" }, "filter", filter),
        ])
            .then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter, "order", { sentAt: "desc" })
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm("filter");
            setInfo(props, "filter", {}, "order", { sentAt: "desc" });
        },
        form: filter,
        changeform: props.changeForm
    }

    function BtnCellRenderer(params) {
        return (
            <OperationButton
                type="visibility"
                to={`/email-log/view/${params.data.id}`}
            />
        );
    }

    function StatusCellRenderer(params) {
        return (
            EMAIL_LOG_STATUS_OPTIONS.filter(s => s.value === params.value)?.[0]?.label || params.value || ''
        );
    }

    function TypeCellRenderer(params) {
        return (
            dataEmailTemplates['hydra:member']?.filter(s => s.key === params.value)?.[0]?.translations?.hu?.name || params.value || ''
        );
    }

    function DateCellRenderer(params) {
        return formatDate(params.value, tenant.timezone.offset);
    }

    const table = {
        columnDefs: [
            { headerName: "Címzett", field: "mailingAddress", sortable: true },
            { headerName: "Típus", field: "type", cellRenderer: "typeCellRenderer", sortable: true },
            { headerName: "Tárgy", field: "subject", sortable: true },
            { headerName: "Tartalom", field: "operations", cellRenderer: "btnCellRenderer", sortable: false },
            { headerName: "Kiküldés", field: "sentAt", cellRenderer: "dateCellRenderer", sortable: true, sort: 'desc' },
            { headerName: "Generálás", field: "generatedAt", cellRenderer: "dateCellRenderer", sortable: true },
            { headerName: "Státusz", field: "status", cellRenderer: "statusCellRenderer", sortable: false },
        ],
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
            typeCellRenderer: TypeCellRenderer,
            dateCellRenderer: DateCellRenderer
        },
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false
    }

    return (
        <ListPageTemplate
            filter={filterForm}
            header={header}
            table={table}
        >
            <TextField
                label="Címzett"
                name="mailingAddress"
                value={filter.mailingAddress || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Tárgy"
                name="subject"
                value={filter.subject || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Tartalom"
                name="emailText"
                value={filter.emailText || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                multiple={true}
                selectLabel="Típus"
                name="type"
                value={filter.type || []}
                optionList={dataEmailTemplates['hydra:member'] ? dataEmailTemplates['hydra:member'].map(t => {
                    return { value: t.key, label: t.translations?.hu?.name || '' }
                }
                ) : []}
                format={{ xs: 6 }}
            />
            <Select
                multiple={true}
                selectLabel="Státusz"
                name="status"
                value={filter.status || []}
                optionList={EMAIL_LOG_STATUS_OPTIONS}
                format={{ xs: 6 }}
            />
            <DatePicker
                label="Kiküldés (-tól)"
                value={filter.sentAtFrom || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="sentAtFrom"
            />
            <DatePicker
                label="Kiküldés (-ig)"
                value={filter.sentAtUntil || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="sentAtUntil"
            />
            <DatePicker
                label="Generálás (-tól)"
                value={filter.generatedAtFrom || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="generatedAtFrom"
            />
            <DatePicker
                label="Generálás (-ig)"
                value={filter.generatedAtUntil || ""}
                format={{ xs: 12, md: 4, lg: 3 }}
                name="generatedAtUntil"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, filterStorageKey, tableInfo } = state.emailLog;
    const dataEmailTemplates = state.emailTemplates.data;
    return { filter, data, filterStorageKey, tableInfo, dataEmailTemplates };
}

const actionCreators = {
    changeForm: emailLogActions.changeForm,
    defaultForm: emailLogActions.defaultForm,
    setInfo: emailLogActions.setInfo,
    searchEmailTemplates: emailTemplatesActions.filter,
    search: emailLogActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(EmailLog);