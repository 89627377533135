import { Grid } from '@material-ui/core';
import React from 'react';
import Header from './Header';
import { useSelector } from "react-redux";
import Loading from "./Loading";

/**
 * 
 * @param header - objektum, amit a Header vár (lásd Header dok.)
 */

export default function PageTemplate(props) {
    const loading = useSelector(state => state.loading.loading);
    
    if (loading) return <Loading/>;

    return(
        <Grid container spacing={1}>
          {props.header &&
            <Header
                {...props.header}
            />}
            {props.children}
        </Grid>
    );
}