export const types = {
    CHANGE_FORM: "@@student-allocation-preference-form/CHANGE_FORM",
    DEFAULT_FORM: "@@student-allocation-preference-form/DEFAULT_FORM",

    GET_REQUEST: "@@student-allocation-preference-form/GET_REQUEST",
    GET_SUCCESS: "@@student-allocation-preference-form/GET_SUCCESS",
    GET_FAILURE: "@@student-allocation-preference-form/GET_FAILURE",

    FILL_FORM_REQUEST: "@@student-allocation-preference-form/FILL_FORM_REQUEST",
    FILL_FORM_SUCCESS: "@@student-allocation-preference-form/FILL_FORM_SUCCESS",
    FILL_FORM_FAILURE: "@@student-allocation-preference-form/FILL_FORM_FAILURE",

    GET_PREFERABLE_ROOMMATES_REQUEST: "@@student-allocation-preference-form/GET_PREFERABLE_ROOMMATES_REQUEST",
    GET_PREFERABLE_ROOMMATES_SUCCESS: "@@student-allocation-preference-form/GET_PREFERABLE_ROOMMATES_SUCCESS",
    GET_PREFERABLE_ROOMMATES_FAILURE: "@@student-allocation-preference-form/GET_PREFERABLE_ROOMMATES_FAILURE",

    AUTOCOMPLETE_FILTER_REQUEST: "@@student-allocation-preference-form//AUTOCOMPLETE_FILTER_REQUEST",
    AUTOCOMPLETE_FILTER_SUCCESS: "@@student-allocation-preference-form//AUTOCOMPLETE_FILTER_SUCCESS",
    AUTOCOMPLETE_FILTER_FAILURE: "@@student-allocation-preference-form//AUTOCOMPLETE_FILTER_FAILURE",
}