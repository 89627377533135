import {templateChangeForm, templateDefaultForm, templateFilter} from '../..';
import { types } from "./types";
import permissionsService from '../../../services/administration/permissions';


const save = (form) => (dispatch) => {
    dispatch({ type: types.SAVE_REQUEST, form });
    return permissionsService.save(form).then(
        (data) => {
            dispatch({ type: types.SAVE_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.SAVE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};
const autocompleteFilter = (form) => (dispatch) => {
    dispatch({ type: types.AUTOCOMPLETE_FILTER_REQUEST, form });
    return permissionsService.autocompleteFilter(form).then(
        (data) => {
            dispatch({ type: types.AUTOCOMPLETE_FILTER_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.AUTOCOMPLETE_FILTER_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, permissionsService),
    save,
    autocompleteFilter
}

export default actions;