import { templateChangeForm } from "../..";
import loginService from "../../../services/login";
import { types } from "./types";

function saveDesiredUrl(desiredUrl) {
    return (dispatch) => dispatch({ type: types.SAVE_DESIRED_URL, desiredUrl });
}

function setUser() {
    return (dispatch) => {
        return loginService.getUser().then(
            (data) => {
                const user = data.data;
                dispatch({ type: types.SET_USER, user });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.LOGIN_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setRoles(roles) {
    return (dispatch) =>
        dispatch({ type: types.SET_ROLES, roles});
}

function setUserCampus(campus) {
    return (dispatch) =>
        dispatch({ type: types.SET_CAMPUS, campus});
}

const actions = {
    changeForm: templateChangeForm(types),
    saveDesiredUrl,
    setUser,
    setRoles,
    setUserCampus,
}

export default actions;