import { types } from "./types";

const initialState = {
    form: {
        oldPassword: null,
        password: null,
        confirmPassword: null
    }
}

export function changePassword(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                ...initialState
            };
        default: 
            return {
                ...state
            }
    }
}