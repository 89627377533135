export const types = {
    CHANGE_FORM: "@@relocation_approve_room_take_over/CHANGE_FORM",
    DEFAULT_FORM: "@@relocation_approve_room_take_over/DEFAULT_FORM",

    GET_REQUEST: "@@relocation_approve_room_take_over/GET_REQUEST",
    GET_SUCCESS: "@@relocation_approve_room_take_over/GET_SUCCESS",
    GET_FAILURE: "@@relocation_approve_room_take_over/GET_FAILURE",
    
    APPROVE_TAKE_OVER_ROOM_REQUEST: "@@relocation_approve_room_take_over/APPROVE_TAKE_OVER_ROOM_REQUEST",
    APPROVE_TAKE_OVER_ROOM_SUCCESS: "@@relocation_approve_room_take_over/APPROVE_TAKE_OVER_ROOM_SUCCESS",
    APPROVE_TAKE_OVER_ROOM_FAILURE: "@@relocation_approve_room_take_over/APPROVE_TAKE_OVER_ROOM_FAILURE",

    GET_FORM_REQUEST: "@@relocation_approve_room_take_over/GET_FORM_REQUEST",
    GET_FORM_SUCCESS: "@@relocation_approve_room_take_over/GET_FORM_SUCCESS",
    GET_FORM_FAILURE: "@@relocation_approve_room_take_over/GET_FORM_FAILURE",
}