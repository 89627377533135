import { types } from "./types";

const initialState = {
    filterStorageKey: "reports.studentAllocationList",
    filter: {},
    data: [],
    allocation: {},
    editForm: {},
    semesterNameForDownload: "",
}

export function studentAllocationPreferenceList(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
            };
        case types.FILTER_STUDENTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case types.FILTER_STUDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data?.['hydra:member'],
            };
        case types.FILTER_STUDENTS_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_ACTIVE_ALLOCATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case types.GET_ACTIVE_ALLOCATION_SUCCESS:
            return {
                ...state,
                allocation: action.data.data,
                loading: false
            }
        case types.GET_ACTIVE_ALLOCATION_FAILURE:
            return {
                ...state,
                loading: false
            }
        case types.DOWNLOAD_EXPORT_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_EXPORT_SUCCESS:
            return {
                ...state,
                exportFile: action.data.data,
            };
        case types.DOWNLOAD_EXPORT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_SEMESTER_NAME_FOR_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.GET_SEMESTER_NAME_FOR_DOWNLOAD_SUCCESS:
            return {
                ...state,
                semesterNameForDownload: action.data.data,
            };
        case types.GET_SEMESTER_NAME_FOR_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}