import {types} from "./types";

const initialState = {
    form: {}
}

export function newProcess(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name]
            }
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    content: action.content
                }
            }
        default:
            return {
                ...state
            }
    }
}