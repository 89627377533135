import { types } from "./types";

const initialState = {
    filter: {},
    form: {}
}

export function financialControl(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name] 
            }
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    emailContent: action.content
                }
            }
        case types.APPROVE_FINANCIAL_CHECK_REQUEST:
            return {
                ...state,
            }
        case types.APPROVE_FINANCIAL_CHECK_SUCCESS:
            return {
                ...state,
            }
        case types.APPROVE_FINANCIAL_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_REQUEST:
            return {
                ...state,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_SUCCESS:
            return {
                ...state,
            }
        case types.SEND_UNPAID_DEBT_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        default: 
            return {
                ...state
            }
    }
}