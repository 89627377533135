import { Grid } from "@material-ui/core";
import React, { Children } from "react";
import loadingActions from '../../store/loading/actions';
import notificationActions from '../../store/notification/actions';
import { connect } from 'react-redux';
import { Formik } from "formik";
import * as yup from "yup";

function FormikForm(props) {
    const {form, validation, childComponent, changeform, ...rest} = props;
    let previousErrors = typeof form !== typeof undefined ? (form.errors || []) : [];
    const getRules = name => validation ? validation.filter(v => v.field === name) : [];
    const getError = name => previousErrors.filter(v => v.name === name).length > 0;
    const getHelperText = name => previousErrors.filter(v => v.name === name)[0]?.label;

    const { onSubmit, ...otherProps} = props;

    (onSubmit && false) && console.log(onSubmit);

    const handleSubmit = (e, formikProps) => {
        e.preventDefault();
        props.loadingOn();

        if (props.onsubmit) { 
            //előző hibákat nem küldjük fel:
            if (form.errors) delete form.errors;
            if (formikProps.errors) formikProps.errors = {};

            props.onsubmit()
            .then(() => {
                props.loginForm ? "" : props.loadingOff();
            })
            .catch((error) => {
                //backend validáció:
                const violations = error?.response?.data?.violations;
                if (formikProps) {
                    violations?.map(({propertyPath, message}) => {
                        if (props.customvalidation) {
                            props.customvalidation(propertyPath, message);
                        } 
                        formikProps.errors[propertyPath] = message;
                        formikProps.touched[propertyPath] = true;
                    })
                }

                props.changeform({...form, errors: formikProps.errors}, props.name);

                props.loginForm ? "" : props.loadingOff();
            });
        }
    }
    
    const buildElem = child => (
        child.props && child.props.button ?
            <Grid item {...child.props.format}>
                {child}
            </Grid> :
            <Grid item {...child.props.format}>
            {
                React.cloneElement(child, 
                { 
                    formToChild: props.form,
                    formNameToChild: props.name || "form",
                    functionToChild: changeform,
                    isFormikForm: props.isformikform,
                    ...(getRules(child.props.name).length > 0 || (typeof form !== typeof undefined && form.errors?.length > 0)) &&
                    {
                        error: getError(child.props.validatorKey) || getError(child.props.name),
                        helperText: getHelperText(child)
                    }
                })
            }
            </Grid>
    )
    
    const contentGenerator = () => (
        <Grid item container spacing={1} {...rest}>
            {Children.toArray(Children.toArray(props.children).map(child => {
                    if(!child.props?.className?.includes("hidden"))
                        return buildElem(child)
                    else
                        return "";
                }
            ))}
        </Grid>
    )

    
    return ( 
        <Formik
            initialValues={props.initialvalues ? props.initialvalues : {}}
            validationSchema={props.validationschema ? props.validationschema : yup.object().shape({ })}
            initialErrors={props.initialerrors}
            enableReinitialize={true}
            {...otherProps}
        >
            {(formikProps) => 
            {
                return (
                    childComponent ? 
                        contentGenerator() :
                        <form onSubmit={(e) => handleSubmit(e, formikProps)} noValidate >
                            {contentGenerator()}
                        </form>
                )
            }}
        </Formik>
    );
}

const actionCreators = {
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(null, actionCreators)(FormikForm);