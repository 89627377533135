export const makeBuildingOptions = (buildings, campus) => {
    let options = [];

    if (typeof campus === 'object' && 'id' in campus) {
        campus = campus['id'];
    }

    if (buildings && campus) {
        buildings.map(elem => {
            if(elem.campus['id'] === campus) {
                options.push({
                    value: elem['id'],
                    label: elem.name
                })
            }
        })
    }

    return options;
}

export const makeFloorOptions = (floors, building) => {
    let options = [];

    if (typeof building === 'object' && 'id' in building) {
        building = building['id'];
    }

    if (floors && building) {
        floors.map(elem => {
            if(elem.building['id'] === building) {
                options.push({
                    value: elem['id'],
                    label: elem.name
                })
            }
        })
    }

    return options;
}

export const makeRoomOptions = (rooms, floor) => {
    let options = [];

    if (typeof floor === 'object' && 'id' in floor) {
        floor = floor['id'];
    }

    if (rooms && floor) {
        rooms.map(elem => {
            if(elem.floor['id'] === floor) {
                options.push({
                    value: elem['id'],
                    label: elem.name
                })
            }
        })
    }

    options.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });

    return options;
}

export const makeOptionsFromID = (data) => {
    return (data && data['hydra:member'])
        ? data['hydra:member'].map((elem) => {
                return {
                    value: elem.id,
                    label: elem.name,
                };
          })
        : [];
};