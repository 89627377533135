import { templateFetch } from '../..';
import API from '../../../utils/API';
import SearchForm from '../../../utils/SearchForm';

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get(`/college-years`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

const trainingProgramsService = {
    filter,
    fetch: templateFetch('college-years')
};

export default trainingProgramsService;