import { types } from "./types";

const initialState = {
    loading: false,
    form: {},
    data: [],
    autocompleteData: []
}

export function permissions(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.DEFAULT_FORM:
            return {
                ...initialState,
            }
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.AUTOCOMPLETE_FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.AUTOCOMPLETE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteData: action.data.data,
            };
        case types.AUTOCOMPLETE_FILTER_FAILURE:
            return {
                ...state,
                loading: false,
                autocompleteData: [],
                error: action.error,
            };
        case types.SAVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.SAVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}