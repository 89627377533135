import {types} from "./types";

const initialState = {
    form: {},
    preferableRoommates: [],
}

export function studentAllocationPreferenceForm(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                preferableRoommates: initialState.preferableRoommates,
                [action.name]: initialState[action.name]
            };
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.FILL_FORM_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.FILL_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.FILL_FORM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.GET_PREFERABLE_ROOMMATES_REQUEST:
            return {
                ...state,
            };
        case types.GET_PREFERABLE_ROOMMATES_SUCCESS:
            return {
                ...state,
                preferableRoommates: action.data.data,
            };
        case types.GET_PREFERABLE_ROOMMATES_FAILURE:
            return {
                ...state,
                preferableRoommates: initialState.preferableRoommmates,
                error: action.error,
            };
        case types.AUTOCOMPLETE_FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.AUTOCOMPLETE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteData: action.data.data,
            };
        case types.AUTOCOMPLETE_FILTER_FAILURE:
            return {
                ...state,
                loading: false,
                autocompleteData: [],
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}