import { templateChangeForm, templateDefaultForm, templateFilter, templateGet } from "../..";
import { types } from "./types";
import studentsService from "../../../services/masterData/students";

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, studentsService),
    get: templateGet(types, studentsService),
    setInfo
}

export default actions;