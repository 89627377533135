import { types } from "./types";

const initialState = {
    filter: {},
    form: {}
}

export function roomApproval(state = initialState, action) {
    switch(action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name] 
            };
        case types.ACCEPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.ACCEPT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.ACCEPT_AND_CLOSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_AND_CLOSE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.ACCEPT_AND_CLOSE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default: 
            return {
                ...state
            }
    }
}