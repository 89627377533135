export const defaultFieldsForEnrollmentForm = [
    { label: "Típus", value: "type", disabled: true },
    { label: "Vezetéknév", value: "lastName" },
    { label: "Keresztnév", value: "firstName" },
    { label: "Képzési szint", value: "trainingLevel" },
    { label: "Képzési program", value: "trainingProgram" },
    { label: "Évfolyam", value: "collegeYear" },
    { label: "Státusz", value: "membershipStatus" },
    { label: "Aktuális státusz", value: "currentMembershipStatus" },
    { label: "Szemeszter státusz", value: "semesterStatus" },
    { label: "Oktatási azonosító", value: "omId" },
    { label: "Adóazonosító jel", value: "taxId" },
    { label: "Személyi szám (CNP)", value: "identificationNumberCnp" },
    { label: "Campus", value: "campus" },
    { label: "Állandó lakcím", value: "address" },
    { label: "Anyja vezetékneve", value: "motherLastName" },
    { label: "Anyja keresztneve", value: "motherFirstName" },
    { label: "Születési hely", value: "birthPlace" },
    { label: "Születési idő", value: "birthDate" },
    { label: "Állampolgárság", value: "nationality"},
    { label: "SZIG szám", value: "idCardNumber" },
    { label: "SZIG szám - KEP", value: "kepIdCardNumber"},
    { label: "TAJ szám", value: "taj"},
    { label: "Pénzintézet neve", value: "bankName" },
    { label: "Bankszámlaszám", value: "bankAccountNumber" },
    { label: "Mobil", value: "phoneNumber" },
    { label: "E-mail", value: "email", disabled: true },
    {
        label: "Elfogadott dokumentumok",
        value: "acceptedDocuments",
        disabled: true,
    },
];

/**
 * A function that returns the fields for the forms based on the university type.
 * We start with the default fields.
 * If the university is foreign
 * (university?.id === 1326 || university?.name === 'Külföldi képzés) || university === 1326)
 * then we add the field { label: "Külföldi egyetem neve", value: "foreignUniversity" } to the fields
 * right after the field { label: "Egyetem", value: "university" }
 */
export const fieldsForForms = (isUniversityForeign, form) => {
    let fields = defaultFieldsForEnrollmentForm;

    fields = fields.filter(e => form.hasOwnProperty.call(form, e.value));

    /**
     * If the fields containt the field { label: "Külföldi egyetem neve", value: "foreignUniversity" }
     * already, we don't add it again
     */

    if (
        fields.findIndex((field) => field.value === "foreignUniversity") !== -1
    ) {
        return fields;
    }

    if (isUniversityForeign) {
        fields.splice(
            fields.findIndex((field) => field.value === "university") + 1,
            0,
            {
                label: "Külföldi egyetem neve",
                value: "foreignUniversity",
            }
        );
    }

    return fields;
};
